import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  user: {},
};

export const addUser = createAction('USER_ADD');

export default createReducer(INITIAL_STATE, {
  [addUser.type]: (state, action) => ({
    ...state,
    user: action.payload,
  }),
});

import { Drawer, List, Empty, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EmptyErrorWrapper, Tag, SoluterName } from '~/components';
import { colorBySeniority, textColorBySeniority } from '~/constants/indicators';
import { getSolutersBySeniorityList } from '~/store/fetchActions/fetchIndicators';

export default function SolutersBySeniorityDrawer({
  isDrawerVisible,
  setIsDrawerVisible,
  selectedCategory,
}) {
  const dispatch = useDispatch();
  const { solutersList, solutersListAmount, groupingMode } = useSelector(
    state => state.indicators.solutersBySeniority,
  );
  const [page, setPage] = useState(1);
  const { selectedTower, selectedPractice } = useSelector(
    state => state.indicators,
  );
  useEffect(() => {
    if (isDrawerVisible) setPage(1);
  }, [isDrawerVisible]);

  useEffect(() => {
    if (selectedCategory)
      dispatch(
        getSolutersBySeniorityList(
          groupingMode,
          selectedCategory,
          page,
          8,
          selectedTower,
          selectedPractice,
        ),
      );
  }, [
    dispatch,
    groupingMode,
    page,
    selectedCategory,
    selectedPractice,
    selectedTower,
  ]);

  return (
    <Drawer
      data-testid="soluters-by-seniority-drawer"
      title={selectedCategory}
      width="42vw"
      placement="right"
      closable={false}
      onClose={() => {
        setIsDrawerVisible(false);
      }}
      visible={isDrawerVisible}
    >
      <EmptyErrorWrapper
        data={solutersList}
        emptyCondition={solutersList}
        loaderTitle="isLoadingSolutersList"
        retryAction={getSolutersBySeniorityList}
        retryProps={[selectedCategory]}
        style={{ fontSize: 'x-small' }}
      >
        <List
          size="large"
          dataSource={solutersList}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Não há Soluters nesta categoria."
              />
            ),
          }}
          pagination={{
            current: page,
            pageSize: 8,
            pageSizeOptions: [],
            showSizeChanger: false,
            total: solutersListAmount,
            hideOnSinglePage: true,
            onChange: _page => {
              setPage(_page);
            },
          }}
          renderItem={soluter => (
            <List.Item>
              <Row
                style={{ width: '100%' }}
                justify="space-between"
                align="middle"
              >
                <Col span={18}>
                  <SoluterName name={soluter.name} email={soluter.email} />
                </Col>

                <Col span={6}>
                  <Tag
                    tag={{ tag: soluter.seniority }}
                    backgroundColor={colorBySeniority[soluter.seniority]}
                    textColor={textColorBySeniority[soluter.seniority]}
                  />
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </EmptyErrorWrapper>
    </Drawer>
  );
}

SolutersBySeniorityDrawer.propTypes = {
  isDrawerVisible: PropTypes.bool.isRequired,
  setIsDrawerVisible: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSoluterCostCenter } from '~/store/fetchActions/fetchPeople';
import { selectPeopleState } from '~/store/selectors';

export default function useSoluterCostCenter(email) {
  const {
    soluter: { costCenter, costCenterId, practice },
  } = useSelector(selectPeopleState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (email) dispatch(getSoluterCostCenter(email, true));
  }, [email, dispatch]);

  return { practice, costCenter, costCenterId };
}

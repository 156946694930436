/* eslint-disable no-console */
import _ from 'lodash';
import { decode } from 'jsonwebtoken';
import { signOut } from '~/store/fetchActions/fetchAuth';

const env = window._env_;

const { REACT_APP_GROUP_ADMIN, REACT_APP_GROUP_LEADER } = process.env;

export const not = obj => !obj;

export const isEmpty = obj => _.isEmpty(obj);

export const isNotEmpty = obj => !isEmpty(obj);

export const getEnviromentVariable = name => {
  return env[name];
};

export const isAuthenticated = () => {
  const auth = localStorage.getItem('AUTH');
  if (auth === 'true') {
    return true;
  }
  return false;
};

export const setIsAuthenticated = isAuth => {
  return localStorage.setItem('AUTH', isAuth);
};

export const setRole = role => {
  return localStorage.setItem('ROLE', role);
};

export const getRole = () => {
  if (getToken()) {
    const { roles } = decode(getToken());
    if (roles) {
      if (roles.includes(REACT_APP_GROUP_ADMIN)) return 'admin';
      if (roles.includes(REACT_APP_GROUP_LEADER)) return 'leader';
    }
  }
  return '';
};

export const getToken = () => {
  return localStorage.getItem('TOKEN');
};

export const setToken = accessToken => {
  return localStorage.setItem('TOKEN', accessToken);
};

export const setExpTime = accessToken => {
  if (accessToken) {
    const { exp } = decode(accessToken);
    return localStorage.setItem('EXP', exp);
  }
  return '';
};

export const getExpTime = () => {
  return localStorage.getItem('EXP');
};

export const clearExpTime = () => {
  return localStorage.setItem('EXP', '');
};

export const setUser = user => {
  return localStorage.setItem('USER', JSON.stringify(user));
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem('USER'));
};

export const hasTokenValid = () => {
  // TODO Validate Token
  return isNotEmpty(getToken());
};

export const verifyCredentialsAuthentication = isAuth => dispatch => {
  if (not(isAuth) && hasTokenValid()) {
    // dispatch({ type: Constants.LOGIN, payload: {} });
    window.history.replaceState('/login');
  } else if (not(hasTokenValid())) {
    dispatch(signOut());
  }
};

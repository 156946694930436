import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';

export default function Legend({
  color,
  text,
  size,
  clickable,
  handleClick,
  tooltip,
  round,
  customLegendStyle,
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        data-testid="legend-color"
        style={
          customLegendStyle || {
            width: size,
            height: size,
            backgroundColor: color,
            borderRadius: round ? '50%' : 2,
          }
        }
      />
      {clickable ? (
        <Tooltip title={tooltip}>
          <Button style={{ paddingLeft: 5 }} type="link" onClick={handleClick}>
            {text}
          </Button>
        </Tooltip>
      ) : (
        <p style={{ marginBottom: 0, marginLeft: 5 }}>{text}</p>
      )}
    </div>
  );
}

Legend.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.number,
  clickable: PropTypes.bool,
  handleClick: PropTypes.func,
  tooltip: PropTypes.string,
  round: PropTypes.bool,
  customLegendStyle: PropTypes.object,
};

Legend.defaultProps = {
  size: 12,
  clickable: false,
  handleClick: () => {},
  tooltip: '',
  round: false,
  customLegendStyle: null,
};

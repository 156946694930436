import { useParams } from 'react-router-dom';
import { DistrictDetails } from '~/container';
import { TitlePage } from '~/components';

export default function DistrictsDetails() {
  const { districtId } = useParams();

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TitlePage>Detalhes do Distrito</TitlePage>
      </div>
      <DistrictDetails districtId={districtId} />
    </>
  );
}

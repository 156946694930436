import { useState } from 'react';
import { Steps, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './styles.scss';

const { Step } = Steps;

export default function PaginatedSteps({
  items,
  itemsPerPage,
  titleAttribute,
  descriptionAttributes,
  formatDescription,
  subtitleAttribute,
  showSubtitle,
  isStepCurrentFn,
  formatSubtitle,
}) {
  const MAX_PAGES = Math.ceil(items?.length / itemsPerPage);
  const [page, setPage] = useState(1);

  const getPaginatedItems = (currentPage, itemsArray) => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx =
      currentPage * itemsPerPage <= itemsArray.length
        ? currentPage * itemsPerPage
        : itemsArray.length;
    return itemsArray.slice(startIdx, endIdx).reverse();
  };

  const shouldShowLeftArrow = (length, currentPage) => {
    return (
      length > itemsPerPage && (currentPage === 1 || currentPage !== MAX_PAGES)
    );
  };

  const shouldShowRightArrow = (length, currentPage) => {
    return (
      length > itemsPerPage && (currentPage === MAX_PAGES || currentPage !== 1)
    );
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {shouldShowLeftArrow(items?.length, page) && (
        <Button
          type="link"
          icon={<LeftOutlined />}
          onClick={() => setPage(currentPage => currentPage + 1)}
        />
      )}
      <div
        className={`soluter-profile__item custom-steps ${
          page !== 1 && page === MAX_PAGES ? 'custom-steps--float-right' : ''
        }`}
      >
        <Steps current={items?.length} progressDot>
          {getPaginatedItems(page, items).map((item, idx) => (
            <Step
              className={`custom-step ${
                isStepCurrentFn(item, page, itemsPerPage, items, idx)
                  ? 'custom-step--current-step'
                  : ''
              } `}
              title={item[titleAttribute]}
              subTitle={
                showSubtitle ? formatSubtitle(item[subtitleAttribute]) : ''
              }
              description={formatDescription(
                ...descriptionAttributes.map(attrName => item[attrName]),
              )}
            />
          ))}
        </Steps>
      </div>
      {shouldShowRightArrow(items?.length, page) && (
        <Button
          type="link"
          icon={<RightOutlined />}
          onClick={() => setPage(currentPage => currentPage - 1)}
        />
      )}
    </div>
  );
}

PaginatedSteps.propTypes = {
  items: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number,
  titleAttribute: PropTypes.string,
  descriptionAttributes: PropTypes.array,
  formatDescription: PropTypes.func,
  subtitleAttribute: PropTypes.string,
  showSubtitle: PropTypes.bool,
  isStepCurrentFn: PropTypes.func,
  formatSubtitle: PropTypes.func,
};

PaginatedSteps.defaultProps = {
  itemsPerPage: window.innerWidth > 1900 ? 5 : 4,
  titleAttribute: 'title',
  descriptionAttributes: ['description'],
  formatDescription: description => description,
  subtitleAttribute: 'subtitle',
  showSubtitle: false,
  isStepCurrentFn: (item, page, itemsPerPage, items, idx) => {
    return (
      page === 1 && (idx === itemsPerPage - 1 || idx === items?.length - 1)
    );
  },
  formatSubtitle: subtitle => subtitle,
};

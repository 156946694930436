import { Form, Radio, Row, Input } from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { SelectSkills, SelectRole, TagRender } from '~/components';

export default function TechnicalInfoForm({
  info,
  form,
  selectedTechnologies,
  selectedRole,
}) {
  const [isNoTechChecked, setIsNoTechChecked] = useState(false);

  useEffect(() => {
    if (selectedTechnologies && !isNoTechChecked) {
      form.setFieldsValue({
        infoTechnologies: selectedTechnologies,
      });
    }
  }, [form, isNoTechChecked, selectedTechnologies]);

  useEffect(() => {
    if (selectedRole) {
      form.setFieldsValue({
        primaryRole: selectedRole,
      });
    }
  }, [form, selectedRole]);

  useEffect(() => {
    if (info && info?.technologies[0] === 'Não se aplica') {
      setIsNoTechChecked(true);
    }
    if (info) {
      form.setFieldsValue({
        primaryRole: info.primaryRole,
        seniority: info.seniority,
        workload: info.workload,
        infoTechnologies: info.technologies,
      });
    }
  }, [form, info]);

  return (
    <>
      <Row align="middle">
        <SelectRole
          fieldLabel="Papel Principal"
          fieldName="primaryRole"
          required
          defaultValue={info?.primaryRole}
          defaultValueSoluter={null}
          style={{ width: '58%', marginRight: 20 }}
        />
        <Form.Item
          name="workload"
          type="number"
          required
          label="Carga de Trabalho (mensal)"
          rules={[
            {
              required: true,
              pattern: new RegExp('^([0-9]{2,3})([.][0-9]{1,2})?$'),
              message: 'Formato: xxx ou xxx.xx',
            },
          ]}
        >
          <Input
            value="a"
            style={{ width: '100%' }}
            placeholder="Ex.: 168.00"
            data-testid="technical-info-form-workload"
          />
        </Form.Item>
      </Row>
      <Form.Item
        name="seniority"
        required
        style={{ width: '56%%' }}
        label="Senioridade"
        rules={[{ type: 'string', required: true }]}
      >
        <Radio.Group>
          <Radio value="Trainee">Trainee</Radio>
          <Radio value="JR">JR</Radio>
          <Radio value="PL">PL</Radio>
          <Radio value="SR">SR</Radio>
          <Radio value="Não se aplica">Não se aplica</Radio>
        </Radio.Group>
      </Form.Item>
      <Row align="middle">
        <SelectSkills
          tagRenderFunc={props => TagRender(props)}
          selectMode="multiple"
          defaultValue={info?.technologies || undefined}
          defaultValueSoluter={info?.technologies || undefined}
          fieldName="infoTechnologies"
          fieldLabel="Tecnologias"
          style={{ width: '58%' }}
          disabled={isNoTechChecked}
          required={!isNoTechChecked}
        />
        <Checkbox
          style={{ marginLeft: 20 }}
          checked={isNoTechChecked}
          data-testid="technical-info-checkbox"
          onChange={({ target }) => {
            form.setFieldsValue({
              infoTechnologies: ['Não se aplica'],
            });
            setIsNoTechChecked(target.checked);
          }}
        >
          Não se aplica
        </Checkbox>
      </Row>
    </>
  );
}

TechnicalInfoForm.propTypes = {
  info: PropTypes.object,
  form: PropTypes.any,
  selectedTechnologies: PropTypes.array,
  selectedRole: PropTypes.string,
};

TechnicalInfoForm.defaultProps = {
  info: null,
  form: null,
  selectedTechnologies: [],
  selectedRole: '',
};

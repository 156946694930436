import { Typography, Row } from 'antd';

const { Title } = Typography;

export default function DashboardBI() {
  return (
    <>
      <Title>Dashboard(BI)</Title>
      <Row style={{ height: '100vh', background: '#FFF', borderRadius: 20 }}>
        <iframe
          title="biInfo"
          style={{
            display: 'flex',
            padding: 0,
            width: '100%',
            border: '1px solid #FFF',
            borderRadius: 20,
          }}
          src="https://app.powerbi.com/view?r=eyJrIjoiZDY1Y2MyMmQtOTkzZS00MzZjLWJhN2ItYjhmMGY4YTAyNmUyIiwidCI6ImM1ZGFkZTc4LTIzY2EtNGM0OC04NmM1LWFiOGE5Y2RmNmQ3OSJ9"
        />
      </Row>
    </>
  );
}

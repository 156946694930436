import neutral from '~/assets/images/neutral-face.png';
import smile from '~/assets/images/smiling-face-with-smiling-eyes.png';
import heartEyes from '~/assets/images/smiling-face-with-heart-eyes.png';
import unhappy from '~/assets/images/persevering-face.png';
import sad from '~/assets/images/frowning-face.png';

export const iconsByMood = {
  5: heartEyes,
  4: smile,
  3: neutral,
  2: unhappy,
  1: sad,
};

export const altTextByMood = {
  5: 'Emoji com corações nos olhos',
  4: 'Emoji sorrindo',
  3: 'Emoji neutro',
  2: 'Emoji com expressão incomodada',
  1: 'Emoji triste',
};

export const descriptionByMood = {
  5: 'Sorridente',
  4: 'Feliz',
  3: 'Neutro',
  2: 'Insatisfeito',
  1: 'Triste',
};

const APPROVED_TAGS_QUESTION = 'O que você mais gostou?';
const NOT_APPROVED_TAGS_QUESTION = 'O que poderia ter sido melhor?';
const APPROVED_COLOR_LIGHT = '#66c6fb';
const NOT_APPROVED_COLOR_LIGHT = '#f989ab';
export const APPROVED_COLOR_DARK = '#12A6F9';
export const NOT_APPROVED_COLOR_DARK = '#F6457B';

const MOOD_COLOR_OBJ = {
  1: '#FB2537',
  2: '#FC5D35',
  3: '#FDC92F',
  4: '#ABD44B',
  5: '#00C94A',
};
const MOOD_COLOR_LIST = ['#00C94A', '#ABD44B', '#FDC92F', '#FC5D35', '#FB2537'];

export default {
  MOOD_COLOR_LIST,
  MOOD_COLOR_OBJ,
  APPROVED_TAGS_QUESTION,
  NOT_APPROVED_TAGS_QUESTION,
  APPROVED_COLOR_DARK,
  NOT_APPROVED_COLOR_DARK,
  APPROVED_COLOR_LIGHT,
  NOT_APPROVED_COLOR_LIGHT,
};

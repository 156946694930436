import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';

import ManageCompetenceForm from './ManageCompetenceForm';
import {
  deleteSoluterCompetenceFile,
  editSoluterCompetenceFile,
  editSoluterCompetence,
  addSoluterCompetence,
} from '~/store/fetchActions/fetchPeople';
import { validationMessages } from '~/constants';

export default function AddEditCompetenceModal({
  competence,
  editing,
  visible,
  handleClose,
  handleSubmit,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitCompetence = useCallback(
    ({ date, expirationDate, files, ...values }, shouldClose = true) => {
      const validado = values.valid == null ? false : values.valid;
      setIsLoading(true);
      return new Promise(resolve => {
        const competenceValues = {
          ...values,
          date: date.format('DD-MM-YYYY'),
          expirationDate: expirationDate?.format('DD-MM-YYYY'),
          type: 'CERTIFICATION',
          status: 'FINISHED',
          valid: validado,
        };

        if (editing) {
          const { id } = competence;
          editSoluterCompetence(id, competenceValues).then(() => {
            message.success(validationMessages.HTTP_EDIT_SUCCESS_MESSAGE);
            return resolve(id);
          });
        } else {
          addSoluterCompetence(competenceValues).then(({ data: { id } }) => {
            message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
            return resolve(id);
          });
        }
      })
        .then(id => {
          if (files.length) {
            const [file] = files;
            if (!file.isServerSide) {
              return editSoluterCompetenceFile(id, file.originFileObj);
            }
          } else if (competence?.filename) {
            return deleteSoluterCompetenceFile(id);
          }
          return false;
        })
        .then(() => {
          if (shouldClose) handleClose(true);
          if (handleSubmit) handleSubmit();
        })
        .finally(() => setIsLoading(false));
    },
    [competence, editing, handleClose, handleSubmit],
  );

  return (
    <Modal
      title={`${editing ? 'Editar' : 'Adicionar'} Certificação`}
      visible={visible}
      onCancel={handleClose}
      data-testid="add-competence-modal"
      cancelText="Cancelar"
      okText={editing ? 'Salvar' : 'Adicionar'}
      okButtonProps={{
        form: 'add-competence-form',
        htmlType: 'submit',
        icon: editing ? <CheckOutlined /> : <PlusOutlined />,
      }}
      key={`${editing ? 'edit' : 'add'}-competence-modal`}
      confirmLoading={isLoading}
    >
      <ManageCompetenceForm
        competence={competence}
        formId="add-competence-form"
        handleSubmit={handleSubmitCompetence}
        editing={editing}
      />
    </Modal>
  );
}

AddEditCompetenceModal.propTypes = {
  competence: PropTypes.objectOf(PropTypes.any),
  editing: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
};

AddEditCompetenceModal.defaultProps = {
  competence: undefined,
  editing: false,
  handleSubmit: () => {},
};

import { Card, Row, Col } from 'antd';
import Pie from '@ant-design/charts/lib/pie';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyErrorWrapper, DownloadButton } from '~/components';
import * as fetchIndicators from '~/store/fetchActions/fetchIndicators';
import { clearPassportUsageCsvData } from '~/store/ducks/indicators/index';
import { formatDate } from '~/helpers/daysOfWeek';
import { colorBySeniority } from '~/constants/indicators';

export default function GraphPassaportUsage() {
  const dispatch = useDispatch();
  const { passportUsage } = useSelector(state => state.indicators);
  const { selectedCostCenter, selectedPractice } = useSelector(
    state => state.indicators,
  );

  useEffect(() => {
    dispatch(
      fetchIndicators.getPassportUsage(
        selectedCostCenter.toString(),
        selectedPractice,
      ),
    );
  }, [dispatch, selectedCostCenter, selectedPractice]);

  const handleCSVDownload = () => {
    dispatch(
      fetchIndicators.getPassportUsageCsv(
        selectedCostCenter.toString(),
        selectedPractice,
      ),
    );
  };

  const config = {
    appendPadding: 10,
    data: passportUsage?.data || [],
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'element-active' }],
    color: ({ type }) => {
      return type === 'Sem interação'
        ? colorBySeniority['Não se aplica']
        : colorBySeniority.PL;
    },
  };

  return (
    <>
      <Card className="graph-card">
        <Row justify="end">
          <DownloadButton
            exportCsvLayout
            label="Exportar CSV"
            fileName={`relatorio_uso_passaporte_${selectedCostCenter}_${formatDate(
              new Date(),
            )}.csv`}
            data={passportUsage?.csvData || null}
            requestData={handleCSVDownload}
            clearData={clearPassportUsageCsvData}
          />
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col span={20}>
            <EmptyErrorWrapper
              data={passportUsage?.data}
              emptyCondition={passportUsage?.data?.length > 0}
              loaderTitle="isLoadingGraph"
              retryAction={fetchIndicators.getPassportUsage}
              retryProps={[selectedCostCenter]}
              style={{ height: '50vh', width: '100%' }}
            >
              <Pie {...config} />
            </EmptyErrorWrapper>
          </Col>
        </Row>
      </Card>
    </>
  );
}

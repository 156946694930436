import { message } from 'antd';
import { endLoading, startLoading } from '../ducks/loading';
import {
  setFamilies,
  deleteFamily,
  addFamily,
  addRoleFamily,
  updateFamily,
  updateRoleFamily,
  deleteRoleFamily,
  setFamiliesById,
} from '../ducks/families';
import api from '~/config/httpRequest';
import { validationMessages } from '~/constants';
import { setCurrentBusinessUnit } from '../ducks/journey';
import { getBusinessUnitById } from './fetchJourney';

export const getFamilies = () => dispatch => {
  dispatch(startLoading());

  api
    .get('/soluters/journey/families')
    .then(({ data }) => {
      dispatch(setFamilies(data));
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(setFamilies([]));
      dispatch(endLoading());
    });
};

export const getFamiliesById = id => dispatch => {
  dispatch(startLoading());

  api
    .get(`/soluters/journey/business/families/${id}`)
    .then(({ data }) => {
      dispatch(setFamiliesById(data));
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(setFamiliesById([]));
      dispatch(endLoading());
    });
};

export const setFamilySkills = (familyId, values) => dispatch => {
  dispatch(startLoading());
  api.put(`soluters/journey/families/${familyId}/skills`, values).then(() => {
    dispatch(getFamilies());
  });
};

export const addFamilies = (name, skills, businessId, family, hierarchy) => {
  return dispatch => {
    dispatch(startLoading('loadingAddFamily'));
    api
      .post(`soluters/journey/business/${businessId}/family`, {
        name,
        skills,
        hierarchy,
      })
      .then(() => {
        dispatch(addFamily({ name, skills, hierarchy, businessId }));
        message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
        dispatch(endLoading('loadingAddFamily'));
        dispatch(getBusinessUnitById(businessId));
      })
      .catch(e => {
        dispatch(endLoading('loadingAddFamily'));
        dispatch(setCurrentBusinessUnit(family));
      });
  };
};

export const updateFamilies = (
  name,
  skills,
  roles,
  businessId,
  familyId,
  hierarchy,
) => {
  return dispatch => {
    dispatch(startLoading('loadingUpdateFamily'));
    api
      .put(`soluters/journey/business/families/${familyId}`, {
        name,
        skills,
        roles: roles || [],
        hierarchy,
      })
      .then(() => {
        dispatch(updateFamily({ name, skills, roles, hierarchy }));
        message.success(validationMessages.HTTP_UPDATE_SUCCESS_MESSAGE);
        dispatch(endLoading('loadingUpdateFamily'));
        dispatch(getBusinessUnitById(businessId));
      })
      .catch(e => {
        dispatch(endLoading('loadingUpdateFamily'));
      });
  };
};

export const deleteFamilies = (familyId, businessId) => {
  return dispatch => {
    dispatch(startLoading('loadingDeleteFamily'));
    api
      .delete(`soluters/journey/business/families/${familyId}`)
      .then(() => {
        dispatch(deleteFamily({ familyId }));
        message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
        dispatch(endLoading('loadingDeleteFamily'));
        dispatch(getBusinessUnitById(businessId));
      })
      .catch(e => {
        dispatch(endLoading('loadingDeleteFamily'));
      });
  };
};

export const addSubFamilies = (name, skills, familyId, businessId) => {
  return dispatch => {
    dispatch(startLoading('loadingAddSubFamily'));
    api
      .post(`soluters/journey/business/${familyId}/family`, {
        name,
        skills,
      })
      .then(() => {
        dispatch(addFamily({ name, skills, familyId }));
        message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
        dispatch(endLoading('loadingAddSubFamily'));
        dispatch(getBusinessUnitById(businessId));
      })
      .catch(e => {
        dispatch(endLoading('loadingAddSubFamily'));
      });
  };
};

export const addRoleFamilies = (name, familyId, businessId, hierarchy) => {
  return dispatch => {
    dispatch(startLoading('loadingAddRoleFamily'));
    api
      .post(`soluters/journey/business/families/${familyId}/role`, {
        name,
        hierarchy,
      })
      .then(() => {
        dispatch(addRoleFamily({ name, hierarchy }));
        message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
        dispatch(endLoading('loadingAddRoleFamily'));
        dispatch(getBusinessUnitById(businessId));
      })
      .catch(e => {
        dispatch(endLoading('loadingAddRoleFamily'));
      });
  };
};

export const updateRoleFamilies = (name, roleId, businessId, hierarchy) => {
  return dispatch => {
    dispatch(startLoading('loadingUpdateRoleFamily'));
    api
      .put(`soluters/journey/business/roles/${roleId}`, {
        name,
        hierarchy,
      })
      .then(() => {
        dispatch(updateRoleFamily({ name, hierarchy }));
        message.success(validationMessages.HTTP_UPDATE_SUCCESS_MESSAGE);
        dispatch(endLoading('loadingUpdateRoleFamily'));
        dispatch(getBusinessUnitById(businessId));
      })
      .catch(e => {
        dispatch(endLoading('loadingUpdateRoleFamily'));
      });
  };
};

export const deleteRoleFamilies = (roleId, businessId) => {
  return dispatch => {
    dispatch(startLoading('loadingDeleteRoleFamily'));
    api
      .delete(`soluters/journey/business/roles/${roleId}`)
      .then(() => {
        dispatch(deleteRoleFamily({ roleId }));
        message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
        dispatch(endLoading('loadingDeleteRoleFamily'));
        dispatch(getBusinessUnitById(businessId));
      })
      .catch(e => {
        dispatch(endLoading('loadingDeleteRoleFamily'));
      });
  };
};

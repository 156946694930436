import { Typography, List, Row, Input } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BankOutlined } from '@ant-design/icons';
import { getRole } from '~/config/environment';

import { EmptyErrorWrapper } from '~/components';
import AddBadgeForm from '~/container/Badges/Register/AddBadgeForm';
import CustomCardBadge from '~/components/CustomCardBadge/CustomCardBadge';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import * as fetchBadges from '~/store/fetchActions/fetchBadges';
import { APP_NAME } from '~/constants/app';

const { Title } = Typography;
const { Search } = Input;

export default function Badges() {
  const dispatch = useDispatch();
  const { badges } = useSelector(state => state.badges);
  const { isLoadingTowers, isLoadingTowerPhoto } = useSelector(
    state => state.loading,
  );
  const [allGroups, setallGroups] = useState(true);
  const [groupName, setGroupName] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    dispatch(fetchBadges.listBadges(groupName));
  }, [dispatch, groupName, allGroups]);

  const handleSearch = value => setGroupName(value);

  const handleDeleteBadge = id => {
    dispatch(fetchBadges.deleteBadgeById(id));
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Title>Registro de Badges</Title>
        <Row justify="space-between" align="bottom">
          <span />
          <div>
            <span>
              <Search
                ref={searchInput}
                placeholder="Nome da Badge"
                style={{
                  width: 320,
                  marginRight: 10,
                }}
                onSearch={handleSearch}
                enterButton
                allowClear
                data-testid="search-badge-name"
              />
            </span>
            <AddBadgeForm
              disabled={getRole() !== 'admin'}
              allGroups={allGroups}
            />
          </div>
        </Row>
      </div>
      <EmptyErrorWrapper
        data={badges}
        emptyCondition={badges?.length > 0}
        emptyMessage="Não há badges registradas."
        retryAction={fetchTowers.listTowers}
        retryProps={[true]}
        loaderTitle="isLoadingTowers"
      >
        <List
          grid={{
            column: 4,
            sm: 1,
            md: 2,
          }}
          pagination={{
            position: 'bottom',
            pageSize: 8,
            hideOnSinglePage: true,
          }}
          dataSource={badges}
          loading={isLoadingTowers || isLoadingTowerPhoto}
          renderItem={badge => (
            <List.Item>
              <CustomCardBadge
                prefix="badges"
                item={badge}
                backgroundColor={badge?.color}
                imgBackup={<BankOutlined />}
                disabledDelete={
                  getRole() !== 'admin' || badge?.totalMembers > 0
                }
                disabledEdit={getRole() !== 'admin'}
                circleImage
                handleDelete={() => {
                  handleDeleteBadge(badge.id);
                }}
              />
            </List.Item>
          )}
        />
      </EmptyErrorWrapper>
    </>
  );
}

import { Empty, Spin } from 'antd';
import PropTypes from 'prop-types';
import Bar from '@ant-design/charts/lib/bar';
import React from 'react';
import { colorBySeniority } from '~/constants/indicators';

function SolutersBySkillsGraph({
  skillsTags,
  setDrawerVisible,
  setDrawerSkill,
  isLoadingSolutersBySkills,
  skillsSearch,
}) {
  const countTotalSolutersByCategory = category => {
    const arrayToSum = skillsSearch?.filter(_item => _item.label === category);
    const total = arrayToSum?.reduce(
      (accumulator, currentItem) => accumulator + currentItem.value,
      0,
    );
    return total;
  };

  const config = {
    data: skillsSearch || [],
    isStack: true,
    xField: 'value',
    yField: 'label',
    seriesField: 'type',
    dodgePadding: 4,
    legend: {
      layout: 'horizontal',
      position: 'top',
    },
    label: {
      position: 'right',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
      formatter: function formatter({ value }) {
        return value || '';
      },
    },
    barStyle: {
      cursor: 'pointer',
    },
    tooltip: {
      formatter: item => {
        return {
          title: `Total: ${countTotalSolutersByCategory(item.label)}`,
          name: item.type,
          value: item.value,
        };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(text, item, index) {
          return text;
        },
      },
    },
    xAxis: {
      grid: null,
      label: null,
    },
    color: ({ type }) => {
      return type ? colorBySeniority[type] : 'grey';
    },
  };

  return (
    <Spin spinning={isLoadingSolutersBySkills}>
      {skillsTags.length > 0 ? (
        <Bar
          {...config}
          onEvent={(chart, event) => {
            if (event.type === 'click' && event.data) {
              setDrawerVisible(true);
              setDrawerSkill(event.data.data.label);
            }
          }}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Nenhuma tag selecionada"
        />
      )}
    </Spin>
  );
}

SolutersBySkillsGraph.propTypes = {
  skillsTags: PropTypes.array.isRequired,
  setDrawerVisible: PropTypes.func.isRequired,
  setDrawerSkill: PropTypes.func.isRequired,
  isLoadingSolutersBySkills: PropTypes.bool.isRequired,
  skillsSearch: PropTypes.array.isRequired,
};

export default React.memo(SolutersBySkillsGraph);

/* eslint-disable no-nested-ternary */
import { Card, Avatar, Typography, Tabs, Skeleton, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const { Meta } = Card;
const { Title } = Typography;
const { TabPane } = Tabs;

export default function DetailsCard({
  image,
  isAvatar,
  icon,
  title,
  titleSize,
  thumbLoading,
  description,
  tabs,
  actions,
}) {
  return (
    <Card className="details-card">
      <Meta
        className="details-card__meta"
        avatar={
          thumbLoading ? (
            <Skeleton.Avatar
              loading={thumbLoading}
              avatar
              active={thumbLoading}
              size={135}
            />
          ) : isAvatar ? (
            <Avatar
              className="tower__avatar"
              shape={isAvatar}
              src={image}
              icon={icon}
              size={135}
            />
          ) : (
            <img src={image} alt="imagem" className="details-card__img" />
          )
        }
        title={
          <Row className="details-card__title">
            <Col span={15}>
              <Title
                level={title?.length >= 34 ? 3 : titleSize}
                style={{ marginBottom: description ? 0 : '0.1em' }}
              >
                {title}
              </Title>
            </Col>
            <Col>{actions}</Col>
          </Row>
        }
        description={description}
      />

      <Tabs defaultActiveKey="1" className="details-card__tabs">
        {tabs?.map((tab, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <TabPane tab={tab.title} key={idx + 1}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </Card>
  );
}

DetailsCard.propTypes = {
  image: PropTypes.string,
  isAvatar: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.number,
  thumbLoading: PropTypes.bool,
  description: PropTypes.any,
  tabs: PropTypes.array.isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.element,
  ]),
};

DetailsCard.defaultProps = {
  image: '',
  isAvatar: true,
  titleSize: 1,
  thumbLoading: false,
  description: '',
  actions: [],
};

import { StarOutlined, IdcardOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Space, Skeleton, Row } from 'antd';
import PropTypes from 'prop-types';
import { Tag } from '~/components';

const { Title } = Typography;

export default function TowerGeneralInfo({ tower }) {
  const { isLoadingTower } = useSelector(state => state.loading);

  return (
    <>
      <Title level={3}>Client Executive</Title>
      <Skeleton paragraph={{ rows: 0 }} loading={isLoadingTower}>
        {tower?.CE?.email ? (
          <Link to={`/soluters/${tower?.CE.email}`}>{tower?.CE.name}</Link>
        ) : (
          <p className="soluter-profile__missing-info">
            <IdcardOutlined /> Esta torre ainda não possui CE
          </p>
        )}
      </Skeleton>
      <Title level={3} className="mt-md">
        Delivery Manager
      </Title>
      <Skeleton paragraph={{ rows: 0 }} loading={isLoadingTower}>
        {tower?.DM?.email ? (
          <Link to={`/soluters/${tower?.DM.email}`}>{tower?.DM.name}</Link>
        ) : (
          <p className="soluter-profile__missing-info">
            <IdcardOutlined /> Esta torre ainda não possui DM
          </p>
        )}
      </Skeleton>
      <Title level={3} className="mt-md">
        Descrição
      </Title>
      <Skeleton paragraph={{ rows: 0 }} loading={isLoadingTower}>
        {tower?.description ? (
          <p>{tower?.description}</p>
        ) : (
          <p className="soluter-profile__missing-info">
            Esta torre ainda não possui uma descrição
          </p>
        )}
      </Skeleton>
      <Title level={3}>Tecnologias</Title>
      <Row>
        <Skeleton paragraph={{ rows: 0 }} loading={isLoadingTower}>
          <Space size={[8, 16]} wrap>
            {tower?.skills?.length > 0 ? (
              tower?.skills.map(skill => (
                <Tag
                  tag={{ tag: skill }}
                  backgroundColor={tower?.color}
                  textColor="white"
                  key={skill}
                />
              ))
            ) : (
              <p className="soluter-profile__missing-info">
                Nenhuma tecnologia registrada (ainda).
              </p>
            )}
          </Space>
        </Skeleton>
      </Row>
      <Title level={3} className="mt-md">
        Vertical
      </Title>
      <Skeleton paragraph={{ rows: 0 }} loading={isLoadingTower}>
        {tower?.vertical ? (
          <p>{tower?.vertical}</p>
        ) : (
          <p className="soluter-profile__missing-info">
            Esta torre ainda não possui uma vertical
          </p>
        )}
      </Skeleton>
      <Title level={3}>Business Units</Title>
      <Row>
        <Skeleton paragraph={{ rows: 0 }} loading={isLoadingTower}>
          <Space size={[8, 16]} wrap>
            {tower?.BUs?.length > 0 ? (
              tower?.BUs.map(bu => (
                <Tag
                  tag={{ tag: bu.name }}
                  backgroundColor={tower?.color}
                  textColor="white"
                  key={bu.name}
                />
              ))
            ) : (
              <p className="soluter-profile__missing-info">
                Nenhuma business unit registrada (ainda).
              </p>
            )}
          </Space>
        </Skeleton>
      </Row>
    </>
  );
}

TowerGeneralInfo.propTypes = {
  tower: PropTypes.object.isRequired,
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Tabs } from 'antd';
import {
  SolutersBySeniorityContainer,
  AllocationIndexContainer,
  IndicatorsHeader,
} from '~/container';
import {
  setSelectedPractice,
  setSolutersBySeniorityAllocated,
} from '~/store/ducks/indicators';

const { Title } = Typography;
const { TabPane } = Tabs;

export default function Indicators() {
  useEffect(() => {
    return () => {
      setSelectedPractice('');
    };
  }, []);
  const dispatch = useDispatch();
  return (
    <>
      <Title>Indicadores de Torres</Title>
      <IndicatorsHeader />
      <div className="card-container">
        <Tabs
          className="graph-card"
          onChange={activeKey => {
            if (activeKey !== '0')
              dispatch(setSolutersBySeniorityAllocated(false));
          }}
        >
          <TabPane tab="Soluters por Senioridade" key={0}>
            <SolutersBySeniorityContainer />
          </TabPane>
          <TabPane tab="Índice de Alocação" key={1}>
            <AllocationIndexContainer />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

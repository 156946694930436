import { Form, Select, Spin } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import handleSearch from '~/helpers/handleSearch';
import {
  getPeopleTower,
  getDismissedSoluters,
} from '~/store/fetchActions/fetchTowers';
import { getByTitle, get } from '~/store/fetchActions/fetchSkills';

const { Option } = Select;

export default function SelectSearchPeoples({
  defaultValue,
  value,
  onChange,
  customStyle,
  testId,
  disabled,
  searchDismissed,
  shouldReturnLabelInValue,
  shouldReturnSoluterInfo,

  selectMode,
  fieldName,
  tagRenderFunc,
  fieldLabel,
  required,
  style,
  placeholder,
}) {
  const { people } = useSelector(state => state.towers);
  const { isLoadingPeople } = useSelector(state => state.loading);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const dispatch = useDispatch();

  return (
    <Form.Item
      name={fieldName}
      style={style}
      required={required}
      rules={
        required
          ? [
              {
                type: selectMode === 'multiple' ? 'array' : 'string',
                required,
                max: 30,
                message: 'É necessário ao menos um Soluter',
                whitespace: true,
              },
            ]
          : []
      }
      key="select-skills-field"
    >
      <Select
        disabled={disabled}
        showSearch
        allowClear
        defaultValue={defaultValue}
        mode={selectMode}
        tagRender={tagRenderFunc}
        data-testid={testId}
        placeholder={placeholder}
        loading={isLoadingPeople}
        onSearch={_value =>
          handleSearch(
            _value,
            getPeopleTower,
            searchTimeout,
            setSearchTimeout,
            dispatch,
          )
        }
        notFoundContent={isLoadingPeople ? <Spin size="small" /> : null}
      >
        {people?.map((person, idx) => (
          <Option
            value={person.email}
            key={person.email}
            data-testid={`select-soluter-badge-${idx}`}
          >
            {person.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

SelectSearchPeoples.propTypes = {
  onChange: PropTypes.func,
  customStyle: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  searchDismissed: PropTypes.bool,
  shouldReturnLabelInValue: PropTypes.bool,
  shouldReturnSoluterInfo: PropTypes.bool,

  selectMode: PropTypes.string,
  defaultValue: PropTypes.array,
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  tagRenderFunc: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
};

SelectSearchPeoples.defaultProps = {
  defaultValue: null,
  value: '',
  customStyle: { minWidth: 200 },
  testId: 'select-search-people',
  disabled: false,
  searchDismissed: false,
  shouldReturnLabelInValue: false,
  shouldReturnSoluterInfo: false,
  onChange: () => {
    /* skip */
  },
  selectMode: null,
  fieldName: 'soluters',
  fieldLabel: 'Solutes',
  placeholder: 'Buscar Soluter',
  tagRenderFunc: () => {
    /* skip */
  },
  style: { width: '100%' },
  required: true,
};

import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  practices: [],
  costCenters: [],
  costCentersNoBu: [],
  costCentersNoGroup: [],
};

export const setPractices = createAction('SET_PRACTICES');
export const setCostCenters = createAction('SET_COST_CENTERS');
export const setCostCentersNoBu = createAction('SET_COST_CENTERS_NO_BU');
export const setCostCentersNoGroup = createAction('SET_COST_CENTERS_NO_GROUP');

export default createReducer(INITIAL_STATE, {
  [setPractices.type]: (state, action) => ({
    ...state,
    practices: action.payload,
  }),
  [setCostCenters.type]: (state, action) => ({
    ...state,
    costCenters: action.payload || [],
  }),
  [setCostCentersNoBu.type]: (state, action) => ({
    ...state,
    costCentersNoBu: action.payload,
  }),
  [setCostCentersNoGroup.type]: (state, action) => ({
    ...state,
    costCentersNoGroup: action.payload,
  }),
});

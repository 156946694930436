import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import PropTypes from 'prop-types';

export default function IndexInfo({ allocationIndexGraph }) {
  const indexFormula = () => {
    let content = <></>;

    if (allocationIndexGraph?.data?.length > 0) {
      const subAllocated = allocationIndexGraph?.data[0].value;
      const wellAllocated = allocationIndexGraph?.data[1].value;
      const superAllocated = allocationIndexGraph?.data[2].value;
      const totalPeople = allocationIndexGraph?.data.reduce(
        (acc, currentValue) => acc + currentValue.value,
        0,
      );

      content = (
        <>
          <p style={{ marginBottom: 2 }}>Total de pessoas = {totalPeople}</p>
          <p style={{ marginBottom: 2 }}>
            Subalocados = {subAllocated} (Peso: 0,5){' '}
          </p>
          <p style={{ marginBottom: 2 }}>
            Alocados = {wellAllocated} (Peso: 1)
          </p>
          <p style={{ marginBottom: 14 }}>
            Superalocados = {superAllocated} (Peso: 1,5){' '}
          </p>

          <p style={{ marginBottom: 2 }}>
            Resultado: ({subAllocated} * 0,5 + {wellAllocated} * 1 +{' '}
            {superAllocated} * 1,5) / {totalPeople} ={' '}
            {allocationIndexGraph?.index}
          </p>
        </>
      );
    }

    return content;
  };
  return (
    <>
      {allocationIndexGraph?.index}
      <Popover
        content={indexFormula}
        title="Fórmula de Cálculo do Índice"
        placement="right"
      >
        <InfoCircleOutlined
          data-testid="info-circle"
          className="hoverable"
          style={{ color: '#1890ff', fontSize: 14, marginLeft: 5 }}
        />
      </Popover>
    </>
  );
}

IndexInfo.propTypes = {
  allocationIndexGraph: PropTypes.object.isRequired,
};

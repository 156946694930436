export function handleCurrencyInput(input, previousValue) {
  const values = previousValue.split('');
  const dotIndex = values.findIndex(el => el === '.');

  if (input !== 'Backspace') {
    values.push(input);
    const numAfterDot = values[dotIndex + 1];
    values[dotIndex] = numAfterDot;
    values[dotIndex + 1] = '.';
    if (values[0] === '0') values.shift();
  } else {
    const numBeforeDot = values[dotIndex - 1];
    values.pop();
    values[dotIndex] = numBeforeDot;
    values[dotIndex - 1] = '.';
    if (values[0] === '.') values.unshift('0');
  }
  return values.join('');
}

export function formatCurrency(value) {
  value = value?.toString();
  if (!value) return '0.00';
  if (!value.includes('.')) {
    return `${value}.00`;
  }
  if (value.indexOf('.') === value.length - 2) {
    return `${value}0`;
  }
  return value;
}

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row, Space, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import BusinessUnitFamiliesTable from './BusinessUnitFamiliesTable';
import './styles.scss';
import EditBusinessUnitFamily from './EditBusinessUnitFamily';
import { selectJourneyState } from '~/store/selectors';
import { getBusinessUnitById } from '~/store/fetchActions/fetchJourney';

const { Title } = Typography;

export default function ManageBusinessUnit() {
  const [isEditFamilyModalVisible, setEditFamilyModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { buId } = useParams();
  const { currentBusinessUnit } = useSelector(selectJourneyState);

  useEffect(() => {
    dispatch(getBusinessUnitById(buId));
  }, [dispatch, buId]);

  const handleCloseEditFamilyModal = useCallback(() => {
    setEditFamilyModalVisible(false);
    dispatch(getBusinessUnitById(buId));
  }, [dispatch, buId]);

  return (
    <>
      <Row justify="space-between" align="middle">
        <div
          data-testid="manage-business-unit"
          className="manage-business-unit-wrapper"
        >
          <Space>
            <Title data-testid="business-unit-name">
              <Link to="/journey">
                <Button
                  data-testid="go-back-business-unit"
                  type="link"
                  color="black"
                  shape="round"
                  icon={
                    <LeftOutlined
                      style={{ fontSize: '25px', color: 'black' }}
                    />
                  }
                />
              </Link>

              {currentBusinessUnit?.name}
            </Title>
            <Title
              data-testid="business-unit-initials"
              level={3}
              type="secondary"
            >
              {currentBusinessUnit?.initials}
            </Title>
          </Space>
        </div>

        <Button
          type="primary"
          data-testid="create-soluter"
          onClick={() => {
            setEditFamilyModalVisible(true);
          }}
        >
          Cadastrar Família
        </Button>
        <EditBusinessUnitFamily
          isVisible={isEditFamilyModalVisible}
          setVisible={handleCloseEditFamilyModal}
          businessUnitId={buId}
          family={currentBusinessUnit}
        />
      </Row>
      <BusinessUnitFamiliesTable
        families={currentBusinessUnit?.families}
        buId={buId}
      />
    </>
  );
}

ManageBusinessUnit.propTypes = {};

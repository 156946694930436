import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import * as fetchTowers from '~/store/fetchActions/fetchTowers';

const { Option } = Select;

export default function ControlledSelectTowers({ tower, onChangeTower }) {
  const { isLoadingTowers } = useSelector(state => state.loading);
  const { towers } = useSelector(state => state.towers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTowers.listTowers());
  }, [dispatch]);

  return (
    <Select
      placeholder="Selecione uma Torre"
      data-testid="tower-select"
      style={{ minWidth: 180, height: 'fit-content' }}
      loading={isLoadingTowers}
      onChange={onChangeTower}
      value={tower || undefined}
      allowClear
    >
      {towers?.map(({ _id, name }) => (
        <Option key={_id} value={_id}>
          {name}
        </Option>
      ))}
    </Select>
  );
}

ControlledSelectTowers.propTypes = {
  onChangeTower: PropTypes.func.isRequired,
  tower: PropTypes.string,
};

ControlledSelectTowers.defaultProps = {
  tower: undefined,
};

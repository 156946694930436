import { getCompetenceFile } from '~/store/fetchActions/fetchCompetences';
import { downloadFile } from '~/helpers/download';

export const getCompetenceFileUrl = competenceId =>
  `${process.env.REACT_APP_API_BASE_URL}/soluters/competence/${competenceId}/file`;

export const downloadCompetenceFile = (filename, competenceId) => {
  getCompetenceFile(competenceId).then(({ data }) => {
    downloadFile(filename, data);
  });
};

import { Collapse, Typography } from 'antd';
import React from 'react';
import './styles.scss';

const { Title } = Typography;
const { Panel } = Collapse;

export default function UsefulLinks() {
  return (
    <>
      <Title>Links Úteis</Title>
      <Typography style={{ marginBottom: '1rem' }}>
        Coleção de links úteis relacionados ao acesso e manutenção do
        backoffice:
      </Typography>
      <Collapse className="useful-links" defaultActiveKey={['1']}>
        <Panel header="Acesso ao backoffice" key="1">
          <Typography>
            Inclusão de novos líderes via Azure:
            <a
              href="https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/8a25e162-867b-4d65-8fdc-fbe1b742a68b"
              target="_blank"
              rel="noreferrer"
            >
              LINK
            </a>
          </Typography>
          <Typography>
            Inclusão de novos administradores via Azure:
            <a
              href="https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Members/groupId/ec74fb55-3d0b-4480-9d12-e206cba2cc35"
              target="_blank"
              rel="noreferrer"
            >
              LINK
            </a>
          </Typography>
        </Panel>
      </Collapse>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { DrawerList, SoluterName } from '~/components';
import { periodTitles } from '~/constants/indicators';
import { getAdmittedUsersData } from '~/store/fetchActions/fetchIndicators';

export default function AdmissionDrawer({
  isDrawerVisible,
  setDrawerVisible,
  period,
}) {
  const dispatch = useDispatch();
  const { admissions } = useSelector(state => state.indicators);
  const { isLoadingAdmittedUsers } = useSelector(state => state.loading);

  useEffect(() => {
    if (
      isDrawerVisible &&
      admissions?.[period].users &&
      typeof admissions?.[period]?.users[0] === 'string'
    )
      dispatch(getAdmittedUsersData(period, admissions?.[period]));
  }, [dispatch, isDrawerVisible, period]);

  const listItem = item => {
    return (
      <>
        <Skeleton
          loading={!item?.name}
          active
          paragraph={{ rows: 0 }}
          className="custom-skeleton"
        >
          <SoluterName name={item?.name || item} email={item?.email || item} />
          <p className="no-margin">{item?.role || ''}</p>
        </Skeleton>
      </>
    );
  };

  return (
    <DrawerList
      title={`Soluters Admitidos ${period === 'WEEK' ? 'na' : 'no'} ${
        periodTitles[period]
      }`}
      isDrawerVisible={isDrawerVisible}
      setDrawerVisible={setDrawerVisible}
      data={admissions?.[period]?.users}
      total={admissions?.[period]?.total}
      listItem={listItem}
      dataTestId="admitted-soluters-drawer"
      isLoading={isLoadingAdmittedUsers}
    />
  );
}

AdmissionDrawer.propTypes = {
  isDrawerVisible: PropTypes.bool.isRequired,
  setDrawerVisible: PropTypes.func.isRequired,
  period: PropTypes.string.isRequired,
};

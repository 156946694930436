import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getRole } from '~/config/environment';

export default function SoluterName({ name, email }) {
  return getRole() === 'admin' ? (
    <Link to={`/soluters/${email}`}>
      <p className="no-margin soluter-name">{name || email}</p>
    </Link>
  ) : (
    <p className="no-margin soluter-name">{name || email}</p>
  );
}

SoluterName.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

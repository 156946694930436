import Icon from '@ant-design/icons';
import './styles.scss';
import PropTypes from 'prop-types';
import { ReactComponent as LeaderSvg } from '~/assets/images/lider.svg';

export default function LeaderBadge({
  active,
  size,
  iconStyles,
  additionalClassNames,
}) {
  return (
    <div
      className={`soluter-profile__badge ${additionalClassNames}  ${
        active
          ? 'soluter-profile__badge--leader'
          : 'soluter-profile__badge--inactive'
      }`}
      style={{
        width: size,
        height: size,
        display: 'flex',
      }}
    >
      <Icon component={LeaderSvg} style={iconStyles} />
    </div>
  );
}

LeaderBadge.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
  iconStyles: PropTypes.object,
  additionalClassNames: PropTypes.string,
};

LeaderBadge.defaultProps = {
  active: false,
  size: 40,
  iconStyles: { fontSize: 20 },
  additionalClassNames: '',
};

const VALIDATION_MESSAGE = {
  required: '${name} é obrigatório',
  types: {
    string: '${name} deve ter no mínimo ${min} letras',
  },
  string: {
    min: '${name} deve ter no mínimo ${min} letras',
    range: '${name} deve ter no mínimo ${min} e no máximo ${max} letras',
  },
};

const ERROR_400_MESSAGE = 'A operação não pode ser completada';
const ERROR_401_MESSAGE =
  'Sessão não autorizada ou expirada, por favor tente novamente';
const ERROR_403_MESSAGE =
  'Desculpe, este acesso é somente para pessoas autorizadas';
const ERROR_404_MESSAGE = 'Sua busca não foi encontrada';
const ERROR_500_MESSAGE = 'Desculpe, ocorreu um erro no servidor';
const ERROR_DEFAULT_MESSAGE = 'Desculpe, algo deu errado';

const ERROR_MESSAGE_DISTRICT_NAME =
  'O nome não deve incluir a palavra distrito';

const HTTP_ADD_SUCCESS_MESSAGE = 'Item adicionado com sucesso';
const HTTP_ADD_FAIL_MESSAGE = 'Falha ao adicionar';
const HTTP_EDIT_SUCCESS_MESSAGE = 'Item editado com sucesso';
const HTTP_EDIT_FAIL_MESSAGE = 'Falha ao editar';
const HTTP_UPDATE_SUCCESS_MESSAGE = 'Item atualizado com sucesso';
const HTTP_DELETE_SUCCESS_MESSAGE = 'Item deletado com sucesso';
const HTTP_DELETE_FAIL_MESSAGE = 'Falha ao deletar';
const HTTP_TIMEOUT_SERVER_RESPONSE =
  'Excedido o tempo de comunicação com o servidor';
const ERROR_ADD_USER =
  'Informe o e-mail cadastrado no teams com o domínio da empresa para adicionar um novo soluter.';

export default {
  VALIDATION_MESSAGE,
  HTTP_ADD_SUCCESS_MESSAGE,
  HTTP_ADD_FAIL_MESSAGE,
  HTTP_EDIT_SUCCESS_MESSAGE,
  HTTP_EDIT_FAIL_MESSAGE,
  HTTP_UPDATE_SUCCESS_MESSAGE,
  HTTP_DELETE_SUCCESS_MESSAGE,
  HTTP_DELETE_FAIL_MESSAGE,
  ERROR_MESSAGE_DISTRICT_NAME,
  ERROR_400_MESSAGE,
  ERROR_401_MESSAGE,
  ERROR_403_MESSAGE,
  ERROR_404_MESSAGE,
  ERROR_500_MESSAGE,
  ERROR_DEFAULT_MESSAGE,
  HTTP_TIMEOUT_SERVER_RESPONSE,
  ERROR_ADD_USER,
};

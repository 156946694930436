/* eslint-disable consistent-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Form,
  Input,
  message,
  Checkbox,
  DatePicker,
  Select,
  Col,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { addSoluter, addCompany } from '~/store/fetchActions/fetchPeople';
import { validationMessages, people } from '~/constants';

const ManageSoluterForm = React.memo(
  ({ handleSubmit, formId, handleClose }) => {
    const [form] = Form.useForm();
    const [isPessoaJuridicaChecked, setPessoaJuridicaChecked] = useState(false);
    const [gender, setGender] = useState('');
    const [admission, setAdmissionDate] = useState(
      new Date().toLocaleDateString(),
    );
    const [birthday, setBirthdayDate] = useState(
      new Date().toLocaleDateString(),
    );

    const { Option } = Select;

    const handleSubmitForm = data => {
      if (isPessoaJuridicaChecked) {
        addUserCompany(data);
        return;
      }
      addUserSoluter(data);
    };

    function addUserCompany(data) {
      const email = data.email;
      const user = { email, admission, birthday, gender };

      return new Promise(() => {
        addCompany(user).then(({ status }) => {
          if (status === 400) {
            message.error(validationMessages.ERROR_ADD_USER);
            return;
          }
          message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
          handleClose(true);
          if (handleSubmit) handleSubmit();
        });
      });
    }

    function addUserSoluter(data) {
      return new Promise(() => {
        addSoluter(data.email.toLowerCase()).then(({ status }) => {
          if (status === 204) {
            message.error(validationMessages.ERROR_ADD_USER);
            return;
          }
          message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
          handleClose(true);
          if (handleSubmit) handleSubmit();
        });
      });
    }

    const disabledDate = current => {
      return current && current._d > new Date();
    };

    return (
      <Form
        layout="vertical"
        data-testid="add-soluter-form"
        id={formId}
        form={form}
        onFinish={handleSubmitForm}
      >
        <Form.Item
          label="E-mail:"
          name="email"
          tooltip={{
            title: people.INFO_ADD_SOLUTER,
            icon: <QuestionCircleOutlined />,
            placement: 'rightBottom',
          }}
          rules={[
            { required: true, message: 'Campo obrigatório' },
            ({ getFieldValue }) => ({
              validator() {
                if (!getFieldValue('email').includes('@solutis.com.br')) {
                  return Promise.reject(
                    new Error('E-mail deve conter domínio da empresa'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          required
        >
          <Input allowClear />
        </Form.Item>
        <Checkbox
          style={{ marginBottom: 0 }}
          checked={isPessoaJuridicaChecked}
          data-testid="pessoa-juridica"
          onChange={({ target }) => {
            form.setFieldsValue({
              primaryTech: null,
            });
            setPessoaJuridicaChecked(target.checked);
          }}
        >
          Pessoa Jurídica
        </Checkbox>
        <div style={{ padding: 10 }} />
        {isPessoaJuridicaChecked && (
          <>
            <Row justify="space-between">
              <Col>
                <Form.Item
                  label="Data de nascimento:"
                  name="birthday"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                  required
                >
                  <DatePicker
                    locale={locale}
                    format="DD-MM-YYYY"
                    style={{ width: '200px' }}
                    inputReadOnly
                    value={birthday}
                    onChange={value => {
                      const date = new Date(value).toLocaleDateString('es-CL');
                      setBirthdayDate(date);
                    }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Data de admissão:"
                  name="date"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                  required
                >
                  <DatePicker
                    locale={locale}
                    format="DD-MM-YYYY"
                    style={{ width: '200px' }}
                    inputReadOnly
                    value={admission}
                    onChange={value => {
                      const date = new Date(value).toLocaleDateString('es-CL');
                      setAdmissionDate(date);
                    }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Genero">
              <Select
                placeholder="Selecione o gênero"
                allowClear
                options={[
                  { value: 'M', label: 'M' },
                  { value: 'F', label: 'F' },
                ]}
                onChange={value => setGender(value)}
              />
            </Form.Item>
          </>
        )}
      </Form>
    );
  },
);

export default ManageSoluterForm;

ManageSoluterForm.propTypes = {
  email: PropTypes.string,
  formId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

ManageSoluterForm.defaultProps = {
  email: undefined,
};

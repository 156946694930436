/* eslint-disable no-console */
import { message } from 'antd';
import api from '~/config/httpRequest';
import * as suggestionAction from '~/store/ducks/suggestion';
import { validationMessages } from '~/constants';
import { startLoading, endLoading } from '../ducks/loading';

export const get = () => {
  return dispatch => {
    api
      .get(`/jobs/skills/suggestions`)
      .then(({ data: payload }) => {
        dispatch(
          suggestionAction.get({
            suggestions: payload,
          }),
        );
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const getByTitle = (title, page = 1) => {
  return dispatch => {
    dispatch(startLoading('isLoadingSkills'));
    api
      .get(`/jobs/skills/suggestions/?page=${page}&term=${title}`)
      .then(({ data: payload }) => {
        dispatch(
          suggestionAction.get({
            suggestions: payload || [],
            page,
          }),
        );
        dispatch(endLoading('isLoadingSkills'));
      })
      .catch(err => {
        console.log(err);
        dispatch(
          suggestionAction.get({
            suggestions: null,
            page: 1,
          }),
        );
        dispatch(endLoading('isLoadingSkills'));
      });
  };
};

export const remove = (id, page = 1) => {
  return dispatch => {
    api
      .put(`/jobs/skills/suggestion/${id}?status=false`)
      .then(() => {
        dispatch(suggestionAction.remove(id));
        dispatch(get(page));
        message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const add = (id, page = 1) => {
  return dispatch => {
    api
      .put(`/jobs/skills/suggestion/${id}?status=true`)
      .then(({ data: payload }) => {
        dispatch(suggestionAction.add(payload));
        dispatch(get(page));
        message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
      })
      .catch(e => {
        console.log(e);
      });
  };
};

/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { Select, Skeleton, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import * as fetchPractices from '~/store/fetchActions/fetchPractices';

export default function SelectMultiplePractice({
  selector,
  dispatchDuck,
  disableEmptyOption,
  emptyOptionText,
  defaultValue,
  fieldLabel,
  fieldName,
  style,
  onChange,
}) {
  const dispatch = useDispatch();
  const { towers } = useSelector(state => state.towers);
  const { practices } = useSelector(state => state.practices);
  const { isLoadingPractices } = useSelector(state => state.loading);
  const { selectedTower, selectedTowerName } = useSelector(selector);
  const { Option } = Select;

  /* useEffect(() => {
    dispatch(fetchTowers.listTowers());
  }, [dispatch]); */

  useEffect(() => {
    dispatch(fetchPractices.getPractices(false));
  }, [dispatch]);

  useEffect(() => {
    if (disableEmptyOption && practices?.length > 0 && selectedTower === '')
      dispatch(
        dispatchDuck({ value: practices[0]?.id, label: practices[0]?.name }),
      );
  }, [practices, dispatch, dispatchDuck, selectedTower, disableEmptyOption]);

  /* const onSearch = value => {
    if (value?.length >= 2) {
      dispatch(fetchTowers.listTowers(value));
    }
  }; */

  const onSearch = value => {
    if (value?.length >= 2) {
      dispatch(fetchPractices.getPractices(false));
    }
  };
  return (
    <Form.Item
      name={fieldName}
      style={{ ...style }}
      label={fieldLabel}
      rules={[
        {
          type: 'array',
          whitespace: true,
        },
      ]}
    >
      {/* {isLoadingPractices && practices?.length === 0 && (
        <Skeleton.Input
          paragraph={{ rows: 0 }}
          style={{ width: 250, margin: 0, padding: 0 }}
          loading
          active
        />
      )} */}
      {practices?.length > 0 && (
        <Select
          placeholder="Selecione as BUs"
          showSearch
          mode="multiple"
          allowClear
          defaultValue={defaultValue}
          // onChange={value => {
          //   if (value) dispatch(dispatchDuck(value));
          //   else dispatch(dispatchDuck({ value: '', label: emptyOptionText }));
          // }}
          onChange={onChange}
          optionFilterProp="children"
          onSearch={onSearch}
          style={{ ...style }}
          labelInValue
          data-testid="select-practices"
        >
          {/* {!disableEmptyOption && <Option value="">{emptyOptionText}</Option>} */}
          <Option value={practices?.map(practice => practice?.id)}>
            Selecionar Todas as BUs
          </Option>
          {practices?.map((practice, idx) => (
            <Option
              value={practice?.id}
              key={practice?.id}
              data-testid={`select-practices-option-${idx}`}
            >
              {practice?.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
}

SelectMultiplePractice.propTypes = {
  selector: PropTypes.func.isRequired,
  dispatchDuck: PropTypes.func.isRequired,
  disableEmptyOption: PropTypes.bool,
  emptyOptionText: PropTypes.string,
  defaultValue: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

SelectMultiplePractice.defaultProps = {
  disableEmptyOption: false,
  emptyOptionText: 'Todos os Soluters',
  defaultValue: null,
  fieldLabel: '',
  onChange: () => {},
  style: {},
};

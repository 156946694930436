import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCostCenters } from '~/store/fetchActions/fetchPractices';

const { Option } = Select;

export default function MultipleCostCenterFilter({
  fieldName,
  fieldLabel,
  style,
  selectedPractice,
  onChange,
  disabled,
}) {
  const dispatch = useDispatch();
  const { costCenters } = useSelector(state => state.practices);
  const { practices } = useSelector(state => state.practices);
  const { isLoadingCostCenter } = useSelector(state => state.loading);
  const [practiceCostCenterList, setPracticeCostCenterList] = useState([]);

  useEffect(() => {
    if (selectedPractice)
      setPracticeCostCenterList(
        practices.filter(practice => practice.id === selectedPractice)[0]
          .costCenters,
      );
    dispatch(getCostCenters('ab'));
  }, [dispatch, selectedPractice, practices]);

  const onSearch = value => {
    if (value?.length >= 2) {
      dispatch(getCostCenters(value));
    }
  };

  return (
    <Form.Item
      name={fieldName}
      style={{ ...style }}
      label={fieldLabel}
      rules={[
        {
          type: 'array',
          whitespace: true,
        },
      ]}
    >
      <Select
        placeholder="Buscar por Centro de Custo"
        showSearch
        allowClear
        mode="multiple"
        disabled={disabled}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSearch={onSearch}
        data-testid="select-cost-center"
        optionFilterProp="children"
        onChange={onChange}
        notFoundContent={isLoadingCostCenter ? <Spin size="small" /> : null}
      >
        {!selectedPractice
          ? costCenters?.map(costCenter => (
              <Option
                key={costCenter.id}
                value={costCenter.id}
                data-testid={`cost-center-option-${costCenter.id}`}
              >
                {`${costCenter.id} - ${costCenter.title}`}
              </Option>
            ))
          : practiceCostCenterList.map(costCenter => (
              <Option
                key={costCenter.id}
                value={costCenter.id}
                data-testid={`cost-center-option-${costCenter.id}`}
              >
                {`${costCenter.id} - ${costCenter.title}`}
              </Option>
            ))}
      </Select>
    </Form.Item>
  );
}

MultipleCostCenterFilter.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string,
  style: PropTypes.object,
  selectedPractice: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

MultipleCostCenterFilter.defaultProps = {
  style: { width: 300 },
  fieldLabel: '',
  selectedPractice: undefined,
  disabled: false,
  onChange: () => {},
};

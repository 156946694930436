import { Skeleton, Avatar, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { BankOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';
import './styles.scss';

const { Text } = Typography;

export default function IconWithCaption({
  useAvatar,
  imageSrc,
  redirectUrl,
  captionText,
  altText,
  isLoading,
}) {
  return (
    <div className="icon-with-caption">
      {isLoading ? (
        <Skeleton.Avatar active size={54} />
      ) : (
        <Link to={redirectUrl} className="icon-with-caption__icon">
          {useAvatar ? (
            <Avatar size={54} src={imageSrc} icon={<BankOutlined />} />
          ) : (
            <img src={imageSrc} alt={altText} />
          )}
        </Link>
      )}
      <Link to={redirectUrl} className="icon-with-caption__caption">
        <Text>{captionText}</Text>
      </Link>
    </div>
  );
}

IconWithCaption.propTypes = {
  useAvatar: PropTypes.bool,
  imageSrc: PropTypes.string.isRequired,
  captionText: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  altText: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

IconWithCaption.defaultProps = {
  useAvatar: false,
  redirectUrl: '',
  altText: '',
};

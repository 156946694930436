import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import useSoluterCostCenter from '~/hooks/interviews/useSoluterCostCenter';
import './styles.scss';

export default function SoluterAllocationInfo({ soluter }) {
  const { email } = soluter;

  const { practice, costCenter, costCenterId } = useSoluterCostCenter(email);
  const costCenterName = useMemo(
    () =>
      email
        ? [costCenterId, costCenter].filter(info => info).join(' - ')
        : 'Não identificado',
    [email, costCenter, costCenterId],
  );

  return (
    <div className="interview-soluter-info">
      {email && costCenterName && (
        <>
          <Typography>
            BU:
            <pre>{practice || 'Não identificada'}</pre>
          </Typography>
          <Typography>
            Centro de Custo:
            <pre>{costCenterName}</pre>
          </Typography>
        </>
      )}
    </div>
  );
}

SoluterAllocationInfo.propTypes = {
  soluter: PropTypes.object.isRequired,
};

export const verticals = {
  PSU: 'PSU - Setor Público e Utilities',
  FS: 'FS - Setor Financeiro',
  CPR: 'CPR - Varejo e Bens de Consumo',
  TM: 'TM - Telecom e Mídia',
};

export default {
  verticals,
};

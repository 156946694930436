import { useParams } from 'react-router-dom';
import { TowerDetails as Details } from '~/container';

export default function TowerDetails() {
  const { towerId } = useParams();
  return (
    <div>
      <Details towerId={towerId} />
    </div>
  );
}

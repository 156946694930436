/* eslint-disable no-console */
// DOCS: https://gitlab.solutis.digital/ads/passport/passport-back-office/wikis/Planejamento

import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from 'antd';
import moment from 'moment';
import { getProjects } from '~/store/ducks/towers';
import {
  TowerScheduleHeader,
  CustomScheduler,
  CustomAllocationForm,
  VacationForm,
} from '~/container';
import './styles.scss';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';

const { Title } = Typography;

export default function ScheduleDashboard() {
  const dispatch = useDispatch();

  const { selectedTower } = useSelector(state => state.towers);

  const [allocationVisible, setAllocationVisible] = useState(false);
  const [isVacationFormVisible, setVacationFormVisible] = useState(false);
  const [res, setRes] = useState([]);
  const [resActive, setResActive] = useState([]);
  const [isLoadingScheduler, setIsLoadingScheduler] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [activeMembers, setActiveMembers] = useState(false);
  const [activeTowers, setActiveTowers] = useState(false);
  const [eventRecord, setEventRecord] = useState({});
  const [allocation, setAllocation] = useState({});

  const getSchedule = useCallback(
    async (selectedTowerState, _year, _activeMembers) => {
      setIsLoadingScheduler(true);
      setResActive([]);
      setActiveTowers(false);
      let result = null;
      try {
        result = await fetchTowers.getSchedule(
          selectedTowerState,
          _year,
          _activeMembers,
        );
        dispatch(getProjects(result));
      } catch (error) {
        console.log(error);
      }
      setRes(result);
      setIsLoadingScheduler(false);
    },
    [dispatch],
  );

  useEffect(() => {
    if (selectedTower) getSchedule(selectedTower, year, activeMembers);
  }, [selectedTower, activeMembers, year, getSchedule]);

  useEffect(() => {
    const today = moment(new Date()).format('YYYY/MM/DD');
    const list = [];
    if (activeTowers && res) {
      res?.forEach(item => {
        if (today < moment(item?.endDate).format('YYYY/MM/DD')) list.push(item);
      });

      setResActive(list);
    } else if (resActive?.length > 0) {
      setResActive(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTowers]);

  const customReload = () => {
    getSchedule(selectedTower, year, activeMembers);
  };

  return (
    <>
      <div className="scheduler-header">
        <Title>Planejamento</Title>
        <TowerScheduleHeader
          reload={customReload}
          selectedTower={selectedTower}
          setYear={setYear}
          year={year}
          activeMembers={activeMembers}
          setActiveMembers={setActiveMembers}
          setActiveTowers={setActiveTowers}
          activeTowers={activeTowers}
        />
      </div>
      <div>
        <CustomScheduler
          scheduleData={resActive?.length > 0 ? resActive : res}
          setFormVisible={setAllocationVisible}
          showEditor={(_eventRecord, _allocation) => {
            setEventRecord(_eventRecord);
            setAllocation(_allocation);
            setAllocationVisible(true);
          }}
          showVacationForm={(_eventRecord, _allocation) => {
            setEventRecord(_eventRecord);
            setAllocation(_allocation);
            setVacationFormVisible(true);
          }}
          currentYear={year}
          setYear={setYear}
          towerId={selectedTower}
          reload={customReload}
          isLoadingScheduler={isLoadingScheduler}
        />
      </div>
      <CustomAllocationForm
        allocationVisible={allocationVisible}
        setAllocationVisible={setAllocationVisible}
        record={eventRecord}
        allocation={allocation}
        reload={customReload}
      />
      <VacationForm
        personName={eventRecord?.person}
        personEmail={eventRecord?.personKey}
        isModalVisible={isVacationFormVisible}
        setModalVisible={setVacationFormVisible}
        allocation={allocation}
        reload={customReload}
      />
    </>
  );
}

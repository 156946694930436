/* eslint-disable no-nested-ternary */
import Bar from '@ant-design/charts/lib/bar';
import React from 'react';
import PropTypes from 'prop-types';
import { colorBySeniority } from '~/constants/indicators';

function GraphSoluterBySeniority({
  handleOpenDrawer,
  data,
  unallocated,
  uniqueValuesCount,
}) {
  const countTotalSolutersByCategory = category => {
    const arrayToSum = data?.filter(_item => _item.label === category);
    const total = arrayToSum?.reduce(
      (accumulator, currentItem) => accumulator + currentItem.value,
      0,
    );
    return total;
  };

  const config = {
    data: data || [],
    isStack: true,
    xField: 'value',
    yField: 'label',
    seriesField: 'type',
    dodgePadding: 4,
    legend: !unallocated
      ? {
          layout: 'horizontal',
          position: 'top',
        }
      : false,
    label: {
      position: 'right',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
      formatter: function formatter({ value }) {
        return value || '';
      },
    },
    barStyle: {
      cursor: unallocated ? 'default' : 'pointer',
    },
    tooltip: {
      formatter: item => {
        if (unallocated)
          return {
            title: `${item.label}`,
            name: 'Total',
            value: item.value,
          };

        return {
          title: `Total: ${countTotalSolutersByCategory(item.label)}`,
          name: item.type,
          value: item.value,
        };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(text, item, index) {
          return `${text} - ${countTotalSolutersByCategory(text)}`;
        },
      },
    },
    xAxis: {
      grid: null,
      label: null,
    },
    color: ({ type }) => {
      if (unallocated) return colorBySeniority.PL;
      return type ? colorBySeniority[type] : 'grey';
    },
  };
  return (
    <Bar
      style={{
        marginTop: 50,
        height: `${(uniqueValuesCount > 5 ? 3.5 : 5) * uniqueValuesCount}vmax`,
      }}
      minBarWidth={15}
      maxBarWidth={30}
      onEvent={(chart, event) => {
        if (
          event.type === 'click' &&
          event.data &&
          event.data?.data?.type !== '' &&
          !unallocated
        ) {
          handleOpenDrawer(event.data.data.label);
        }
      }}
      {...config}
    />
  );
}

GraphSoluterBySeniority.propTypes = {
  handleOpenDrawer: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  unallocated: PropTypes.bool.isRequired,
  uniqueValuesCount: PropTypes.number.isRequired,
};

export default React.memo(GraphSoluterBySeniority);

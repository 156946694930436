import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import { SelectSkills } from '~/components';
import {
  addFamilies,
  updateFamilies,
} from '~/store/fetchActions/fetchFamilies';

const { Option } = Select;

export default function EditBusinessUnitFamily({
  isVisible,
  setVisible,
  family,
  isAddFamily,
  businessUnitId,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { buId } = useParams();

  const handleCancel = useCallback(async () => {
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    form.resetFields();
    if (isVisible && !isAddFamily && family)
      form.setFieldsValue({
        ...family,
        skills: family.skills || [],
      });
  }, [form, isVisible, family, isAddFamily]);

  const handleOk = useCallback(async () => {
    if (isAddFamily) {
      const hierarchy = family.families?.length;
      return form.validateFields().then(({ name, skills }) => {
        dispatch(addFamilies(name, skills, businessUnitId, family, hierarchy));
        setVisible(false);
      });
    }
    return form.validateFields().then(({ name, skills, roles }) => {
      const familyId = family?.id;
      dispatch(updateFamilies(name, skills, roles, buId, familyId));
      setVisible(false);
    });
  }, [form, family, isAddFamily, businessUnitId, setVisible, buId, dispatch]);

  return (
    <Modal
      visible={isVisible}
      title={
        isAddFamily
          ? 'Cadastrar Família'
          : 'Editar Objetivos Específicos da Família'
      }
      okText="Salvar"
      cancelText="Cancelar"
      data-testid="business-unit-edit-family-modal"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          data-testid="edit-bu-save-btn"
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" requiredMark>
        <Form.Item label="Família" name="name">
          <Input
            type="text"
            data-testid="family-name-input"
            key="family-name-input"
            placeholder="Nome da Família"
          />
        </Form.Item>
        {!isAddFamily ? (
          <Form.Item label="Cargos da Família" name="roles" defaultValue={[]}>
            <Select
              mode="multiple"
              label="Cargos da Família"
              key="family-roles-select"
              tagRender={({ label: { id, name } }) => (
                <Tag
                  data-testid={`role-option-${id}`}
                  style={{ margin: 2 }}
                  closable={false}
                >
                  {name}
                </Tag>
              )}
              disabled
            >
              {family?.roles?.map(({ id, name }) => (
                <Option key={`role-option-${id}-${name}`}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <></>
        )}
        <SelectSkills
          tagRenderFunc={({ label, ...props }) => (
            <Tag
              data-testid={`skill-${label}`}
              style={{ margin: 2 }}
              color="blue"
              {...props}
            >
              {label}
            </Tag>
          )}
          fieldLabel="Objetivos Específicos da Família"
          placeholder="Buscar objetivo específico (ex.: Análise de indicadores, React)"
          testId="select-family-skills"
          selectMode="multiple"
          fieldName="skills"
        />
      </Form>
    </Modal>
  );
}

EditBusinessUnitFamily.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  family: PropTypes.objectOf(PropTypes.any),
  isAddFamily: PropTypes.bool,
  businessUnitId: PropTypes.string,
};

EditBusinessUnitFamily.defaultProps = {
  family: {},
  isAddFamily: true,
  businessUnitId: '',
};

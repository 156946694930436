import { Form } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as fetchDistricts from '~/store/fetchActions/fetchDistricts';
import { DistrictsForm, TitlePage } from '~/components';

export default function DistrictsEdit() {
  const { districtId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [districtsForm] = Form.useForm();

  function handleSubmit(values) {
    (() => {
      dispatch(fetchDistricts.edit(values, districtId));
      districtsForm.setFieldsValue({ values });
      // history.push('/districts');
    })();
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TitlePage>Editar Distrito</TitlePage>
      </div>
      <DistrictsForm districtId={districtId} onSubmit={handleSubmit} />
    </>
  );
}

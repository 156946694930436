import locale from 'antd/lib/date-picker/locale/pt_BR';
import { DatePicker, Row, Col, Radio, Select } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DownloadButton, SelectTowers } from '~/components';
import {
  setSelectedTower,
  setCapacityMonthInterval,
  setCapacityGroupingMode,
  setAllocationFilter,
  clearCsvData,
} from '~/store/ducks/towers';
import { getCapacityCSV } from '~/store/fetchActions/fetchTowers';

export default function CapacityHeader() {
  const { monthInterval, csvData } = useSelector(
    state => state.towers.capacity,
  );
  const { selectedTower } = useSelector(state => state.towers);
  const { allocationFilter, groupingMode } = useSelector(
    state => state.towers.capacity,
  );
  const dispatch = useDispatch();
  const { Option } = Select;

  useEffect(() => {
    if (monthInterval.start === '') {
      const startMonth = moment().startOf('month');
      dispatch(
        setCapacityMonthInterval({
          start: startMonth.format('YYYY-MM-DD'),
          end: startMonth.endOf('month').format('YYYY-MM-DD'),
        }),
      );
    }
  }, [monthInterval, dispatch]);

  const handleCSVDownload = () => {
    dispatch(
      getCapacityCSV(monthInterval.start, monthInterval.end, selectedTower),
    );
  };

  return (
    <>
      <Row style={{ marginBottom: '20px' }} justify="space-between">
        <Col style={{ display: 'flex', alignItems: 'flex-start' }}>
          <SelectTowers
            selector={state => state.towers}
            dispatchDuck={setSelectedTower}
            disableEmptyOption
          />
          <Select
            defaultValue={allocationFilter}
            style={{ width: 200, marginLeft: 10 }}
            onChange={e => {
              dispatch(setAllocationFilter(e));
            }}
            data-testid="select-capacity-allocated"
          >
            <Option data-testid="select-option-all-allocations" value="">
              Geral
            </Option>
            <Option data-testid="select-option-only-allocated" value>
              Somente Alocados
            </Option>
            <Option data-testid="select-option-unallocated" value={false}>
              Somente Não Alocados
            </Option>
          </Select>
          <DatePicker
            data-testid="capacity-date-picker"
            locale={locale}
            inputReadOnly
            picker="month"
            style={{ width: 120, marginLeft: 10 }}
            placeholder={['início', 'fim']}
            allowClear={false}
            defaultValue={
              monthInterval.start ? moment(monthInterval.start) : moment()
            }
            format="YYYY-MM"
            onChange={(date, value) => {
              const selectMonth = moment(`${value}-01`, 'YYYY-MM-DD');
              dispatch(
                setCapacityMonthInterval({
                  start: selectMonth.format('YYYY-MM-DD'),
                  end: selectMonth.endOf('month').format('YYYY-MM-DD'),
                }),
              );
            }}
          />
          <Radio.Group
            defaultValue={groupingMode}
            style={{ marginBottom: 15, marginLeft: 10 }}
            onChange={e => {
              dispatch(setCapacityGroupingMode(e.target.value));
            }}
          >
            <Radio.Button
              data-testid="capacity-group-by-project"
              value="projects"
            >
              Projetos
            </Radio.Button>
            <Radio.Button data-testid="capacity-group-by-people" value="people">
              Pessoas
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <DownloadButton
            label="Exportar CSV"
            data={csvData || ''}
            requestData={handleCSVDownload}
            clearData={clearCsvData}
            fileName={`relatorio_capacity_${selectedTower}_${monthInterval.start}_${monthInterval.end}.csv`}
            exportCsvLayout
          />
        </Col>
      </Row>
    </>
  );
}

import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import { selectCompetenceState } from '~/store/selectors/competence';
import AddEditCompetenceModal from './modal/AddEditCompetenceModal';
import { DownloadButton } from '~/components';
import useModal from '~/hooks/modal/useModal';
import './styles.scss';
import {
  exportCompetences,
  getCompetences,
} from '~/store/fetchActions/fetchCompetences';
import { getRole } from '~/config/environment';
import { clearCompetencesCsvData } from '~/store/ducks/competence';

export default function CompetenceActions() {
  const { visible, handleOpen, handleClose } = useModal();

  const {
    filter: {
      businessUnit,
      tower,
      soluter,
      competence: filteredCompetenceName,
      valid,
    },
    competencesCsvData,
  } = useSelector(selectCompetenceState);
  const dispatch = useDispatch();

  const handleCreateCompetence = useCallback(() => {
    dispatch(
      getCompetences(
        businessUnit,
        tower,
        soluter,
        filteredCompetenceName,
        valid,
      ),
    );
  }, [dispatch, businessUnit, tower, soluter, filteredCompetenceName, valid]);

  const handleClickExportCsv = useCallback(() => {
    dispatch(
      exportCompetences(
        businessUnit,
        tower,
        soluter,
        filteredCompetenceName,
        valid,
      ),
    );
  }, [dispatch, businessUnit, tower, soluter, filteredCompetenceName, valid]);

  return (
    <div className="competence-actions">
      {visible && (
        <AddEditCompetenceModal
          visible={visible}
          handleClose={handleClose}
          handleSubmit={handleCreateCompetence}
        />
      )}
      {getRole() !== 'leader' && (
        <Button
          type="primary"
          data-testid="create-competence"
          onClick={handleOpen}
        >
          Adicionar nova certificação
        </Button>
      )}
      <DownloadButton
        exportCsvLayout
        data={competencesCsvData || null}
        requestData={handleClickExportCsv}
        clearData={clearCompetencesCsvData}
        fileName="Competences.csv"
        label="Exportar CSV"
      />
    </div>
  );
}

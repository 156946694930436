import { useCallback, useState } from 'react';

export default function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return { visible: isOpen, handleOpen, handleClose };
}

/* eslint-disable no-console */
import api from '~/config/httpRequest';
import {
  getAllTags,
  getAllMoods,
  getParticipation,
  getApprovedTags,
  getNotApprovedTags,
  getApprovedSmileTags,
  getNotApprovedSmileTags,
  getApprovedWeeklyTags,
  getNotApprovedWeeklyTags,
  getCsvData,
  getMoodComments as getComments,
  updateUserName,
  getMonthlyAverageAction,
} from '~/store/ducks/mood';
import { getSoluter } from '~/store/ducks/people';
import { getUserNames } from '~/store/fetchActions/fetchPeople';
import { endLoading, startLoading } from '../ducks/loading';
import { getRole } from '~/config/environment';

export const getMonthlyAvarage = (week, towerId) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      selectedPractice,
      selectedCostCenters,
      selectedTowers,
      selectedPractices,
    } = state.mood;
    const multiplePractices = selectedPractices.map(practice => {
      return practice.key;
    });
    dispatch(startLoading('isLoadingMonthlyAverage'));
    api
      .get('/soluters/moods/monthly-average', {
        params: {
          startDate: week.start,
          endDate: week.end,
          groupsIds: towerId,
          practicesIds: multiplePractices.toString(),
          costCentersIds: selectedCostCenters.toString(),
        },
      })
      .then(({ data: payload }) => {
        dispatch(getMonthlyAverageAction(payload));
        dispatch(endLoading('isLoadingMonthlyAverage'));
      })
      .catch(error => {
        dispatch(getMonthlyAverageAction(null));
        dispatch(endLoading('isLoadingMonthlyAverage'));
        console.log(error);
      });
  };
};

export const participation = (week, towerId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedPractice, selectedCostCenters } = state.mood;
    dispatch(startLoading('isLoadingParticipation'));
    api
      .get('/soluters/moods/participation', {
        params: {
          startDate: week.start,
          endDate: week.end,
          groupId: towerId,
          practiceId: selectedPractice,
          costCenterId: selectedCostCenters.toString(),
        },
      })
      .then(({ data: payload }) => {
        dispatch(getParticipation(payload));
        dispatch(endLoading('isLoadingParticipation'));
      })
      .catch(error => {
        dispatch(getParticipation(null));
        dispatch(endLoading('isLoadingParticipation'));
        console.log(error);
      });
  };
};

export const getMood = (week, towerId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedPractice, selectedCostCenters, isYearly } = state.mood;
    dispatch(startLoading('isLoadingMoods'));
    api
      .get('/soluters/moods', {
        params: {
          startDate: week.start,
          endDate: week.end,
          groupId: towerId,
          practiceId: selectedPractice,
          costCenterId: selectedCostCenters.toString(),
          yearly: isYearly,
        },
      })
      .then(({ data: payload }) => {
        if (payload) {
          payload.periodicMoods.sort((a, b) => {
            if (a.mood < b.mood) return 1;
            if (b.mood < a.mood) return -1;
            return 0;
          });

          payload.periodicMoods = payload.periodicMoods.map(mood => {
            return { ...mood, mood: mood.mood.toString() };
          });

          payload.averageMood = payload.averageMood.reverse();
        }
        dispatch(getAllMoods(payload));
        dispatch(endLoading('isLoadingMoods'));
      })
      .catch(error => {
        dispatch(getAllMoods(null));
        dispatch(endLoading('isLoadingMoods'));
        console.log(error);
      });
  };
};

export const approvedTags = (week, towerId, onlyVoted = false, mood) => {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedPractice, selectedCostCenters } = state.mood;
    dispatch(startLoading('isLoadingApprovedTags'));
    api
      .get('/soluters/moods/approved', {
        params: {
          startDate: week.start,
          endDate: week.end,
          groupId: towerId,
          mood,
          practiceId: selectedPractice,
          costCenterId: selectedCostCenters.toString(),
          onlyVoted,
        },
      })
      .then(({ data: payload }) => {
        if (mood) {
          dispatch(getApprovedSmileTags(payload));
        } else if (onlyVoted) {
          dispatch(getApprovedWeeklyTags(payload));
        } else {
          dispatch(getApprovedTags(payload));
        }
        dispatch(endLoading('isLoadingApprovedTags'));
      })
      .catch(error => {
        dispatch(getApprovedTags({}));
        dispatch(endLoading('isLoadingApprovedTags'));
        console.log(error);
      });
  };
};

export const monthlyAverageApprovedTags = (week, onlyVoted = false, mood) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      selectedPractices,
      selectedCostCenters,
      selectedTowers,
    } = state.mood;
    const towers = selectedTowers.map(tower => {
      return tower?.key;
    });
    const practices = selectedPractices.map(practice => {
      return practice?.key;
    });
    dispatch(startLoading('isLoadingApprovedTags'));
    api
      .get('/soluters/moods/monthly-average/approved', {
        params: {
          startDate: week.start,
          endDate: week.end,
          groupsIds: towers.toString(),
          mood,
          practicesIds: practices.toString(),
          costCentersIds: selectedCostCenters.toString(),
          onlyVoted,
        },
      })
      .then(({ data: payload }) => {
        if (mood) {
          dispatch(getApprovedSmileTags(payload));
        } else if (onlyVoted) {
          dispatch(getApprovedWeeklyTags(payload));
        } else {
          dispatch(getApprovedTags(payload));
        }
        dispatch(endLoading('isLoadingApprovedTags'));
      })
      .catch(error => {
        dispatch(getApprovedTags({}));
        dispatch(endLoading('isLoadingApprovedTags'));
        console.log(error);
      });
  };
};

export const monthlyAverageNotApprovedTags = (
  week,
  onlyVoted = false,
  mood,
) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      selectedPractices,
      selectedCostCenters,
      selectedTowers,
    } = state.mood;
    const towers = selectedTowers.map(tower => {
      return tower?.key;
    });
    const practices = selectedPractices.map(practice => {
      return practice?.key;
    });
    dispatch(startLoading('isLoadingNotApprovedTags'));
    api
      .get('/soluters/moods/monthly-average/notapproved', {
        params: {
          startDate: week.start,
          endDate: week.end,
          groupsIds: towers.toString(),
          mood,
          practicesIds: practices.toString(),
          costCentersIds: selectedCostCenters.toString(),
          onlyVoted,
        },
      })
      .then(({ data: payload }) => {
        if (mood) {
          dispatch(getNotApprovedSmileTags(payload));
        } else if (onlyVoted) {
          dispatch(getNotApprovedWeeklyTags(payload));
        } else {
          dispatch(getNotApprovedTags(payload));
        }
        dispatch(endLoading('isLoadingNotApprovedTags'));
      })
      .catch(error => {
        dispatch(endLoading('isLoadingNotApprovedTags'));
        dispatch(getNotApprovedTags({}));
        console.log(error);
      });
  };
};

export const notApprovedTags = (week, towerId, onlyVoted = false, mood) => {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedPractice, selectedCostCenters } = state.mood;
    dispatch(startLoading('isLoadingNotApprovedTags'));
    api
      .get('/soluters/moods/notapproved', {
        params: {
          startDate: week.start,
          endDate: week.end,
          groupId: towerId,
          mood,
          practiceId: selectedPractice,
          costCenterId: selectedCostCenters.toString(),
          onlyVoted,
        },
      })
      .then(({ data: payload }) => {
        if (mood) {
          dispatch(getNotApprovedSmileTags(payload));
        } else if (onlyVoted) {
          dispatch(getNotApprovedWeeklyTags(payload));
        } else {
          dispatch(getNotApprovedTags(payload));
        }
        dispatch(endLoading('isLoadingNotApprovedTags'));
      })
      .catch(error => {
        dispatch(endLoading('isLoadingNotApprovedTags'));
        dispatch(getNotApprovedTags({}));
        console.log(error);
      });
  };
};

export const getTags = () => {
  return async dispatch => {
    api
      .get(`/soluters/moods/tags`)
      .then(({ data: payload }) => {
        dispatch(getAllTags(payload));
      })
      .catch(err => {
        dispatch(getAllTags(null));
        console.log(err);
      });
  };
};

export const addTag = tag => {
  return async dispatch => {
    api
      .post('/soluters/moods/tags', tag)
      .then(data => {
        dispatch(getTags());
      })
      .catch(console.log);
  };
};

export const deleteTag = name => {
  return async dispatch => {
    api
      .delete(`/soluters/moods/tags/${name}`)
      .then(data => {
        dispatch(getTags());
      })
      .catch(console.log);
  };
};

export const getCSV = (startDate, endDate, towerId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedPractice, selectedCostCenters } = state.mood;
    api
      .get('/soluters/moods/export', {
        params: {
          startDate,
          endDate,
          groupId: towerId,
          practiceId: selectedPractice,
          costCenterId: selectedCostCenters.toString(),
        },
      })
      .then(({ data }) => {
        dispatch(getCsvData(data));
      })
      .catch(err => console.error(err));
  };
};

export const getSoluterMoodStatus = userEmail => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSoluterMood'));
    api
      .get(`/soluters/moods/status/${userEmail}`)
      .then(data => {
        const content = data.data;
        const dataToDispatch = {
          lastMood: content.lastMood,
          participationCount: content.participationCount,
          averages: [
            content.averageMood05,
            content.averageMood04,
            content.averageMood03,
            content.averageMood02,
            content.averageMood01,
          ],
        };
        dispatch(getSoluter(dataToDispatch));
        dispatch(endLoading('isLoadingSoluterMood'));
      })
      .catch(err => {
        dispatch(endLoading('isLoadingSoluterMood'));
        console.log(err);
      });
  };
};

export const getSoluterMoods = (week, userEmail) => {
  return async dispatch => {
    api
      .get(`/soluters/moods/user/${userEmail}`, {
        params: {
          startDate: week.start,
          endDate: week.end,
        },
      })
      .then(data => {
        dispatch(getSoluter({ moods: data.data.content }));
      })
      .catch(console.log);
  };
};

export const getMoodComments = (
  mood,
  week,
  towerId,
  page = 1,
  pageSize = 9,
) => {
  return async (dispatch, getState) => {
    dispatch(startLoading('isLoadingComments'));
    const state = getState();
    const { selectedPractice, selectedCostCenters } = state.mood;
    api
      .get(`/soluters/moods/comments`, {
        params: {
          startDate: week.start,
          endDate: week.end,
          groupId: towerId,
          mood,
          page,
          pageSize,
          practiceId: selectedPractice,
          costCenterId: selectedCostCenters.toString(),
        },
      })
      .then(async ({ data: payload }) => {
        await dispatch(
          getComments({
            data: payload?.content || [],
            total: payload?.total || 0,
          }),
        );
        if (payload) {
          const userEmailsList = payload?.content
            ?.map(comment => comment.userEmail)
            .filter((value, index, self) => {
              return self.indexOf(value) === index && value !== '';
            });

          const dispatchAction = res => {
            dispatch(
              updateUserName({
                userEmail: res?.email,
                userName: res?.name,
              }),
            );
          };

          getUserNames(userEmailsList, dispatchAction);
        }
        dispatch(endLoading('isLoadingComments'));
      })
      .catch(err => {
        dispatch(getComments({ data: null, total: 0 }));
        console.log(err);
        dispatch(endLoading('isLoadingComments'));
      });
  };
};

import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

export default function IconButton({
  title,
  style,
  disabled,
  icon,
  onClick,
  testId,
}) {
  return (
    <div
      style={
        disabled
          ? {
              ...style,
              cursor: 'not-allowed',
              width: 'fit-content',
              color: '#ccc',
              fontSize: 16,
            }
          : {
              ...style,
              width: 'fit-content',
              cursor: 'pointer',
              fontSize: 16,
            }
      }
      onClick={onClick}
      className="icon-button-wrapper"
      data-testid={testId}
    >
      <Tooltip title={title} trigger="hover">
        {icon && icon}
      </Tooltip>
    </div>
  );
}

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  style: {},
  title: '',
};

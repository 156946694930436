import { SoluterProfile } from '~/container';
import TitlePage from '~/components/TitlePage/TitlePage';

export default function PeopleDetail() {
  return (
    <>
      <TitlePage>Perfil do Soluter</TitlePage>
      <SoluterProfile />
    </>
  );
}

import { Typography } from 'antd';
import { FormatPainterOutlined } from '@ant-design/icons';

const { Title } = Typography;

export default function Empty() {
  return (
    <div
      style={{
        background: '#ececec',
        padding: '30px',
        display: 'grid',
        justifyItems: 'center',
      }}
    >
      <FormatPainterOutlined
        style={{ fontSize: '50px', marginBottom: '23px' }}
      />
      <Title level={3}>Estamos em construção</Title>
    </div>
  );
}

import { useSelector } from 'react-redux';
import { Typography, Skeleton } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;

export default function BadgeGeneralInfo({ badge }) {
  const { isLoadingTower } = useSelector(state => state.loading);

  return (
    <>
      <Title level={3} className="mt-md">
        Descrição
      </Title>
      <Skeleton paragraph={{ rows: 0 }} loading={isLoadingTower}>
        {badge?.description ? (
          <p>{badge?.description}</p>
        ) : (
          <p className="soluter-profile__missing-info">
            Esta badge ainda não possui uma descrição
          </p>
        )}
      </Skeleton>
    </>
  );
}

BadgeGeneralInfo.propTypes = {
  badge: PropTypes.object.isRequired,
};

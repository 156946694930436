import React from 'react';
import './index.scss';
import { Link, Router } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

const NotFound = () => {
  return (
    <div className="containerNotFound" data-testid="not-found-component">
      <p className="textNotFound">
        Essa página não existe ou é de acesso limitado
      </p>
      <Link to="/">
        Voltar para a página inicial <HomeOutlined />
      </Link>
    </div>
  );
};

export default NotFound;

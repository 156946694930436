import 'moment/locale/pt-br';
import { useDispatch } from 'react-redux';
import { Typography, Card, Tabs } from 'antd';
import { useEffect } from 'react';
import {
  MoodDistribution,
  MoodParticipation,
  MoodMonthlyTags,
  MoodHeader,
  MoodComments,
  MoodMonthlyAverage,
} from '~/container';
import './styles.scss';
import {
  setIsMonthlyAverageTrue,
  setIsMonthlyAverageFalse,
} from '~/store/ducks/mood';

const { Title } = Typography;
const { TabPane } = Tabs;

export default function Mood() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsMonthlyAverageFalse('isMonthlyAverage'));
  }, [dispatch]);
  return (
    <>
      <Title>Mood</Title>
      <MoodHeader />
      <div className="card-container">
        <Tabs
          defaultValue={1}
          className="graph-card"
          onChange={activeKey => {
            if (activeKey === '4') {
              dispatch(setIsMonthlyAverageTrue('isMonthlyAverage'));
            } else {
              dispatch(setIsMonthlyAverageFalse('isMonthlyAverage'));
            }
          }}
        >
          <TabPane tab="Distribuição de Sentimentos" key={1}>
            <Card className="graph-card graph-card-tags">
              <MoodDistribution />
            </Card>
          </TabPane>
          <TabPane tab="Visão Segmentada" key={4}>
            <Card className="graph-card graph-card-tags">
              <MoodMonthlyAverage />
            </Card>
          </TabPane>
          <TabPane tab="Seleção das Tags" key={0}>
            <Card className="graph-card graph-card-tags">
              <MoodMonthlyTags />
            </Card>
          </TabPane>
          <TabPane tab="Taxa de Adesão" key={2}>
            <Card className="graph-card graph-card-tags">
              <MoodParticipation />
            </Card>
          </TabPane>
          {/* <TabPane tab="Comentários" key={5}>
            <Card className="graph-card graph-card-tags">
              <MoodComments />
            </Card>
          </TabPane>{' '}  */}
        </Tabs>
      </div>
    </>
  );
}

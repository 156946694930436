import { Modal, Typography, Tag, Row, Button, List } from 'antd';
import { DownloadOutlined, LinkOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { downloadCompetenceFile } from '~/container/Competences/helpers/files';
import { formatLink } from '~/helpers/links';
import { people } from '~/constants';

const { Title } = Typography;

export default function SoluterCompetenceDetails({
  isVisible,
  setVisible,
  competence,
}) {
  return (
    <Modal
      title={<Title level={5}>{competence?.title}</Title>}
      visible={isVisible}
      onCancel={() => setVisible(false)}
      width="42vw"
      footer={null}
    >
      <Row justify="space-between">
        <div>
          <Title level={5}>Tipo</Title>
          <Tag
            style={{ marginBottom: 8 }}
            color={competence.type === 'GRADUATION' ? 'green' : 'blue'}
          >
            {people.mapCompetenceType[competence?.type]}
          </Tag>
        </div>
        <div>
          <Title level={5}>Status</Title>
          <p>{people.mapCompetenceStatus[competence.status]}</p>
        </div>
      </Row>
      <Title level={5}>Descrição</Title>
      <p
        className={`${
          competence?.description ? '' : `generic-card__description--empty`
        }`}
      >
        {competence?.description ||
          'Essa competência não possui uma descrição.'}
      </p>
      <Title level={5}>Data de Conclusão</Title>
      <p>{competence?.date}</p>

      <Title level={5}>Data de Validade</Title>
      <p>{competence?.expirationDate || 'Não se aplica'}</p>

      <Title level={5}>Link</Title>
      <p
        className={`${
          competence?.link ? '' : `generic-card__description--empty`
        }`}
      >
        {competence.link ? (
          <a
            href={formatLink(competence.link)}
            target="blank"
            style={{ marginLeft: 4 }}
          >
            {formatLink(competence.link)}
            <LinkOutlined style={{ marginLeft: 4 }} />
          </a>
        ) : (
          'Essa competência não possui um link associado.'
        )}
      </p>
      <Title level={5}>Anexo</Title>
      <p
        className={`${
          competence?.link ? '' : `generic-card__description--empty`
        }`}
      >
        {competence.filename ? (
          <List
            itemLayout="horizontal"
            dataSource={[competence.filename]}
            bordered
            renderItem={item => (
              <List.Item
                actions={[
                  <DownloadOutlined
                    style={{ cursor: 'pointer' }}
                    title="Baixar Certificação"
                    onClick={() =>
                      downloadCompetenceFile(competence.filename, competence.id)
                    }
                  />,
                ]}
              >
                {item}
              </List.Item>
            )}
          />
        ) : (
          'Essa competência não possui um arquivo anexo.'
        )}
      </p>
    </Modal>
  );
}

SoluterCompetenceDetails.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  competence: PropTypes.string.isRequired,
};

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BasicTable } from '~/components';
import { selectSuggestions } from '~/store/selectors';
import * as fetchSuggestions from '~/store/fetchActions/fetchSuggestions';
import { search as searchAction } from '~/store/ducks/suggestion';

export default function SkillsSuggestions() {
  const dispatch = useDispatch();
  const { suggestions } = useSelector(selectSuggestions);

  useEffect(() => {
    dispatch(fetchSuggestions.get());
  }, [dispatch]);

  return (
    <>
      <BasicTable
        stateSelector={state => state.suggestions}
        data={suggestions}
        title="Habilidades"
        fetchActions={fetchSuggestions}
        searchAction={searchAction}
        validationList
        paginate={false}
      />
    </>
  );
}

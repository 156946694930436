/* eslint-disable no-console */
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useEffect } from 'react';
import { updateExperiences } from '~/store/fetchActions/fetchPeople';
import { SelectExperiences, TagRender } from '~/components';

export default function AddEditExperiences({
  experienceList,
  isVisible,
  setVisible,
}) {
  const dispatch = useDispatch();
  const { email } = useParams();
  const [form] = Form.useForm();

  const handleSubmit = ({ experience }) => {
    if (email && experience) {
      dispatch(updateExperiences(email, experience));
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    if (isVisible && experienceList) {
      form.setFieldsValue({
        experience: experienceList,
      });
    }
  }, [form, experienceList, isVisible]);

  return (
    <Modal
      visible={isVisible}
      title={`${experienceList.length ? 'Editar' : 'Adicionar'} Experiências`}
      okText={experienceList.length ? 'Salvar' : 'Adicionar'}
      cancelText="Cancelar"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            setVisible(false);
            handleSubmit(values);
            form.resetFields();
          })
          .catch(console.error);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark
        onFinish={handleSubmit}
        data-testid="modal-experience-form"
      >
        <SelectExperiences
          fieldName="experience"
          required={false}
          selectMode="multiple"
          tagRenderFunc={TagRender}
          style={{ width: 400 }}
          testId="select-experience"
          defaultValue={experienceList || []}
        />
      </Form>
    </Modal>
  );
}

AddEditExperiences.propTypes = {
  experienceList: PropTypes.array,
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

AddEditExperiences.defaultProps = {
  experienceList: [],
};

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMonthlyAverageAction } from '~/store/ducks/mood';

export default function useMoodData(
  data,
  service,
  weekIntervalSelector,
  returnedDataSelector,
  ...props
) {
  const dispatch = useDispatch();

  const week = useSelector(weekIntervalSelector);
  const tower = useSelector(state => state.mood.selectedTower);
  const towers = useSelector(state => state.mood.selectedTowers);
  const practice = useSelector(state => state.mood.selectedPractice);
  const practices = useSelector(state => state.mood.selectedPractices);
  const costCenters = useSelector(state => state.mood.selectedCostCenters);
  const returned = useSelector(
    returnedDataSelector || (state => state.mood[data]),
  );

  useEffect(() => {
    if (
      week.start !== null &&
      week.start !== '' &&
      week.end !== null &&
      week.end !== ''
    ) {
      if (
        data !== 'monthlyAverage' ||
        towers.length > 0 ||
        practices.length > 0 ||
        costCenters.length > 0
      ) {
        dispatch(service(week, ...props));
      } else {
        dispatch(getMonthlyAverageAction(''));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    service,
    week,
    tower,
    towers,
    practice,
    practices,
    costCenters,
  ]);

  return returned;
}

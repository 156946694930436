import { useSelector, useDispatch } from 'react-redux';
import { Space, Typography, Skeleton, Row, Col, Button } from 'antd';
import {
  IdcardOutlined,
  StarOutlined,
  AlertOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { Button as CustomButton, Tag, ModalForm } from '~/components';
import './styles.scss';
import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import TechnicalInfoForm from '~/container/Towers/TechnicalInfoForm';
import { colors } from '~/constants';

const { Title } = Typography;

export default function SoluterTechnicalInfo() {
  const dispatch = useDispatch();

  const {
    primaryRole: _primaryRole,
    infoTechnologies: _infoTechnologies,
    technologies: _technologies,
    seniority: _seniority,
    workload: _workload,
    email,
  } = useSelector(state => state.people.soluter);

  const { isLoadingSoluterBasicData } = useSelector(state => state.loading);

  const handleSubmit = async ({
    workload,
    primaryRole,
    seniority,
    infoTechnologies,
    technologies,
  }) => {
    await fetchPeople.addTechnicalInfo(email, {
      workload,
      primaryRole,
      seniority,
      infoTechnologies,
      technologies,
    });

    dispatch(fetchPeople.getSoluterBasicData(email, true));
  };

  return (
    <div>
      <Row style={{ justifyContent: 'space-between  ' }}>
        <Col span={_workload ? 12 : 8}>
          <div>
            <Title level={5}>Papel Principal</Title>
            <div style={{ display: 'flex' }}>
              <Skeleton
                paragraph={{ rows: 0 }}
                loading={isLoadingSoluterBasicData}
              >
                {_primaryRole ? (
                  <p>{_primaryRole}</p>
                ) : (
                  <p className="soluter-profile__missing-info">
                    <IdcardOutlined /> Este usuário ainda não tem um papel
                    atribuído.
                  </p>
                )}
              </Skeleton>
            </div>
          </div>
          <div className="soluter-profile__item">
            <Title level={5}>Senioridade</Title>
            <div style={{ display: 'flex' }}>
              <Skeleton
                paragraph={{ rows: 0 }}
                loading={isLoadingSoluterBasicData}
              >
                {_seniority ? (
                  <p>{_seniority}</p>
                ) : (
                  <p className="soluter-profile__missing-info">
                    <StarOutlined /> Este usuário ainda não tem uma senioridade
                    atribuída.
                  </p>
                )}
              </Skeleton>
            </div>
          </div>
          <div className="soluter-profile__item">
            <Title level={5}>Tecnologias</Title>
            <Skeleton
              paragraph={{ rows: 0 }}
              loading={isLoadingSoluterBasicData}
            >
              <Space size={[8, 16]} wrap>
                {_technologies && _technologies?.length > 0 ? (
                  _technologies?.map(tech => (
                    <Tag
                      tag={{ tag: tech }}
                      backgroundColor={colors.TECHNOLOGIES}
                      textColor="white"
                    />
                  ))
                ) : (
                  <p className="soluter-profile__missing-info">
                    <StarOutlined /> Nenhuma tecnologia registrada (ainda).
                  </p>
                )}
              </Space>
            </Skeleton>
          </div>
        </Col>
        <Col>
          <Title level={5}>Carga de Trabalho</Title>
          <Skeleton paragraph={{ rows: 0 }} loading={isLoadingSoluterBasicData}>
            {_workload ? (
              <p>{_workload}h mensais</p>
            ) : (
              <p className="soluter-profile__missing-info">
                <ClockCircleOutlined /> Este usuário ainda não tem carga de
                trabalho registrada.
              </p>
            )}
          </Skeleton>
        </Col>
        <Col span={_workload ? 2 : 5}>
          <ModalForm
            button={
              _workload ? (
                <CustomButton
                  type="edit"
                  style={{ fontSize: '20px', color: '#dfa211' }}
                  tooltipIdentifier="Informações Gerais do Soluter"
                />
              ) : (
                <Button>Adicionar Informações</Button>
              )
            }
            handleSubmit={handleSubmit}
            title={
              _workload
                ? 'Editar Informações do Soluter'
                : 'Adicionar Informações do Soluter'
            }
            okButtonText={_workload ? 'Salvar' : 'Adicionar'}
          >
            <TechnicalInfoForm
              info={
                _workload
                  ? {
                      workload: _workload,
                      primaryRole: _primaryRole,
                      technologies: _technologies,
                      seniority: _seniority,
                    }
                  : null
              }
            />
          </ModalForm>
        </Col>
      </Row>
    </div>
  );
}

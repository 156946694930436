/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { downloadFile } from '~/helpers/download';

export default function DownloadButton({
  data,
  requestData,
  clearData,
  label,
  fileName,
  exportCsvLayout,
  disabled,
}) {
  const downloadRef = useRef(null);
  const dispatch = useDispatch();

  const handleClick = () => {
    requestData();
  };

  useEffect(() => {
    if (data) {
      downloadFile(fileName, data);
      dispatch(clearData());
    }
  }, [data, dispatch, clearData, fileName]);

  return (
    <div className={exportCsvLayout ? 'download' : ''}>
      <Button disabled={disabled} onClick={handleClick}>
        {label}
      </Button>
    </div>
  );
}

DownloadButton.propTypes = {
  data: PropTypes.string.isRequired,
  requestData: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  label: PropTypes.string,
  fileName: PropTypes.string,
  exportCsvLayout: PropTypes.bool,
  disabled: PropTypes.bool,
};

DownloadButton.defaultProps = {
  label: 'Download',
  fileName: 'file',
  exportCsvLayout: false,
  disabled: false,
};

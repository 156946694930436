// DOCS: https://gitlab.solutis.digital/ads/passport/passport-back-office/wikis/Planejamento
import { isMonthInInterval, monthToNumber } from './scheduler';

export default class Allocation {
  constructor(
    { startDate, endDate, id, _id, amount, isVacation },
    month,
    currentYear,
  ) {
    this.id = id || _id;
    this.startDate = new Date(startDate);
    this.endDate = new Date(endDate);
    this.amount = amount;
    this.month = month;
    this.currentYear = currentYear;
    this.isVacation = isVacation || null;
  }

  getStartDay() {
    return this.startDate.getDate();
  }

  getEndDay() {
    return this.endDate.getDate();
  }

  getStartMonth() {
    return this.startDate.getMonth();
  }

  getEndMonth() {
    return this.endDate.getMonth();
  }

  getStartOfSecondMonth() {
    return new Date(this.getStartYear(), this.startDate.getMonth() + 1, 1);
  }

  getStartYear() {
    return this.startDate.getFullYear().toString();
  }

  getEndYear() {
    return this.endDate.getFullYear().toString();
  }

  isMonthAllocated() {
    return isMonthInInterval(
      this.month,
      this.currentYear,
      this.getStartMonth(),
      this.getEndMonth(),
      this.getStartYear(),
      this.getEndYear(),
    );
  }

  isStartMonth() {
    return (
      monthToNumber[this.month] === this.getStartMonth() &&
      (!this.currentYear || this.getStartYear() === this.currentYear)
    );
  }

  isEndMonth() {
    return (
      monthToNumber[this.month] === this.getEndMonth() &&
      (!this.currentYear || this.getEndYear() === this.currentYear)
    );
  }

  isWithinSameMonth() {
    return this.isEndMonth() && this.isStartMonth();
  }

  isSecondMonth() {
    return (
      this.startDate.getDate() > (this.isVacation ? 26 : 21) &&
      monthToNumber[this.month] === this.getStartMonth() + 1 &&
      this.getStartYear() === this.currentYear
    );
  }

  isSecondYear() {
    return (
      this.currentYear !== this.getStartYear() &&
      monthToNumber[this.month] === 0
    );
  }

  getBarWidth() {
    if (!this.isEndMonth() && !this.isStartMonth()) return '100%';
    if (this.isEndMonth() && this.isStartMonth()) {
      const interval = this.endDate.getDate() - this.startDate.getDate();
      return interval > 1 ? (interval + 1) * 5 : 10;
    }
    if (this.isStartMonth()) {
      return (32 - this.startDate.getDate()) * 5;
    }
    if (this.isEndMonth()) return this.endDate.getDate() * 5;
    return 0;
  }
}

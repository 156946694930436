export const HOME = '/';
export const LOGIN = '/login';
export const SKILLS = '/skills';
export const EXPERIENCES = '/experiences';
export const DISTRICTS = {
  LIST: '/districts',
  ADD: '/districts/add',
  EDIT: '/districts/edit/:districtId',
  DETAILS: '/districts/details/:districtId',
};
export const MOOD = {
  DASHBOARD: '/mood',
  TAGS: '/mood/tags',
};
export const SOLUTERS = {
  LIST: '/soluters',
  DETAIL: '/soluters/:email',
};
export const TOWER = {
  REGISTER: '/towers/register',
  SCHEDULE: '/towers/schedule',
  CAPACITY: '/towers/capacity',
  DETAILS: '/towers/details/:towerId',
  EDIT: '/towers/edit/:towerId',
};
export const EMPTY_PAGE = '/empty';
export const ROLES = '/roles';
export const PRACTICES = '/practices';
export const INDICATORS = {
  TOWERS: '/indicators/towers',
  GENERAL: '/indicators/general',
  MOOD: '/indicators/moods',
};
export const BADGES = {
  DASHBOARD: '/badges/dashboard',
  EDIT: '/badges/edit/:badgeId',
};
export const COMPETENCES = '/competences';
export const INTERVIEWS = '/interviews';
export const FAMILIES = '/families';
export const JOURNEY = '/journey';
export const JOURNEY_EDIT = '/journey/:buId';
export const USEFUL_LINKS = '/utils';
export const BUSINESS_UNITS = '/business-units/:businessUnitId';
export const DASHBORD_BI = '/dashboardBi';

export default {
  HOME,
  SKILLS,
  LOGIN,
  DISTRICTS,
  EMPTY_PAGE,
  MOOD,
  SOLUTERS,
  TOWER,
  BADGES,
  ROLES,
  INDICATORS,
  PRACTICES,
  COMPETENCES,
  INTERVIEWS,
  FAMILIES,
  JOURNEY,
  JOURNEY_EDIT,
  USEFUL_LINKS,
  BUSINESS_UNITS,
  EXPERIENCES,
  DASHBORD_BI,
};

import { configureStore } from '@reduxjs/toolkit';
import SkillsReducer from '~/store/ducks/skills';
import FamiliesReducer from '~/store/ducks/families';
import AuthReducer from '~/store/ducks/auth';
import LoadingReducer from '~/store/ducks/loading';
import DistrictsReducer from '~/store/ducks/districts';
import UserReducer from '~/store/ducks/user';
import MoodReducer from '~/store/ducks/mood';
import PeopleReducer from '~/store/ducks/people';
import SoluterProjectReducer from '~/store/ducks/soluterProject';
import TowerReducer from '~/store/ducks/towers';
import BadgeReducer from '~/store/ducks/badges';
import RolesReducer from '~/store/ducks/roles';
import IndicatorsReducer from '~/store/ducks/indicators';
import PracticesReducer from '~/store/ducks/practices';
import InterviewsReducer from '~/store/ducks/interviews';
import JourneyReducer from '~/store/ducks/journey';
import CompetenceReducer from '~/store/ducks/competence';
import ExperiencesReducer from '~/store/ducks/experiences';
import TecnologiasReducer from '~/store/ducks/tecnologias';
import SuggestionReducer from '~/store/ducks/suggestion';

import { hasTokenValid, isAuthenticated, getUser } from '~/config/environment';

let preloadedState;

if (hasTokenValid() && isAuthenticated()) {
  preloadedState = { auth: { authorized: true }, user: { user: getUser() } };
}

export default configureStore({
  reducer: {
    skills: SkillsReducer,
    auth: AuthReducer,
    loading: LoadingReducer,
    districts: DistrictsReducer,
    user: UserReducer,
    mood: MoodReducer,
    people: PeopleReducer,
    towers: TowerReducer,
    indicators: IndicatorsReducer,
    roles: RolesReducer,
    practices: PracticesReducer,
    interviews: InterviewsReducer,
    families: FamiliesReducer,
    journey: JourneyReducer,
    competence: CompetenceReducer,
    badges: BadgeReducer,
    experiences: ExperiencesReducer,
    tecnologias: TecnologiasReducer,
    suggestions: SuggestionReducer,
    soluterProject: SoluterProjectReducer,
  },
  preloadedState,
});

import React from 'react';
import { Row } from 'antd';
import Pie from '@ant-design/charts/lib/pie';
import PropTypes from 'prop-types';
import { EmptyErrorWrapper } from '~/components';
import { soluterJourneyColors } from '~/constants/indicators';
import { getSoluterJourneyGraph } from '~/store/fetchActions/fetchIndicators';

function GraphSoluterJourney({
  selectedTower,
  data,
  amountOfSolutersWithJourney,
}) {
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    innerRadius: 0.65,
    legend: false,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },

    statistic: {
      title: {
        style: { fontSize: '17px', whiteSpace: 'pre-wrap', height: '45px' },
        offsetY: -4,
        customHtml: function customHtml() {
          const text = 'Total de Soluters com um Norte Registrado';
          return '<div style="width: 200px;">'.concat(text, '</div>');
        },
      },
      content: {
        offsetY: 4,
        style: { fontSize: '28px' },
        customHtml: function customHtml() {
          const total = amountOfSolutersWithJourney || '-';
          return ''.concat(total);
        },
      },
    },
    color: ({ type }) => {
      return soluterJourneyColors[type];
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      {
        type: 'tooltip',
        cfg: {
          start: [{ trigger: 'element:mousemove', action: 'tooltip:show' }],
        },
      },
    ],
  };

  return (
    <Row data-testid="soluter-journey-graph" justify="center">
      <EmptyErrorWrapper
        data={data}
        emptyCondition={data?.length > 0}
        loaderTitle="isLoadingGraph"
        retryAction={getSoluterJourneyGraph}
        retryProps={[selectedTower]}
        style={{ height: '50vh', width: '100%' }}
        emptyMessage="Não há Soluters com um norte definido dentre os filtros selecionados."
      >
        <Pie {...config} />
      </EmptyErrorWrapper>
    </Row>
  );
}

GraphSoluterJourney.propTypes = {
  selectedTower: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  amountOfSolutersWithJourney: PropTypes.number.isRequired,
};

export default React.memo(GraphSoluterJourney);

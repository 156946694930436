import { Typography, Tabs, Form, Row, Col } from 'antd';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  GraphPassaportUsage,
  SolutersBySkills,
  SoluterJourneyContainer,
  SolutersMapContainer,
  AdmissionContainer,
} from '~/container';
import {
  PracticeFilter,
  MultipleCostCenterFilter,
  ActiveFilters,
} from '~/components';
import {
  setSelectedPractice,
  setSelectedCostCenter,
  removeSelectedCostCenter,
  setSelectedTower,
} from '~/store/ducks/indicators';

const { Title } = Typography;
const { TabPane } = Tabs;

export default function IndicatorsGeneral() {
  const [form] = Form.useForm();

  const { selectedPractice, selectedCostCenter } = useSelector(
    state => state.indicators,
  );
  const dispatch = useDispatch();

  const onChangePractice = practiceId => {
    dispatch(setSelectedPractice(practiceId));
    dispatch(setSelectedCostCenter([]));
    dispatch(setSelectedTower({ value: '', label: '' }));
  };

  const onChangeSelectedTower = useCallback(() => {
    dispatch(setSelectedPractice(''));
    dispatch(setSelectedCostCenter([]));
    form.setFieldsValue({
      selectedPractice: null,
      selectedCostCenters: [],
    });
  }, [dispatch, form]);

  const onChangeCostCenterSelect = ([value]) => {
    if (!selectedCostCenter.includes(value)) {
      dispatch(setSelectedCostCenter([...selectedCostCenter, value]));
    }
    dispatch(setSelectedTower({ value: '', label: '' }));
    form.setFieldsValue({
      selectedCostCenters: [],
    });
  };

  const handleCostCentersTagClose = filter => {
    dispatch(removeSelectedCostCenter(filter));
  };

  return (
    <>
      <Row justify="space-between" align="bottom">
        <Col>
          <Title>Indicadores Gerais</Title>
          <Form form={form}>
            <div
              style={{
                display: 'flex',
                width: '40vw',
              }}
            >
              <PracticeFilter
                onChangePractice={onChangePractice}
                fieldName="selectedPractice"
                defaultValue={selectedPractice}
              />
              <MultipleCostCenterFilter
                selectedPractice={selectedPractice}
                fieldName="selectedCostCenters"
                onChange={onChangeCostCenterSelect}
                style={{ width: 300, marginLeft: 20 }}
              />
            </div>
          </Form>
        </Col>
      </Row>
      <ActiveFilters
        filters={[...selectedCostCenter]}
        label="Centros de Custos"
        isTagClosable
        handleTagClose={handleCostCentersTagClose}
        handleClear={() => dispatch(setSelectedCostCenter([]))}
      />
      <div className="card-container">
        <Tabs className="graph-card">
          <TabPane tab="Distribuição Geográfica" key="soluters-map">
            <SolutersMapContainer
              selectedPractice={selectedPractice}
              selectedCostCenter={selectedCostCenter}
            />
          </TabPane>
          <TabPane tab="Soluters por Tecnologia" key="soluters-skills">
            <SolutersBySkills />
          </TabPane>
          <TabPane tab="Uso do Passaporte" key="passport-usage">
            <GraphPassaportUsage />
          </TabPane>
          <TabPane tab="Jornada do Soluter" key="soluters-journey">
            <SoluterJourneyContainer
              onChangeSelectedTower={onChangeSelectedTower}
            />
          </TabPane>
          <TabPane tab="Movimentações" key="admissions">
            <AdmissionContainer />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

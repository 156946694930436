import { useCallback, useMemo, useState } from 'react';

import { removeSoluterCompetence } from '~/store/fetchActions/fetchPeople';
import { downloadCompetenceFile } from '../helpers/files';

export default function useCompetenceTableActions() {
  const [competence, setCompetence] = useState();
  const [mode, setMode] = useState();

  const isEditing = useMemo(() => mode === 'edit', [mode]);

  const handleEditCompetence = useCallback(currentCompetence => {
    setCompetence(currentCompetence);
    setMode('edit');
  }, []);

  const handleDownloadCompetence = useCallback(({ id, filename }) => {
    downloadCompetenceFile(filename, id);
  }, []);

  const handleDeleteCompetence = useCallback(competenceId => {
    return removeSoluterCompetence(competenceId);
  }, []);

  const clearCurrentCompetence = useCallback(() => {
    setCompetence(undefined);
    setMode(undefined);
  }, []);

  return {
    competence,
    isEditing,
    handleEditCompetence,
    handleDownloadCompetence,
    handleDeleteCompetence,
    clearCurrentCompetence,
  };
}

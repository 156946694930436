import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DistrictsForm, TitlePage } from '~/components';
import * as fetchDistricts from '~/store/fetchActions/fetchDistricts';

export default function DistrictsAdd() {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleSubmit(values) {
    (() => {
      dispatch(fetchDistricts.add(values));
      history.push('/districts');
    })();
  }

  return (
    <>
      <TitlePage>Adicionar Distrito</TitlePage>
      <DistrictsForm onSubmit={handleSubmit} />
    </>
  );
}

/* eslint-disable no-nested-ternary */
import { useSelector } from 'react-redux';
import { Tooltip, Space, Spin, Empty } from 'antd';
import PropTypes from 'prop-types';
import { Tag, ErrorHandler } from '~/components';
import {
  approvedTags as setApprovedTags,
  notApprovedTags as setNotApprovedTags,
} from '~/store/fetchActions/fetchMood';
import moods from '~/constants/moods';
import './styles.scss';

export default function MoodTagList({
  tags,
  approvedTags,
  loader,
  handleEmptyContentAsError,
}) {
  const { week, selectedTower } = useSelector(state => state.mood);
  const tagColor = approvedTags
    ? moods.APPROVED_COLOR_LIGHT
    : moods.NOT_APPROVED_COLOR_LIGHT;
  const question = approvedTags
    ? moods.APPROVED_TAGS_QUESTION
    : moods.NOT_APPROVED_TAGS_QUESTION;

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '30px' }}>
        <p className="mood-tag__question">{question}</p>
        <Space direction="vertical" className="space-tags" size={16}>
          {loader ? (
            <Spin spinning={loader} />
          ) : tags.length ? (
            tags.map(tag => {
              return (
                <div className="mood-tag__div" key={tag}>
                  <Tag tag={tag} backgroundColor={tagColor} />
                  <Tooltip title={`${tag.votes} votos`} trigger="hover">
                    <p className="mood-tag__p">{tag.percentage}%</p>
                  </Tooltip>
                </div>
              );
            })
          ) : handleEmptyContentAsError ? (
            <ErrorHandler
              action={approvedTags ? setApprovedTags : setNotApprovedTags}
              props={[week, selectedTower]}
            />
          ) : (
            <Empty
              description="Parece que os Soluters que avaliaram com este emoji não marcaram
            nenhuma tag"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Space>
      </div>
    </>
  );
}

MoodTagList.propTypes = {
  approvedTags: PropTypes.bool,
  tags: PropTypes.array,
  loader: PropTypes.bool,
  handleEmptyContentAsError: PropTypes.bool,
};

MoodTagList.defaultProps = {
  approvedTags: false,
  loader: false,
  tags: [],
  handleEmptyContentAsError: false,
};

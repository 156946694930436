import { Tag } from 'antd';
import PropTypes from 'prop-types';

export default function TagRender({ label, closable, onClose }) {
  return (
    <Tag closable={closable} onClose={onClose} color="blue">
      {label}
    </Tag>
  );
}

TagRender.propTypes = {
  label: PropTypes.string.isRequired,
  closable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

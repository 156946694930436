import React from 'react';

import { Typography } from 'antd';

import CompetenceActions from '~/container/Competences/CompetenceActions';
import CompetenceTable from '~/container/Competences/CompetenceTable';
import CompetenceFilter from '~/container/Competences/CompetenceFilter';

const { Title } = Typography;

export default function Competences() {
  return (
    <>
      <Title style={{ display: 'flex', justifyContent: 'space-between' }}>
        Certificações
        <CompetenceActions />
      </Title>
      <CompetenceFilter />
      <CompetenceTable />
    </>
  );
}

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Input, Upload, message, Tooltip, Card, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionCircleOutlined, CameraOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { BlockPicker } from 'react-color';
import * as fetchDistricts from '~/store/fetchActions/fetchDistricts';
import { FormButton, MemberList } from '~/components';
import { validationMessages, districtsConsts, colors } from '~/constants';
import { selectDistrictsDetail } from '~/store/selectors';

function beforeUpload(file) {
  // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  // if (!isJpgOrPng) {
  //   message.error('You can only upload JPG/PNG file!');
  // }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error('Image must be smaller than 2MB!');
  // }
  // return isJpgOrPng && isLt2M;
  return false;
}

export default function DistrictsForm({
  districtId,
  onSubmit: customOnSubmit,
}) {
  const dispatch = useDispatch();
  const [districtsForm] = Form.useForm();

  const { pathname } = useLocation();
  const isEditing = pathname.includes('edit');

  const district = useSelector(selectDistrictsDetail);
  const { isUpdatingDistrict } = useSelector(state => state.loading);

  const [fileListThumb, setFileListThumb] = useState([]);
  const [fileListPhoto, setFileListPhoto] = useState([]);
  const [color, setColor] = useState(colors.DEFAULT_COLOR);

  useEffect(() => {
    if (isEditing) dispatch(fetchDistricts.getByDistrictId(districtId));
  }, [dispatch, districtId, isEditing]);

  useEffect(() => {
    districtsForm.resetFields();
    setColor(district?.color || colors.DEFAULT_COLOR);
  }, [district, districtsForm]);

  const onChange = ({ fileList: newFileListThumb }) => {
    setFileListThumb(newFileListThumb);
  };

  const onChangePhoto = ({ fileList: newFileListPhoto }) => {
    setFileListPhoto(newFileListPhoto);
  };

  function onSubmit(e) {
    e = { ...e, color, id: district?.id };
    if (e.name.toLowerCase().includes('distrito')) {
      message.error(validationMessages.ERROR_MESSAGE_DISTRICT_NAME);
    } else {
      customOnSubmit(e);
      if (isEditing && e.thumb !== undefined) {
        const formDataThumb = new FormData();
        formDataThumb.append('file', e.thumb.file);
        dispatch(
          fetchDistricts.editThumbByDistrictId(districtId, formDataThumb),
        );
      }
      if (isEditing && e.photo !== undefined) {
        const formDataPhoto = new FormData();
        formDataPhoto.append('file', e.photo.file);
        dispatch(
          fetchDistricts.editPhotoByDistrictId(districtId, formDataPhoto),
        );
      }
    }
  }

  return (
    <Form
      form={districtsForm}
      name="edit-district"
      onFinish={onSubmit}
      layout="vertical"
      style={{ width: '100%', height: 'auto' }}
      validateMessages={validationMessages.VALIDATION_MESSAGE}
    >
      <Card
        style={{
          boxShadow: '1px 2px 2px #d8d6d6ee',
          borderRadius: '1rem',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: isEditing ? 'column' : 'row',
            width: isEditing ? '50%' : '100%',
            float: 'left',
          }}
        >
          <div style={{ flexGrow: 2 }}>
            <div style={{ display: 'flex' }}>
              <Form.Item
                name="name"
                style={{ width: isEditing ? '95%' : '70%' }}
                initialValue={district?.name}
                rules={[
                  {
                    type: 'string',
                    min: 2,
                    required: true,
                    message:
                      'O nome é obrigatório e deve ter no mínimo 2 caracteres',
                  },
                ]}
                label="Nome"
                tooltip={{
                  title: districtsConsts.DISTRICT_NAME_INFO,
                  icon: <QuestionCircleOutlined />,
                  placement: 'rightBottom',
                }}
              >
                <Input
                  disabled={isEditing && !district.name}
                  placeholder="Nome"
                  allowClear
                  aria-label="name"
                />
              </Form.Item>
            </div>
            <Form.Item
              name="description"
              style={{ width: isEditing ? '95%' : '70%' }}
              initialValue={district?.description}
              label="Descrição"
              rules={[
                {
                  type: 'string',
                  min: 3,
                  max: 240,
                  required: true,
                  message:
                    'A descrição é obrigatória e deve ter entre 3 e 240 caracteres',
                },
              ]}
            >
              <Input.TextArea
                disabled={isEditing && district.description == null}
                rows="4"
                placeholder="Descrição"
                allowClear
                aria-label="description"
              />
            </Form.Item>

            {isEditing ? null : (
              <div style={{ display: 'flex' }}>
                <Tooltip
                  placement="right"
                  title={districtsConsts.DISTRICT_CHANNELS_INFO}
                >
                  <div style={{ display: 'flex' }}>
                    <p
                      style={{ textDecoration: 'underline', fontSize: '15px' }}
                    >
                      Informações sobre os canais do distrito
                    </p>
                    <QuestionCircleOutlined
                      style={{
                        height: '30px',
                        marginLeft: '5px',
                        alignSelf: 'center',
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
            }}
          >
            <div>
              <Form.Item
                name="thumb"
                style={{ marginBottom: 4 }}
                initialValue={undefined}
              >
                <Upload
                  beforeUpload={beforeUpload}
                  listType="picture-card"
                  fileList={fileListThumb}
                  onChange={onChange}
                  maxCount={1}
                >
                  Thumb
                  <CameraOutlined style={{ marginLeft: '5px' }} />
                </Upload>
              </Form.Item>
              <Form.Item
                name="photo"
                // style={{ width: '49.5%' }}
                initialValue={undefined}
              >
                <Upload
                  beforeUpload={beforeUpload}
                  listType="picture-card"
                  fileList={fileListPhoto}
                  onChange={onChangePhoto}
                  maxCount={1}
                >
                  Foto
                  <CameraOutlined style={{ marginLeft: '5px' }} />
                </Upload>
              </Form.Item>
            </div>
            <Form.Item name="color">
              <BlockPicker
                color={color}
                colors={colors.COLOR_PICKER_OPTIONS}
                triangle="hide"
                onChange={_color => setColor(_color.hex)}
              />
            </Form.Item>
            <Tooltip
              title={districtsConsts.DISTRICT_DEFAULT_COLOR_INFO}
              placement="top"
            >
              <QuestionCircleOutlined style={{ marginLeft: 10 }} />
            </Tooltip>
          </div>
        </div>

        <div
          style={{
            display: 'inline-block',
            width: '50%',
            float: 'left',
            minHeight: 100,
          }}
        >
          {isEditing && (
            <Card style={{ height: '600px' }}>
              <MemberList district={district} isEditing={isEditing} />
            </Card>
          )}
        </div>
      </Card>
      <Row justify="end">
        <Form.Item style={{ width: '150px', marginLeft: '10px' }}>
          <FormButton
            link="/districts"
            type="default"
            text="Retornar"
            htmlType="button"
          />
        </Form.Item>
        <Form.Item style={{ width: '150px', marginLeft: '10px' }}>
          <FormButton
            type="primary"
            htmlType="submit"
            text={isEditing ? 'Salvar' : 'Cadastrar'}
            isLoading={isUpdatingDistrict}
          />
        </Form.Item>
      </Row>
    </Form>
  );
}

DistrictsForm.propTypes = {
  districtId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

DistrictsForm.defaultProps = {
  districtId: null,
};

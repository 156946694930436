import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getPractices } from '~/store/fetchActions/fetchPractices';

const { Option } = Select;

export default function PracticeFilter({
  style,
  fieldLabel,
  fieldName,
  onChangePractice,
  defaultValue,
}) {
  const dispatch = useDispatch();
  const { practices } = useSelector(state => state.practices);
  const { isLoadingPractices } = useSelector(state => state.loading);

  useEffect(() => {
    dispatch(getPractices(false));
  }, [dispatch]);

  return (
    <Form.Item
      name={fieldName}
      style={{ ...style }}
      label={fieldLabel}
      initialValue={defaultValue || null}
    >
      <Select
        placeholder="Buscar por BU"
        allowClear
        filterOption={false}
        onChange={onChangePractice}
        notFoundContent={isLoadingPractices ? <Spin size="small" /> : null}
        data-testid="practice-select"
      >
        {practices?.map((practice, key) => (
          <Option
            key={practice.name}
            value={practice.id}
            data-testid={`practice-option-${key}`}
          >
            {`${practice.name}`}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

PracticeFilter.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string,
  style: PropTypes.object,
  onChangePractice: PropTypes.func,
  defaultValue: PropTypes.string,
};

PracticeFilter.defaultProps = {
  style: { width: 300 },
  fieldLabel: '',
  onChangePractice: () => {},
  defaultValue: null,
};

import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  tecnologias: [],
  tecnologia: {},
  total: 0,
  page: 1,
  selectedTecnologia: -1,
  search: false,
  searchTitle: '',
};

export const get = createAction('TECNOLOGIAS_GET');
export const search = createAction('TECNOLOGIAS_SEARCH');

export default createReducer(INITIAL_STATE, {
  [get.type]: (state, action) => ({
    ...state,
    tecnologias: action.payload.tecnologias,
    total: action.payload.total,
    page: action.payload.page,
  }),
  [search.type]: (state, action) => ({
    ...state,
    search: action.payload.search,
    searchTitle: action.payload.searchTitle,
  }),
});

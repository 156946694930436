import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isLoading: false,
};

export const startLoading = createAction('START_LOADING');
export const endLoading = createAction('END_LOADING');

export default createReducer(INITIAL_STATE, {
  [startLoading.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'isLoading' : payload]: true,
    };
  },
  [endLoading.type]: (state, { payload }) => ({
    ...state,
    [!payload ? 'isLoading' : payload]: false,
  }),
});

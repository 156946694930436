import './styles.scss';
import PropTypes from 'prop-types';
import { Button } from '~/components';
import { colors } from '~/constants';

export default function Tag({
  tag,
  backgroundColor,
  textColor,
  isRemovable,
  handleDelete,
}) {
  return (
    <div
      key={tag.tag}
      className="passport-tag"
      style={{
        backgroundColor,
        color: textColor,
        textAlign: tag?.tag?.length > 20 ? 'left' : 'center',
      }}
    >
      <p
        style={{
          margin: !isRemovable ? '0px 0px 0px 0px' : '0px 10px 0px 10px',
        }}
      >
        {tag.tag}
      </p>
      {isRemovable && (
        <Button
          type="delete"
          title={tag.tag}
          handleDelete={handleDelete}
          id={tag.tag}
          data-testid={`delete-tag-button-${tag.tag}`}
        />
      )}
    </div>
  );
}

Tag.propTypes = {
  tag: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  isRemovable: PropTypes.bool,
  handleDelete: PropTypes.func,
};

Tag.defaultProps = {
  backgroundColor: colors.DEFAULT_COLOR,
  textColor: 'black',
  isRemovable: false,
  handleDelete: id => {},
};

import { Space, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Legend } from '~/components';
import { soluterJourneyColors } from '~/constants/indicators';

export default function SoluterJourneyLegend({ handleOpenDrawer }) {
  const { data } = useSelector(state => state.indicators.soluterJourney);
  const { isLoadingGraph } = useSelector(state => state.loading);

  return (
    <Space
      data-testid="soluter-journey-legend"
      size={15}
      direction="vertical"
      style={{ width: '100%' }}
    >
      {data?.map((datum, index) => {
        return (
          <Row key={datum.type} align="middle" style={{ minHeight: 32 }}>
            <Legend
              color={soluterJourneyColors[datum.type]}
              text={datum.type}
              size={10}
              round
            />
            {isLoadingGraph && <Spin size="small" style={{ marginLeft: 8 }} />}

            {!isLoadingGraph && data?.length > 0 && (
              <>
                :
                <p className="mb-0" style={{ marginLeft: 5 }}>
                  {data[index]?.value}
                </p>
                <Button
                  style={{ marginLeft: 5 }}
                  type="view"
                  isButton
                  tooltipIdentifier="Soluters"
                  handleClick={() => {
                    handleOpenDrawer(datum.type);
                  }}
                />
              </>
            )}
          </Row>
        );
      })}
    </Space>
  );
}

SoluterJourneyLegend.propTypes = {
  handleOpenDrawer: PropTypes.func.isRequired,
};

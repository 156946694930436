import { useState } from 'react';
import { List, Skeleton, Tag, Typography, Empty } from 'antd';
import PropTypes from 'prop-types';

import SoluterCompetenceDetails from './SoluterCompetenceDetails';
import { formatLink } from '~/helpers/links';
import { Button } from '~/components';
import { people } from '~/constants';
import './styles.scss';

const { Text, Link } = Typography;

export default function SoluterCompetences({ competences }) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCompetence, setSelectedCompetence] = useState('');

  return (
    <>
      <List
        dataSource={competences}
        grid={{ column: 3, sm: 1, md: 2, xxl: 4, gutter: 8 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Este Soluter ainda não possui competências associadas."
            />
          ),
        }}
        renderItem={competence => {
          return (
            <>
              <List.Item>
                <Skeleton loading={false}>
                  <div className="soluter-comment" style={{ minHeight: 120 }}>
                    <div
                      className="d-flex"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <Tag
                        color={
                          competence.type === 'GRADUATION' ? 'green' : 'blue'
                        }
                      >
                        {people.mapCompetenceType[competence.type]}
                      </Tag>
                      {competence.status === 'ONGOING'
                        ? 'Em andamento'
                        : competence.date}
                    </div>
                    <div className="soluter-competence__title-link-btn">
                      <div>
                        <Text ellipsis strong style={{ width: 190 }}>
                          {competence.title}
                        </Text>

                        {competence.link && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <p className="mb-0">Link:</p>
                            <Link
                              href={formatLink(competence.link)}
                              target="_blank"
                              ellipsis
                              className="soluter-competence__link"
                            >
                              {formatLink(competence.link)}
                            </Link>
                          </div>
                        )}
                      </div>
                      <Button
                        type="view"
                        isButton
                        tooltipIdentifier="Detalhes"
                        handleClick={() => {
                          setModalVisible(true);
                          setSelectedCompetence(competence);
                        }}
                        buttonStyle={{
                          fontSize: 16,
                          height: 22,
                        }}
                      />
                    </div>
                  </div>
                </Skeleton>
              </List.Item>
            </>
          );
        }}
      />
      <SoluterCompetenceDetails
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        competence={selectedCompetence}
      />
    </>
  );
}

SoluterCompetences.propTypes = {
  competences: PropTypes.array.isRequired,
};

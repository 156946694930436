/* eslint-disable no-console */
import { message } from 'antd';
import api from '~/config/httpRequest';
import {
  addDistrict,
  getDistricts,
  editDistrict,
  removeDistrict,
  getDistrictDetail,
  getDistrictMembers,
  getMembersCsvData,
} from '~/store/ducks/districts';
import { startLoading, endLoading } from '~/store/ducks/loading';
import { validationMessages } from '~/constants';
import imageHelper from '~/helpers/imageHelper';

export const get = () => {
  return async dispatch => {
    api
      .get(`/districts`)
      .then(async ({ data: payload }) => {
        await dispatch(getDistricts(payload));
        if (payload) getThumbs(payload, dispatch);
      })
      .catch(err => {
        console.log(err);
        dispatch(getDistricts(null));
      });
  };
};

const getThumbs = async (payload, dispatch) => {
  Promise.all(
    payload?.map(async loaded => {
      try {
        loaded = {
          ...loaded,
          thumb: await getThumbByDistrictId(loaded.districtId),
        };
      } catch (error) {
        console.log(
          `Não foi possível carregar o thumb do distrito: ${loaded.name}`,
          error,
        );
      }
      return loaded;
    }),
  ).then(values => dispatch(getDistricts(values)));
};

export const getByProperty = (query, page) => {
  return dispatch => {
    api
      .get(`/districts/name?term=${query}`)
      .then(async ({ data: payload }) => {
        dispatch(getDistricts(payload));
        getThumbs(payload, dispatch);
      })
      .catch(err => {
        console.log(err);
        dispatch(getDistricts(null));
      });
  };
};

export const getByDistrictId = districtId => {
  return dispatch => {
    dispatch(startLoading('isLoadingMembers'));
    api
      .get(`/districts/${districtId}`)
      .then(async ({ data: payload }) => {
        try {
          payload.photo = await getPhotoByDistrictId(payload?.districtId);
        } catch (error) {
          console.log(
            `Não foi possível carregar o foto do distrito: ${payload?.name}`,
            error,
          );
        }
        dispatch(endLoading('isLoadingMembers'));
        dispatch(getDistrictDetail(payload));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingMembers'));
      });
  };
};

const getPhotoByDistrictId = districtId => {
  return new Promise((resolve, reject) => {
    api
      .get(`/districts/${districtId}/photo`, imageHelper.responseType())
      .then(response => imageHelper.convertBytesToBase64(response, resolve))
      .catch(reject);
  });
};

export const editPhotoByDistrictId = (districtId, photo) => {
  return dispatch => {
    api
      .put(`/districts/${districtId}/photo`, photo)
      .then(({ data: payload }) => {
        // dispatch(getDistricts(payload));
      })
      .catch(console.log);
  };
};

export const editThumbByDistrictId = (districtId, thumb) => {
  return dispatch => {
    api
      .put(`/districts/${districtId}/thumb`, thumb)
      .then(({ data: payload }) => {
        // dispatch(getDistricts(payload));
      })
      .catch(console.log);
  };
};

const getThumbByDistrictId = districtId => {
  return new Promise((resolve, reject) => {
    api
      .get(`/districts/${districtId}/thumb`, imageHelper.responseType())
      .then(response => imageHelper.convertBytesToBase64(response, resolve))
      .catch(reject);
  });
};

export const getMembersByDistrictId = (
  districtId,
  page,
  pageSize,
  searchTitle = '',
) => {
  return dispatch => {
    api
      .get(
        `/districts/${districtId}/members-backoffice?page=${page}&pageSize=${pageSize}&term=${searchTitle}`,
      )
      .then(({ data: payload }) => {
        dispatch(getDistrictMembers(payload));
        if (payload) {
          Promise.all(
            payload?.content?.map(async loaded => {
              try {
                loaded = {
                  ...loaded,
                  photo: await imageHelper.getSinglePhoto(
                    `/soluters/users/${loaded.email}/photo`,
                  ),
                };
              } catch (error) {
                console.log(
                  `Não foi possível carregar o thumb do distrito: ${loaded.name}`,
                  error,
                );
              }
              return loaded;
            }),
          ).then(members =>
            dispatch(
              getDistrictMembers({ content: members, total: payload?.total }),
            ),
          );
        }
      })
      .catch(console.log);
  };
};

export const getEventsByDistrictId = districtId => {
  return dispatch => {
    api
      .get(`/districts/${districtId}/events`)
      .then(({ data: payload }) => {
        // dispatch(getDistricts(payload));
      })
      .catch(console.log);
  };
};

export const editEventsByDistrictId = district => {
  return dispatch => {
    api
      .post(`/districts/${district.districtId}/events`, district)
      .then(({ data: payload }) => {
        // dispatch(getDistricts(payload));
      })
      .catch(console.log);
  };
};

export const add = district => {
  return dispatch => {
    api
      .post(`districts`, district)
      .then(response => {
        dispatch(addDistrict(district));
        message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
        // dispatch(get());
        if (district?.photo !== undefined) {
          const formDataPhoto = new FormData();
          formDataPhoto.append('file', district.photo.file);
          dispatch(
            editPhotoByDistrictId(response.data.districtId, formDataPhoto),
          );
        }
        if (district?.thumb !== undefined) {
          const formDataThumb = new FormData();
          formDataThumb.append('file', district.thumb.file);
          dispatch(
            editThumbByDistrictId(response.data.districtId, formDataThumb),
          );
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const edit = (district, districtId) => {
  const updatedDistrict = {
    name: district.name,
    description: district.description,
    color: district.color,
  };

  return dispatch => {
    dispatch(startLoading('isUpdatingDistrict'));
    api
      .put(`districts/${districtId}`, updatedDistrict)
      .then(({ data: payload }) => {
        dispatch(editDistrict(district));
        message.success(validationMessages.HTTP_EDIT_SUCCESS_MESSAGE);
        // dispatch(get());
        dispatch(endLoading('isUpdatingDistrict'));
      })
      .catch(e => {
        console.log(e);
        dispatch(endLoading('isUpdatingDistrict'));
      });
  };
};

/*
// temporariamente desabilitado

export const remove = districtId => {
  return dispatch => {
    api
      .delete(`districts/${districtId}`)
      .then(() => {
        dispatch(removeDistrict(districtId));
        message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
        dispatch(get());
      })
      .catch(e => {
        console.log(e);
      });
  };
}; */

export const getMembersCsv = districtId => {
  return async dispatch => {
    api
      .get(`districts/users-csv/${districtId}`)
      .then(({ data }) => {
        dispatch(getMembersCsvData(data));
      })
      .catch(err => console.error(err));
  };
};

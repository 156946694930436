/* eslint-disable no-nested-ternary */
import { Collapse, Typography, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button as CustomButton, Tag, EmptyErrorWrapper } from '~/components';
import { AddProjectForm } from '~/container';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import { formatDateSlash as formatDate } from '~/helpers/daysOfWeek';
import { formatCurrency } from '~/helpers/handleCurrency';
import ListProjectMembers from './ListProjectMembers';
import './styles.scss';

const { Panel } = Collapse;
const { Title } = Typography;

export default function ProjectsTable({
  towerId,
  tower,
  currentYear,
  orderBy,
  isAsc,
}) {
  const dispatch = useDispatch();
  const { projects } = useSelector(state => state.towers);

  useEffect(() => {
    const asc = isAsc ? 'asc' : 'dsc';
    const shouldGetMembersPhotos = true;
    dispatch(
      fetchTowers.getTowerProjects(
        towerId,
        shouldGetMembersPhotos,
        currentYear,
        orderBy,
        asc,
      ),
    );
  }, [dispatch, currentYear, towerId, orderBy, isAsc]);

  const handleDelete = project => {
    dispatch(fetchTowers.deleteProject(project.id, tower._id, currentYear));
  };
  const handleOnChange = projectKey => {};

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <EmptyErrorWrapper
        data={projects}
        emptyCondition={projects?.length > 0}
        emptyMessage="Não existem projetos registrados."
        loaderTitle="isLoadingProjects"
        retryAction={fetchTowers.getTowerProjects}
        retryProps={[
          towerId,
          true,
          currentYear,
          orderBy,
          isAsc ? 'asc' : 'dsc',
        ]}
      >
        {projects && (
          <Collapse accordion onChange={handleOnChange}>
            {projects?.map(project => (
              <Panel
                className="tower-projects-list"
                header={
                  <p style={{ marginBottom: 0 }}>
                    <span>{project.name}</span>
                  </p>
                }
                key={project._id}
              >
                <div className="tower-projects-list__panel">
                  <AddProjectForm
                    button={
                      <CustomButton
                        type="edit"
                        style={{ fontSize: '20px', color: '#dfa211' }}
                        tower={tower}
                        tooltipIdentifier="Projeto"
                        id={project._id}
                      />
                    }
                    project={project}
                    tower={tower}
                    towerId={towerId}
                    name={project.name}
                    year={currentYear}
                  />
                  <CustomButton
                    type="delete"
                    style={{ fontSize: '20px' }}
                    id={{ id: project._id, towerKey: project.group }}
                    title={project.name}
                    tooltipIdentifier="Projeto"
                    handleDelete={handleDelete}
                  />
                </div>
                <div
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ maxWidth: '60%' }}>
                    <Title level={5}>Período do Projeto</Title>

                    {project.startDate ? (
                      <p>
                        {`${formatDate(
                          new Date(project?.startDate),
                        )} - ${formatDate(new Date(project.endDate))}`}
                      </p>
                    ) : (
                      <p className="soluter-profile__missing-info">
                        Este projeto não possui um período associado (ainda).
                      </p>
                    )}
                    <Title level={5}>Descrição</Title>
                    <p
                      className={
                        !project.description && 'soluter-profile__missing-info'
                      }
                    >
                      {project.description ||
                        'Este projeto ainda não possui uma descrição (ainda).'}
                    </p>
                    <Title level={5}>Tecnologias</Title>
                    <div style={{ display: 'flex' }}>
                      <Space size={[8, 16]} wrap>
                        {project?.technology?.length > 0 ? (
                          project?.technology.map(skill => (
                            <Tag
                              tag={{ tag: skill }}
                              backgroundColor={tower.color}
                              textColor="white"
                              key={skill}
                            />
                          ))
                        ) : (
                          <p className="soluter-profile__missing-info">
                            Nenhuma tecnologia registrada (ainda).
                          </p>
                        )}
                      </Space>
                    </div>
                    <Title className="mt-md" level={5}>
                      Custo médio por hora
                    </Title>
                    {project.averageCPH ? (
                      <p>R$ {formatCurrency(project.averageCPH)}</p>
                    ) : (
                      <p className="soluter-profile__missing-info">
                        Nenhum custo informado.
                      </p>
                    )}
                    <Title className="mt-md" level={5}>
                      Custo médio do projeto
                    </Title>
                    {project.averageCPHPA ? (
                      <p>R$ {formatCurrency(project.averageCPHPA)}</p>
                    ) : (
                      <p className="soluter-profile__missing-info">
                        Nenhum custo informado.
                      </p>
                    )}
                  </div>
                  <div style={{ minWidth: '45%', marginLeft: 10 }}>
                    <Title level={5}>Equipe</Title>
                    <ListProjectMembers
                      list={project.team}
                      project={project}
                      currentYear={currentYear}
                    />
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
        )}
      </EmptyErrorWrapper>
    </div>
  );
}

ProjectsTable.propTypes = {
  tower: PropTypes.object.isRequired,
  currentYear: PropTypes.string.isRequired,
  towerId: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  isAsc: PropTypes.bool,
};

ProjectsTable.defaultProps = {
  orderBy: 'name',
  isAsc: true,
};

import moment from 'moment';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as fetchBadges from '~/store/fetchActions/fetchBadges';

moment.locale('pt-BR');
const { Search } = Input;

export default function SearchUser({ badgeId }) {
  const dispatch = useDispatch();

  const handleSubmit = values => {
    dispatch(fetchBadges.listUsersByBadge(badgeId, values));
  };

  return (
    <>
      <Search
        placeholder="Buscar por Soluter"
        className="badge-filter"
        data-testid="badge-field"
        onSearch={handleSubmit}
        enterButton
        allowClear
      />
    </>
  );
}

SearchUser.propTypes = {
  badgeId: PropTypes.string,
};

SearchUser.defaultProps = {
  badgeId: '',
};

import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  badges: [],
  badge: {},
  usersByBadge: [],
  usersNotifications: [],
  updateBadge: false,
};

export const getBadges = createAction('GET_BADGES');
export const getBadge = createAction('GET_BADGE');
export const updateBadgeById = createAction('GET_BADGE_BY_ID');
export const getUsersByBadgeId = createAction('GET_USERS_BY_BADGE_ID');
export const getUsersNotifications = createAction('GET_USERS_NOTIFICATIONS');
export const updateUsersByBadgeById = createAction('UPDATE_BADGE_BY_ID');

export default createReducer(INITIAL_STATE, {
  [getBadges.type]: (state, action) => ({
    ...state,
    badges: action.payload,
  }),
  [getBadge.type]: (state, action) => ({
    ...state,
    badge: action.payload,
  }),
  [updateBadgeById.type]: (state, action) => ({
    ...state,
    badges: state.badges.map(badge =>
      badge.id === action.payload.id ? action.payload : badge,
    ),
  }),
  [getUsersByBadgeId.type]: (state, action) => ({
    ...state,
    usersByBadge: action.payload,
  }),
  [getUsersNotifications.type]: (state, action) => ({
    ...state,
    usersNotifications: action.payload,
  }),
  [updateUsersByBadgeById.type]: (state, action) => ({
    ...state,
    updateBadge: action.payload,
  }),
});

import { TeamOutlined } from '@ant-design/icons';
import { Row, Col, Alert, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { LineGraph, GaugeGraph } from '~/container';
import { EmptyErrorWrapper } from '~/components';
import { participation } from '~/store/fetchActions/fetchMood';
import { selectMoodWeekInterval } from '~/store/selectors/mood';
import useMoodData from '~/hooks/useMoodData';

const { Title } = Typography;

export default function MoodParticipation() {
  const { selectedTower } = useSelector(state => state.mood);
  const week = useSelector(selectMoodWeekInterval);
  const { isLoadingParticipation } = useSelector(state => state.loading);
  const { moods } = useSelector(state => state.mood);

  const data = useMoodData(
    'participation',
    participation,
    selectMoodWeekInterval,
    null,
    selectedTower,
  );

  return (
    <Row align="middle">
      <Col span={14}>
        {data?.message && (
          <Alert
            message={data?.message}
            type="warning"
            showIcon
            closable
            style={{ marginBottom: 20 }}
          />
        )}
        <Row align="middle" style={{ marginBottom: 20 }}>
          <TeamOutlined style={{ marginRight: 5 }} />
          <div style={{ fontSize: 17 }}>Total de Soluters: {data?.total}</div>
        </Row>
        <Title className="mood-title" level={4}>
          Taxa de Adesão
        </Title>
        <EmptyErrorWrapper
          data={data}
          emptyCondition={data?.participations}
          loaderTitle="isLoadingParticipation"
          retryAction={participation}
          retryProps={[week, selectedTower]}
          emptyMessage="Não há moods registrados no período selecionado."
        >
          <LineGraph data={data} moods={moods} />
        </EmptyErrorWrapper>
      </Col>
      <Col span={6} offset={3}>
        <GaugeGraph
          participation={data}
          isLoadingParticipation={isLoadingParticipation}
        />
      </Col>
    </Row>
  );
}

/* eslint-disable no-nested-ternary */
import Line from '@ant-design/charts/lib/line';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';
import { isDateBetweenRange } from '~/helpers/isDateBetweenRange';
import { calcPercentage } from './calcPercentage';

function LineGraph({ data, moods }) {
  const { innerWidth: width, innerHeight: height } = window;

  const config = {
    data: data?.participations || [],
    height: height > 768 ? 300 : 200,
    xField: 'startDate',
    yField: 'percentage',
    point: {
      size: 5,
      shape: 'diamond',
    },
    tooltip: {
      formatter: function formatter(item) {
        return {
          name: 'porcentagem de adesão',
          value: `${item.percentage}% (${calcPercentage(
            item.percentage,
            data.total,
          )} soluters)`,
        };
      },
    },
    meta: {
      percentage: { max: 100, min: 0 },
    },
  };

  return <Line {...config} />;
}

LineGraph.propTypes = {
  data: PropTypes.object.isRequired,
  moods: PropTypes.object.isRequired,
};

export default React.memo(LineGraph);

import { Typography } from 'antd';
import PropTypes from 'prop-types';
import GoBackButton from '~/components/GoBackButton/GoBackButton';

const { Title } = Typography;

export default function TitlePage({ children }) {
  return (
    <Title
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <GoBackButton /> {children}
    </Title>
  );
}

TitlePage.propTypes = {
  children: PropTypes.string.isRequired,
};

import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Card, Col, Spin, Alert, Radio } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getSolutersMap } from '~/store/fetchActions/fetchIndicators';
import { EmptyErrorWrapper, Legend } from '~/components';
import { solutersMapColors } from '~/constants/indicators';
import { setMapLocationType } from '~/store/ducks/indicators';

const SolutersMap = lazy(() =>
  import(/* webpackChunkName: "soluters-map" */ './SolutersMap'),
);

function SolutersMapContainer({ selectedCostCenter, selectedPractice }) {
  const { isLoadingSolutersMap } = useSelector(state => state.loading);
  const [isLoadingMap, setLoadingMap] = useState(true);

  const { solutersMap } = useSelector(state => state.indicators);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSolutersMap(
        solutersMap.locationType,
        selectedCostCenter,
        selectedPractice,
      ),
    );
  }, [
    dispatch,
    selectedCostCenter,
    selectedPractice,
    solutersMap.locationType,
  ]);

  const MapLegend = () => {
    return (
      <div className="soluters-map__legend">
        <p>
          Total: {solutersMap.total}{' '}
          <TeamOutlined style={{ color: '#727272' }} />{' '}
        </p>
        <p className="mb-0">Quantidade de Soluters:</p>
        <Col>
          <Legend
            customLegendStyle={{
              background: solutersMapColors.one,
              height: 10,
              minWidth: 10,
              marginRight: 4,
              borderRadius: '50%',
              boxShadow: '0px 0px 0px 4px rgba(96, 197, 255, 0.315)',
            }}
            text="1"
            color={solutersMapColors.one}
          />
        </Col>
        <Col>
          <Legend color={solutersMapColors.leq10} text=">1 e <=10" round />
        </Col>
        <Col>
          <Legend color={solutersMapColors.gt10} text=">10" round />
        </Col>
        <Col>
          <Legend color={solutersMapColors.gt50} text=">50" round />
        </Col>
        <Col>
          <Legend color={solutersMapColors.gt100} text=">100" round />
        </Col>
      </div>
    );
  };

  return (
    <Card className="graph-card">
      <Spin spinning={isLoadingMap || isLoadingSolutersMap}>
        <Row className="soluters-map__row" justify="space-between">
          <Radio.Group
            defaultValue={solutersMap.locationType}
            onChange={({ target }) =>
              dispatch(setMapLocationType(target.value))
            }
            className="soluters-map__radio"
          >
            <Radio.Button value="CITY">Cidade</Radio.Button>
            <Radio.Button value="STATE">Estado</Radio.Button>
          </Radio.Group>
          <MapLegend />
          <Alert
            description="Use o botão direito do mouse para rotacionar o mapa. 
            Posicione o cursor sobre um ponto para ver o nome do local :)"
            type="info"
            closable
            className="soluters-map__alert"
          />
          <Col span={24}>
            {!solutersMap?.map ? (
              <EmptyErrorWrapper
                data={solutersMap?.map}
                emptyCondition={solutersMap?.map?.length > 0}
                retryAction={getSolutersMap}
                retryProps={[solutersMap?.locationType]}
              />
            ) : (
              <Suspense fallback={<div />}>
                <SolutersMap
                  data={solutersMap?.map}
                  setLoadingMap={setLoadingMap}
                />
              </Suspense>
            )}
          </Col>
        </Row>
      </Spin>
    </Card>
  );
}

export default React.memo(SolutersMapContainer);

SolutersMapContainer.propTypes = {
  selectedCostCenter: PropTypes.array.isRequired,
  selectedPractice: PropTypes.string.isRequired,
};

import React from 'react';
import { Row, Col } from 'antd';
import Pie from '@ant-design/charts/lib/pie';
import PropTypes from 'prop-types';
import { EmptyErrorWrapper } from '~/components';
import * as fetchIndicators from '~/store/fetchActions/fetchIndicators';

function GraphAllocationIndex({ data }) {
  const config = {
    appendPadding: 10,
    data: data || [],
    angleField: 'value',
    colorField: 'type',
    color: ({ type }) => {
      if (type === 'Superalocados') return 'rgb(30 110 205)';
      if (type === 'Alocados') return 'rgb(51 156 254)';
      return '#BFD7FF';
    },
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };

  return (
    <Row justify="space-around">
      <Col span={20}>
        <EmptyErrorWrapper
          data={data}
          emptyCondition={data?.length > 0}
          loaderTitle="isLoadingGraph"
          retryAction={fetchIndicators.getAllocationGraphData}
          emptyMessage="Ainda não há dados registrados."
          style={{ height: '50vh' }}
        >
          <Pie {...config} />
        </EmptyErrorWrapper>
      </Col>
    </Row>
  );
}

GraphAllocationIndex.propTypes = {
  data: PropTypes.array.isRequired,
};

export default React.memo(GraphAllocationIndex);

/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { Modal, Row, Space, message } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import ControlledPracticeField from '~/components/PracticeFilter/ControlledPracticeField';
import {
  editPractice,
  getCostCentersNoBus,
} from '~/store/fetchActions/fetchPractices';

export default function AddCentroCustoSemBus({ isVisible, setVisible }) {
  const dispatch = useDispatch();
  const { practices, costCentersNoBu } = useSelector(state => state.practices);
  const [selected, setSelected] = useState([]);
  const [practiceId, setPracticeId] = useState([]);
  const [options, setOptions] = useState([]);
  const [buSelected, setBuSelected] = useState();

  useEffect(() => {
    dispatch(getCostCentersNoBus());
    setBuSelected(null);
  }, [dispatch]);

  useEffect(() => {
    setOptions([
      {
        options: costCentersNoBu?.map(cc => ({
          value: cc.id,
          label: `${cc.id} - ${cc.title}`,
        })),
      },
    ]);
  }, [costCentersNoBu]);

  const handleCancel = () => {
    setVisible(false);
    cleanOptionsSelected();
    setBuSelected(null);
  };

  const handleSubmit = () => {
    if (!buSelected) {
      message.error('Selecione um BU');
      return;
    }

    const values = {
      costCenters: selected,
    };
    dispatch(editPractice(values, practiceId));
    handleCancel();
  };

  const onChangePractice = value => {
    setBuSelected(value);
    if (value === undefined) {
      cleanOptionsSelected();
      return;
    }

    let costCentersSelected;
    const listSelected = [];
    setPracticeId(value);
    practices.forEach(practice => {
      if (practice?.id === value) {
        costCentersSelected = [
          {
            options: practice.costCenters?.map(cc => ({
              value: cc.id,
              label: `${cc.id} - ${cc.title}`,
            })),
          },
        ];
      }
    });
    if (options.length > 1) options.pop();

    setOptions([...options, { options: costCentersSelected[0]?.options }]);
    setSelected([costCentersSelected[0]?.options[0]?.value]);
    costCentersSelected[0]?.options.map(opt => listSelected.push(opt.value));
    setSelected(listSelected);
  };

  const cleanOptionsSelected = () => {
    setSelected([]);
    if (options.length > 1) options.pop();
  };

  return (
    <Modal
      visible={isVisible}
      title="Centro de Custo"
      okText="Salvar"
      cancelText="Cancelar"
      className="modal-cost-center"
      onCancel={handleCancel}
      onOk={() => {
        handleSubmit();
      }}
    >
      <Row style={{ marginBottom: 10, justifyContent: 'space-between' }}>
        <Space className="title">Sem BU</Space>
        <Space justify="end">
          <ControlledPracticeField
            style={{ minWidth: 250 }}
            onChangePractice={onChangePractice}
            allowClear
            practice={buSelected}
          />
        </Space>
      </Row>
      <DualListBox
        options={options}
        selected={selected}
        onChange={value => setSelected(value)}
        iconsClass="native"
      />
    </Modal>
  );
}

AddCentroCustoSemBus.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

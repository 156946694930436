import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  competences: [],
  loading: false,
  competencesCsvData: null,
  total: '',
  filter: {
    businessUnit: '',
    tower: '',
    soluter: '',
    competence: '',
    valid: '',
  },
};

export const setCompetences = createAction('SET_COMPETENCES');
export const setCompetencesLoading = createAction('SET_COMPETENCES_LOADING');

export const setCompetenceBUFilter = createAction('SET_FILTER_BU');
export const setCompetenceTowerFilter = createAction('SET_FILTER_TOWER');
export const setCompetenceSoluterFilter = createAction('SET_FILTER_SOLUTER');
export const setCompetenceValidFilter = createAction('SET_FILTER_VALID');
export const setCompetenceNameFilter = createAction('SET_FILTER_COMPETENCE');
export const setCompetencesCsvData = createAction('SET_COMPETENCES_CSV_DATA');
export const clearCompetencesCsvData = createAction(
  'CLEAR_COMPETENCES_CSV_DATA',
);
export const resetCompetenceFilters = createAction('RESET_FILTERS');

export default createReducer(INITIAL_STATE, {
  [setCompetences.type]: (state, action) => ({
    ...state,
    competences: action.payload.competences,
    total: action.payload.total,
  }),
  [setCompetenceBUFilter.type]: (state, action) => ({
    ...state,
    filter: {
      ...state.filter,
      businessUnit: action.payload,
      tower: INITIAL_STATE.filter.tower,
    },
  }),
  [setCompetenceTowerFilter.type]: (state, action) => ({
    ...state,
    filter: {
      ...state.filter,
      businessUnit: INITIAL_STATE.filter.businessUnit,
      tower: action.payload,
    },
  }),
  [setCompetenceSoluterFilter.type]: (state, action) => ({
    ...state,
    filter: {
      ...state.filter,
      soluter: action.payload,
    },
  }),
  [setCompetenceValidFilter.type]: (state, action) => ({
    ...state,
    filter: {
      ...state.filter,
      valid: action.payload,
    },
  }),
  [setCompetenceNameFilter.type]: (state, action) => ({
    ...state,
    filter: {
      ...state.filter,
      competence: action.payload,
    },
  }),
  [resetCompetenceFilters.type]: state => ({
    ...state,
    filter: INITIAL_STATE.filter,
  }),
  [setCompetencesCsvData.type]: (state, action) => ({
    ...state,
    competencesCsvData: action.payload,
  }),
  [clearCompetencesCsvData.type]: state => ({
    ...state,
    competencesCsvData: INITIAL_STATE.competencesCsvData,
  }),
  [setCompetencesLoading.type]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
});

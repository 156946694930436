// ! Ref: https://medium.com/@otaviobn/evitando-repeti%C3%A7%C3%A3o-de-c%C3%B3digo-com-interceptors-no-axios-8f7be9545b2
// ! Ref: https://medium.com/@monkov/react-using-axios-interceptor-for-token-refreshing-1477a4d5fc26
// import api from "~/config/httpRequest";
import axios from 'axios';
import { message } from 'antd';
import { setAuthorized } from '~/store/ducks/auth';
import { startLoading, endLoading } from '~/store/ducks/loading';
import { refreshToken, signOut } from '~/store/fetchActions/fetchAuth';
import {
  getToken,
  setToken,
  setExpTime,
  setIsAuthenticated,
} from './environment';
import { validationMessages } from '~/constants';

const handleUnauthorizedError = async (error, dispatch, history) => {
  const originalRequest = error.config;
  if (
    error.response.status === 401 &&
    error.config &&
    !error.config.__isRetryRequest &&
    !error.config._retry
  ) {
    originalRequest._retry = true;
    try {
      const res = await refreshToken();
      originalRequest.headers.Authorization = `Bearer ${res}`;
      setToken(res);
      setExpTime(res);
      return axios(originalRequest);
    } catch (err) {
      dispatch(signOut(history));
      message.error(validationMessages.ERROR_401_MESSAGE);
    }
  }
  return null;
};

export const addInterceptors = (http, dispatch, history) => {
  http.interceptors.response.use(
    response => {
      dispatch(endLoading());
      return response;
    },
    error => {
      return new Promise((resolve, reject) => {
        if (typeof error.response !== 'undefined') {
          if (error?.response.data.errorMessage) {
            message.error(error.response.data.errorMessage);
          } else {
            switch (error?.response.status) {
              case 400:
                message.error(validationMessages.ERROR_400_MESSAGE);
                break;
              case 401:
                resolve(handleUnauthorizedError(error, dispatch, history));
                break;
              case 403:
                setIsAuthenticated(false);
                dispatch(setAuthorized(false));
                history.replace('/', {});
                message.error(validationMessages.ERROR_403_MESSAGE);
                break;
              case 404:
                message.error(validationMessages.ERROR_404_MESSAGE);
                break;
              case 500:
                message.error(validationMessages.ERROR_500_MESSAGE);
                break;
              default:
                message.error(validationMessages.ERROR_DEFAULT_MESSAGE);
            }
          }
        } else {
          message.error(validationMessages.HTTP_TIMEOUT_SERVER_RESPONSE);
        }
        dispatch(endLoading());
        return reject(error);
      });
    },
  );

  http.interceptors.request.use(
    async config => {
      dispatch(startLoading());
      const token = getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token || ''}`;
      }

      return config;
    },
    error => Promise.reject(error),
  );

  return http;
};

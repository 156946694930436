import React from 'react';
import rotas from '~/constants/paths';
import { ManageBusinessUnit } from '~/container';
import {
  Districts,
  DistrictsAdd,
  DistrictsDetails,
  DistrictsEdit,
  Login,
  NotFound,
  Skills,
  MoodPrototype,
  ManageTags,
  People,
  PeopleDetail,
  Capacity,
  ScheduleDashboard,
  TowerDetails,
  Towers,
  EmptyPage,
  Roles,
  IndicatorsTowers,
  IndicatorsGeneral,
  Practices,
  Interviews,
  ManageJourney,
  UsefulLinks,
  Badges,
  BadgesDetails,
} from '~/pages';
import Competences from '~/pages/competences/Competences';
import DashboardBI from '~/pages/dashboardBI/DashboardBI';
import Experiences from '~/pages/experiences/Experiences';

export const routes = [
  {
    path: rotas.HOME,
    exact: true,
    title: () => 'Login',
    view: () => <Login />,
    onlyAuthorized: false,
    role: ['admin'],
  },
  {
    path: rotas.SKILLS,
    exact: true,
    title: () => 'Habilidades',
    view: () => <Skills />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.EXPERIENCES,
    exact: true,
    title: () => 'Experiências',
    view: () => <Experiences />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.PRACTICES,
    exact: true,
    title: () => 'BUs',
    view: () => <Practices />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.ROLES,
    exact: true,
    title: () => 'Papéis',
    view: () => <Roles />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.DISTRICTS.LIST,
    exact: true,
    title: () => 'Distritos',
    view: () => <Districts />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.DISTRICTS.ADD,
    exact: true,
    title: () => 'Adicionar Distrito',
    view: () => <DistrictsAdd />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.DISTRICTS.EDIT,
    exact: true,
    title: () => 'Editar Distrito',
    view: () => <DistrictsEdit />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.DISTRICTS.DETAILS,
    exact: true,
    title: () => 'Distrito',
    view: () => <DistrictsDetails />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.EMPTY_PAGE,
    exact: true,
    title: () => 'Empty',
    view: () => <EmptyPage />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.DASHBORD_BI,
    exact: true,
    title: () => 'Dashboard(BI)',
    view: () => <DashboardBI />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.INDICATORS.MOOD,
    exact: true,
    title: () => 'Mood Dashboard',
    view: () => <MoodPrototype />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.MOOD.TAGS,
    exact: true,
    title: () => 'Gerenciamento de Tags',
    view: () => <ManageTags />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.JOURNEY,
    exact: true,
    title: () => 'Famílias e Cargos',
    view: () => <ManageJourney />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.JOURNEY_EDIT,
    exact: true,
    title: () => 'Editar Famílias',
    view: () => <ManageBusinessUnit />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: rotas.USEFUL_LINKS,
    exact: true,
    title: () => 'Links Úteis',
    view: () => <UsefulLinks />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: [rotas.HOME, rotas.SOLUTERS.LIST],
    exact: true,
    title: () => 'SOLUTERS',
    view: () => <People />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.SOLUTERS.DETAIL,
    exact: true,
    title: () => 'SOLUTERS',
    view: () => <PeopleDetail />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.COMPETENCES,
    exact: true,
    title: () => 'Certificações',
    view: () => <Competences />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.INTERVIEWS,
    exact: true,
    title: () => 'Entrevistas',
    view: () => <Interviews />,
    onlyAuthorized: true,
    role: ['admin'],
  },
  {
    path: [rotas.HOME, rotas.TOWER.REGISTER],
    exact: true,
    title: () => 'Registro de Torres',
    view: () => <Towers />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.TOWER.SCHEDULE,
    exact: true,
    title: () => 'Schedule',
    view: () => <ScheduleDashboard />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.TOWER.CAPACITY,
    exact: true,
    title: () => 'Capacity',
    view: () => <Capacity />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.INDICATORS.GENERAL,
    exact: true,
    title: () => 'Indicators',
    view: () => <IndicatorsGeneral />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: rotas.INDICATORS.TOWERS,
    exact: true,
    title: () => 'Indicators',
    view: () => <IndicatorsTowers />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: [rotas.TOWER.DETAILS, rotas.TOWER.EDIT],
    exact: true,
    title: () => 'Torre',
    view: () => <TowerDetails />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: [rotas.BADGES.DASHBOARD],
    exact: true,
    title: () => 'Registro de Badges',
    view: () => <Badges />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },
  {
    path: [rotas.BADGES.EDIT],
    exact: true,
    title: () => 'Edição de Badges',
    view: () => <BadgesDetails />,
    onlyAuthorized: true,
    role: ['admin', 'leader'],
  },

  {
    path: '*',
    exact: false,
    title: () => 'Página não Encontrada',
    view: () => <NotFound />,
    onlyAuthorized: 'both',
    role: ['admin', 'leader'],
  },
];

export default routes;

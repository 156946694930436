import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  interviews: [],
  startDate: '',
  endDate: '',
  csvData: null,
  selectedPractice: '',
  term: '',
};

export const setInterviews = createAction('INTERVIEWS_SET');
export const setDateRange = createAction('INTERVIEWS_SET_DATE_RANGE');
export const getCsvData = createAction('INTERVIEWS_SET_CSV');
export const clearCsvData = createAction('INTERVIEWS_CLEAR_CSV');
export const setSelectedPractice = createAction('INTERVIEW_SELECTED_PRACTICE');
export const searchByTerm = createAction('INTERVIEW_SEARCH_BY_TERM');
export const setTerm = createAction('INTERVIEW_SET_TERM');

export default createReducer(INITIAL_STATE, {
  [setInterviews.type]: (state, action) => ({
    ...state,
    interviews: action.payload,
  }),
  [setDateRange.type]: (state, action) => ({
    ...state,
    startDate: action.payload.startDate,
    endDate: action.payload.endDate,
  }),
  [getCsvData.type]: (state, action) => ({
    ...state,
    csvData: action.payload,
  }),
  [clearCsvData.type]: (state, action) => ({
    ...state,
    csvData: null,
  }),
  [setSelectedPractice.type]: (state, action) => ({
    ...state,
    selectedPractice: action.payload,
  }),
  [setTerm.type]: (state, action) => ({
    ...state,
    term: action.payload,
  }),
  [searchByTerm.type]: (state, action) => ({
    ...state,
    term: action.payload.searchTitle,
  }),
});

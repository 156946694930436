export const GO_FORWARD_ARROW_INDEX = -1;
export const MAX_DAYS_IN_MONTH = 30;
export const DECEMBER = 11;
export const VACATION_COLOR = 'rgb(54, 114, 255)';

export default {
  GO_FORWARD_ARROW_INDEX,
  MAX_DAYS_IN_MONTH,
  DECEMBER,
  VACATION_COLOR,
};

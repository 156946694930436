import { Form, Select, Spin } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import handleSearch from '~/helpers/handleSearch';
import {
  getPeopleTower,
  getDismissedSoluters,
} from '~/store/fetchActions/fetchTowers';
import * as fetchPractices from '~/store/fetchActions/fetchPractices';
import { getByTitle, get } from '~/store/fetchActions/fetchSkills';

const { Option } = Select;

export default function SelectSearchBusinessUnits({
  defaultValue,
  value,
  onChange,
  customStyle,
  testId,
  disabled,
  searchDismissed,
  shouldReturnLabelInValue,
  selectMode,
  fieldName,
  tagRenderFunc,
  fieldLabel,
  required,
  style,
  placeholder,
}) {
  const [searchTimeout, setSearchTimeout] = useState(0);
  const dispatch = useDispatch();
  const { isLoadingPractices } = useSelector(state => state.loading);
  const { practices } = useSelector(state => state.practices);

  useEffect(() => {
    dispatch(fetchPractices.getPractices(false));
  }, [dispatch]);

  return (
    <Form.Item
      name={fieldName}
      style={style}
      required={required}
      rules={
        required
          ? [
              {
                type: selectMode === 'multiple' ? 'array' : 'string',
                required,
                max: 30,
                message: 'É necessário ao menos uma Business Unit',
                whitespace: true,
              },
            ]
          : []
      }
      key="select-skills-field"
    >
      <Select
        disabled={disabled}
        showSearch
        allowClear
        showArrow
        defaultValue={defaultValue}
        mode={selectMode}
        tagRender={tagRenderFunc}
        data-testid={testId}
        placeholder={placeholder}
        loading={isLoadingPractices}
        onSearch={_value =>
          handleSearch(
            _value,
            fetchPractices.getPractices,
            searchTimeout,
            setSearchTimeout,
            dispatch,
          )
        }
        onChange={_value => {
          onChange(_value);
        }}
        notFoundContent={isLoadingPractices ? <Spin size="small" /> : null}
      >
        {practices?.map((bu, idx) => (
          <Option
            value={bu.id || bu._id}
            key={bu.id || bu._id}
            data-testid={`select-soluter-badge-${idx}`}
          >
            {bu.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

SelectSearchBusinessUnits.propTypes = {
  onChange: PropTypes.func,
  customStyle: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  searchDismissed: PropTypes.bool,
  shouldReturnLabelInValue: PropTypes.bool,

  selectMode: PropTypes.string,
  defaultValue: PropTypes.array,
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  tagRenderFunc: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
};

SelectSearchBusinessUnits.defaultProps = {
  defaultValue: null,
  onChange: () => {},
  value: '',
  customStyle: { minWidth: 200 },
  testId: 'select-search-people',
  disabled: false,
  searchDismissed: false,
  shouldReturnLabelInValue: false,

  selectMode: null,
  fieldName: 'bus',
  fieldLabel: 'Busines Unit',
  placeholder: 'Buscar Business Unit',
  tagRenderFunc: () => {
    /* skip */
  },
  style: { width: '100%' },
  required: true,
};

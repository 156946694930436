const DISTRICT_ICON_INFO =
  'Esse é um ícone padrão do distrito. Para adicionar um ícone personalizado, faça upload da imagem.';
const DISTRICT_NAME_INFO =
  "O nome sempre será salvo começando com a palavra 'Distrito', portanto não é necessário escrevê-la";
const DISTRICT_CHANNELS_INFO =
  'Por padrão, os canais gerados serão os seguintes: Geral, Anúncios, Dicas e Tutoriais, Dúvidas e Projetos';
const DISTRICT_DEFAULT_ICON_URL =
  'https://images.theconversation.com/files/341416/original/file-20200612-38729-440wg3.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1000&fit=clip';
const DISTRICT_DEFAULT_COLOR_INFO =
  'Para escolher a cor do distrito, clique em uma das opções abaixo ou insira o código hexadecimal da cor desejada.';

export default {
  DISTRICT_ICON_INFO,
  DISTRICT_NAME_INFO,
  DISTRICT_CHANNELS_INFO,
  DISTRICT_DEFAULT_ICON_URL,
  DISTRICT_DEFAULT_COLOR_INFO,
};

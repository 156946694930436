import { Row, Form } from 'antd';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SelectTowers, PracticeFilter } from '~/components';
import {
  setSelectedTower,
  setSelectedPractice,
} from '~/store/ducks/indicators';

export default function IndicatorsHeader() {
  const dispatch = useDispatch();
  const [formPracticeAndCostCenters] = Form.useForm();
  const { selectedTower, selectedPractice } = useSelector(
    state => state.indicators,
  );
  const { unallocated } = useSelector(
    state => state.indicators.solutersBySeniority,
  );
  useEffect(() => {
    if (selectedTower !== '') onChangeSelectedTower();
  }, [onChangeSelectedTower, selectedTower]);

  useEffect(() => {
    if (selectedPractice !== '') onChangeSelectedPractice();
  }, [onChangeSelectedPractice, selectedPractice]);

  // Limpa os valores de selectedPractice e selectedTower quando o componente é destruído
  // Afinal esses atributos são usados em outras telas e nao podem ter valores setados
  useEffect(() => {
    return () => {
      setSelectedPractice('');
      setSelectedTower({ value: '', label: '' });
    };
  }, []);

  const onChangePractice = useCallback(
    practiceId => {
      dispatch(setSelectedPractice(practiceId));
      dispatch(setSelectedTower({ value: '', label: '' }));
    },
    [dispatch],
  );

  const onChangeSelectedTower = useCallback(() => {
    dispatch(setSelectedPractice(''));
    formPracticeAndCostCenters.setFieldsValue({
      selectedPractice: null,
    });
  }, [dispatch, formPracticeAndCostCenters]);

  const onChangeSelectedPractice = useCallback(() => {
    dispatch(setSelectedTower({ value: '', label: '' }));
    formPracticeAndCostCenters.setFieldsValue({
      selectedTower: { value: '', label: '' },
    });
  }, [dispatch, formPracticeAndCostCenters]);

  return (
    <>
      <Row justify="space-between">
        <div
          className="d-flex"
          style={{ display: 'flex', flexDirection: 'space-between' }}
        >
          <Form form={formPracticeAndCostCenters}>
            <div
              className="cost-center-practice-container"
              style={{
                display: 'flex',
                marginRight: 10,
              }}
            >
              {!unallocated && (
                <PracticeFilter
                  onChangePractice={onChangePractice}
                  fieldName="selectedPractice"
                  defaultValue={selectedPractice}
                  style={{ width: '15vw', marginRight: 20 }}
                />
              )}
              <SelectTowers
                selector={state => state.indicators}
                dispatchDuck={setSelectedTower}
                fieldName="selectedTower"
                emptyOptionText="Selecione uma Torre"
                allowClear
              />
            </div>
          </Form>
        </div>
      </Row>
      <Row />
    </>
  );
}

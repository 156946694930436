import { scheduler } from '~/constants';

export const monthToNumber = {
  Jan: 0,
  Fev: 1,
  Mar: 2,
  Abr: 3,
  Mai: 4,
  Jun: 5,
  Jul: 6,
  Ago: 7,
  Set: 8,
  Out: 9,
  Nov: 10,
  Dez: 11,
};

export const percentageToColor = (percentage, active = true) => {
  if (active) {
    if (percentage >= 0 && percentage <= 30) return 'rgb(255, 237, 87)';
    if (percentage > 30 && percentage <= 60) return 'rgb(255, 215, 0)';
    if (percentage > 60 && percentage <= 90) return 'rgb(197, 231, 36)';
    return 'rgb(77, 214, 0)';
  }
  return 'rgb(208, 208, 208)';
};

export const isMonthInInterval = (
  month,
  currentYear,
  startDateMonth,
  endDateMonth,
  startDateYear,
  endDateYear,
) => {
  let checkMonth = false;

  // casos possíveis:
  // 1. o ano atual está todo alocado (entre um ano e outro)
  // 2. o ano atual é o ano inicial
  // 3. o ano atual é o ano final
  // 4. o ano atual é o inicial e final

  if (currentYear > startDateYear && currentYear < endDateYear) return true;

  if (startDateYear === currentYear) {
    if (startDateYear !== endDateYear)
      checkMonth =
        monthToNumber[month] >= startDateMonth && monthToNumber[month] <= 11;
    else
      checkMonth =
        monthToNumber[month] >= startDateMonth &&
        monthToNumber[month] <= endDateMonth;
  } else if (endDateYear === currentYear) {
    if (endDateYear !== startDateYear) {
      checkMonth =
        monthToNumber[month] <= endDateMonth && monthToNumber[month] >= 0;
    } else
      checkMonth =
        monthToNumber[month] >= startDateMonth &&
        monthToNumber[month] <= endDateMonth;
  }

  return checkMonth;
};

export const amountOfDaysInMonth = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (start.getMonth() !== end.getMonth()) return 30 - start.getDate();
  return end.getDate() - start.getDate();
};

export const getBarStyles = (allocation, active, index) => {
  const width =
    index === scheduler.GO_FORWARD_ARROW_INDEX
      ? scheduler.MAX_DAYS_IN_MONTH
      : allocation?.getBarWidth();

  return {
    background: allocation?.isVacation
      ? scheduler.VACATION_COLOR
      : percentageToColor(allocation?.amount, active),
    height: 25,
    marginTop: -11,
    top: '50%',
    right:
      allocation.isStartMonth() && !allocation.isWithinSameMonth() ? 0 : '',
    // eslint-disable-next-line no-nested-ternary
    left: allocation.isWithinSameMonth()
      ? 5 * (allocation.getStartDay() - 1)
      : allocation.isEndMonth() || allocation.getStartDay() === 1
      ? 0
      : '',
    width,
    position: 'absolute',
  };
};

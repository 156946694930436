/* eslint-disable no-nested-ternary */
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { MoodTagList } from '~/components';
import { approvedTags, notApprovedTags } from '~/store/fetchActions/fetchMood';
import { selectMoodWeekInterval } from '~/store/selectors/mood';

export default function MonthlyTagsColumn({ approved }) {
  const { selectedTower, selectedPractice, selectedCostCenters } = useSelector(
    state => state.mood,
  );
  const data = useSelector(state => {
    return approved ? state.mood.approvedTags : state.mood.notApprovedTags;
  });
  const week = useSelector(selectMoodWeekInterval);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      approved
        ? approvedTags(week, selectedTower)
        : notApprovedTags(week, selectedTower),
    );
  }, [
    week,
    selectedTower,
    dispatch,
    approved,
    selectedPractice,
    selectedCostCenters,
  ]);

  const { isLoadingApprovedTags, isLoadingNotApprovedTags } = useSelector(
    state => state.loading,
  );

  return (
    <>
      <div style={{ textAlign: 'center', marginTop: '30px' }}>
        <div>
          <Tooltip title={`${data?.noTag?.votes} soluters`} trigger="hover">
            <p style={{ background: '#f4f4f4' }}>
              Responderam ao moods e não marcaram tags{' '}
              {approved ? 'positivas' : 'negativas'}:
              {data?.noTag?.percentage >= 0 && (
                <b
                  style={{ color: '#5b5959', marginLeft: 5 }}
                >{` ${data?.noTag?.percentage}%`}</b>
              )}
            </p>
          </Tooltip>
        </div>
        <div>
          <p style={{ marginBottom: 30 }}>
            Dentre os Soluters que responderam e marcaram as tags, as
            porcentagens foram:
          </p>
          <div>
            <MoodTagList
              tags={data?.tags}
              approvedTags={approved}
              loader={
                approved ? isLoadingApprovedTags : isLoadingNotApprovedTags
              }
              handleEmptyContentAsError
            />
          </div>
        </div>
      </div>
    </>
  );
}

MonthlyTagsColumn.propTypes = {
  approved: PropTypes.bool,
};

MonthlyTagsColumn.defaultProps = {
  approved: false,
};

import axios from 'axios';
import api from '~/config/httpRequest';
import { setAuthorized, setRole } from '~/store/ducks/auth';

const { REACT_APP_BASE_URL, REACT_APP_API_BASE_URL } = process.env;

/* export const sendCredentials = (user, callback) => {
  return dispatch => {
    dispatch(setAuthLoading(true));
    // api
    //     .post(paths.LOGIN, user)
    //     .then(response => {
    dispatch(setAuthorized(true));
    callback();
    //     dispatch(setToken(response?.data?.token));
    //     dispatch(setProfile(response?.data?.user));
    //     response?.data?.token &&
    //         localStorage.setItem('TOKEN', response?.data?.token);
    // })
    // .catch(error => {
    //     error?.response?.status === 400 &&
    //         notification.error({
    //             message: 'Ops, ocorreu um erro ao tentar acessar!',
    //             description: 'Verifique se o usuário existe e todos os dados estão preenchidos corretamente'
    //         });
    // })
    // .finally(() => {
    dispatch(setAuthLoading(false));
    // });
  };
}; */

export const signOut = history => {
  return dispatch => {
    localStorage.clear();
    dispatch(setAuthorized(false));
    dispatch(setRole(''));
    try {
      history.replace('/', {});
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
};

export const getToken = code => {
  const params = new URLSearchParams();
  params.append('code', code);

  return new Promise((resolve, reject) => {
    api
      .post(
        `/soluters/moods/getToken?redirectUri=${encodeURIComponent(
          REACT_APP_BASE_URL,
        )}`,
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        },
      )
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const checkAuthentication = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/soluters/moods/authenticate`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const refreshToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${REACT_APP_API_BASE_URL}/soluters/moods/refreshToken?redirectUri=${encodeURIComponent(
          REACT_APP_BASE_URL,
        )}`,
        {
          withCredentials: true,
        },
      )
      .then(({ data }) => resolve(data.access_token))
      .catch(error => reject(error));
  });
};

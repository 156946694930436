import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Empty } from 'antd';
import PropTypes from 'prop-types';
import { percentageToColor } from '~/helpers/scheduler';
import './styles.scss';
import { scheduler } from '~/constants';

export default function InfoButton({ schedule, monthlyHours, personId }) {
  const content = (
    <div style={{ width: 225, maxHeight: 100, overflow: 'auto' }}>
      {schedule?.length > 0 ? (
        schedule?.map(allocation => (
          <div
            className="d-flex"
            key={`${allocation?.startDate}-${allocation?.endDate}-${personId}`}
            data-testid={`${moment(allocation?.startDate).format(
              'DD/MM/YYYY',
            )}-${moment(allocation?.endDate).format('DD/MM/YYYY')}-${personId}`}
          >
            <p style={{ marginBottom: 4 }}>{`${moment(
              allocation?.startDate,
            ).format('DD/MM/YYYY')} - ${moment(allocation?.endDate).format(
              'DD/MM/YYYY',
            )} `}</p>
            <p
              className="allocation-amount"
              style={{
                background: allocation?.isVacation
                  ? scheduler.VACATION_COLOR
                  : percentageToColor(allocation?.amount),
                marginBottom: 4,
                color: allocation?.isVacation ? 'white' : 'black',
              }}
            >
              {allocation?.isVacation ? 'Férias' : `${allocation?.amount}%`}
            </p>
          </div>
        ))
      ) : (
        <Empty
          style={{ maxHeight: 100, margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Não há alocações"
          imageStyle={{
            height: 30,
          }}
        />
      )}
    </div>
  );

  return (
    <Popover
      data-testid={`schedule-info-popover-${personId}`}
      content={content}
      title={`Alocações do Soluter ${
        monthlyHours ? `(${monthlyHours}h mensais)` : '(horas não definidas)'
      }`}
    >
      <InfoCircleOutlined
        data-testid={`schedule-info-button-${personId}`}
        className="hoverable"
        style={{ color: '#1890ff', fontSize: 14, marginLeft: 5 }}
      />
    </Popover>
  );
}

InfoButton.propTypes = {
  schedule: PropTypes.array.isRequired,
  monthlyHours: PropTypes.number.isRequired,
  personId: PropTypes.string,
};

InfoButton.defaultProps = {
  personId: 'personKey',
};

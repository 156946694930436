export const categories = {
  LEADER: 'Líder',
  HIGH_POTENTIAL: 'High Potential',
  DISMISSED: 'Desligados',
};

export const reasonsToLeave = {
  BETTER_PAY: 'Melhor salário',
  BETTER_BENEFITS: 'Melhores benefícios',
  NEW_CHALLENGES:
    'Melhor desafio profissional (novas tecnologias, stack, metodologias)',
  CAREER_CHANGE: 'Mudança de carreira',
  LACK_OF_PURPOSE: 'Falta de identificação com o propósito da empresa',
  BETTER_OPORTUNITIES: 'Melhor oportunidade de desenvolvimento profissional',
  LACK_OF_SUPPORT: 'Falta de suporte e orientação da liderança',
  OTHER: 'Outro',
};

export const reasonsToLeaveInvolutary = {
  END_OF_PROJECT: 'Fim do projeto',
  LACK_OF_EFFORT: 'Baixo desempenho',
  LACK_OF_COMMITMENT: 'Falta de comprometimento com o trabalho',
  DOESNT_FIT_THE_CULTURE: 'Não adequação ao fit cultural',
  OTHER: 'Outro',
};

export const dismissalType = {
  VOLUNTARY: 'Voluntário (por solicitação do Soluter)',
  INVOLUNTARY: 'Involuntário (por solicitação da Solutis)',
};

export const viewModes = {
  MAP: 'map',
  LIST: 'list',
};

export const mapCompetenceType = {
  GRADUATION: 'Formação',
  CERTIFICATION: 'Certificação',
};

export const mapCompetenceStatus = {
  ONGOING: 'Em Andamento',
  FINISHED: 'Concluído',
};

export const mapCompetenceFilterModes = {
  ONLY_WITH_COMPETENCES: 'Apenas Soluters com competências cadastradas',
};

const INFO_ADD_SOLUTER =
  'Informe o email cadastrado no teams (@solutis) para cadastrar um novo soluter. Se for uma pessoa jurídica click no checkbox';

export default {
  categories,
  reasonsToLeave,
  dismissalType,
  viewModes,
  mapCompetenceType,
  mapCompetenceStatus,
  mapCompetenceFilterModes,
  INFO_ADD_SOLUTER,
};

import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  page: 1,
  suggestions: [],
  search: false,
  searchTitle: '',
};

export const get = createAction('SKILLS_SUGGESTIONS');
export const remove = createAction('SKILLS_REMOVE_SUGGESTIONS');
export const add = createAction('SKILLS_ADD_SUGGESTIONS');
export const search = createAction('SKILLS_SEARCH_SUGGESTIONS');

export default createReducer(INITIAL_STATE, {
  [get.type]: (state, action) => ({
    ...state,
    suggestions: action.payload.suggestions,
    page: action.payload.page,
  }),
  [remove.type]: state => ({ ...state }),
  [add.type]: (state, action) => ({
    ...state,
    suggestions: action.payload,
  }),
  [search.type]: (state, action) => ({
    ...state,
    search: action.payload.search,
    searchTitle: action.payload.searchTitle,
  }),
});

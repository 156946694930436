import { WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { RetryConnectButton } from '~/components';
import './styles.scss';

export default function ErrorHandler({ action, props, style }) {
  return (
    <div
      className="error-handler__container"
      data-testid="error-handler"
      style={{ ...style }}
    >
      <WarningOutlined className="error-handler__icon" />
      <h1>Ops, ocorreu um erro</h1>
      <RetryConnectButton action={action} props={props} />
    </div>
  );
}

ErrorHandler.propTypes = {
  action: PropTypes.func.isRequired,
  props: PropTypes.array,
  style: PropTypes.object,
};

ErrorHandler.defaultProps = {
  props: [],
  style: {},
};

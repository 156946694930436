import { Typography, Button, Row, Alert, Tabs, Form, Col, Badge } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EnvironmentOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
  SearchBar,
  MultipleCostCenterFilter,
  PracticeFilter,
  DownloadButton,
  ActiveFilters,
} from '~/components';
import { formatDate } from '~/helpers/daysOfWeek';
import {
  SolutersList,
  AdvancedSearch,
  SolutersMapContainer,
} from '~/container';
import {
  search as searchPeople,
  setSelectedCostCenter,
  setSelectedPractice,
  clearSoluter,
  clearCsvData,
  removeSelectedCostCenter,
  clearAdvancedSearchFilters,
  setAdvancedSearchFilters,
} from '~/store/ducks/people';
import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import { viewModes } from '~/constants/people';
import useModal from '~/hooks/modal/useModal';
import AddSoluterModal from './modal/AddSoluterModal';

const { Title } = Typography;
const { TabPane } = Tabs;

const searchStyles = {
  display: 'flex',
  padding: 0,
  inputMargin: 0,
  inputMarginRight: '0px',
  inputWidth: 350,
  layout: 'inline',
};

export default function People() {
  const { searchTitle, search, advancedSearch } = useSelector(
    state => state.people,
  );
  const { selectedCostCenter, selectedPractice, csvData, total } = useSelector(
    state => state.people,
  );
  const { solutersMap } = useSelector(state => state.indicators);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isAdvancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [currentViewMode, setCurrentViewMode] = useState(viewModes.LIST);
  const { visible, handleOpen, handleClose } = useModal();

  const isAdvancedSearchActive = useCallback(() => {
    return (
      advancedSearch.skills?.length > 0 ||
      advancedSearch.seniorities?.length > 0 ||
      advancedSearch.leader ||
      advancedSearch.highPotential ||
      advancedSearch.onlyDismissed ||
      advancedSearch.competence ||
      advancedSearch.haveCompetence ||
      advancedSearch.primaryTech?.length > 0 ||
      advancedSearch.primaryRole?.length > 0 ||
      advancedSearch.notAllocated
    );
  }, [
    advancedSearch.highPotential,
    advancedSearch.leader,
    advancedSearch.seniorities?.length,
    advancedSearch.skills?.length,
    advancedSearch.onlyDismissed,
    advancedSearch.competence,
    advancedSearch.haveCompetence,
    advancedSearch.primaryTech?.length,
    advancedSearch.primaryRole?.length,
    advancedSearch.notAllocated,
  ]);

  useEffect(() => {
    dispatch(clearSoluter());
  }, [dispatch]);

  useEffect(() => {
    if (advancedSearch.onlyDismissed) {
      dispatch(
        fetchPeople.getDismissedSoluters(
          advancedSearch.leader,
          advancedSearch.highPotential,
          advancedSearch.primaryTech,
          advancedSearch.primaryRole,
          advancedSearch.competence,
          advancedSearch.haveCompetence,
          searchTitle,
        ),
      );
    } else if (search || isAdvancedSearchActive())
      dispatch(
        fetchPeople.getByProperty(
          searchTitle,
          advancedSearch.skills,
          advancedSearch.skillsOperator,
          advancedSearch.seniorities,
          advancedSearch.leader,
          advancedSearch.highPotential,
          advancedSearch.competence,
          advancedSearch.haveCompetence,
          advancedSearch.primaryTech,
          advancedSearch.primaryRole,
          advancedSearch.notAllocated,
        ),
      );
    else dispatch(fetchPeople.get());
  }, [
    selectedCostCenter,
    dispatch,
    searchTitle,
    search,
    advancedSearch.skills,
    advancedSearch.seniorities,
    advancedSearch.skillsOperator,
    advancedSearch.leader,
    advancedSearch.highPotential,
    advancedSearch.competence,
    advancedSearch.haveCompetence,
    advancedSearch.primaryTech,
    advancedSearch.primaryRole,
    advancedSearch.notAllocated,
    isAdvancedSearchActive,
    advancedSearch.onlyDismissed,
  ]);

  const handleCostCentersTagClose = filter => {
    dispatch(removeSelectedCostCenter(filter));
  };

  const onChangePractice = practiceId => {
    dispatch(setSelectedPractice(practiceId));
    dispatch(setSelectedCostCenter([]));
  };

  const onChangeCostCenterSelect = ([value]) => {
    if (!selectedCostCenter.includes(value)) {
      dispatch(setSelectedCostCenter([...selectedCostCenter, value]));
    }
    form.setFieldsValue({
      selectedCostCenters: [],
    });
  };

  const filtersAndTotal = (
    <Row justify="space-between">
      <ActiveFilters
        filters={[...selectedCostCenter]}
        label="Centros de Custos"
        isTagClosable
        handleTagClose={handleCostCentersTagClose}
        handleClear={() => dispatch(setSelectedCostCenter([]))}
      />
      <p>
        Total: {currentViewMode === viewModes.MAP ? solutersMap?.total : total}
      </p>
    </Row>
  );

  const handleCsvDownload = () => {
    dispatch(
      fetchPeople.getSolutersCsv(
        searchTitle,
        advancedSearch.skills,
        advancedSearch.skillsOperator,
        advancedSearch.seniorities,
        advancedSearch.leader,
        advancedSearch.highPotential,
        advancedSearch.competence,
        advancedSearch.haveCompetence,
        advancedSearch.primaryTech,
        advancedSearch.primaryRole,
        advancedSearch.notAllocated,
      ),
    );
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Title>Soluters</Title>
        <div className="competence-actions">
          {visible && (
            <AddSoluterModal visible={visible} handleClose={handleClose} />
          )}
          <Button
            type="primary"
            data-testid="create-soluter"
            onClick={handleOpen}
          >
            Adicionar novo Soluter
          </Button>
          <DownloadButton
            exportCsvLayout
            label="Exportar CSV"
            data={csvData || ''}
            requestData={handleCsvDownload}
            clearData={clearCsvData}
            fileName={`soluters_${formatDate(new Date())}.csv`}
            disabled={
              advancedSearch.onlyDismissed || currentViewMode === viewModes.MAP
            }
          />
        </div>
      </Row>
      <Row
        justify="space-between"
        style={{
          marginTop: 20,
        }}
      >
        <Form form={form}>
          <div
            style={{
              display: 'flex',
              width: '40vw',
            }}
          >
            <PracticeFilter
              onChangePractice={onChangePractice}
              fieldName="selectedPractice"
              defaultValue={selectedPractice}
            />
            <MultipleCostCenterFilter
              selectedPractice={selectedPractice}
              fieldName="selectedCostCenters"
              onChange={onChangeCostCenterSelect}
              style={{ width: 300, marginLeft: 20 }}
            />
          </div>
        </Form>
        <div style={{ display: 'flex', position: 'relative' }}>
          <div>
            {isAdvancedSearchActive() && search && (
              <Alert
                style={{
                  maxWidth: 350,
                  maxHeight: 70,
                  position: 'absolute',
                  top: '-75px',
                }}
                closable
                message="Enquanto a busca avançada estiver ativa, a pesquisa simples será feita apenas por nome"
              />
            )}
            <SearchBar
              title={`Pesquisar Soluter ${
                advancedSearch.onlyDismissed || isAdvancedSearchActive()
                  ? '(nome)'
                  : '(nome, habilidade, cargo...)'
              }`}
              actionGetByProperty={fetchPeople.getByProperty}
              actionGetAll={fetchPeople.getByProperty}
              actionGetAllProps={[
                '',
                advancedSearch.skills,
                advancedSearch.skillsOperator,
                advancedSearch.seniorities,
                advancedSearch.leader,
                advancedSearch.highPotential,
                advancedSearch.competence,
              ]}
              actionSearch={searchPeople}
              customStyles={searchStyles}
              currentSearch={searchTitle}
              shouldFetchOnSearch={false}
              isSimpleSearchBar
              disabled={currentViewMode === viewModes.MAP}
            />
          </div>
          <Badge
            count={
              isAdvancedSearchActive() && currentViewMode === viewModes.LIST
                ? '!'
                : ''
            }
            size="default"
            offset={[-2, 1]}
            title="A busca avançada está ativa"
            style={{
              background:
                currentViewMode === viewModes.MAP ? 'grey' : '#1890ff',
            }}
          >
            <Button
              onClick={() => setAdvancedSearchVisible(isVisible => !isVisible)}
              data-testid="advanced-search-button"
              disabled={currentViewMode === viewModes.MAP}
            >
              Busca Avançada
            </Button>
          </Badge>
        </div>
      </Row>
      <Row justify="end">
        {isAdvancedSearchVisible && (
          <AdvancedSearch
            style={{
              zIndex: 5,
              boxShadow: 'rgba(124, 124, 124, 0.35) -8px 8px 13px',
              position: 'absolute',
            }}
            setAdvancedSearchVisible={setAdvancedSearchVisible}
          />
        )}
      </Row>
      <Tabs onChange={activeKey => setCurrentViewMode(activeKey)}>
        <TabPane
          tab={
            <span>
              <UnorderedListOutlined style={{ marginRight: 4 }} /> Lista
            </span>
          }
          key={viewModes.LIST}
        >
          {filtersAndTotal}
          <SolutersList isAdvancedSearchActive={isAdvancedSearchActive} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <EnvironmentOutlined style={{ marginRight: 4 }} /> Mapa
            </span>
          }
          key={viewModes.MAP}
        >
          {filtersAndTotal}
          <SolutersMapContainer
            selectedPractice={selectedPractice}
            selectedCostCenter={selectedCostCenter}
          />
        </TabPane>
      </Tabs>
    </>
  );
}

/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Card, Skeleton, Avatar } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  TeamOutlined,
  PictureOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import { Button } from '~/components';

import './styles.scss';

const { Meta } = Card;

export default function CustomCardBadge({
  prefix,
  item,
  imgBackup,
  showMembers,
  handleDelete,
  disabledEdit,
  disabledDelete,
  circleImage,
  displayPhoto,
  customActions,
  additionalActions,
  backgroundColor,
}) {
  const cardActions = [
    <Button
      type="edit"
      link={`/${prefix}/edit/${item.id}`}
      disabled={disabledEdit}
    />,
    <Button type="view" link={`/${prefix}/edit/${item.id}`} />,
    <Button
      type="delete"
      id={item.id}
      handleDelete={handleDelete}
      title={item.name}
      disabled={!item.id || disabledDelete}
    />,
  ];

  const [thumbLoading, setThumbLoading] = useState(false);
  useEffect(() => {
    setThumbLoading(!item.thumb);
  }, [item.thumb]);

  const mountAvatar = () => {
    return thumbLoading ? (
      <Skeleton.Avatar
        loading={thumbLoading}
        avatar
        active={thumbLoading}
        size={64}
      />
    ) : item?.thumb?.length > 30 ? (
      circleImage ? (
        <Avatar
          className="tower__avatar"
          size={64}
          alt="ImagemTorre"
          src={item?.thumb}
        />
      ) : (
        <img style={{ width: 50 }} alt="ImagemDistrito" src={item.thumb} />
      )
    ) : (
      <Avatar icon={imgBackup} size={64} />
    );
  };

  return (
    <>
      <Card
        className="generic-badge-card"
        id="generic-card"
        actions={
          customActions
            ? customActions(item)
            : [...cardActions, ...additionalActions]
        }
      >
        <Meta
          className="generic-badge-card__meta"
          avatar={displayPhoto ? mountAvatar() : <></>}
          style={{
            background: backgroundColor,
          }}
          title={
            <div className="generic-badge-card__div">
              <p className="generic-badge-card__title">{item?.title}</p>
              {showMembers && (
                <div className="generic-badge-card__members">
                  <p className="generic-badge-card__members-number">
                    {item?.totalMembers}
                  </p>
                  <TeamOutlined style={{ marginLeft: 2, marginTop: 5 }} />
                </div>
              )}
            </div>
          }
        />

        <div
          style={{
            marginBottom: '50px',
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            className="generic-card__description_custom"
            style={{ marginBottom: 20 }}
          >
            {item?.description}
          </p>
        </div>
      </Card>
    </>
  );
}

CustomCardBadge.propTypes = {
  prefix: PropTypes.string,
  item: PropTypes.object.isRequired,
  imgBackup: PropTypes.element,
  showMembers: PropTypes.bool,
  disabledEdit: PropTypes.bool,
  disabledDelete: PropTypes.bool,
  circleImage: PropTypes.bool,
  displayPhoto: PropTypes.bool,
  customActions: PropTypes.func,
  additionalActions: PropTypes.array,
  backgroundColor: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
};

CustomCardBadge.defaultProps = {
  prefix: 'towers',
  imgBackup: <PictureOutlined />,
  showMembers: true,
  disabledEdit: false,
  disabledDelete: false,
  circleImage: false,
  displayPhoto: true,
  customActions: null,
  additionalActions: [],
  backgroundColor: '#1976d2',
};

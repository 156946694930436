import React from 'react';
import Gauge from '@ant-design/charts/lib/gauge';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

function GaugeGraph({ participation, isLoadingParticipation }) {
  const { innerWidth: width, innerHeight: height } = window;

  const config = {
    percent: participation?.averageParticipation / 100 || 0,
    height: height > 768 ? 250 : 283,
    range: { color: 'l(0) 0:#bde8ff 1:#9ec9ff' },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: '24px',
          color: '#4B535E',
        },
        formatter: function formatter(item) {
          return `${(item.percent * 100).toFixed(2)}%`;
        },
      },
      content: {
        style: {
          fontSize: width > 1366 ? '20px' : '17px',
          lineHeight: '44px',
          color: '#4B535E',
        },
        formatter: function formatter() {
          return 'Média da Adesão';
        },
      },
    },
  };
  return (
    <Spin spinning={isLoadingParticipation || false}>
      <Gauge {...config} />
    </Spin>
  );
}

GaugeGraph.propTypes = {
  participation: PropTypes.object.isRequired,
  isLoadingParticipation: PropTypes.bool.isRequired,
};

export default React.memo(GaugeGraph);

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetCompetenceFilters,
  setCompetenceBUFilter,
  setCompetenceNameFilter,
  setCompetenceSoluterFilter,
  setCompetenceValidFilter,
  setCompetenceTowerFilter,
} from '~/store/ducks/competence';
import { selectCompetenceState } from '~/store/selectors/competence';

export const useCompetenceFilter = () => {
  const dispatch = useDispatch();
  const { filter } = useSelector(selectCompetenceState);

  const handleChangeBU = useCallback(
    businessUnit => dispatch(setCompetenceBUFilter(businessUnit)),
    [dispatch],
  );

  const handleChangeTower = useCallback(
    value => dispatch(setCompetenceTowerFilter(value)),
    [dispatch],
  );

  const handleChangeSoluter = useCallback(
    email => dispatch(setCompetenceSoluterFilter(email)),
    [dispatch],
  );

  const handleChangeCompetence = useCallback(
    competence => dispatch(setCompetenceNameFilter(competence)),
    [dispatch],
  );

  const handleChangeValid = useCallback(
    valid => dispatch(setCompetenceValidFilter(valid)),
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(resetCompetenceFilters());
    };
  }, [dispatch]);

  // Reset competence title on unmount..

  return {
    ...filter,
    handleChangeTower,
    handleChangeBU,
    handleChangeSoluter,
    handleChangeValid,
    handleChangeCompetence,
  };
};

export default useCompetenceFilter;

export default function handleSearch(
  value,
  action,
  searchTimeout,
  setSearchTimeout,
  dispatch,
) {
  if (searchTimeout) clearTimeout(searchTimeout);
  setSearchTimeout(
    setTimeout(() => {
      dispatch(action(value));
    }, 170),
  );
}

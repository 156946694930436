import _ from 'lodash';

import {
  setCompetences,
  setCompetencesCsvData,
  setCompetencesLoading,
} from '../ducks/competence';

import api from '~/config/httpRequest';

export const getCompetences = (
  businessUnit = '',
  tower = '',
  soluter = '',
  filteredCompetenceName = '',
  valid = '',
) => dispatch => {
  dispatch(setCompetencesLoading(true));
  return api
    .get(
      `soluters/competence?${new URLSearchParams(
        valid
          ? {
              email: soluter,
              groupId: tower,
              practiceId: businessUnit,
              title: filteredCompetenceName,
              valid,
            }
          : {
              email: soluter,
              groupId: tower,
              practiceId: businessUnit,
              title: filteredCompetenceName,
            },
      ).toString()}`,
    )
    .then(response =>
      dispatch(
        setCompetences(
          response?.data
            ? { competences: response?.data, total: response?.data?.length }
            : [],
        ),
      ),
    )
    .finally(() => dispatch(setCompetencesLoading(false)));
};

export const exportCompetences = (
  businessUnit = '',
  tower = '',
  soluter = '',
  filteredCompetenceName = '',
  valid = '',
) => {
  return async dispatch => {
    api
      .get('soluters/competence/export', {
        headers: {
          Accept: 'text/csv',
        },
        params: valid
          ? {
              email: soluter,
              groupId: tower,
              practiceId: businessUnit,
              title: filteredCompetenceName,
              valid,
            }
          : {
              email: soluter,
              groupId: tower,
              practiceId: businessUnit,
              title: filteredCompetenceName,
            },
      })
      .then(({ data }) => {
        dispatch(setCompetencesCsvData(data));
      });
  };
};

export const getCompetenceFile = competenceId =>
  api.get(`soluters/competence/${competenceId}/file`, {
    responseType: 'blob',
  });

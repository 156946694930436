/* eslint-disable no-console */
import { message } from 'antd';
import api from '~/config/httpRequest';
import * as roleActions from '~/store/ducks/roles';
import { validationMessages } from '~/constants';
import { startLoading, endLoading } from '../ducks/loading';

export const get = (page = 1, pageSize = 10) => {
  return dispatch => {
    api
      .get(`/jobs/roles?term&page=${page}&pageSize=${pageSize}`)
      .then(({ data: payload }) => {
        dispatch(
          roleActions.get({
            roles: payload?.content || [],
            total: payload?.total || 0,
            page,
          }),
        );
      })
      .catch(e => {
        console.log('Erro', e);
        dispatch(
          roleActions.get({
            roles: null,
            total: 0,
            page: 1,
          }),
        );
      });
  };
};

export const getByTitle = (title, page = 1, pageSize = 10) => {
  return dispatch => {
    dispatch(startLoading('isLoadingRoles'));
    api
      .get(`/jobs/roles?term=${title}&page=${page}&pageSize=${pageSize}`)
      .then(({ data: payload }) => {
        dispatch(
          roleActions.get({
            roles: payload?.content || [],
            total: payload?.total || 0,
            page,
          }),
        );
        dispatch(endLoading('isLoadingRoles'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingRoles'));
        dispatch(
          roleActions.get({
            roles: null,
            total: 0,
            page: 1,
          }),
        );
      });
  };
};

export const add = (searching, searchTerm, title, page = 1) => {
  return dispatch => {
    api
      .post(`/jobs/roles`, { title })
      .then(() => {
        dispatch(roleActions.add(title));
        message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
        if (searching) dispatch(getByTitle(searchTerm, page));
        else dispatch(get(page));
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const edit = (searching, searchTerm, title, id, page = 1) => {
  return dispatch => {
    api
      .put(`/jobs/roles/${id}`, { id, title })
      .then(({ data: payload }) => {
        dispatch(roleActions.edit(payload));
        message.success(validationMessages.HTTP_EDIT_SUCCESS_MESSAGE);
        if (searching) dispatch(getByTitle(searchTerm, page));
        else dispatch(get(page));
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const remove = (searching, searchTerm, id, page, onlyOne) => {
  return dispatch => {
    api
      .delete(`/jobs/roles/${id}`)
      .then(() => {
        dispatch(roleActions.remove(id));
        message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
        if (onlyOne) {
          if (searching)
            dispatch(getByTitle(searchTerm, page > 1 ? page - 1 : 1));
          else dispatch(get(page > 1 ? page - 1 : 1));
        } else if (searching) {
          dispatch(getByTitle(searchTerm, page));
        } else {
          dispatch(get(page));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
};

import React, { useMemo, useState, useCallback } from 'react';
import { Empty, Table, Typography } from 'antd';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from '~/components';
import EditBusinessUnitFamily from './EditBusinessUnitFamily';
import {
  deleteFamilies,
  deleteRoleFamilies,
  getFamiliesById,
  updateFamilies,
  updateRoleFamilies,
} from '~/store/fetchActions/fetchFamilies';
import AddRoleOrSubFamily from './AddRoleOrSubFamily';
import { selectLoadingState } from '~/store/selectors';

const { Link } = Typography;

export default function BusinessUnitFamiliesTable({ families, buId }) {
  const [isEditFamilyModalVisible, setEditFamilyModalVisible] = useState(false);
  const [handleIsRole, setHandleIsRole] = useState(false);
  const [
    isAddRoleOrSubFamilyModalVisible,
    setAddRoleOrSubFamilyModalVisible,
  ] = useState(false);
  const [currentFamily, setCurrentFamily] = useState();
  const [currentRole, setCurrentRole] = useState();
  const [currentRoleList, setCurrentRoleList] = useState();
  const [currentFamilyId, setCurrentFamilyId] = useState();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectLoadingState);

  const handleCloseEditFamilyModal = useCallback(() => {
    setEditFamilyModalVisible(false);
  }, []);

  const handleCloseAddRoleOrSubFamily = useCallback(() => {
    setAddRoleOrSubFamilyModalVisible(false);
    setCurrentRole(null);
  }, []);

  const sortHierarchyUp = useCallback(
    (hierarchyEdit, familyEdit) => {
      // se for de baixo pra cima

      if (familyEdit) {
        // registro clicado
        dispatch(
          updateFamilies(
            familyEdit.name,
            familyEdit.skills,
            familyEdit.roles,
            buId,
            familyEdit.id,
            hierarchyEdit - 1,
          ),
        );
      }
    },
    [buId, dispatch],
  );

  const sortHierarchyRoleUp = useCallback(
    (hierarchyEdit, roleEdit) => {
      // se for de baixo pra cima

      if (roleEdit) {
        // registro clicado
        dispatch(
          updateRoleFamilies(
            roleEdit.name,
            roleEdit.id,
            buId,
            hierarchyEdit - 1,
          ),
        );
      }
    },
    [buId, dispatch],
  );

  const sortHierarchyDown = useCallback(
    (hierarchyEdit, familyEdit) => {
      // se for de cima pra baixo

      if (familyEdit) {
        // registro clicado
        dispatch(
          updateFamilies(
            familyEdit.name,
            familyEdit.skills,
            familyEdit.roles,
            buId,
            familyEdit.id,
            hierarchyEdit + 1,
          ),
        );
      }
    },
    [buId, dispatch],
  );

  const sortHierarchyRoleDown = useCallback(
    (hierarchyEdit, roleEdit) => {
      // se for de cima pra baixo
      if (roleEdit) {
        // registro clicado
        dispatch(
          updateRoleFamilies(
            roleEdit.name,
            roleEdit.id,
            buId,
            hierarchyEdit + 1,
          ),
        );
      }
    },
    [buId, dispatch],
  );

  const handleDeleteFamily = useCallback(
    id => {
      dispatch(deleteFamilies(id, buId));
    },
    [dispatch, buId],
  );

  const handleDeleteRoleFamily = useCallback(
    id => {
      dispatch(deleteRoleFamilies(id, buId));
    },
    [dispatch, buId],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Nome da Família',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Ações',
        align: 'end',
        render: family => {
          const { id, hierarchy } = family;
          return (
            <>
              <Link
                key={`add-family-${id}`}
                data-testid={`btn-add-family-${id}`}
                onClick={() => {
                  dispatch(getFamiliesById(id));
                  setCurrentRole(null);
                  setAddRoleOrSubFamilyModalVisible(true);
                  setCurrentFamilyId(id);
                  setCurrentFamily(family);
                  setHandleIsRole(false);
                }}
              >
                <Button type="add" style={{ marginRight: 12 }} />
              </Link>
              <Link
                key={`edit-family-${id}`}
                data-testid={`btn-edit-family-${id}`}
                onClick={() => {
                  setEditFamilyModalVisible(true);
                  setCurrentFamily(family);
                }}
              >
                <Button type="edit" style={{ marginRight: 12 }} />
              </Link>

              <Button
                type="delete"
                style={{ marginRight: 24 }}
                handleDelete={() => handleDeleteFamily(id)}
              />
              <Link
                key={`sort-up-family-${id}`}
                data-testid={`btn-sort-up-family-${id}`}
                onClick={() => {
                  sortHierarchyUp(hierarchy, family);
                }}
              >
                <Button type="sort-up" style={{ marginRight: 24 }} />
              </Link>
              <Link
                key={`sort-down-family-${id}`}
                data-testid={`btn-sort-down-family-${id}`}
                onClick={() => {
                  sortHierarchyDown(hierarchy, family);
                }}
              >
                <Button type="sort-down" style={{ marginRight: 24 }} />
              </Link>
            </>
          );
        },
      },
    ],
    [handleDeleteFamily, sortHierarchyUp, sortHierarchyDown, dispatch],
  );

  const subFamilyColumns = useMemo(
    () => [
      {
        title: 'Sub-Famílias',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Ações',
        align: 'end',
        render: subFamily => {
          const { id, hierarchy } = subFamily;
          return (
            <>
              <Link
                key={`add-subFamily-${id}`}
                data-testid={`btn-add-subFamily-${id}`}
                onClick={() => {
                  setAddRoleOrSubFamilyModalVisible(true);
                  setCurrentFamilyId(id);
                  setHandleIsRole(true);
                }}
              >
                <Button type="add" style={{ marginRight: 12 }} />
              </Link>
              <Link
                key={`edit-subFamily-${id}`}
                data-testid={`btn-edit-subFamily-${id}`}
                onClick={() => {
                  setEditFamilyModalVisible(true);
                  setCurrentFamily(subFamily);
                }}
              >
                <Button type="edit" style={{ marginRight: 12 }} />
              </Link>
              <Button
                type="delete"
                style={{ marginRight: 24 }}
                handleDelete={() => handleDeleteFamily(id)}
              />
              <Link
                key={`sort-up-subFamily-${id}`}
                data-testid={`btn-sort-up-subFamily-${id}`}
                onClick={() => {
                  sortHierarchyUp(hierarchy, subFamily);
                }}
              >
                <Button type="sort-up" style={{ marginRight: 24 }} />
              </Link>
              <Link
                key={`sort-down-subFamily-${id}`}
                data-testid={`btn-sort-down-subFamily-${id}`}
                onClick={() => {
                  sortHierarchyDown(hierarchy, subFamily);
                }}
              >
                <Button type="sort-down" style={{ marginRight: 24 }} />
              </Link>
            </>
          );
        },
      },
    ],
    [handleDeleteFamily, sortHierarchyDown, sortHierarchyUp],
  );

  const rolesColumns = useMemo(
    () => [
      {
        title: 'Cargos',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Ações',
        align: 'end',
        render: role => {
          const { id, hierarchy } = role;

          return (
            <>
              <Link
                key={`edit-role-${role}`}
                data-testid={`btn-edit-role-${role}`}
                onClick={() => {
                  setCurrentRole(role);
                  setAddRoleOrSubFamilyModalVisible(true);
                }}
              >
                <Button type="edit" style={{ marginRight: 12 }} />
              </Link>
              <Button
                type="delete"
                style={{ marginRight: 24 }}
                handleDelete={() => handleDeleteRoleFamily(id)}
              />
              <Link
                key={`sort-up-role-${id}`}
                data-testid={`btn-sort-up-role-${id}`}
                onClick={() => {
                  sortHierarchyRoleUp(hierarchy, role);
                }}
              >
                <Button type="sort-up" style={{ marginRight: 24 }} />
              </Link>
              <Link
                key={`sort-down-role-${id}`}
                data-testid={`btn-sort-down-role-${id}`}
                onClick={() => {
                  sortHierarchyRoleDown(hierarchy, role);
                }}
              >
                <Button type="sort-down" style={{ marginRight: 24 }} />
              </Link>
            </>
          );
        },
      },
    ],
    [handleDeleteRoleFamily, sortHierarchyRoleUp, sortHierarchyRoleDown],
  );

  return (
    <>
      <Table
        columns={columns}
        dataSource={families}
        loading={isLoading}
        expandable={{
          expandedRowRender: record => {
            if (record.roles.length > 0) {
              setCurrentRoleList(record.roles);
            }
            return (
              <>
                {record?.roles?.length === 0 &&
                  record?.subFamilies?.length <= 0 && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Não há dados registrados."
                    />
                  )}
                {record.roles.length > 0 && (
                  <Table
                    columns={rolesColumns}
                    dataSource={record.roles}
                    pagination={false}
                  />
                )}
                {record.subFamilies && record.subFamilies.length > 0 && (
                  <div>
                    <Table
                      columns={subFamilyColumns}
                      dataSource={record.subFamilies}
                      pagination={false}
                      rowKey="id"
                      expandable={{
                        expandedRowRender: subFamily => (
                          <Table
                            columns={rolesColumns}
                            dataSource={subFamily.roles}
                            pagination={false}
                          />
                        ),
                        rowExpandable: subFamily =>
                          (subFamily.roles && subFamily.roles.length > 0) ||
                          (subFamily.skills && subFamily.skills.length > 0),
                      }}
                    />
                  </div>
                )}
              </>
            );
          },
          rowExpandable: record =>
            (record.roles && record.roles.length > 0) ||
            (record.skills && record.skills.length > 0) ||
            (record.subFamilies && record.subFamilies.length > 0),
        }}
        rowKey="id"
      />
      <EditBusinessUnitFamily
        isVisible={isEditFamilyModalVisible}
        setVisible={handleCloseEditFamilyModal}
        family={currentFamily}
        isAddFamily={false}
      />
      <AddRoleOrSubFamily
        isVisible={isAddRoleOrSubFamilyModalVisible}
        setVisible={handleCloseAddRoleOrSubFamily}
        familyId={currentFamilyId}
        isRole={handleIsRole}
        role={currentRole}
        familyList={currentFamily}
      />
    </>
  );
}

BusinessUnitFamiliesTable.propTypes = {
  families: PropTypes.arrayOf(PropTypes.object),
  buId: PropTypes.string,
};
BusinessUnitFamiliesTable.defaultProps = {
  families: [],
  buId: '',
};

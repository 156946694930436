import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import './styles.scss';

export default function RetryConnectButton({ action, props }) {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(action(...props));
  }
  return (
    <Button
      className="retry-connect__button"
      data-testid="retry-connect-button"
      onClick={handleClick}
    >
      <p className="retry-connect__text">Tentar Novamente?</p>
      <RedoOutlined style={{ fontSize: 16 }} />
    </Button>
  );
}

RetryConnectButton.propTypes = {
  action: PropTypes.func.isRequired,
  props: PropTypes.array,
};

RetryConnectButton.defaultProps = {
  props: [],
};

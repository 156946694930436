import { Modal, Typography, Tag, Space } from 'antd';
import PropTypes from 'prop-types';
import './styles.scss';

const { Title } = Typography;

export default function PracticeDetailsModal({
  practice,
  isVisible,
  setVisible,
}) {
  return (
    <Modal
      title={practice?.name}
      visible={isVisible}
      onCancel={() => setVisible(false)}
      width="42vw"
      footer={null}
    >
      <div className="d-flex">
        <div className="mr-md">
          <Title level={5}>Sigla</Title>
          <p>{practice?.initials}</p>
        </div>
        <div>
          <Title level={5}>Nome</Title>
          <p>{practice?.name}</p>
        </div>
      </div>

      <Title level={5}>Descrição</Title>
      <p
        className={`${
          practice?.description ? '' : `generic-card__description--empty`
        }`}
      >
        {practice?.description || 'Essa BU não possui uma descrição.'}
      </p>

      <Title level={5}>Centros de Custo Associados</Title>
      <Space className="w-100" wrap>
        {!practice?.costCenters?.length > 0 && (
          <p className="generic-card__description--empty">
            Essa BU não possui centros de custo associados.
          </p>
        )}
        {practice?.costCenters?.map(costCenter => (
          <Tag key={costCenter?.id} color="blue">
            {costCenter?.id} - {costCenter?.title}
          </Tag>
        ))}
      </Space>
    </Modal>
  );
}

PracticeDetailsModal.propTypes = {
  practice: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Switch, Modal } from 'antd';
import PropTypes from 'prop-types';
import { getRole } from '~/config/environment';
import { disableTower } from '~/store/fetchActions/fetchTowers';
import './styles.scss';
import { APP_NAME } from '~/constants/app';

export default function DisableTowerSwitch({ tower, allGroups }) {
  const [isSwitchChecked, setSwitchChecked] = useState(
    typeof tower?.active !== 'undefined' ? tower?.active : true,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setSwitchChecked(
      typeof tower?.active !== 'undefined' ? tower?.active : true,
    );
  }, [tower]);

  const handleDisableTower = (_towerId, active) => {
    Modal.confirm({
      title: 'Atenção',
      content: (
        <div>
          {active ? (
            <p>
              Ao habilitar a torre {tower?.name}, a mesma voltará a ser exibida
              no aplicativo do Passaporte.
            </p>
          ) : (
            <p>
              Ao desabilitar a torre {tower?.name}, a mesma deixará de ser
              exibida no aplicativo do Passaporte, mas seus dados continuarão
              disponíveis no {APP_NAME}.
            </p>
          )}
          <p>Deseja prosseguir?</p>
        </div>
      ),
      cancelText: 'Não',
      okText: 'Sim',
      onCancel() {},
      onOk() {
        setSwitchChecked(active);
        dispatch(disableTower(_towerId, active, allGroups));
      },
    });
  };

  return (
    <Tooltip title={isSwitchChecked ? 'Desabilitar Torre' : 'Habilitar Torre'}>
      <Switch
        className="tower-switch"
        disabled={getRole() !== 'admin'}
        style={{ marginLeft: 8 }}
        checked={isSwitchChecked}
        onChange={checked => handleDisableTower(tower?._id, checked)}
      />
    </Tooltip>
  );
}

DisableTowerSwitch.propTypes = {
  tower: PropTypes.object.isRequired,
  allGroups: PropTypes.bool,
};

DisableTowerSwitch.defaultProps = {
  allGroups: true,
};

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export default function Comment({ comment, date, author }) {
  return (
    <div
      className="soluter-comment"
      style={{ alignItems: author ? 'left' : 'center' }}
    >
      {author && <p className="soluter-comment__author">{author}</p>}
      <p className={`soluter-comment__text${!comment ? '--empty' : ''}`}>
        {comment || 'Sem comentários.'}
      </p>
      <div className="soluter-comment__date">{date}</div>
    </div>
  );
}

Comment.propTypes = {
  comment: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  author: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Comment.defaultProps = {
  author: null,
};

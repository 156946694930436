import { Typography } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CapacityHeader, CapacityTable } from '~/container/';
import { getCapacity } from '~/store/fetchActions/fetchTowers';

const { Title } = Typography;

export default function Capacity() {
  const dispatch = useDispatch();
  const {
    monthInterval,
    roleActiveFilters,
    techActiveFilters,
    seniorityActiveFilters,
    allocationFilter,
    groupingMode,
  } = useSelector(state => state.towers.capacity);

  const { selectedTower } = useSelector(state => state.towers);

  useEffect(() => {
    if (selectedTower && monthInterval.start && monthInterval.end)
      dispatch(
        getCapacity(
          monthInterval.start,
          monthInterval.end,
          roleActiveFilters,
          techActiveFilters,
          seniorityActiveFilters,
          allocationFilter,
          selectedTower,
          groupingMode,
        ),
      );
  }, [
    monthInterval,
    allocationFilter,
    dispatch,
    roleActiveFilters,
    seniorityActiveFilters,
    techActiveFilters,
    selectedTower,
    groupingMode,
  ]);

  return (
    <>
      <Title>Capacity</Title>
      <CapacityHeader />
      <CapacityTable
        retryProps={[
          monthInterval.start,
          monthInterval.end,
          roleActiveFilters,
          techActiveFilters,
          seniorityActiveFilters,
          allocationFilter,
          selectedTower,
          groupingMode,
        ]}
      />
    </>
  );
}

/* eslint-disable no-nested-ternary */
import { useEffect, useMemo } from 'react';
import { Select, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import { getRole } from '~/config/environment';

export default function SelectTowers({
  selector,
  dispatchDuck,
  disableEmptyOption,
  emptyOptionText,
  allowClear,
  onChange,
}) {
  const { towers } = useSelector(state => state.towers);
  const { isLoadingTowers } = useSelector(state => state.loading);
  const { selectedTower, selectedTowerName } = useSelector(selector);
  const dispatch = useDispatch();
  const { Option } = Select;

  const changed = () => {
    onChange();
  };

  useEffect(() => {
    dispatch(fetchTowers.listTowers());
  }, [dispatch]);

  useEffect(() => {
    if (disableEmptyOption && towers?.length > 0 && selectedTower === '')
      dispatch(dispatchDuck({ value: towers[0]?._id, label: towers[0]?.name }));
  }, [towers, dispatch, dispatchDuck, selectedTower, disableEmptyOption]);

  const selectedValue = useMemo(
    () => ({
      value: selectedTower,
      label: selectedTowerName || (disableEmptyOption ? '' : emptyOptionText),
    }),
    [selectedTower, selectedTowerName, emptyOptionText, disableEmptyOption],
  );

  return (
    <>
      {isLoadingTowers && towers?.length === 0 && (
        <Skeleton.Input
          paragraph={{ rows: 0 }}
          style={{ width: 250, margin: 0, padding: 0 }}
          loading
          active
        />
      )}
      {towers?.length > 0 && (
        <Select
          allowClear={allowClear}
          defaultValue={
            disableEmptyOption && selectedTower === ''
              ? { value: towers[0]?._id, label: towers[0]?.name }
              : !disableEmptyOption && selectedTower === ''
              ? { value: '', label: emptyOptionText }
              : { value: selectedTower, label: selectedTowerName }
          }
          onChange={value => {
            changed();
            if (value) dispatch(dispatchDuck(value));
            else dispatch(dispatchDuck({ value: '', label: emptyOptionText }));
          }}
          style={{ width: 250 }}
          placeholder="Selecione uma Torre"
          labelInValue
          data-testid="select-towers"
          value={selectedValue}
        >
          {!disableEmptyOption && <Option value="">{emptyOptionText}</Option>}
          {getRole() !== 'admin' && (
            <Option
              value="smallGroups"
              key="smallGroups"
              data-testid="select-towers-option-smallGroups"
            >
              Torres com menos de três membros
            </Option>
          )}
          {towers?.map((tower, idx) => (
            <Option
              value={tower?._id}
              key={tower?._id}
              data-testid={`select-towers-option-${idx}`}
            >
              {tower?.name}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
}

SelectTowers.propTypes = {
  selector: PropTypes.func.isRequired,
  dispatchDuck: PropTypes.func.isRequired,
  disableEmptyOption: PropTypes.bool,
  emptyOptionText: PropTypes.string,
  allowClear: PropTypes.bool,
  onChange: PropTypes.func,
};

SelectTowers.defaultProps = {
  disableEmptyOption: false,
  emptyOptionText: 'Todos os Soluters',
  allowClear: false,
  onChange: () => {},
};

import {
  Row,
  Typography,
  DatePicker,
  List,
  Card,
  Button,
  Col,
  Dropdown,
  Menu,
  Select,
  Badge,
} from 'antd';
import { FireOutlined, AlertOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import moment from 'moment';
import {
  ExitInterviewForm,
  ExitInterviewDetails,
  ExitInvolutaryInterviewForm,
  ExitInvolutaryInterviewDetails,
} from '~/container';
import {
  DownloadButton,
  Button as CustomButton,
  EmptyErrorWrapper,
  SoluterName,
  SearchBar,
  PracticeFilter,
} from '~/components';

import {
  getSoluterExitInterviews,
  removeSoluterExitInterview,
  removeSoluterExitInvoluntaryInterview,
} from '~/store/fetchActions/fetchPeople';

import {
  clearCsvData,
  searchByTerm,
  setDateRange,
  setSelectedPractice,
} from '~/store/ducks/interviews';

import {
  getExitInterviewsByDate,
  getInterviewsCsv,
} from '~/store/fetchActions/fetchInterviews';
import './styles.scss';

const { Meta } = Card;
const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function ExitInterview() {
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [isAddInvolutaryModalVisible, setAddInvolutaryModalVisible] = useState(
    false,
  );
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [
    isInvoluntaryEditModalVisible,
    setInvoluntaryEditModalVisible,
  ] = useState(false);
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [
    isInvoluntaryDetailsModalVisible,
    setInvoluntaryDetailsModalVisible,
  ] = useState(false);
  const [currentInterview, setCurrentInterview] = useState(null);
  const [currentTypeInterview, setCurrentTypeInterview] = useState(0);
  const dispatch = useDispatch();
  const { soluter } = useSelector(state => state.people);
  const { selectedPractice, term } = useSelector(state => state.interviews);
  const { csvData, startDate, endDate, interviews } = useSelector(
    state => state.interviews,
  );
  const searchStyles = {
    display: 'flex',
    padding: 0,
    inputMargin: 0,
    inputMarginRight: '0px',
    inputWidth: 250,
    layout: 'inline',
  };

  const allInterviews = () => {
    if (
      interviews !== null &&
      interviews?.voluntary !== undefined &&
      interviews?.involuntary !== undefined
    )
      return interviews?.voluntary.concat(interviews?.involuntary);
    return [];
  };

  const typeInterview = [
    allInterviews(),
    interviews?.voluntary,
    interviews?.involuntary,
  ];

  useEffect(() => {
    dispatch(
      setDateRange({
        startDate: moment().startOf('month').format('DD-MM-YYYY'),
        endDate: moment().endOf('month').format('DD-MM-YYYY'),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getExitInterviewsByDate(startDate, endDate, selectedPractice, term),
    );
  }, [dispatch, startDate, endDate, selectedPractice, term]);

  const reload = () => {
    dispatch(
      getExitInterviewsByDate(startDate, endDate, selectedPractice, term),
    );
  };

  const onChangePractice = practiceId => {
    dispatch(setSelectedPractice(practiceId));
  };

  const handleDelete = async item => {
    if (item.typeInterview === 'VOLUNTARY') {
      try {
        await removeSoluterExitInterview(item.employeeEmail, item.id);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        reload();
      }
    } else {
      try {
        await removeSoluterExitInvoluntaryInterview(
          item.employeeEmail,
          item.id,
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        reload();
      }
    }
  };

  const handleTypeInterview = value => {
    setCurrentTypeInterview(value);
  };

  const handleCsvDownload = () => {
    dispatch(
      getInterviewsCsv(
        startDate,
        endDate,
        selectedPractice,
        term,
        translateTypeInterview(currentTypeInterview),
      ),
    );
  };

  const translateTypeInterview = param =>
    ({
      1: 'VOLUNTARY',
      2: 'INVOLUNTARY',
    }[param]);

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setAddModalVisible(true);
        }}
      >
        Entrevista Voluntária
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setAddInvolutaryModalVisible(true);
        }}
      >
        Entrevista Involuntária
      </Menu.Item>
    </Menu>
  );

  const applyVoluntaryBadge = (content, isVoluntary) => {
    return isVoluntary ? (
      <Badge.Ribbon
        text={
          <div className="d-flex exit-interview-badge exit-interview-badge--voluntary">
            <AlertOutlined />
          </div>
        }
        className="exit-interview-badge"
        style={{
          marginTop: 0,
          zIndex: 2,
          background:
            'linear-gradient(42deg, rgb(235, 216, 16), rgb(242, 231, 116))',
          minWidth: 45,
          display: 'flex',
          justifyContent: 'center',
        }}
        color="yellow"
      >
        {content}
      </Badge.Ribbon>
    ) : (
      <Badge.Ribbon
        text={
          <div className="d-flex exit-interview-badge exit-interview-badge--involuntary">
            <FireOutlined />
          </div>
        }
        className="exit-interview-badge"
        style={{
          marginTop: 0,
          zIndex: 2,
          background:
            'linear-gradient(42deg, rgb(204, 18, 46), rgb(219, 105, 122))',
          minWidth: 45,
          display: 'flex',
          justifyContent: 'center',
        }}
        color="red"
      >
        {content}
      </Badge.Ribbon>
    );
  };

  return (
    <>
      <Row justify="space-between" align="bottom">
        <Title>Entrevistas</Title>
      </Row>
      <Row align="bottom" justify="space-between">
        <Col>
          <Row align="bottom" style={{ marginBottom: 8 }}>
            <span>
              <p className="mb-xs">Filtrar por Data de Desligamento: </p>
              <RangePicker
                style={{ marginRight: 8 }}
                locale={locale}
                format="DD-MM-YYYY"
                inputReadOnly
                defaultValue={[
                  moment().startOf('month'),
                  moment().endOf('month'),
                ]}
                onChange={(value, dateString) =>
                  dispatch(
                    setDateRange({
                      startDate: dateString[0],
                      endDate: dateString[1],
                    }),
                  )
                }
              />
            </span>
            <span>
              <p className="mb-xs">Tipo de entrevista:</p>
              <Select
                defaultValue={0}
                style={{ width: 200 }}
                onChange={handleTypeInterview}
              >
                <Option value={0}>Todas</Option>
                <Option value={1}>Entrevistas Voluntárias</Option>
                <Option value={2}>Entrevistas Involuntária</Option>
              </Select>
            </span>
            <span style={{ marginLeft: '0.5rem' }}>
              <p className="mb-xs">Buscar por BU:</p>
              <PracticeFilter
                onChangePractice={onChangePractice}
                style={{ marginBottom: 0, width: 250 }}
              />
            </span>

            <span style={{ marginLeft: '0.5rem' }}>
              <p className="mb-xs">Pesquisar Soluter:</p>
              <SearchBar
                title={`Pesquisar Soluter `}
                isSimpleSearchBar
                shouldFetchOnSearch={false}
                actionSearch={searchByTerm}
                customStyles={searchStyles}
              />
            </span>
          </Row>
        </Col>
        <Row>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button className="mr-md" type="primary">
              Nova Entrevista
            </Button>
          </Dropdown>
          <DownloadButton
            exportCsvLayout
            label="Exportar CSV"
            data={csvData || null}
            requestData={handleCsvDownload}
            clearData={clearCsvData}
            fileName="Entrevistas.csv"
          />
        </Row>
      </Row>
      <Row style={{ marginTop: 8 }} />
      <Row justify="space-between" align="center" className="mt-md">
        <EmptyErrorWrapper
          data={interviews}
          emptyCondition={
            typeInterview[currentTypeInterview] !== undefined &&
            typeInterview[currentTypeInterview].length > 0
          }
          loaderTitle="isLoadingExitInterviews"
          emptyMessage="Não há entrevista de desligamento para o período selecionado."
          retryAction={getSoluterExitInterviews}
          retryProps={[soluter?.email]}
        >
          <List
            dataSource={typeInterview[currentTypeInterview] || []}
            grid={{
              column: 3,
              sm: 1,
              md: 2,
              gutter: 18,
            }}
            pagination={{
              pageSize: 6,
              hideOnSinglePage: true,
            }}
            renderItem={item => {
              const cardActions = [
                <CustomButton
                  style={{ marginLeft: 5, fontSize: 16 }}
                  type="view"
                  isButton
                  tooltipIdentifier="Entrevista"
                  handleClick={() => {
                    setCurrentInterview(item);
                    // eslint-disable-next-line no-unused-expressions
                    item?.type === 'VOLUNTARY'
                      ? setDetailsModalVisible(true)
                      : setInvoluntaryDetailsModalVisible(true);
                  }}
                />,
                <CustomButton
                  style={{ marginLeft: 5, fontSize: 16 }}
                  type="edit"
                  isButton
                  tooltipIdentifier="Entrevista"
                  handleClick={() => {
                    setCurrentInterview(item);
                    // eslint-disable-next-line no-unused-expressions
                    item?.type === 'VOLUNTARY'
                      ? setEditModalVisible(true)
                      : setInvoluntaryEditModalVisible(true);
                  }}
                />,
                <CustomButton
                  style={{ marginLeft: 5, fontSize: 16 }}
                  type="delete"
                  title={`a entrevista do dia ${item.interviewDate}`}
                  id={{
                    id: item.id,
                    employeeEmail: item.employeeEmail,
                    typeInterview: item.type,
                  }}
                  tooltipIdentifier="Entrevista"
                  handleDelete={handleDelete}
                />,
              ];

              return (
                <List.Item>
                  {applyVoluntaryBadge(
                    <Card className="shadowed-card" actions={cardActions}>
                      <Meta
                        title="Entrevista de Desligamento"
                        description={
                          <>
                            <Row justify="space-between">
                              <Col>
                                <p className="mb-0 description__title">
                                  Data de Desligamento:{' '}
                                </p>
                                <p className="description__content">
                                  {item.exitDate || 'Não Informada'}
                                </p>
                                <p className="mb-0 description__title">BU: </p>
                                <p className="description__content">
                                  {item.practice || 'Não Informada'}
                                </p>
                                <p className="mb-0 description__title">
                                  Cargo:{' '}
                                </p>
                                <p className="description__content">
                                  {item.jobTitle || 'Não Informada'}
                                </p>
                              </Col>
                              <Col>
                                <p className="mb-0 description__title">
                                  Data da Entrevista:{' '}
                                </p>
                                <p className="description__content">
                                  {item.interviewDate || 'Não Informada'}
                                </p>
                                <p className="mb-0 description__title">
                                  Centro de Custo:{' '}
                                </p>
                                <p className="description__content">
                                  {item.team || 'Não Informada'}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <p className="mb-0 description__title">
                                Soluter Entrevistado:
                              </p>
                              <div style={{ marginLeft: '0.5rem' }}>
                                <SoluterName
                                  name={item.name}
                                  email={item.employeeEmail}
                                />
                              </div>
                            </Row>
                            <Row>
                              <p className="mb-0 description__title">
                                Entrevistador:
                              </p>
                              {/* <SoluterName name={item.BP} email={item.BP} /> */}
                              <p style={{ marginLeft: '0.5rem' }}>{item.BP}</p>
                            </Row>
                          </>
                        }
                      />
                    </Card>,
                    item.type === 'VOLUNTARY',
                  )}
                </List.Item>
              );
            }}
          />
        </EmptyErrorWrapper>
      </Row>

      <ExitInterviewDetails
        interview={currentInterview}
        isModalVisible={isDetailsModalVisible}
        setModalVisible={setDetailsModalVisible}
      />
      <ExitInterviewForm
        soluter={soluter}
        isModalVisible={isAddModalVisible}
        setModalVisible={setAddModalVisible}
        customReload={reload}
      />
      <ExitInterviewForm
        soluter={soluter}
        isModalVisible={isEditModalVisible}
        setModalVisible={setEditModalVisible}
        interview={currentInterview}
        customReload={reload}
      />

      <ExitInvolutaryInterviewDetails
        interview={currentInterview}
        isModalVisible={isInvoluntaryDetailsModalVisible}
        setModalVisible={setInvoluntaryDetailsModalVisible}
      />
      <ExitInvolutaryInterviewForm
        soluter={soluter}
        isModalVisible={isAddInvolutaryModalVisible}
        setModalVisible={setAddInvolutaryModalVisible}
        customReload={reload}
      />
      <ExitInvolutaryInterviewForm
        soluter={soluter}
        isModalVisible={isInvoluntaryEditModalVisible}
        setModalVisible={setInvoluntaryEditModalVisible}
        interview={currentInterview}
        customReload={reload}
      />
    </>
  );
}

import { Card, Typography, Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMoodData from '~/hooks/useMoodData';
import { getMonthlyAvarage } from '~/store/fetchActions/fetchMood';
import { selectMoodWeekInterval } from '~/store/selectors/mood';
import MoodMonthlyAverageGraph from './MoodMonthlyAverageGraph';
import { EmptyErrorWrapper } from '~/components';
import MoodProgress from '~/container/Mood/MoodMonthlyAverage/MoodProgress';
import MoodEmojiTagsDrawer from './MoodEmojiTagsDrawer';

const { Title } = Typography;

export default function MoodMonthAverage() {
  const { selectedTower } = useSelector(state => state.mood);
  const { selectedTowers } = useSelector(state => state.mood);
  const week = useSelector(selectMoodWeekInterval);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerSkill, setDrawerSkill] = useState('');
  const dispatch = useDispatch();
  const { monthlyAverage, selectedCostCenter, selectedPractice } = useSelector(
    state => state.indicators,
  );
  const { isLoadingMoods } = useSelector(state => state.loading);
  const { isLoadingMonthlyAverage } = useSelector(state => state.loading);
  const [moodIndex, setMoodIndex] = useState('');
  const [isEmojiDrawerOpen, setIsEmojiDrawerOpen] = useState(false);
  const emptyList = [0, 0, 0, 0, 0];
  const handleOpenDrawer = mood => {
    setMoodIndex(mood);
    setIsEmojiDrawerOpen(true);
  };
  const towers = selectedTowers.map(tower => {
    return tower?.key;
  });

  const data = useMoodData(
    'monthlyAverage',
    getMonthlyAvarage,
    selectMoodWeekInterval,
    null,
    towers.toString(),
  );

  return (
    <Row>
      <Col span={17}>
        <Title className="mood-title mood-title-mood-progress" level={4}>
          Visão Segmentada
        </Title>
        <EmptyErrorWrapper
          data={data}
          emptyCondition={data}
          loaderTitle="isLoadingMoods"
          retryAction={getMonthlyAvarage}
          retryProps={[week, towers]}
          emptyMessage="Selecione pelo menos um dos filtros de torre, centro de custo ou BU para realizar a busca"
          style={{ height: 350 }}
        >
          <MoodMonthlyAverageGraph
            setDrawerSkill={setDrawerSkill}
            setDrawerVisible={setDrawerVisible}
            isLoadingMonthlyAverage={isLoadingMonthlyAverage}
            data={data}
          />
        </EmptyErrorWrapper>
      </Col>
      <Col span={5} offset={2}>
        <MoodProgress
          progressList={data?.averageMood || emptyList}
          title="Média de Reações do Período"
          margin={58}
          loaderTitle="isLoadingMoods"
          handleOpenDrawer={handleOpenDrawer}
          isLoading={isLoadingMoods}
        />
        <MoodEmojiTagsDrawer
          moodIndex={moodIndex}
          isDrawerOpen={isEmojiDrawerOpen}
          setIsDrawerOpen={setIsEmojiDrawerOpen}
        />
      </Col>
    </Row>
  );
}

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Modal, Select, Spin, Divider, Tooltip, Checkbox } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import { validationMessages } from '~/constants';
import {
  SelectRole,
  SelectSkills,
  SelectSearchPeople,
  SelectTowers,
  TagRender,
} from '~/components';
import TechnicalInfoForm from '../../Towers/TechnicalInfoForm';
import { setSelectedTower } from '~/store/ducks/towers';

const { Option } = Select;

export default function AddProjectModalProject({
  isVisible,
  setVisible,
  towerId,
  reload,
}) {
  const [form] = Form.useForm(); // Conectar o formulário ao componente Form
  const formRef = useRef(null);
  const { projects } = useSelector(state => state.towers);
  const { isLoadingSoluterBasicData } = useSelector(state => state.loading);
  const dispatch = useDispatch();
  const [isPersonRegistered, setIsPersonRegistered] = useState(false);
  const [isLeader, setIsLeader] = useState(false);
  const [project, setSelectedProject] = useState(null);
  const soluter = useSelector(state => state.soluterProject.soluter);
  const labelProject = `${soluter.name} - ${soluter.employeeNumber}`;
  const labelRole = soluter.primaryRole;
  const labelTech = soluter.primaryTech;
  const { email } = useParams();
  const [createAnotherItem, setCreateAnotherItem] = useState(false);
  // eslint-disable-next-line
  useEffect(() => {
    if (towerId) {
      dispatch(fetchTowers.getTowerProjects(towerId, false));
    }
    if (soluter && project) {
      form.setFieldsValue({
        mainRole: soluter.primaryRole,
        technology: soluter.primaryTech,
        projectKey: project.name,
      });
      dispatch(fetchTowers.getPeopleTower(soluter.email));
      setIsLeader(soluter.isLeader);
    }
  }, [towerId, project, form, dispatch, soluter]);

  useEffect(() => {
    if (soluter?.primaryRole) {
      setIsPersonRegistered(true);
      if (form.getFieldsValue().personKey && (!soluter || !project)) {
        form.setFieldsValue({
          mainRole: soluter.primaryRole,
          technology: soluter.primaryTech,
        });
      }
    } else setIsPersonRegistered(false);
  }, [soluter, form, project]);

  useEffect(() => {
    if (!project && !soluter) {
      // eslint-disable-next-line
      dispatch(fetchTowers.getPeopleTower());
    }
    // eslint-disable-next-line
  }, [dispatch, project, soluter]);

  async function handleSubmitAdd({
    mainRole,
    technologies,
    workload,
    primaryRole,
    seniority,
    primaryTech,
  }) {
    const personKey = soluter.email;
    if (!isPersonRegistered && personKey)
      try {
        await fetchPeople.addTechnicalInfo(personKey, {
          workload,
          primaryRole,
          seniority,
          primaryTech,
        });
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    try {
      await fetchTowers.createTeamMember(project._id, {
        mainRole,
        personKey,
        isLeader,
        technologies,
      });
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
    dispatch(fetchPeople.getSoluterBasicData(email, true, false));
    reset();
    reload();
  }

  const handleCreateAnotherItemChange = useCallback(e => {
    setCreateAnotherItem(e.target.checked);
  }, []);

  const reset = () => {
    if (!createAnotherItem) {
      setVisible(false);
    }
    // eslint-disable-next-line
    form.resetFields();
    if (!project) {
      setSelectedProject(null);
    }
    setIsLeader(false);
  };

  return (
    <Modal
      style={{ marginTop: '1rem' }}
      title="Adicionar Pessoa ao Projeto"
      visible={isVisible}
      onCancel={reset}
      okText="Salvar"
      cancelText="Cancelar"
      onOk={async () => {
        form
          .validateFields()
          .then(values => {
            handleSubmitAdd(values);
          })
          // eslint-disable-next-line
          .catch(console.error);
      }}
    >
      <Form
        ref={formRef}
        form={form}
        layout="vertical"
        requiredMark
        initialValues=""
        validateMessages={validationMessages.VALIDATION_MESSAGE}
      >
        <Form.Item
          required
          rules={[
            {
              type: 'string',
              min: 3,
            },
          ]}
          label="Torre"
        >
          <SelectTowers
            selector={state => state.towers}
            dispatchDuck={setSelectedTower}
            disableEmptyOption
            onChange={() => {
              setSelectedProject(null);
              form.resetFields(['project']);
            }}
          />
        </Form.Item>
        <Form.Item
          fieldName="project"
          name="project"
          required
          rules={[{ required: true, message: 'O projeto é obrigatório' }]}
          label="Projeto"
        >
          <Select
            defaultValue={project ? project.name : null}
            data-testid={
              soluter && project
                ? 'project-name-input-edit'
                : 'project-name-input-add'
            }
            onChange={value => {
              if (value)
                setSelectedProject(projects?.find(x => x._id === value));
            }}
            placeholder="Selecione o projeto"
          >
            {projects &&
              projects?.map((_project, idx) => (
                <Option
                  value={_project?._id}
                  key={_project?._id}
                  data-testid={`project-name-option-${idx}`}
                >
                  {_project?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="personKey" label="Pessoa">
          <SelectSearchPeople valueSoluter={labelProject} disabled />
        </Form.Item>
        <SelectRole
          fieldName="mainRole"
          fieldLabel="Papel"
          defaultValueSoluter={labelRole}
          required
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <SelectSkills
            selectMode="multiple"
            style={{ width: '60%' }}
            tagRenderFunc={props => TagRender(props)}
            required={false}
            fieldLabel="Tecnologias"
            fieldName="technologies"
            defaultValueSoluter={labelTech}
          />
          <Form.Item initialValue="">
            <Checkbox
              checked={isLeader}
              disabled={!project}
              onChange={() => setIsLeader(_isLeader => !isLeader)}
            >
              Líder do Projeto
            </Checkbox>
          </Form.Item>
        </div>
        <Checkbox
          onChange={handleCreateAnotherItemChange}
          checked={createAnotherItem}
        >
          Criar outro item
        </Checkbox>
        <Spin
          style={{ alignSelf: 'center', textAlign: 'center', width: '100%' }}
          spinning={isLoadingSoluterBasicData || false}
        >
          {soluter?.name && !isPersonRegistered && (
            <>
              <Divider plain>
                Informações Gerais
                <Tooltip
                  placement="right"
                  title="Dados referentes à atuação do Soluter na empresa como um todo. "
                >
                  <QuestionCircleOutlined
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      color: '#454545',
                      fontSize: 14,
                    }}
                  />
                </Tooltip>
              </Divider>
              <TechnicalInfoForm form={form} />
            </>
          )}
        </Spin>
      </Form>
    </Modal>
  );
}

AddProjectModalProject.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  towerId: PropTypes.string,
  reload: PropTypes.func.isRequired,
};

AddProjectModalProject.defaultProps = {
  towerId: '',
};

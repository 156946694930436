import { Card } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSolutersBySkills } from '~/store/fetchActions/fetchIndicators';
import SolutersBySkillsHeader from './SolutersBySkillsHeader';
import SkillSolutersDrawer from './SkillSolutersDrawer';
import SolutersBySkillsGraph from './SolutersBySkillsGraph';

export default function SolutersBySkills() {
  const [stateTags, setStateTags] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerSkill, setDrawerSkill] = useState('');
  const dispatch = useDispatch();
  const {
    solutersBySkills,
    selectedCostCenter,
    selectedPractice,
  } = useSelector(state => state.indicators);
  const { isLoadingSolutersBySkills } = useSelector(state => state.loading);

  useEffect(() => {
    if (!solutersBySkills.selectedSkills) {
      dispatch(
        getSolutersBySkills(true, [], selectedCostCenter, selectedPractice),
      );
    } else {
      dispatch(
        getSolutersBySkills(
          false,
          solutersBySkills.selectedSkills,
          selectedCostCenter,
          selectedPractice,
        ),
      );
    }
  }, [
    dispatch,
    solutersBySkills.selectedSkills,
    selectedCostCenter,
    selectedPractice,
  ]);

  return (
    <Card className="graph-card">
      <SolutersBySkillsHeader
        tags={solutersBySkills?.skillsTags}
        stateTags={stateTags}
      />
      <SolutersBySkillsGraph
        isLoadingSolutersBySkills={isLoadingSolutersBySkills}
        setDrawerSkill={setDrawerSkill}
        setDrawerVisible={setDrawerVisible}
        skillsTags={solutersBySkills.skillsTags}
        skillsSearch={solutersBySkills.skillsSearch}
      />
      <SkillSolutersDrawer
        drawerSkill={drawerSkill}
        setDrawerVisible={setDrawerVisible}
        drawerVisible={drawerVisible}
      />
    </Card>
  );
}

import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { confirm } = Modal;

export default function DeleteDialog({
  currentKey,
  item,
  handleDelete,
  content,
  disabled,
  subText,
}) {
  const confirmDelete = () =>
    confirm({
      title: `Tem certeza de que deseja deletar ${item.title}?`,
      icon: <ExclamationCircleOutlined />,
      content: subText,
      okText: 'Sim',
      okType: 'danger',
      okButtonProps: {
        'data-testid': 'confirm-delete-button',
      },
      cancelText: 'Não',
      onOk() {
        handleDelete(item.id);
      },
      onCancel() {},
    });
  return (
    <a key={currentKey} onClick={!disabled ? confirmDelete : undefined}>
      {content}
    </a>
  );
}

DeleteDialog.propTypes = {
  currentKey: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  content: PropTypes.node,
  subText: PropTypes.string,
  disabled: PropTypes.bool,
};

DeleteDialog.defaultProps = {
  content: 'Excluir',
  subText: 'Isso removerá o item das listas de todos os usuários',
  disabled: false,
};

import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { MoodTagList } from '~/components';
import { approvedTags, notApprovedTags } from '~/store/fetchActions/fetchMood';
import { iconsByMood, altTextByMood } from '~/constants/moods';

export default function MoodEmojiTagsDrawer({
  moodIndex,
  isDrawerOpen,
  setIsDrawerOpen,
}) {
  const { week, selectedTower, smileTags } = useSelector(state => state.mood);
  const dispatch = useDispatch();
  const { isLoadingApprovedTags, isLoadingNotApprovedTags } = useSelector(
    state => state.loading,
  );
  const onlyVoted = true;

  useEffect(() => {
    if (week.start && isDrawerOpen) {
      dispatch(approvedTags(week, selectedTower, onlyVoted, moodIndex));
      dispatch(notApprovedTags(week, selectedTower, onlyVoted, moodIndex));
    }
  }, [moodIndex, week, selectedTower, dispatch, isDrawerOpen, onlyVoted]);

  const drawerTitle = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>Tags marcadas por quem avaliou: </div>
        <img
          src={iconsByMood[moodIndex]}
          alt={altTextByMood[moodIndex]}
          style={{ width: 30, height: 30, marginLeft: 15 }}
        />
      </div>
    );
  };

  return (
    <Drawer
      title={drawerTitle()}
      placement="right"
      closable={false}
      onClose={() => setIsDrawerOpen(false)}
      visible={isDrawerOpen}
      width={500}
    >
      <MoodTagList
        approvedTags
        tags={smileTags?.approved?.tags}
        loader={isLoadingApprovedTags}
      />
      <MoodTagList
        tags={smileTags?.notApproved?.tags}
        loader={isLoadingNotApprovedTags}
      />
    </Drawer>
  );
}

MoodEmojiTagsDrawer.propTypes = {
  moodIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
};

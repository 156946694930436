import { Row, Col } from 'antd';
import { MonthlyTagsColumn } from '~/container';

export default function MoodMonthlyTags() {
  return (
    <>
      <Row>
        <Col span={8} offset={3}>
          <MonthlyTagsColumn approved />
        </Col>
        <Col span={8} offset={2}>
          <MonthlyTagsColumn />
        </Col>
      </Row>
    </>
  );
}

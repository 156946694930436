/* eslint-disable no-nested-ternary */
import { Spin, Empty } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorHandler } from '~/components';
import './styles.scss';

export default function EmptyErrorWrapper({
  children,
  data,
  emptyCondition,
  loaderTitle,
  retryAction,
  retryProps,
  style,
  emptyMessage,
}) {
  const loading = useSelector(state => state.loading);
  return (
    <Spin
      spinning={loading[loaderTitle] || false}
      wrapperClassName="wrapper-spin"
    >
      {data !== null ? (
        emptyCondition ? (
          children
        ) : (
          <div className="empty-error__container">
            <Empty
              description={emptyMessage}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              data-testid="empty-message"
            />
          </div>
        )
      ) : (
        <ErrorHandler
          action={retryAction}
          props={[...retryProps]}
          style={{ ...style }}
        />
      )}
    </Spin>
  );
}

EmptyErrorWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  data: PropTypes.any,
  emptyCondition: PropTypes.any,
  loaderTitle: PropTypes.string,
  retryAction: PropTypes.func.isRequired,
  retryProps: PropTypes.array,
  style: PropTypes.object,
  emptyMessage: PropTypes.string,
};

EmptyErrorWrapper.defaultProps = {
  data: null,
  emptyCondition: false,
  loaderTitle: 'isLoading',
  retryProps: [],
  style: {},
  emptyMessage: 'Não há dados registrados.',
};

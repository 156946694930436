import { Select, Spin } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import handleSearch from '~/helpers/handleSearch';
import {
  getPeopleTower,
  getDismissedSoluters,
} from '~/store/fetchActions/fetchTowers';

const { Option } = Select;

export default function SelectSearchPeople({
  valueSoluter,
  defaultValue,
  value,
  onChange,
  customStyle,
  testId,
  disabled,
  searchDismissed,
  shouldReturnLabelInValue,
  shouldReturnSoluterInfo,
  placeholder,
  allowClear,
}) {
  const { people } = useSelector(state => state.towers);
  const { isLoadingPeople } = useSelector(state => state.loading);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const dispatch = useDispatch();
  const getValueSoluter = value === '' ? valueSoluter : value;
  // eslint-disable-next-line
  
  return (
    <Select
      showSearch
      data-testid={testId}
      placeholder={placeholder}
      defaultValue={defaultValue}
      filterOption={false}
      style={customStyle}
      labelInValue={shouldReturnLabelInValue}
      value={getValueSoluter}
      allowClear
      onSearch={_value =>
        handleSearch(
          _value,
          searchDismissed ? getDismissedSoluters : getPeopleTower,
          searchTimeout,
          setSearchTimeout,
          dispatch,
        )
      }
      onChange={_value => {
        if (_value && shouldReturnSoluterInfo) {
          const { value: email } = _value;
          onChange({
            ..._value,
            ...people.find(person => person.email === email),
          });
        } else onChange(_value);
      }}
      notFoundContent={isLoadingPeople ? <Spin size="small" /> : null}
      disabled={disabled}
      loading={isLoadingPeople}
    >
      {people?.map((person, idx) => (
        <Option
          key={person.email}
          value={person.email}
          data-testid={`search-people-option-${idx}`}
        >
          {person.name}
          {person.employeeNumber && <> - {person.employeeNumber}</>}
        </Option>
      ))}
    </Select>
  );
}

SelectSearchPeople.propTypes = {
  valueSoluter: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customStyle: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  searchDismissed: PropTypes.bool,
  shouldReturnLabelInValue: PropTypes.bool,
  shouldReturnSoluterInfo: PropTypes.bool,
  placeholder: PropTypes.string,
  allowClear: PropTypes.bool,
};

SelectSearchPeople.defaultProps = {
  valueSoluter: '',
  defaultValue: null,
  value: '',
  customStyle: { minWidth: 200 },
  testId: 'select-search-people',
  disabled: false,
  searchDismissed: false,
  shouldReturnLabelInValue: false,
  shouldReturnSoluterInfo: false,
  placeholder: 'Buscar por Soluter',
  allowClear: false,
};

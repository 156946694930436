/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Space, List, Table, Modal } from 'antd';
import { DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as fetchBadges from '~/store/fetchActions/fetchBadges';
import { EmptyErrorWrapper, Button, IconButton } from '~/components';

import './styles.scss';
import { getRole } from '~/config/environment';

import('antd/lib/table');

export default function UsersTable({
  badgeId,
  badge,
  currentYear,
  orderBy,
  isAsc,
}) {
  const dispatch = useDispatch();
  const { usersByBadge } = useSelector(state => state.badges);
  const { isLoadingTowers } = useSelector(state => state.loading);
  const [modal, modalContextHolder] = Modal.useModal();
  useEffect(() => {
    dispatch(fetchBadges.listUsersByBadge(badgeId));
  }, [dispatch, badgeId, orderBy, isAsc]);

  const handleUpdateUser = userEmail => {
    dispatch(fetchBadges.UpdateUserInBadge(badgeId, userEmail));
  };

  const handleClickDeleteCompetence = useCallback(
    email => {
      modal.confirm({
        title: 'Tem certeza de que deseja deletar?',
        content: `Isso removerá essa badge do Soluter`,
        okText: 'Sim',
        cancelText: 'Não',
        onOk: () => dispatch(fetchBadges.deleteUserInBadge(badgeId, email)),
        okButtonProps: {
          danger: true,
        },
      });
    },
    [modal, badgeId, dispatch],
  );

  const columns = [
    {
      title: 'Nome',
      key: 'name',
      render: value =>
        getRole() === 'admin' && value?.personKey ? (
          <Link to={`/soluters/${value.personKey}`}>{value?.name}</Link>
        ) : (
          <p>{value?.name}</p>
        ),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Obtida em',
      key: 'associatedAt',
      render: value => (
        <span>
          {moment(
            value?.badges?.find(item => item?.badgeId === badgeId)
              ?.associatedAt,
          ).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Expira em',
      key: 'updatedAt',
      render: value => (
        <span>
          {moment(
            value?.badges?.find(item => item?.badgeId === badgeId)?.expiredAt,
          ).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'expirationDate',
      render: (_, badges) => (
        <div className="badge-table-actions">
          {badges?.badges?.find(item => item?.badgeId === badgeId)?.expired && (
            <IconButton
              onClick={() => handleUpdateUser(badges.email)}
              icon={<RedoOutlined />}
              title="Renovar Usuário"
              testId={`download-${badges.id}`}
              style={{ color: '#1f6f45' }}
            />
          )}
          <IconButton
            onClick={() => handleClickDeleteCompetence(badges.email)}
            icon={<DeleteOutlined />}
            title="Desvincular Soluter da Badge"
            style={{ color: '#f21f15' }}
            testId={`delete-${badges.id}`}
          />
        </div>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {modalContextHolder}
      <EmptyErrorWrapper
        data={usersByBadge?.users}
        emptyCondition={usersByBadge?.users?.length > 0}
        emptyMessage="Não existem usuários associados."
        loaderTitle="isLoadingProjects"
        retryAction={fetchBadges.listUsersByBadge}
      >
        <Table
          columns={columns}
          dataSource={usersByBadge?.users}
          loading={isLoadingTowers}
        />
      </EmptyErrorWrapper>
    </div>
  );
}

UsersTable.propTypes = {
  badge: PropTypes.object.isRequired,
  currentYear: PropTypes.string.isRequired,
  badgeId: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  isAsc: PropTypes.bool,
};

UsersTable.defaultProps = {
  orderBy: 'name',
  isAsc: true,
};

import { message } from 'antd';
import api from '~/config/httpRequest';

export const responseType = () => ({
  responseType: 'arraybuffer',
});

export const convertBytesToBase64 = async (response, resolve) => {
  if (response.data) {
    const buffer = Buffer.from(response.data, 'base64');
    const blob = new Blob([buffer], { type: 'image/jpg' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    resolve(
      // eslint-disable-next-line no-shadow
      new Promise((resolve, reject) => {
        reader.addEventListener('load', () => resolve(reader.result));
        reader.addEventListener('error', () =>
          reject(new Error('Ocorreu um erro ao ler o arquivo')),
        );
      }),
    );

    resolve('');
  }
};

export const getSinglePhoto = url => {
  return new Promise((resolve, reject) => {
    api
      .get(url, responseType())
      .then(response => convertBytesToBase64(response, resolve))
      .catch(reject);
  });
};

export const getUserPhotos = async (
  dispatch,
  payload,
  updateUserPhoto,
  keyTerm = 'email',
  photoTerm = 'thumb',
) => {
  await Promise.all(
    payload?.map(async loaded => {
      try {
        let thumb;
        if (loaded[keyTerm] !== undefined) {
          thumb = await getSinglePhoto(
            `/soluters/users/${loaded[keyTerm]}/photo`,
          );
        } else {
          thumb = 'no photo';
        }
        dispatch(
          updateUserPhoto({
            [keyTerm]: loaded[keyTerm] || 'undefined',
            [photoTerm]: thumb,
          }),
        );
      } catch (error) {
        dispatch(
          updateUserPhoto({
            [keyTerm]: loaded[keyTerm],
            [photoTerm]: 'no photo',
          }),
        );

        message.warn('As fotos de alguns usuários não puderam ser recuperadas');
      }
      return loaded;
    }),
  );
};

export default {
  responseType,
  convertBytesToBase64,
  getSinglePhoto,
  getUserPhotos,
};

import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  authLoading: false,
  authorized: false,
  profile: null,
  role: null,
};

export const setAuthLoading = createAction('AUTH_SET_IS_LOADING');
export const setAuthorized = createAction('AUTH_SET_AUTHORIZED');
export const setProfile = createAction('AUTH_SET_PROFILE');
export const setRole = createAction('AUTH_SET_ROLE');

export default createReducer(INITIAL_STATE, {
  [setProfile.type]: (state, action) => ({
    ...state,
    profile: action.payload,
  }),
  [setAuthLoading.type]: (state, action) => ({
    ...state,
    authLoading: action.payload,
  }),
  [setAuthorized.type]: (state, action) => ({
    ...state,
    authorized: action.payload,
  }),
  [setRole.type]: (state, action) => ({
    ...state,
    role: action.payload,
  }),
});

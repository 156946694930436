import { Drawer, List, Empty } from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function DrawerList({
  isDrawerVisible,
  setDrawerVisible,
  onPageChange,
  title,
  data,
  total,
  emptyText,
  listItem,
  dataTestId,
  isLoading,
}) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (isDrawerVisible) setPage(1);
  }, [isDrawerVisible]);

  return (
    <Drawer
      data-testid={dataTestId}
      title={title}
      width="42vw"
      placement="right"
      closable={false}
      onClose={() => {
        setDrawerVisible(false);
      }}
      visible={isDrawerVisible}
    >
      <List
        size="large"
        dataSource={data}
        loading={isLoading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyText}
            />
          ),
        }}
        pagination={{
          current: page,
          pageSize: 8,
          pageSizeOptions: [],
          showSizeChanger: false,
          total,
          hideOnSinglePage: true,
          onChange: _page => {
            setPage(_page);
            if (onPageChange) onPageChange(_page);
          },
        }}
        renderItem={item => <List.Item>{listItem(item)}</List.Item>}
      />
    </Drawer>
  );
}

DrawerList.propTypes = {
  isDrawerVisible: PropTypes.bool.isRequired,
  setDrawerVisible: PropTypes.func.isRequired,
  onPageChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  emptyText: PropTypes.string,
  listItem: PropTypes.any.isRequired,
  dataTestId: PropTypes.string,
  isLoading: PropTypes.bool,
};

DrawerList.defaultProps = {
  onPageChange: null,
  emptyText: 'Não existem Soluters nesta categoria.',
  dataTestId: 'drawer-list',
  isLoading: false,
};

/* eslint-disable no-console */
import { message } from 'antd';
import api from '~/config/httpRequest';
import * as skillAction from '~/store/ducks/skills';
import { validationMessages } from '~/constants';
import { startLoading, endLoading } from '../ducks/loading';

export const get = (page, isTechnology, endpoint = 'skills') => {
  if (isTechnology) endpoint = 'tecnologias';

  return dispatch => {
    api
      .get(`/jobs/${endpoint}/pagination?page=${page}&term`)
      .then(({ data: payload }) => {
        dispatch(
          skillAction.get({
            skills: payload ? payload.content : [],
            total: payload ? payload.total : 0,
            page,
          }),
        );
      })
      .catch(e => {
        console.log('fetchSkills - get', e);
        dispatch(
          skillAction.get({
            skills: null,
            total: 0,
            page: 1,
          }),
        );
        // message.error("Falha ao buscar os dados");
      });
  };
};

export const getByTitle = (
  title,
  page = 1,
  isTechnology,
  endpoint = 'skills',
) => {
  if (isTechnology) endpoint = 'tecnologias';

  return dispatch => {
    dispatch(startLoading('isLoadingSkills'));
    api
      .get(`/jobs/${endpoint}/pagination?page=${page}&term=${title}`)
      .then(({ data: payload }) => {
        dispatch(
          skillAction.get({
            skills: payload.content || [],
            total: payload.total || 0,
            page,
          }),
        );
        dispatch(endLoading('isLoadingSkills'));
      })
      .catch(err => {
        console.log(err);
        dispatch(
          skillAction.get({
            skills: null,
            total: 0,
            page: 1,
          }),
        );
        dispatch(endLoading('isLoadingSkills'));
      });
  };
};

export const add = (searching, searchTerm, title, page) => {
  return dispatch => {
    api
      .post(`/jobs/skills`, { title })
      .then(() => {
        dispatch(skillAction.add(title));
        message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
        if (searching) dispatch(getByTitle(searchTerm, page));
        else dispatch(get(page));
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const edit = (searching, searchTerm, title, id, page) => {
  return dispatch => {
    api
      .put(`/jobs/skills/${id}`, { id, title })
      .then(({ data: payload }) => {
        dispatch(skillAction.edit(payload));
        message.success(validationMessages.HTTP_EDIT_SUCCESS_MESSAGE);
        if (searching) dispatch(getByTitle(searchTerm, page));
        else dispatch(get(page));
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const remove = (searching, searchTerm, id, page, onlyOne) => {
  return dispatch => {
    api
      .delete(`/jobs/skills/${id}`)
      .then(() => {
        dispatch(skillAction.remove(id));
        message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
        if (onlyOne) {
          if (searching)
            dispatch(getByTitle(searchTerm, page > 1 ? page - 1 : 1));
          else dispatch(get(page > 1 ? page - 1 : 1));
        } else if (searching) {
          dispatch(getByTitle(searchTerm, page));
        } else {
          dispatch(get(page));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
};

/* eslint-disable no-console */
import { Form, Input, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MultipleCostCenterFilter } from '~/components';
import { addPractice, editPractice } from '~/store/fetchActions/fetchPractices';

const { TextArea } = Input;

export default function AddEditPracticeForm({
  isVisible,
  setVisible,
  practice,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onSelectCostCenter = value => {
    const lastSelectedValue = value[value.length - 1];
    if (
      practice &&
      practice?.costCenters.some(
        costCenter => costCenter.id === lastSelectedValue,
      )
    ) {
      message.info(
        `O centro de custo ${lastSelectedValue} já está associado à BU`,
      );
      value.pop();
    }
  };

  useEffect(() => {
    if (isVisible && practice) {
      form.setFieldsValue({
        initials: practice?.initials,
        name: practice?.name,
        description: practice?.description,
        costCenters: practice?.costCenters.map(
          // mapeando os centros de custo (objetos) para string, para que possam ser exibidos no campo do input
          costCenter => `${costCenter?.id} - ${costCenter?.title}`,
        ),
      });
    }
  }, [form, practice, isVisible]);

  const handleSubmit = values => {
    if (practice) {
      // retornando os valores da string composta (id + name) para o id original
      values.costCenters = values.costCenters
        .map(costCenter => {
          const isCostCenterString = typeof costCenter === 'string';

          const costCenterId = isCostCenterString
            ? costCenter.split(' - ')[0]
            : costCenter;

          return isCostCenterString && !costCenterId.includes('.')
            ? parseInt(costCenterId, 10)
            : costCenterId;
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      dispatch(editPractice(values, practice.id));
    } else dispatch(addPractice(values));
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title={`${practice ? 'Editar' : 'Adicionar'} BU`}
      okText={practice ? 'Salvar' : 'Adicionar'}
      cancelText="Cancelar"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            setVisible(false);
            handleSubmit(values);
            form.resetFields();
          })
          .catch(console.error);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark
        onFinish={handleSubmit}
        data-testid="modal-practice-form"
      >
        <Form.Item
          label="Sigla"
          name="initials"
          rules={[
            {
              required: true,
              max: 12,
              message:
                'A sigla é obrigatória e deve ter no máximo 12 caracteres',
              whitespace: true,
            },
          ]}
        >
          <Input type="text" data-testid="add-practice-initials-input" />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              max: 60,
              message:
                'O nome é obrigatório e deve ter no máximo 60 caracteres',
              whitespace: true,
            },
          ]}
        >
          <Input type="text" data-testid="add-practice-name-input" />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              max: 240,
              message: 'A descrição deve ter no máximo 240 caracteres',
              whitespace: true,
            },
          ]}
        >
          <TextArea data-testid="add-practice-description-input" />
        </Form.Item>
        <MultipleCostCenterFilter
          fieldLabel="Centros de Custo"
          fieldName="costCenters"
          style={{ width: '100%' }}
          disabled
          onChange={onSelectCostCenter}
        />
      </Form>
    </Modal>
  );
}

AddEditPracticeForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  practice: PropTypes.object,
};

AddEditPracticeForm.defaultProps = {
  practice: null,
};

import { Row, message, Form, Modal, Button } from 'antd';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DownloadButton,
  MoodFilter,
  SelectTowers,
  PracticeFilter,
  ActiveFilters,
  MultipleCostCenterFilter,
  SelectMultipleTower,
  SelectMultiplePractice,
} from '~/components';
import { getCSV } from '~/store/fetchActions/fetchMood';
import {
  setWeekInterval,
  setSelectedTower,
  clearCsvData,
  setSelectedCostCenters,
  setSelectedTowers,
  setSelectedPractice,
  setSelectedPractices,
  removeSelectedPractices,
  removeSelectedCostCenter,
  removeSelectedTower,
} from '~/store/ducks/mood';
import {
  selectMoodWeekInterval,
  selectMoodState,
} from '~/store/selectors/mood';
import { getRole } from '~/config/environment';

export default function MoodHeader() {
  const dispatch = useDispatch();
  const week = useSelector(selectMoodWeekInterval);
  const { csvData } = useSelector(selectMoodState);
  const {
    selectedTower,
    selectedPractice,
    selectedCostCenters,
    selectedTowers,
    isMonthlyAverage,
    selectedPractices,
    isYearly,
  } = useSelector(state => state.mood);
  const [formPracticeAndCostCenters] = Form.useForm();
  const [formTower] = Form.useForm();

  useEffect(() => {
    if (selectedTower !== '') onChangeSelectedTower();
  }, [onChangeSelectedTower, selectedTower]);

  useEffect(() => {
    if (selectedPractice !== '' || selectedCostCenters?.length > 0)
      formTower.resetFields();
  }, [formTower, selectedPractice, selectedCostCenters]);

  const handleCSVDownload = () => {
    Modal.info({
      title: 'Aviso',
      content: (
        <div>
          <p>
            O moods tem como dia padrão de resposta a sexta-feira porém também
            permite que os usuários votem aos sábados. Todos os votos realizados
            aos śabados são exibidos na sexta-feira que o precede.
          </p>
        </div>
      ),
      okText: 'Ok',
      onOk() {
        dispatch(getCSV(week.start, week.end, selectedTower));
      },
    });
  };

  const onChangePractice = practiceId => {
    dispatch(setSelectedPractice(practiceId));
    dispatch(setSelectedCostCenters([]));
    dispatch(setSelectedTower({ value: '', label: '' }));
  };

  const onChangeCostCenterSelect = ([value]) => {
    if (!selectedCostCenters.includes(value)) {
      dispatch(setSelectedPractices([]));
      dispatch(setSelectedTowers([]));
      dispatch(setSelectedCostCenters([...selectedCostCenters, value]));
    }
    dispatch(setSelectedTower({ value: '', label: '' }));
    formPracticeAndCostCenters.setFieldsValue({
      selectedCostCenters: [],
    });
  };

  const onChangeSelectedTower = useCallback(() => {
    dispatch(setSelectedPractice(''));
    dispatch(setSelectedCostCenters([]));
    formPracticeAndCostCenters.setFieldsValue({
      selectedPractice: null,
      selectedCostCenters: [],
    });
  }, [dispatch, formPracticeAndCostCenters]);

  const onChangeSelectedTowers = ([value]) => {
    if (!selectedTowers.includes(value)) {
      dispatch(setSelectedCostCenters([]));
      dispatch(setSelectedPractices([]));
      dispatch(setSelectedTowers([...selectedTowers, value]));
    }
    formPracticeAndCostCenters.setFieldsValue({
      selectedMultipleTower: [],
    });
  };

  const onChangeSelectedPractices = ([value]) => {
    if (!selectedPractices.includes(value)) {
      dispatch(setSelectedTowers([]));
      dispatch(setSelectedCostCenters([]));
      dispatch(setSelectedPractices([...selectedPractices, value]));
    }
    formPracticeAndCostCenters.setFieldsValue({
      selectedMultiplePractice: [],
    });
  };

  const handleCostCentersTagClose = filter => {
    dispatch(removeSelectedCostCenter(filter));
  };

  const handleTowersTagClose = filter => {
    dispatch(removeSelectedTower(filter));
  };

  const handlePracticesTagClose = filter => {
    dispatch(removeSelectedPractices(filter));
  };

  return (
    <>
      <Row justify="space-between">
        <div className="d-flex">
          <Form form={formPracticeAndCostCenters}>
            <div
              className="cost-center-practice-container"
              style={{
                display: 'flex',
                marginRight: 20,
              }}
            >
              {isMonthlyAverage ? (
                <SelectMultiplePractice
                  selector={state => state.mood}
                  dispatchDuck={setSelectedPractices}
                  onChange={onChangeSelectedPractices}
                  fieldName="selectedMultiplePractice"
                  style={{ width: '15vw', marginRight: 20 }}
                />
              ) : (
                <PracticeFilter
                  onChangePractice={onChangePractice}
                  fieldName="selectedPractice"
                  defaultValue={selectedPractice}
                  style={{ width: '15vw', marginRight: 20 }}
                />
              )}
              <MultipleCostCenterFilter
                selectedPractice={isMonthlyAverage ? '' : selectedPractice}
                fieldName="selectedCostCenters"
                onChange={onChangeCostCenterSelect}
                style={{ width: '15vw', marginRight: '20px' }}
              />
              {isMonthlyAverage ? (
                <SelectMultipleTower
                  selector={state => state.mood}
                  dispatchDuck={setSelectedTowers}
                  onChange={onChangeSelectedTowers}
                  fieldName="selectedMultipleTower"
                />
              ) : (
                <SelectTowers
                  selector={state => state.mood}
                  dispatchDuck={setSelectedTower}
                  allowClear
                  emptyOptionText="Selecione uma Torre"
                />
              )}
            </div>
          </Form>
          <div style={{ marginLeft: 20, marginRight: 10 }}>
            <MoodFilter
              weekSelector={selectMoodWeekInterval}
              setWeekInterval={setWeekInterval}
              typeFilter={isYearly ? 'year' : 'month'}
            />
          </div>
        </div>
        <div>
          <DownloadButton
            label="Exportar CSV"
            data={csvData || ''}
            requestData={handleCSVDownload}
            clearData={clearCsvData}
            fileName={`relatorio_mood_${week.start}_${week.end}.csv`}
            exportCsvLayout
            disabled={isMonthlyAverage || getRole() !== 'admin'}
          />
        </div>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        {isMonthlyAverage && (
          <ActiveFilters
            filters={selectedPractices.map(practice => {
              return practice?.label;
            })}
            label="BUs"
            isTagClosable
            handleTagClose={handlePracticesTagClose}
            handleClear={() => dispatch(setSelectedPractices([]))}
          />
        )}
        <ActiveFilters
          filters={[...selectedCostCenters]}
          label="Centros de Custos"
          isTagClosable
          handleTagClose={handleCostCentersTagClose}
          handleClear={() => dispatch(setSelectedCostCenters([]))}
        />
        {isMonthlyAverage && (
          <ActiveFilters
            filters={selectedTowers.map(tower => {
              return tower?.label;
            })}
            label="Torres"
            isTagClosable
            handleTagClose={handleTowersTagClose}
            handleClear={() => dispatch(setSelectedTowers([]))}
          />
        )}
      </Row>
      <Row />
    </>
  );
}

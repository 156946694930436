import { Typography, List, Checkbox, Tooltip, Row, Input } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BankOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { getRole } from '~/config/environment';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import { EmptyErrorWrapper, SelectSearchPeople, TagRender } from '~/components';
import { AddTowerForm } from '~/container';
import { clearTower } from '~/store/ducks/towers';
import DisableTowerSwitch from '~/container/Towers/DisableTowerSwitch';
import { APP_NAME } from '~/constants/app';
import GenericCard from '~/components/CustomCardTower/CustomCardTower';
import SelectSearchBusinessUnits from '~/components/SelectSearchBusinessUnits/SelectSearchBusinessUnits';

const { Title } = Typography;
const { Search } = Input;
const _pageSize = 8;

export default function Towers() {
  const dispatch = useDispatch();
  const { towersPaged, page } = useSelector(state => state.towers);
  const { isLoadingTowers, isLoadingTowerPhoto } = useSelector(
    state => state.loading,
  );
  const [allGroups, setallGroups] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [CE, setCE] = useState('');
  const [BUs, setBUs] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    dispatch(
      fetchTowers.listTowersPaged(
        true,
        allGroups,
        groupName,
        CE,
        BUs,
        page,
        _pageSize,
      ),
    );
    dispatch(clearTower());
  }, [dispatch, groupName, allGroups, CE, BUs, page]);

  const handleDeleteTower = towerId => {
    dispatch(fetchTowers.deleteTower(towerId, allGroups));
  };

  const handleSearchByTitle = value => setGroupName(value);
  const handleSearchByCe = value => setCE(value);
  const handleSearchByBUS = value => {
    setBUs(value);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Title>Mapa de Torres</Title>
        <Row justify="space-between" align="top">
          <span>
            <Checkbox
              style={{ marginLeft: 10 }}
              checked={!allGroups}
              onChange={() => {
                setallGroups(enabled => !enabled);
              }}
              data-testid="checkbox-only-enabled-towers"
            >
              Somente Torres Habilitadas
            </Checkbox>
            <Tooltip
              placement="right"
              title={`As torres habilitadas são exibidas para todos os Soluters no aplicativo do Passaporte. Torres desabilitadas são exibidas apenas no ${APP_NAME}.`}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
          <div style={{ display: 'flex' }}>
            <span
              style={{
                marginRight: 10,
                width: 275,
                display: 'block',
              }}
            >
              <SelectSearchBusinessUnits
                defaultValue={[]}
                fieldName="BUs"
                fieldLabel="Busca por Business Units"
                required={false}
                selectMode="multiple"
                tagRenderFunc={props => TagRender(props)}
                placeholder="Buscar por Business Units"
                onChange={handleSearchByBUS}
              />
            </span>
            <span style={{ marginRight: 0, width: 220 }}>
              <SelectSearchPeople
                value={CE || null}
                onChange={handleSearchByCe}
                testId="serch-by-ce-test"
                placeholder="Buscar por CE"
                allowClear
                customStyle={{ width: 210, marginBottom: 0 }}
              />
            </span>
            <span>
              <Search
                ref={searchInput}
                placeholder="Nome da Torre"
                style={{
                  width: 220,
                  marginRight: 10,
                }}
                onSearch={handleSearchByTitle}
                enterButton
                allowClear
                data-testid="search-tower-name"
              />
            </span>
            <AddTowerForm
              disabled={getRole() !== 'admin'}
              allGroups={allGroups}
            />
          </div>
        </Row>
      </div>
      <EmptyErrorWrapper
        data={towersPaged?.content}
        emptyCondition={towersPaged?.content?.length > 0}
        emptyMessage="Não há torres registradas."
        retryAction={fetchTowers.listTowersPaged}
        retryProps={[true]}
        loaderTitle="isLoadingTowers"
      >
        <List
          grid={{
            column: 4,
            sm: 1,
            md: 2,
          }}
          pagination={{
            current: page,
            position: 'bottom',
            pageSize: _pageSize,
            pageSizeOptions: [],
            total: towersPaged?.total,
            hideOnSinglePage: true,
            onChange: _page =>
              dispatch(
                fetchTowers.listTowersPaged(
                  true,
                  allGroups,
                  groupName,
                  CE,
                  BUs,
                  _page,
                  _pageSize,
                ),
              ),
          }}
          dataSource={towersPaged?.content}
          loading={isLoadingTowers || isLoadingTowerPhoto}
          renderItem={tower => (
            <List.Item>
              <GenericCard
                prefix="towers"
                item={tower}
                backgroundColor={tower?.color}
                handleDelete={() => {
                  handleDeleteTower(tower._id);
                }}
                imgBackup={<BankOutlined />}
                disabledDelete={getRole() !== 'admin'}
                disabledEdit={getRole() !== 'admin'}
                circleImage
                additionalActions={[
                  <DisableTowerSwitch tower={tower} allGroups={allGroups} />,
                ]}
              />
            </List.Item>
          )}
        />
      </EmptyErrorWrapper>
    </>
  );
}

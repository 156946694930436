import { useSelector } from 'react-redux';
import { Typography, List, Empty } from 'antd';
import PropTypes from 'prop-types';
import { Legend, MoodFilter, SoluterMoodItem } from '~/components';
import MoodProgress from '~/container/Mood/MoodDistribution/MoodProgress';
import * as fetchMood from '~/store/fetchActions/fetchMood';
import { setWeekInterval } from '~/store/ducks/people';
import { selectLoadingState } from '~/store/selectors';
import useMoodData from '~/hooks/useMoodData';
import { moods as moodConstants } from '~/constants';

import './styles.scss';

const { Title } = Typography;

export default function SoluterMood({ email }) {
  const emptyList = [0, 0, 0, 0, 0];
  const { averages, participationCount } = useSelector(
    state => state.people.soluter,
  );

  const weekIntervalSelector = state => state.people.week;
  const returnedDataSelector = state => state.people.soluter.moods;
  const { isLoading } = useSelector(selectLoadingState);

  const moods = useMoodData(
    null,
    fetchMood.getSoluterMoods,
    weekIntervalSelector,
    returnedDataSelector,
    email,
  );

  return (
    <div className="soluter-mood">
      <div style={{ width: '75%' }}>
        <div>
          <Title level={5}>Avaliações Recentes</Title>
          <div style={{ width: 120 }}>
            <MoodFilter
              weekSelector={state => state.people.week}
              setWeekInterval={setWeekInterval}
            />
          </div>
        </div>
        <div className="soluter-mood__legend">
          <p style={{ marginBottom: 0 }}>Legenda</p>
          <Legend
            color={moodConstants.APPROVED_COLOR_DARK}
            text={moodConstants.APPROVED_TAGS_QUESTION}
          />
          <Legend
            color={moodConstants.NOT_APPROVED_COLOR_DARK}
            text={moodConstants.NOT_APPROVED_TAGS_QUESTION}
          />
        </div>
        <List
          loading={isLoading}
          style={{ width: '88%' }}
          dataSource={moods}
          locale={{
            emptyText: (
              <Empty
                description="Não há avaliações deste usuário"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
          grid={{ gutter: 14, xxl: 3, xl: 2, xs: 1 }}
          renderItem={mood => <SoluterMoodItem mood={mood} />}
        />
      </div>
      <div className="soluter-mood__participation">
        <Title style={{ textAlign: 'center' }} level={5}>
          Contagem de Participações do Soluter no Mood
        </Title>
        <div className="soluter-mood__participation-wrap">
          <Title level={2} className="soluter-mood__participation-count">
            {participationCount || 0}
          </Title>
        </div>
        <MoodProgress
          progressList={averages || emptyList}
          title="Distribuição de Reações do Soluter"
          margin={40}
          loaderTitle="isLoadingSoluterMood"
          soluterMood
        />
      </div>
    </div>
  );
}

SoluterMood.propTypes = {
  email: PropTypes.string.isRequired,
};

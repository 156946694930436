/* eslint-disable no-nested-ternary */
import { useDispatch, useSelector } from 'react-redux';
import { List, Empty } from 'antd';
import PropTypes from 'prop-types';
import { EmptyErrorWrapper, ActiveFilters } from '~/components';
import { selectPeopleState } from '~/store/selectors';
import SoluterCard from './SoluterCard';
import {
  removeAdvancedSearchFilter,
  clearAdvancedSearchFilters,
} from '~/store/ducks/people';
import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import './styles.scss';
import { people } from '~/constants';

export default function SolutersList({ isAdvancedSearchActive }) {
  const _pageSize = 9;

  const dispatch = useDispatch();
  const { isLoadingSolutersCostCenters } = useSelector(state => state.loading);
  const {
    people: soluters,
    total,
    search,
    searchTitle,
    page,
    advancedSearch,
  } = useSelector(selectPeopleState);

  const handleAdvancedFilterTagClose = filter => {
    dispatch(removeAdvancedSearchFilter(filter));
  };

  const getAdditionalFilters = () => {
    const filters = [];
    if (advancedSearch?.leader) {
      filters.push(people.categories.LEADER);
    }
    if (advancedSearch?.highPotential) {
      filters.push(people.categories.HIGH_POTENTIAL);
    }
    if (advancedSearch.onlyDismissed) {
      filters.push(people.categories.DISMISSED);
    }
    if (advancedSearch.haveCompetence) {
      filters.push(people.mapCompetenceFilterModes.ONLY_WITH_COMPETENCES);
    }
    if (advancedSearch.competence) filters.push(advancedSearch.competence);
    if (advancedSearch.primaryRole) filters.push(advancedSearch.primaryRole);
    if (advancedSearch?.notAllocated)
      filters.push(people.categories.NOT_ALLOCATED);

    return filters;
  };

  return (
    <EmptyErrorWrapper
      data={soluters}
      emptyCondition={soluters !== null}
      retryAction={fetchPeople.get}
      loaderTitle="isLoadingSoluters"
    >
      <List
        className="soluters-list"
        grid={{
          column: 3,
          sm: 1,
          md: 2,
          gutter: 18,
        }}
        locale={{
          emptyText: (
            <>
              <Empty
                description="Não há Soluters que correspondem à busca."
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </>
          ),
        }}
        pagination={{
          current: page,
          showSizeChanger: false,
          position: 'bottom',
          pageSize: _pageSize,
          hideOnSinglePage: true,
          total,
          onChange: _page =>
            advancedSearch.onlyDismissed
              ? dispatch(
                  fetchPeople.getDismissedSoluters(
                    advancedSearch.leader,
                    advancedSearch.highPotential,
                    advancedSearch.primaryTech,
                    advancedSearch.primaryRole,
                    advancedSearch.competence,
                    advancedSearch.haveCompetence,
                    searchTitle,
                    _page,
                  ),
                )
              : search || isAdvancedSearchActive()
              ? dispatch(
                  fetchPeople.getByProperty(
                    searchTitle,
                    advancedSearch.skills,
                    advancedSearch.skillsOperator,
                    advancedSearch.seniorities,
                    advancedSearch.leader,
                    advancedSearch.highPotential,
                    advancedSearch.competence,
                    advancedSearch.haveCompetence,
                    advancedSearch.primaryTech,
                    advancedSearch.primaryRole,
                    advancedSearch.notAllocated,
                    _page,
                    _pageSize,
                  ),
                )
              : dispatch(fetchPeople.get(_page, _pageSize)),
        }}
        dataSource={soluters}
        header={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ActiveFilters
                filters={[
                  ...advancedSearch?.skills,
                  ...advancedSearch?.seniorities,
                  ...advancedSearch?.primaryTech,
                  ...getAdditionalFilters(),
                ]}
                label="Busca Avançada"
                isTagClosable
                handleTagClose={handleAdvancedFilterTagClose}
                handleClear={() => dispatch(clearAdvancedSearchFilters())}
              />
            </div>
          </>
        }
        renderItem={(soluter, idx) => {
          const thumbLoading = !soluter.thumb;
          return (
            <List.Item data-testid={`soluter-card-${idx}`}>
              <SoluterCard
                soluter={soluter}
                thumbLoading={thumbLoading}
                isLoadingCostCenter={isLoadingSolutersCostCenters}
              />
            </List.Item>
          );
        }}
      />
    </EmptyErrorWrapper>
  );
}

SolutersList.propTypes = {
  isAdvancedSearchActive: PropTypes.func.isRequired,
};

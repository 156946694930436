/* eslint-disable no-console */
import {
  Form,
  Input,
  Row,
  Col,
  Radio,
  Rate,
  DatePicker,
  Drawer,
  Button,
  Divider,
  Space,
} from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { reasonsToLeave } from '~/constants/people';
import {
  addSoluterExitInterview,
  updateSoluterExitInterview,
} from '~/store/fetchActions/fetchPeople';
import { clearSoluter, getSoluter } from '~/store/ducks/people';
import { SelectSearchPeople, SelectTowers } from '~/components';
import { setSelectedTower } from '~/store/ducks/towers';
import SoluterAllocationInfo from './SoluterAllocationInfo';

const { TextArea } = Input;

export default function ExitInterviewForm({
  interview,
  isModalVisible,
  setModalVisible,
  customReload,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isAddingExitInterview } = useSelector(state => state.loading);
  const { soluter } = useSelector(state => state.people);
  const { towers, selectedTowerName } = useSelector(state => state.towers);
  const [searchDismissedSoluters, setSearchDismissedSoluters] = useState(false);

  useEffect(() => {
    if (interview && isModalVisible) {
      // confere se o motivo de saída está entre uma das opções pré-determinadas
      const isOtherReason = !Object.values(reasonsToLeave).includes(
        interview?.reason,
      );

      form.setFieldsValue({
        person: { value: interview?.employeeEmail, label: interview?.name },
        name: interview?.name,
        exitDate: interview?.exitDate
          ? moment(interview?.exitDate, 'DD-MM-YYYY')
          : null,
        interviewDate: interview?.interviewDate
          ? moment(interview?.interviewDate, 'DD-MM-YYYY')
          : null,
        project: interview?.project,
        type: interview?.type,
        reason: isOtherReason ? reasonsToLeave.OTHER : interview?.reason,
        otherReason: isOtherReason ? interview?.reason : '',
        leader: interview?.leader,
        leaderRelationshipRating: interview?.leaderRelationshipRating,
        leaderComment: interview?.leaderComment,
        journeyRating: interview?.journeyRating,
        journeyComment: interview?.journeyComment,
        nextCompany: interview?.nextCompany,
        salaryBenefits: interview?.salaryBenefits,
        salary: interview?.salary,
        benefits: interview?.benefits,
        costCenter: interview?.team,
        practice: interview?.practice,
        whatIsGood: interview?.whatIsGood,
        whatShouldChange: interview?.whatShouldChange,
        wouldYouReturn: interview?.wouldYouReturn,
        personalEmail: interview?.personalEmail,
        cellphone: interview?.cellphone,
        otherComments: interview?.otherComments,
        BP: interview?.BP,
        BPComments: interview?.BPComments,
        leaderRecommendsReturn: interview?.leaderRecommendsReturn,
        leaderCommentAboutUser: interview?.leaderCommentAboutUser,
        counterOffer: interview?.counterOffer,
        knowledge: interview?.knowledge,
        counterOfferComment: interview?.counterOfferComment,
      });
    }
  }, [form, interview, isModalVisible]);

  useEffect(() => {
    form.setFieldsValue({
      name: soluter?.name,
    });
  }, [form, soluter]);

  useEffect(() => {
    form.setFieldsValue({
      group: selectedTowerName,
    });
  }, [form, selectedTowerName]);

  useEffect(() => {
    if (isModalVisible && interview?.id && interview?.group) {
      const selectedTower = towers.find(
        ({ name }) => name === interview?.group,
      );
      if (selectedTower) {
        const { _id, name } = selectedTower;
        dispatch(setSelectedTower({ value: _id, label: name }));
      }
    }
  }, [interview, towers, form, isModalVisible, dispatch]);

  useEffect(() => {
    if (!isModalVisible) {
      dispatch(setSelectedTower({ value: '', label: '' }));
      form.setFieldsValue({
        group: '',
      });
    }
  }, [dispatch, isModalVisible, form]);

  const handlePersonChange = value => {
    dispatch(clearSoluter());
    if (value) {
      const { name, email, practice, team } = value;
      if (name && email) {
        dispatch(
          getSoluter({
            name,
            email,
            practice,
            team,
          }),
        );
      }
    }
  };

  const handleSubmit = async values => {
    const formattedDate = values?.exitDate?.format('DD-MM-YYYY') || null;
    const formattedInterviewDate =
      values?.interviewDate?.format('DD-MM-YYYY') || null;
    const reason =
      values.reason === reasonsToLeave.OTHER
        ? values.otherReason
        : values.reason;

    try {
      if (!interview) {
        await dispatch(
          addSoluterExitInterview(interview?.employeeEmail || soluter?.email, {
            ...values,
            exitDate: formattedDate,
            interviewDate: formattedInterviewDate,
            reason,
          }),
        );
      } else {
        await dispatch(
          updateSoluterExitInterview(interview?.employeeEmail, interview?.id, {
            ...values,
            exitDate: formattedDate,
            interviewDate: formattedInterviewDate,
            reason,
          }),
        );
      }
      setModalVisible(false);
      form.resetFields();
    } catch (err) {
      console.log(err);
    } finally {
      customReload();
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(formValues => {
        handleSubmit(formValues);
      })
      .catch(console.error);
  };

  const validateMessages = {
    types: {
      email: 'Este não é um email válido',
    },
    string: {
      max: 'A resposta não deve ultrapassar ${max} caracteres.',
    },
    required: 'Este campo é obrigatório.',
  };

  const textAreaRules = {
    max: 500,
  };

  return (
    <Drawer
      visible={isModalVisible}
      onClose={() => setModalVisible(false)}
      title={
        interview
          ? `Editar entrevista de desligamento (${interview?.interviewDate})`
          : 'Adicionar entrevista de desligamento'
      }
      width="75vw"
      footer={
        <Row justify="end">
          <Button onClick={() => setModalVisible(false)} className="mr-sm">
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleOk}
            loading={isAddingExitInterview}
          >
            Salvar
          </Button>
        </Row>
      }
      footerStyle={{
        position: 'relative',
      }}
    >
      <Form layout="vertical" form={form} validateMessages={validateMessages}>
        <Row gutter={16}>
          <Col>
            <Form.Item
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
              name="person"
              label="Soluter Entrevistado: "
              initialValue={
                interview
                  ? { value: interview?.employeeEmail, label: interview?.name }
                  : null
              }
              className="mb-0"
            >
              <SelectSearchPeople
                disabled={interview}
                customStyle={{ minWidth: 300, marginBottom: 0 }}
                onChange={handlePersonChange}
                testId="select-soluter-interview"
                searchDismissed={searchDismissedSoluters}
                shouldReturnLabelInValue
                shouldReturnSoluterInfo
              />
            </Form.Item>
            {!interview && (
              <>
                <p className="mb-xs mt-md">Buscar na base de dados de:</p>
                <Radio.Group
                  onChange={({ target }) => {
                    setSearchDismissedSoluters(target.value);
                  }}
                  defaultValue={searchDismissedSoluters}
                >
                  <Space direction="horizontal">
                    <Radio value={false} checked>
                      Soluters Ativos
                    </Radio>
                    <Radio data-testid="only-fired-checkbox" value>
                      Soluters Desligados
                    </Radio>
                  </Space>
                </Radio.Group>
              </>
            )}
          </Col>
          <Col>
            <Form.Item
              name="exitDate"
              label="Data do Desligamento"
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={
                interview ? moment(interview?.exitDate, 'DD-MM-YYYY') : moment()
              }
            >
              <DatePicker
                locale={locale}
                format="DD/MM/YYYY"
                inputReadOnly
                defaultValue={
                  interview
                    ? moment(interview?.exitDate, 'DD-MM-YYYY')
                    : moment()
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="interviewDate"
              label="Data da Entrevista"
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={
                interview
                  ? moment(interview?.interviewDate, 'DD-MM-YYYY')
                  : moment()
              }
            >
              <DatePicker
                locale={locale}
                format="DD/MM/YYYY"
                inputReadOnly
                defaultValue={
                  interview
                    ? moment(interview?.interviewDate, 'DD-MM-YYYY')
                    : moment()
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              requiredMark
              rules={[
                {
                  required: true,
                  max: 100,
                },
              ]}
              name="BP"
              label="Business Partner - Nome do entrevistador: "
              initialValue={
                interview
                  ? { value: interview?.BP, label: interview?.BP }
                  : null
              }
              className="mb-0"
            >
              <Input name="BP" value={interview?.BP} />
            </Form.Item>
            {/* <Form.Item
              label="Cargo"
              name="jobTitle"
              initialValue={interview?.jobTitle || 'Não Informado'}
              rules={[
                {
                  required: false,
                  max: 100,
                },
              ]}
              className="mb-0"
            >
              <Input
                name="jobTitle"
                value={interview?.jobTitle || 'Não Informado'}
              />
            </Form.Item> */}
          </Col>
          <Col>
            <Form.Item
              requiredMark
              name="group"
              label="Torre:"
              initialValue=""
              className="mb-0"
            >
              <SelectTowers
                selector={state => state.towers}
                dispatchDuck={setSelectedTower}
                fieldName="group"
                emptyOptionText="Nenhuma torre selecionada"
              />
            </Form.Item>
          </Col>
          <Col>
            {/*   <Form.Item
              label="Centro de Custo"
              name="team"
              initialValue={interview?.team || 'Não Informado'}
              rules={[
                {
                  required: false,
                  max: 100,
                },
              ]}
              className="mb-0"
            >
              <Input name="team" value={interview?.team || 'Não Informado'} />
            </Form.Item>
            <Form.Item
              label="BU"
              name="practice"
              initialValue={interview?.practice || 'Não Informado'}
              rules={[
                {
                  required: false,
                  max: 100,
                },
              ]}
              className="mb-0"
            >
              <Input
                name="practice"
                value={interview?.practice || 'Não Informado'}
              />
            </Form.Item> */}
          </Col>
        </Row>
        <Divider />
        <Row justify="space-between" align="top" gutter={24}>
          <Col span={12}>
            <Form.Item
              label="1. Qual seu nome completo?"
              name="name"
              initialValue={interview?.name || soluter?.name}
              requiredMark
              rules={[
                {
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Input name="name" value={interview?.name || soluter?.name} />
            </Form.Item>
            <SoluterAllocationInfo soluter={soluter} />
            <Form.Item
              name="project"
              label="2. Qual o projeto que atuava?"
              requiredMark
              rules={[{ max: 100, required: true }]}
            >
              <Input />
            </Form.Item>
            <Row>
              <Form.Item
                requiredMark
                rules={[{ required: true }]}
                name="type"
                label="3. Tipo do Desligamento"
              >
                <Radio.Group>
                  <Radio value="VOLUNTARY" checked>
                    Voluntário (por solicitação do Soluter)
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Form.Item
              label="4. Qual o principal motivo do seu desligamento?"
              name="reason"
              className="mb-0"
              requiredMark
              rules={[{ required: true, max: 500 }]}
            >
              <Radio.Group
                style={{
                  maxWidth: 500,
                  minWidth: 150,
                  display: 'grid',
                  gridTemplateColumns: 'auto',
                }}
              >
                <Radio value={reasonsToLeave.BETTER_PAY}>
                  {reasonsToLeave.BETTER_PAY}
                </Radio>
                <Radio
                  value={reasonsToLeave.BETTER_BENEFITS}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeave.BETTER_BENEFITS}
                </Radio>
                <Radio
                  value={reasonsToLeave.NEW_CHALLENGES}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeave.NEW_CHALLENGES}
                </Radio>
                <Radio
                  value={reasonsToLeave.CAREER_CHANGE}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeave.CAREER_CHANGE}
                </Radio>
                <Radio
                  value={reasonsToLeave.LACK_OF_PURPOSE}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeave.LACK_OF_PURPOSE}
                </Radio>
                <Radio
                  value={reasonsToLeave.BETTER_OPORTUNITIES}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeave.BETTER_OPORTUNITIES}
                </Radio>
                <Radio
                  value={reasonsToLeave.LACK_OF_SUPPORT}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeave.LACK_OF_SUPPORT}
                </Radio>
                <Radio value={reasonsToLeave.OTHER} style={{ width: '100%' }}>
                  <Form.Item
                    name="otherReason"
                    className="mb-md"
                    rules={[{ max: 500 }]}
                  >
                    <Input
                      placeholder={reasonsToLeave.OTHER}
                      style={{ width: '25vw' }}
                    />
                  </Form.Item>
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Row align="middle" />
            <Form.Item
              name="leader"
              label="5. Quem era seu líder na Solutis?"
              requiredMark
              rules={[{ required: true, max: 100 }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="leaderRelationshipRating"
              label="6. Como era sua relação com seu líder?"
              requiredMark
              rules={[{ required: true }]}
            >
              <Rate />
            </Form.Item>
            <Form.Item
              name="leaderComment"
              label="7. Comente sobre seu líder (informação tratada como sigilo)"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="journeyRating"
              label="8. Como classificaria sua jornada na Solutis?"
              requiredMark
              rules={[{ required: true }]}
            >
              <Rate />
            </Form.Item>
            <Form.Item
              name="knowledge"
              label="9. Conhecimento"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="journeyComment"
              label="10. Como foi sua jornada? (Fale um pouco sobre feedback, avaliação de desempenho, capacitação e treinamentos)"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="nextCompany"
              label="11. Para qual empresa está indo?"
              requiredMark
              rules={[{ max: 100, required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="salary"
              label="12. Qual será o seu salário?"
              validateFirst
              requiredMark
              rules={[
                { ...textAreaRules, required: true },
                {
                  message: 'O salário informado deve ser maior ou igual a 0.',
                  validator: (rule, value, cb) =>
                    value >= 0 ? cb() : cb(true),
                },
              ]}
            >
              <Input type="number" min={0} />
            </Form.Item>
            <Form.Item
              name="benefits"
              label="13. Quais serão seus benefícios na nova oportunidade?"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="counterOffer"
              label="14. Recebeu contraproposta?"
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="counterOfferComment"
              label="15. Comentários da contraproposta"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="whatIsGood"
              label="16. O que acha que devemos manter na Solutis? (O que temos de bom)"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="whatShouldChange"
              label="17. O que acha que devemos mudar na Solutis? (Processos e gestão)"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="wouldYouReturn"
              label="18. Você retornaria para a Solutis?"
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="personalEmail"
              label="19. Deixe seu e-mail para futuros contatos"
              rules={[
                {
                  type: 'email',
                  max: 100,
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="cellphone"
              label="20. Deixe seu celular para futuros contatos"
              requiredMark
              rules={[
                {
                  max: 100,
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="otherComments"
              label="21. Espaço aberto. Fique à vontade para comentar algo, elogios, sugestões, críticas."
              rules={[textAreaRules]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="BPComments"
              label="22. Comentários do Business Partner"
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="leaderRecommendsReturn"
              label="23. O líder recomenda o Soluter para retorno?"
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="leaderCommentAboutUser"
              label="24. Alguma ressalva do líder?"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

ExitInterviewForm.propTypes = {
  interview: PropTypes.object,
  isModalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  customReload: PropTypes.func,
};

ExitInterviewForm.defaultProps = {
  interview: null,
  customReload: () => {},
};

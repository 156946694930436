/* eslint-disable no-console */
import qs from 'qs';
import {
  getAllocationIndexGraph,
  getSolutersBySeniorityData,
  setPassportUsage,
  getPassportUsageCsvData,
  getAllocationCsvData,
  getSoluterJourneyData,
  getSoluterJourneyList as getSoluterJourneyListStore,
  setSkillsSearch,
  setSkillSoluters,
  getSolutersBySeniorityList as getSolutersBySeniorityListStore,
  setSoluterJourneyTotalPeople,
  setAdmissions,
  setSolutersMap,
} from '~/store/ducks/indicators';
import { startLoading, endLoading } from '~/store/ducks/loading';
import api from '~/config/httpRequest';

export const getAllocationGraphData = (practicesId, groupsId) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingGraph'));
    api
      .get(`/rpm/indicators/index`, {
        params: {
          practiceId: practicesId,
          groupId: groupsId,
        },
      })
      .then(async ({ data: payload }) => {
        dispatch(getAllocationIndexGraph(payload));
        dispatch(endLoading('isLoadingGraph'));
      })
      .catch(err => {
        dispatch(getAllocationIndexGraph({ data: null, index: null }));
        dispatch(endLoading('isLoadingGraph'));
        console.error(err);
      });
  };
};

export const getSolutersBySeniority = (
  groupingMode,
  unallocated,
  practicesIds,
  groupsIds,
) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingIndicators'));
    api
      .get(`/rpm/indicators`, {
        params: {
          unallocated,
          groupField: groupingMode,
          practiceId: practicesIds,
          groupId: groupsIds,
        },
      })
      .then(({ data }) => {
        dispatch(getSolutersBySeniorityData(data));
        dispatch(endLoading('isLoadingIndicators'));
      })
      .catch(err => {
        dispatch(getSolutersBySeniorityData(null));
        dispatch(endLoading('isLoadingIndicators'));
        console.error(err);
      });
  };
};

export const getSolutersBySeniorityList = (
  groupingMode,
  selectedCategory,
  page = 1,
  pageSize = 8,
  groupsIds,
  practicesIds,
) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSolutersList'));
    api
      .get(`/rpm/indicators-employees`, {
        params: {
          groupField: groupingMode,
          attribute: selectedCategory,
          page,
          pageSize,
          groupId: groupsIds,
          practiceId: practicesIds,
        },
      })
      .then(({ data }) => {
        dispatch(getSolutersBySeniorityListStore(data));
        dispatch(endLoading('isLoadingSolutersList'));
      })
      .catch(err => {
        dispatch(getSolutersBySeniorityListStore(null));
        dispatch(endLoading('isLoadingSolutersList'));
        console.error(err);
      });
  };
};

export const getPassportUsage = (costCenters, practiceId) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingGraph'));
    api
      .get(`/soluters/users/interacted`, {
        params: {
          costCenterId: costCenters,
          practiceId,
        },
      })
      .then(async ({ data: payload }) => {
        dispatch(setPassportUsage(payload));
        dispatch(endLoading('isLoadingGraph'));
      })
      .catch(err => {
        console.error(err);
        dispatch(setPassportUsage(null));
        dispatch(endLoading('isLoadingGraph'));
      });
  };
};

export const getSoluterJourneyGraph = groupId => {
  return async (dispatch, getState) => {
    dispatch(startLoading('isLoadingGraph'));
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.indicators;
    api
      .get(`/soluters/journey/track/indicator`, {
        params: {
          groupId,
          practiceId: selectedPractice,
          costCenterId: selectedCostCenter.toString(),
        },
      })
      .then(async ({ data: payload }) => {
        const amountOfSolutersWithNorth = payload
          ? payload.reduce((a, b) => a + b.value, 0)
          : null;

        dispatch(
          getSoluterJourneyData({
            data: payload || [],
            total: amountOfSolutersWithNorth || 0,
          }),
        );
        dispatch(endLoading('isLoadingGraph'));
      })
      .catch(err => {
        console.error(err);
        dispatch(
          getSoluterJourneyData({
            data: null,
            total: null,
          }),
        );
        dispatch(endLoading('isLoadingGraph'));
      });
  };
};

export const getSoluterJourneyTotalPeople = (
  selectedPractice,
  selectedCostCenter,
) => {
  return async (dispatch, getState) => {
    dispatch(startLoading('isLoadingJourneyPeople'));
    api
      .get(`/soluters`, {
        params: {
          term: '',
          page: 1,
          pageSize: 1,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
        },
      })
      .then(({ data: payload }) => {
        dispatch(setSoluterJourneyTotalPeople(payload?.total || 0));
        dispatch(endLoading('isLoadingJourneyPeople'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingJourneyPeople'));
      });
  };
};

export const getSoluterJourneyList = (
  groupId,
  status,
  page = 1,
  pageSize = 8,
) => {
  return async (dispatch, getState) => {
    dispatch(startLoading('isLoadingSoluterJourneyList'));
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.indicators;
    api
      .get(`/soluters/journey/track/indicator/users`, {
        params: {
          groupId,
          status,
          page,
          pageSize,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
        },
      })
      .then(async ({ data: payload }) => {
        dispatch(getSoluterJourneyListStore(payload));
        dispatch(endLoading('isLoadingSoluterJourneyList'));
      })
      .catch(err => {
        console.error(err);
        dispatch(getSoluterJourneyListStore({ content: null, total: 0 }));
        dispatch(endLoading('isLoadingSoluterJourneyList'));
      });
  };
};

export const getSolutersWithoutJourneyList = (
  groupId,
  page = 1,
  pageSize = 8,
) => {
  return async (dispatch, getState) => {
    dispatch(startLoading('isLoadingSoluterJourneyList'));
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.indicators;
    api
      .get(`/soluters/journey/track/indicator/users/no-track`, {
        params: {
          groupId,
          page,
          pageSize,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
        },
      })
      .then(async ({ data: payload }) => {
        dispatch(getSoluterJourneyListStore(payload));
        dispatch(endLoading('isLoadingSoluterJourneyList'));
      })
      .catch(err => {
        console.error(err);
        dispatch(getSoluterJourneyListStore({ content: null, total: 0 }));
        dispatch(endLoading('isLoadingSoluterJourneyList'));
      });
  };
};

export const getPassportUsageCsv = (costCenterId, practiceId) => {
  return async dispatch => {
    api
      .get(`/soluters/users/interacted/csv`, {
        params: {
          costCenterId,
          practiceId,
        },
      })
      .then(async ({ data }) => {
        dispatch(getPassportUsageCsvData(data));
      })
      .catch(err => {
        dispatch(getPassportUsageCsvData(null));
        console.error(err);
      });
  };
};

export const getAllocationCsv = (groupsId, practicesId) => {
  return async dispatch => {
    api
      .get(`/rpm/indicators/index/csv`, {
        params: {
          practiceId: practicesId,
          groupId: groupsId,
        },
      })
      .then(async ({ data }) => {
        dispatch(getAllocationCsvData(data));
      })
      .catch(err => {
        dispatch(getAllocationCsvData(null));
        console.error(err);
      });
  };
};

export const getSolutersBySkills = (top, tags, costCenters, practiceId) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSolutersBySkills'));
    api
      .get(`/rpm/indicators/skill`, {
        params: {
          top,
          skills: tags,
          costCenterId: costCenters.toString(),
          practiceId,
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
      .then(async ({ data: payload }) => {
        dispatch(setSkillsSearch(payload));
        dispatch(endLoading('isLoadingSolutersBySkills'));
      })
      .catch(err => {
        console.error(err);
        dispatch(endLoading('isLoadingSolutersBySkills'));
      });
  };
};

export const getSkillSoluters = (
  skill,
  page,
  selectedCostCenter,
  selectedPractice,
) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSkillSoluters'));
    api
      .get(`/rpm/indicators/skill-employees`, {
        params: {
          skill,
          page,
          pageSize: 10,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
        },
      })
      .then(async ({ data: payload }) => {
        dispatch(setSkillSoluters(payload));
        dispatch(endLoading('isLoadingSkillSoluters'));
      })
      .catch(err => {
        console.error(err);
        dispatch(endLoading('isLoadingSkillSoluters'));
      });
  };
};

export const getAdmissions = period => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.indicators;
    dispatch(startLoading(`isLoadingAdmissions${period}`));
    api
      .get(`/soluters/journey/users/last-hired-users?period=${period}`, {
        params: {
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
        },
      })
      .then(async ({ data }) => {
        const payload = data || { total: 0, users: [] };
        dispatch(setAdmissions({ period, payload }));
        dispatch(endLoading(`isLoadingAdmissions${period}`));
      })
      .catch(err => {
        console.error(err);
        dispatch(setAdmissions({ period, payload: { total: 0, users: [] } }));
        dispatch(endLoading(`isLoadingAdmissions${period}`));
      });
  };
};

export const getAdmittedUsersData = (period, payload) => {
  return async dispatch => {
    dispatch(startLoading(`isLoadingAdmittedUsers`));
    Promise.all(
      payload.users.map(async user => {
        try {
          const data = await getUserData(user);
          user = { name: data?.name, role: data?.jobTitle, email: data?.email };
        } catch (err) {
          console.log(err);
          user = { name: user, role: '', email: user };
        }
        return user;
      }),
    ).then(users => {
      dispatch(setAdmissions({ period, payload: { ...payload, users } }));
    });
    dispatch(endLoading(`isLoadingAdmittedUsers`));
  };
};

export const getUserData = email => {
  return new Promise((resolve, reject) => {
    api
      .get(`/soluters/users/${email}/profile`)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err));
  });
};

export const getSolutersMap = (
  locationType,
  selectedCostCenter,
  selectedPractice,
) => {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(startLoading('isLoadingSolutersMap'));
    api
      .get(`/soluters/cities`, {
        params: {
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
          locationType,
        },
      })
      .then(async ({ data: payload }) => {
        if (payload) dispatch(setSolutersMap(payload));
        else dispatch(setSolutersMap({ total: 0, content: [] }));
        dispatch(endLoading('isLoadingSolutersMap'));
      })
      .catch(err => {
        dispatch(setSolutersMap(null));
        dispatch(endLoading('isLoadingSolutersMap'));
        console.error(err);
      });
  };
};

import { Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleAddForm, BasicTable, EmptyErrorWrapper } from '~/components';
import { selectExperiencesState } from '~/store/selectors';
import * as fetchExperiences from '~/store/fetchActions/fetchExperiences';
import { search as searchAction, selectEdit } from '~/store/ducks/experiences';

const { Title } = Typography;

export default function Experiences() {
  const {
    search,
    searchTitle,
    page,
    selectedExperience,
    experiences,
  } = useSelector(selectExperiencesState);
  const dispatch = useDispatch();

  const onFinish = (values, form) => {
    if (values.experience) {
      dispatch(
        fetchExperiences.add(search, searchTitle, values.experience, page),
      );
      form.resetFields();
    }
  };

  return (
    <>
      <Title>Experiências</Title>
      <SimpleAddForm
        onFinish={onFinish}
        itemName="experience"
        placeholderMessage="Adicionar Experiência"
        rules={[
          {
            type: 'string',
            min: 1,
            max: 30,
            required: true,
            message: 'A experiência deve ter no máximo 30 caracteres',
          },
        ]}
      />
      <EmptyErrorWrapper
        data={experiences}
        emptyCondition={experiences !== null}
        retryAction={fetchExperiences.get}
        retryProps={[page]}
        loaderTitle=""
      >
        <BasicTable
          stateSelector={state => state.experiences}
          data={experiences}
          title="Experiências"
          editingItem={selectedExperience}
          fetchActions={fetchExperiences}
          searchAction={searchAction}
          selectEdit={selectEdit}
          rulesTooltip="A nova experiência deve ter 3 caracteres ou mais"
        />
      </EmptyErrorWrapper>
    </>
  );
}

import { Button, Form, Modal, Input, Upload, Row, Col, Select } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useSelector } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BlockPicker } from 'react-color';
import { clearSoluter } from '~/store/ducks/people';
import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import { validationMessages, colors, towers } from '~/constants';
import { SelectSearchPeople, TagRender } from '~/components';
import SelectSearchBusinessUnits from '~/components/SelectSearchBusinessUnits/SelectSearchBusinessUnits';

const { Option } = Select;

export default function AddTowerForm({
  button,
  tower,
  towerId,
  disabled,
  allGroups,
}) {
  const [isVisible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [fileListThumb, setFileListThumb] = useState([]);
  const [ceEmail, setCeEmail] = useState(tower ? tower.CE?.email : '');
  const [dmEmail, setDmEmail] = useState(tower ? tower.DM?.email : '');
  const [color, setColor] = useState(
    tower?.color || `#${colors.DEFAULT_COLOR}`,
  );
  const { pathname } = useLocation();
  const isEditing = pathname.includes('details');

  useEffect(() => {
    if (tower?.color) setColor(tower?.color);
    if (tower?.CE?.email) setCeEmail(tower.CE.email);
    if (tower?.DM?.email) setDmEmail(tower.DM.email);
  }, [tower]);

  const handleSubmit = values => {
    let _values = tower
      ? { ...values, id: towerId, color }
      : { ...values, color };

    _values = {
      ..._values,
      CE: {
        email: ceEmail,
      },
      DM: {
        email: dmEmail,
      },
    };

    dispatch(fetchTowers.addTower(_values, allGroups));

    if (tower) form.setFieldsValue({ name: values.name });
    else form.resetFields();
    setFileListThumb([]);
    setVisible(false);
  };

  const onChange = ({ fileList: newFileListThumb }) => {
    setFileListThumb(newFileListThumb);
  };

  const handleChangeCe = value => {
    dispatch(clearSoluter());
    if (value) dispatch(fetchPeople.getSoluterBasicData(value, true));
    if (value) setCeEmail(value);
    else setCeEmail('');
  };

  const handleChangeDm = value => {
    dispatch(clearSoluter());
    if (value) dispatch(fetchPeople.getSoluterBasicData(value, true));
    if (value) setDmEmail(value);
    else setDmEmail('');
  };

  const disabledEdit = button.props.disabled;

  return (
    <>
      {tower ? (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation();
            if ((!isEditing || (isEditing && tower?.name)) && !disabledEdit)
              setVisible(true);
          }}
        >
          {button}
        </Button>
      ) : (
        <Button
          disabled={disabled}
          type="primary"
          onClick={() => setVisible(true)}
          data-testid="add-tower-button"
        >
          Adicionar Torre
        </Button>
      )}
      <Modal
        data-testid="add-tower-modal"
        title={tower ? 'Editar Torre' : 'Adicionar Torre'}
        visible={isVisible}
        onCancel={() => setVisible(false)}
        width="40vw"
        okText={tower ? 'Salvar' : 'Adicionar'}
        cancelText="Cancelar"
        okButtonProps={{
          'data-testid': 'add-tower-form-submit-button',
        }}
        onOk={() => {
          form
            .validateFields()
            .then(values => handleSubmit(values))
            // eslint-disable-next-line no-console
            .catch(console.error);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark
          onFinish={handleSubmit}
          validateMessages={validationMessages.VALIDATION_MESSAGE}
        >
          <Row justify="center">
            <Form.Item
              name="photo"
              label="Foto"
              style={{ marginBottom: 4 }}
              initialValue={tower ? tower.photo : ''}
            >
              <Upload
                beforeUpload={() => false}
                listType="picture-card"
                fileList={fileListThumb}
                onChange={onChange}
                maxCount={1}
              >
                Thumb
                <CameraOutlined style={{ marginLeft: '5px' }} />
              </Upload>
            </Form.Item>
          </Row>
          <Col>
            <Form.Item
              name="name"
              required
              label="Nome"
              initialValue={tower ? tower.name : ''}
              rules={[
                {
                  required: true,
                  max: 30,
                  message:
                    'O nome é obrigatório e deve ter menos de 30 caracteres',
                  whitespace: true,
                },
              ]}
            >
              <Input data-testid="tower-name-input" />
            </Form.Item>
            <Form.Item
              name="description"
              required
              label="Descrição"
              initialValue={tower ? tower.description : ''}
              rules={[
                {
                  required: true,
                  max: 240,
                  message:
                    'A descrição é obrigatória e deve ter menos de 240 caracteres',
                  whitespace: true,
                },
              ]}
            >
              <TextArea
                data-testid="tower-description-input"
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
            <Row justify="space-between">
              <Col span={12}>
                <Form.Item
                  name="vertical"
                  label="Vertical"
                  initialValue={tower ? tower.vertical : null}
                >
                  <Select placeholder="Selecionar vertical">
                    <Option value="PSU">{towers.verticals.PSU}</Option>
                    <Option value="FS">{towers.verticals.FS}</Option>
                    <Option value="CPR">{towers.verticals.CPR}</Option>
                    <Option value="TM">{towers.verticals.TM}</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="CE"
                  label="Client Executive"
                  initialValue={tower ? tower.CE?.name : null}
                >
                  <SelectSearchPeople
                    testId="tower-CE-input"
                    onChange={handleChangeCe}
                  />
                </Form.Item>
                <Form.Item
                  name="DM"
                  label="Delivery Manager"
                  initialValue={tower ? tower.DM?.name : null}
                >
                  <SelectSearchPeople
                    testId="tower-DM-input"
                    onChange={handleChangeDm}
                  />
                </Form.Item>
                <Form.Item name="BUS" label="Business Units">
                  <SelectSearchBusinessUnits
                    defaultValue={tower ? tower.BUs?.map(bu => bu._id) : []}
                    fieldName="BUs"
                    fieldLabel="Business Units"
                    required={false}
                    selectMode="multiple"
                    tagRenderFunc={props => TagRender(props)}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name="color" label="Cor">
                  <BlockPicker
                    color={color}
                    colors={colors.COLOR_PICKER_OPTIONS}
                    triangle="hide"
                    onChange={_color => setColor(_color.hex)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>
    </>
  );
}

AddTowerForm.propTypes = {
  button: PropTypes.element,
  tower: PropTypes.object,
  towerId: PropTypes.string,
  disabled: PropTypes.bool,
  allGroups: PropTypes.bool,
};

AddTowerForm.defaultProps = {
  tower: null,
  button: <></>,
  towerId: '',
  disabled: false,
  allGroups: true,
};

import { message } from 'antd';
import api from '~/config/httpRequest';
import {
  getBadge,
  getBadges,
  updateBadgeById,
  getUsersByBadgeId,
  getUsersNotifications,
  updateUsersByBadgeById,
} from '../ducks/badges';
import imageHelper from '~/helpers/imageHelper';
import { validationMessages } from '~/constants';
import { endLoading, startLoading } from '../ducks/loading';

export const listBadges = (title = '') => {
  return async (dispatch, getState) => {
    dispatch(startLoading('isLoadingTowers'));

    try {
      const payload = await api.get(`/soluters/badge`, {
        params: {
          title,
        },
      });

      await dispatch(getBadges(payload.data));
      if (payload.data) updateBadgesWithMembersAndThumb(dispatch, getState);
    } catch (e) {
      dispatch(endLoading('isLoadingTowers'));
      dispatch(getBadges(null));
    }
  };
};

export const updateBadgesWithMembersAndThumb = (dispatch, getState) => {
  const { badges } = getState();
  if (badges.badges) {
    badges.badges.forEach(async badge => {
      const { id } = badge;

      try {
        const usersPayload = await listUsersByBadgePromisse(id);
        const thumb = await getBadgePhoto(id);
        dispatch(
          updateBadgeById({
            ...badge,
            totalMembers: usersPayload.total,
            thumb,
          }),
        );
        dispatch(endLoading('isLoadingTowers'));
        dispatch(endLoading('isLoadingTowerPhoto'));
      } catch (e) {
        dispatch(updateBadgeById({ ...badge, totalMembers: 0 }));
        dispatch(updateBadgeById({ ...badge, thumb: 'no photo' }));
      }
    });
  }
};

export const getAllBadgesThumb = (badges, dispatch) => {
  if (badges) {
    badges.forEach(async badge => {
      const { id } = badge;

      try {
        const thumb = await getBadgePhoto(id);
        dispatch(updateBadgeById({ ...badge, thumb }));
      } catch (e) {
        dispatch(updateBadgeById({ ...badge, thumb: 'no photo' }));
      }
    });
  }
};

export const getBadgePhoto = badgeId => {
  return new Promise((resolve, reject) => {
    api
      .get(`/soluters/badge/${badgeId}/image`, imageHelper.responseType())
      .then(response => imageHelper.convertBytesToBase64(response, resolve))
      .catch(reject);
  });
};

export const getBadgeById = (
  badgeId,
  loadingString = 'isLoadingTower',
  shouldLoadPhoto = true,
) => {
  return async dispatch => {
    dispatch(startLoading(loadingString));
    api
      .get(`/soluters/badge/${badgeId}`)
      .then(async ({ data: payload }) => {
        if (payload && shouldLoadPhoto) {
          try {
            payload.photo = await getBadgePhoto(badgeId);
          } catch (error) {
            /* passs */
          }
        }
        dispatch(endLoading(loadingString));
        dispatch(getBadge({ ...payload, _id: badgeId }));
      })
      .catch(err => {
        dispatch(endLoading(loadingString));
      });
  };
};

export function addBadge(badge, allGroups = true) {
  return async dispatch => {
    api
      .post(`/soluters/badge`, { ...badge, photo: '' })
      .then(res => {
        if (badge.photo?.file) {
          const formDataPhoto = new FormData();
          formDataPhoto.append('file', badge.photo.file);
          dispatch(addBadgePhoto(res.data.id, formDataPhoto, false, allGroups));
        } else if (res.data.id) {
          dispatch(listBadges());
        }
      })
      .catch(err => {
        /* pass */
      });
  };
}

export function updateBadge(
  badge,
  badgeId,
  allGroups = true,
  editTitle = false,
) {
  const formDataPhoto = new FormData();
  formDataPhoto.append('file', badge.photo.file);
  const badgePhoto = badge.photo;
  delete badge.photo;

  if (editTitle) delete badge.title;

  return async dispatch => {
    api
      .put(`/soluters/badge/${badgeId}`, { ...badge })
      .then(res => {
        if (badgePhoto?.file) {
          dispatch(addBadgePhoto(badgeId, formDataPhoto, badge.id, allGroups));
        } else if (badge.id) {
          dispatch(getBadgeById(badge.id));
        } else dispatch(listBadges());
      })
      .catch(err => {
        /* pass */
      });
  };
}

export const addBadgePhoto = (badgeId, photo, hasId, allGroups = true) => {
  return dispatch => {
    dispatch(startLoading('isLoadingTowerPhoto'));
    api
      .put(`/soluters/badge/${badgeId}/image`, photo)
      .then(({ data: payload }) => {
        if (hasId) dispatch(getBadgeById(badgeId));
        else dispatch(listBadges());
        dispatch(endLoading('isLoadingTowerPhoto'));
      })
      .catch(err => {
        dispatch(endLoading('isLoadingTowerPhoto'));
      });
  };
};

export const listUsersByBadge = (badgeId, name) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingProjects'));

    api
      .get(
        `/soluters/badge/users/${badgeId}`,
        name && {
          params: {
            name,
          },
        },
      )
      .then(({ data: payload }) => {
        dispatch(getUsersByBadgeId(payload));
        dispatch(endLoading('isLoadingProjects'));
      })
      .catch(err => {
        dispatch(endLoading('isLoadingProjects'));
        dispatch(getUsersByBadgeId(null));
      });
  };
};

export const listUsersByBadgePromisse = badgeId => {
  return new Promise((resolve, reject) => {
    api
      .get(`/soluters/badge/users/${badgeId}`)
      .then(({ data: payload }) => resolve(payload))
      .catch(reject);
  });
};

export const addUsersToBadge = (badgeId, solutersList) => {
  return dispatch => {
    api
      .put(`/soluters/badge/${badgeId}/users`, solutersList)
      .then(({ data: payload }) => {
        dispatch(getBadgeById(badgeId));
        dispatch(listUsersByBadge(badgeId));
      })
      .catch(err => {
        /* pass */
      });
  };
};

export const deleteUserInBadge = (badgeId, userEmail) => {
  return dispatch => {
    api
      .put(`/soluters/badge/${badgeId}/users/${userEmail}`)
      .then(({ data: payload }) => {
        dispatch(getBadgeById(badgeId));
        dispatch(listUsersByBadge(badgeId));
      })
      .catch(err => {
        /* pass */
      });
  };
};

export const deleteBadgeById = badgeId => {
  return dispatch => {
    api
      .delete(`/soluters/badge/${badgeId}/delete`)
      .then(({ data: payload }) => {
        dispatch(listBadges());
      })
      .catch(err => {
        /* pass */
      });
  };
};

export const UpdateUserInBadge = (badgeId, userEmail) => {
  return dispatch => {
    api
      .put(`/soluters/badge/user/${userEmail}?badgeId=${badgeId}`, badgeId)
      .then(() => {
        dispatch(listUsersByBadge(badgeId));
        dispatch(updateUsersByBadgeById(true));
        message.success(validationMessages.HTTP_EDIT_SUCCESS_MESSAGE);
      })
      .catch(err => {
        /* pass */
      });
  };
};
export const getUnvalidatedUser = () => {
  return dispatch => {
    api
      .get(`/soluters/badge/unvalidated`)
      .then(({ data: payload }) => {
        dispatch(getUsersNotifications(payload));
      })
      .catch(err => {
        /* pass */
      });
  };
};

export const UpdateAllUsersInBadge = badgeId => {
  return dispatch => {
    api
      .put(`/soluters/badge/${badgeId}/update-expired-users`)
      .then(({ data: payload }) => {
        dispatch(listUsersByBadge(badgeId));
        dispatch(updateUsersByBadgeById(true));
        message.success(validationMessages.HTTP_UPDATE_SUCCESS_MESSAGE);
      })
      .catch(err => {
        /* pass */
      });
  };
};

import { Typography, Table, Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Button, SearchBar, ActiveFilters } from '~/components';
import { selectLoadingState } from '~/store/selectors';

import './styles.scss';

const { Text, Link } = Typography;

export default function BasicTable({
  data,
  title,
  editingItem,
  fetchActions,
  searchAction,
  selectEdit,
  stateSelector,
  rulesTooltip,
  validationList,
  paginate = true,
}) {
  const dispatch = useDispatch();
  const { total, page, search, searchTitle } = useSelector(stateSelector);
  const { isLoading } = useSelector(selectLoadingState);
  const [currentText, setCurrentText] = useState('');
  const [clearSearch, setClearSearch] = useState(false);

  useEffect(() => {
    if (searchTitle === '') dispatch(fetchActions.get(1));
  }, [dispatch, fetchActions, searchTitle]);

  const handleChange = e => {
    setCurrentText(e);
  };

  const handleEdit = item => {
    if (currentText?.length > 0 && currentText !== item?.title) {
      dispatch(selectEdit(-1));
      dispatch(
        fetchActions.edit(search, searchTitle, currentText, item.id, page),
      );
    }
  };

  const columns = [
    {
      title: `${title}`,
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'descend',
      className: 'basic-table-cell',
      filterDropdown: () => [
        <SearchBar
          key="dropdown"
          title={`Pesquisar ${title}`}
          actionSearch={searchAction}
          actionGetByProperty={fetchActions.getByTitle}
          actionGetAll={fetchActions.get}
          toggleClear={clearSearch}
          currentSearch={searchTitle}
        />,
      ],
      filterIcon: filtered => (
        <SearchOutlined
          data-testid="search-icon"
          key="filter-icon"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
      render: (text, item) => (
        <Text
          key={`item-${item.id}`}
          editable={{
            icon: <></>,
            tooltip: `${rulesTooltip}`,
            editing: editingItem === item.id,
            maxLength: 30,
            onChange: e => {
              handleChange(e);
            },
          }}
        >
          {text}
        </Text>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'end',
      render: (id, item) => (
        <div>
          {validationList ? (
            <Text key={`actions-${id}`}>
              <Link
                key={`remove-${id}`}
                onClick={() => handleRemove(id)}
                data-testid="remove-button"
              >
                <Button
                  type="remove"
                  title={item.title}
                  id={id}
                  style={{ color: '#dfa211', marginRight: 30 }}
                />
              </Link>
              <Link
                key={`accept-${id}`}
                onClick={() => handleAccept(id)}
                data-testid="accept-button"
              >
                <Button type="accept" title={item.title} id={id} />
              </Link>
            </Text>
          ) : (
            <Text key={`actions-${id}`}>
              <Link
                key={`save-${id}`}
                style={editingItem === id ? '' : { display: 'none' }}
                onClick={() => handleEdit(item)}
                data-testid="save-button"
              >
                {' Salvar '}
              </Link>
              {editingItem === id ? '|' : ''}
              <Link
                key={`edit-${id}`}
                onClick={() => handleSelectEdit(id)}
                disabled={editingItem !== -1 && editingItem !== id}
              >
                {editingItem !== id ? (
                  <Button
                    type="edit"
                    style={{ color: '#dfa211', marginRight: 30 }}
                  />
                ) : (
                  ' Cancelar  '
                )}
              </Link>
              <Button
                type="delete"
                title={item.title}
                id={id}
                handleDelete={handleDelete}
              />
            </Text>
          )}
        </div>
      ),
    },
  ];

  const handleSelectEdit = id => {
    dispatch(selectEdit(editingItem === id ? -1 : id));
  };

  const handleDelete = id => {
    const onlyOne = data?.length === 1;
    dispatch(fetchActions.remove(search, searchTitle, id, page, onlyOne));
    dispatch(selectEdit(-1));
  };

  const handleRemove = id => {
    dispatch(fetchActions.remove(id));
  };

  const handleAccept = id => {
    dispatch(fetchActions.add(id));
  };

  return (
    <>
      <ActiveFilters
        filters={searchTitle !== '' ? [searchTitle] : []}
        label="Busca Ativa"
        handleClear={() => {
          setClearSearch(state => !state);
          dispatch(fetchActions.get(1));
          dispatch(
            searchAction({
              search: false,
              searchTitle: '',
            }),
          );
        }}
      />
      <Table
        rowKey="id"
        key="table"
        className="basic-table"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Não há dados registrados."
            />
          ),
        }}
        pagination={
          paginate
            ? {
                current: page,
                pageSize: 10,
                pageSizeOptions: [],
                showSizeChanger: false,
                total,
                onChange: _page =>
                  search
                    ? dispatch(fetchActions.getByTitle(searchTitle, _page))
                    : dispatch(fetchActions.get(_page)),
              }
            : false
        }
        loading={isLoading}
        dataSource={data || null}
        columns={columns}
        onChange={() => editingItem && handleSelectEdit(editingItem)}
      />
    </>
  );
}

BasicTable.propTypes = {
  data: PropTypes.array.isRequired,
  editingItem: PropTypes.string.isRequired,
  fetchActions: PropTypes.func.isRequired,
  searchAction: PropTypes.object.isRequired,
  selectEdit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  stateSelector: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  rulesTooltip: PropTypes.string.isRequired,
  validationList: PropTypes.bool.isRequired,
  paginate: PropTypes.bool.isRequired,
};

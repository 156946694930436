import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getPractices } from '~/store/fetchActions/fetchPractices';

const { Option } = Select;

export default function ControlledPracticeField({
  onChangePractice,
  practice,
  style,
  ...props
}) {
  const dispatch = useDispatch();
  const { practices } = useSelector(state => state.practices);
  const { isLoadingPractices } = useSelector(state => state.loading);

  useEffect(() => {
    dispatch(getPractices(false));
  }, [dispatch]);

  return (
    <Select
      placeholder="Buscar por BU"
      onChange={onChangePractice}
      filterOption={false}
      allowClear
      notFoundContent={isLoadingPractices ? <Spin size="small" /> : null}
      data-testid="practice-select"
      value={practice || undefined}
      style={style}
      {...props}
    >
      {practices?.map(({ name, id }, key) => (
        <Option key={name} value={id} data-testid={`practice-option-${key}`}>
          {`${name}`}
        </Option>
      ))}
    </Select>
  );
}

ControlledPracticeField.propTypes = {
  practice: PropTypes.string.isRequired,
  onChangePractice: PropTypes.func,
  style: PropTypes.object,
};

ControlledPracticeField.defaultProps = {
  onChangePractice: () => {},
  style: { width: 300 },
};

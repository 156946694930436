import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

export default function GoBackButton() {
  const history = useHistory();

  return (
    <Button
      onClick={() => {
        history.goBack();
      }}
      data-testid="go-back-button"
      type="link"
      color="black"
      shape="round"
      icon={<LeftOutlined style={{ fontSize: '25px', color: 'black' }} />}
    />
  );
}

/* eslint-disable no-nested-ternary */
import { Row, Spin, Form } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SelectTowers, Button } from '~/components';
import { setSelectedTower } from '~/store/ducks/indicators/index';

export default function SoluterJourneyHeader({
  handleOpenDrawer,
  onChangeSelectedTower,
}) {
  const [form] = Form.useForm();
  const { tower } = useSelector(state => state.towers);
  const {
    selectedTower,
    soluterJourney,
    selectedPractice,
    selectedCostCenter,
  } = useSelector(state => state.indicators);
  const {
    isLoadingSoluters,
    isLoadingTower,
    isLoadingGraph,
    isLoadingJourneyPeople,
  } = useSelector(state => state.loading);

  const isLoading =
    isLoadingSoluters ||
    isLoadingTower ||
    isLoadingGraph ||
    isLoadingJourneyPeople;

  useEffect(() => {
    if (selectedPractice !== '' || selectedCostCenter?.length > 0)
      form.resetFields();
  }, [form, selectedPractice, selectedCostCenter]);

  useEffect(() => {
    if (selectedTower !== '') onChangeSelectedTower();
  }, [onChangeSelectedTower, selectedTower]);

  return (
    <>
      <Row justify="space-between">
        <Form form={form}>
          <Form.Item name="tower" style={{ marginBottom: 0 }}>
            <SelectTowers
              selector={state => state.indicators}
              dispatchDuck={setSelectedTower}
              emptyOptionText="Selecione uma Torre"
              allowClear
            />
          </Form.Item>
        </Form>
      </Row>
      <Row style={{ marginTop: 10, minHeight: 32 }} align="middle">
        <TeamOutlined style={{ marginRight: 5 }} />
        <p className="mb-0">
          Total de Soluters na categoria selecionada:{' '}
          {isLoading ? (
            <Spin style={{ marginLeft: 8 }} size="small" />
          ) : selectedTower === '' ? (
            soluterJourney?.peopleTotal
          ) : (
            tower?.totalMembers
          )}
        </p>
      </Row>
      <Row
        align="middle"
        style={{ minHeight: 32 }}
        data-testid="no-north-soluters"
      >
        <TeamOutlined style={{ marginRight: 5 }} />
        <p className="mb-0">
          Soluters sem um norte registrado:{' '}
          {isLoading ? (
            <Spin style={{ marginLeft: 8 }} size="small" />
          ) : selectedTower === '' ? (
            soluterJourney?.peopleTotal -
            soluterJourney?.totalSolutersWithJourney
          ) : (
            tower?.totalMembers &&
            tower?.totalMembers - soluterJourney?.totalSolutersWithJourney
          )}
        </p>
        {!isLoading &&
          (tower?.totalMembers || 0) -
            soluterJourney?.totalSolutersWithJourney !==
            0 && (
            <Button
              style={{ marginLeft: 5 }}
              type="view"
              isButton
              tooltipIdentifier="Soluters"
              handleClick={() => {
                handleOpenDrawer('Não possuem norte registrado');
              }}
            />
          )}
      </Row>
    </>
  );
}

SoluterJourneyHeader.propTypes = {
  handleOpenDrawer: PropTypes.func.isRequired,
  onChangeSelectedTower: PropTypes.func.isRequired,
};

import { Button, Form, Modal, Input, Upload, Row, Col, Select } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { clearSoluter } from '~/store/ducks/people';
import * as fetchBadges from '~/store/fetchActions/fetchBadges';
import { validationMessages, colors, towers } from '~/constants';

const { Option } = Select;

export default function AddBadgeForm({
  button,
  badge,
  badgeId,
  disabled,
  allGroups,
}) {
  const [isVisible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [fileListThumb, setFileListThumb] = useState([]);
  const [color, setColor] = useState(
    badge?.color || `#${colors.DEFAULT_COLOR}`,
  );
  const { pathname } = useLocation();
  const isEditing = pathname.includes('details');

  useEffect(() => {
    if (badge?.color) setColor(badge?.color);
  }, [badge]);

  const handleSubmit = values => {
    let editTitle = false;
    if (values?.title === badge?.title) editTitle = true;

    let _values = badge
      ? { ...values, id: badgeId, color }
      : { ...values, color };

    _values = {
      ..._values,
    };

    if (badge) {
      dispatch(fetchBadges.updateBadge(_values, badgeId, allGroups, editTitle));
    } else {
      dispatch(fetchBadges.addBadge(_values, allGroups));
    }

    if (badge) form.setFieldsValue({ name: values.name });
    else form.resetFields();
    setFileListThumb([]);
    setVisible(false);
  };

  const onChange = ({ fileList: newFileListThumb }) => {
    setFileListThumb(newFileListThumb);
  };

  const disabledEdit = button.props.disabled;

  return (
    <>
      {badge ? (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation();
            if ((!isEditing || (isEditing && badge?.name)) && !disabledEdit)
              setVisible(true);
          }}
        >
          {button}
        </Button>
      ) : (
        <Button
          disabled={disabled}
          type="primary"
          onClick={() => setVisible(true)}
          data-testid="add-tower-button"
        >
          Adicionar Badge
        </Button>
      )}
      <Modal
        data-testid="add-tower-modal"
        title={badge ? 'Editar Badge' : 'Adicionar Badge'}
        visible={isVisible}
        onCancel={() => setVisible(false)}
        width="40vw"
        okText={badge ? 'Salvar' : 'Adicionar'}
        cancelText="Cancelar"
        okButtonProps={{
          'data-testid': 'add-tower-form-submit-button',
        }}
        onOk={() => {
          form
            .validateFields()
            .then(values => handleSubmit(values))
            // eslint-disable-next-line no-console
            .catch(console.error);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark
          onFinish={handleSubmit}
          validateMessages={validationMessages.VALIDATION_MESSAGE}
        >
          <Row justify="center">
            <Form.Item
              name="photo"
              label="Foto"
              style={{ marginBottom: 4 }}
              initialValue={badge ? badge.photo : ''}
            >
              <Upload
                beforeUpload={() => false}
                listType="picture-card"
                fileList={fileListThumb}
                onChange={onChange}
                maxCount={1}
              >
                Thumb
                <CameraOutlined style={{ marginLeft: '5px' }} />
              </Upload>
            </Form.Item>
          </Row>
          <Col>
            <Form.Item
              name="title"
              required
              label="Título"
              initialValue={badge ? badge.title : ''}
              rules={[
                {
                  required: true,
                  max: 30,
                  message:
                    'O nome é obrigatório e deve ter menos de 30 caracteres',
                  whitespace: true,
                },
              ]}
            >
              <Input data-testid="tower-name-input" />
            </Form.Item>
            <Form.Item
              name="description"
              required
              label="Descrição"
              initialValue={badge ? badge.description : ''}
              rules={[
                {
                  required: true,
                  max: 200,
                  message:
                    'A descrição é obrigatória e deve ter menos de 200 caracteres',
                  whitespace: true,
                },
              ]}
            >
              <TextArea
                data-testid="tower-description-input"
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  );
}

AddBadgeForm.propTypes = {
  button: PropTypes.element,
  badge: PropTypes.object,
  badgeId: PropTypes.string,
  disabled: PropTypes.bool,
  allGroups: PropTypes.bool,
};

AddBadgeForm.defaultProps = {
  badge: null,
  button: <></>,
  badgeId: '',
  disabled: false,
  allGroups: true,
};

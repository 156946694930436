import { Typography, Row, Space, Button, Tooltip, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { iconsByMood, altTextByMood } from '~/constants/moods';
import { Legend } from '~/components';
import { moods } from '~/constants';
import { setCommentsCurrentPage } from '~/store/ducks/mood';

import './styles.scss';

const { Title } = Typography;

export default function MoodCommentsHeader({ activeMood, setActiveMood }) {
  const dispatch = useDispatch();

  const isMoodFilterActive = mood => {
    return mood === activeMood;
  };

  return (
    <>
      <Row justify="center">
        <Title level={4}>Comentários do Mood</Title>
      </Row>
      <Row align="middle" style={{ marginTop: 20, marginBottom: 20 }}>
        <Col span={8}>
          <div className="soluter-mood__legend">
            <p style={{ marginBottom: 0 }}>Legenda</p>
            <Legend
              color={moods.APPROVED_COLOR_DARK}
              text={moods.APPROVED_TAGS_QUESTION}
            />
            <Legend
              color={moods.NOT_APPROVED_COLOR_DARK}
              text={moods.NOT_APPROVED_TAGS_QUESTION}
            />
          </div>
        </Col>
        <Col span={8}>
          <p style={{ marginBottom: 8, textAlign: 'center' }}>
            Filtrar comentários por mood:
          </p>
          <Space size={10} style={{ width: '100%', justifyContent: 'center' }}>
            {[5, 4, 3, 2, 1].map(mood => {
              return (
                <Button
                  data-testid={`mood-button-${mood}`}
                  key={mood}
                  onClick={() => {
                    if (isMoodFilterActive(mood)) setActiveMood('');
                    else setActiveMood(mood);
                    dispatch(setCommentsCurrentPage(1));
                  }}
                  type="link"
                  className={`comments-mood-filter__button${
                    isMoodFilterActive(mood)
                      ? ' comments-mood-filter__button--active'
                      : ''
                  }`}
                  icon={
                    <img
                      src={iconsByMood[mood]}
                      alt={altTextByMood[mood]}
                      style={{ width: '30px', height: '30px' }}
                    />
                  }
                />
              );
            })}
            {activeMood !== '' && (
              <Tooltip title="Limpar filtro" placement="right">
                <Button
                  size="small"
                  onClick={() => setActiveMood('')}
                  type="link"
                  icon={<CloseOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        </Col>
      </Row>

      <Row />
    </>
  );
}

MoodCommentsHeader.propTypes = {
  activeMood: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  setActiveMood: PropTypes.func.isRequired,
};

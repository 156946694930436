import { Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as fetchDistricts from '~/store/fetchActions/fetchDistricts';
import './styles.scss';
import { DistrictsList } from '~/container';
import { SearchBar } from '~/components';
import { searchDistrict, cleanDistrictById } from '~/store/ducks/districts';

const { Title } = Typography;

const searchStyles = {
  display: 'flex',
  padding: 0,
  inputMargin: 0,
  inputMarginRight: 10,
  inputWidth: 300,
  layout: 'inline',
};

export default function Districts() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanDistrictById());
  }, [dispatch]);

  return (
    <>
      <Title>Distritos</Title>
      <div className="district-buttons">
        <SearchBar
          title="Pesquisar Distrito"
          actionGetByProperty={fetchDistricts.getByProperty}
          actionGetAll={fetchDistricts.get}
          actionSearch={searchDistrict}
          customStyles={searchStyles}
        />
        <Link to="/districts/add">
          <Button type="primary">Adicionar Novo Distrito</Button>
        </Link>
      </div>

      <DistrictsList />
    </>
  );
}

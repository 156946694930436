/* eslint-disable react/no-array-index-key */
import { Tag, Space, Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './styles.scss';

export default function ActiveFilters({
  label,
  filters,
  handleClear,
  isTagClosable,
  handleTagClose,
}) {
  return (
    <div className="active-filters">
      {filters?.length > 0 && (
        <>
          <p className="active-filters__label">{label}: </p>
          <Space size={2} wrap>
            {filters?.map((filter, idx) => (
              <Tag
                key={idx}
                closable={isTagClosable}
                data-testid={`active-filter-${filter}`}
                color="blue"
                onClose={e => {
                  e.preventDefault();
                  handleTagClose(filter);
                }}
              >
                {filter}
              </Tag>
            ))}
          </Space>
          <Tooltip title="Limpar todos os filtros" placement="right">
            <Button
              size="small"
              onClick={handleClear}
              type="link"
              icon={<CloseOutlined />}
              data-testid="clean-all-filters"
            />
          </Tooltip>
        </>
      )}
    </div>
  );
}

ActiveFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  handleClear: PropTypes.func.isRequired,
  isTagClosable: PropTypes.bool,
  label: PropTypes.string,
  handleTagClose: PropTypes.func,
};

ActiveFilters.defaultProps = {
  isTagClosable: false,
  label: 'Filtros Ativos',
  handleTagClose: () => {},
};

/* eslint-disable no-console */
import {
  Form,
  Input,
  Row,
  Col,
  Radio,
  DatePicker,
  Drawer,
  Button,
  Divider,
  Space,
} from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { reasonsToLeaveInvolutary } from '~/constants/people';
import {
  addSoluterExitInterviewInvoluntary,
  updateSoluterInvoluntaryExitInterview,
} from '~/store/fetchActions/fetchPeople';
import { clearSoluter, getSoluter } from '~/store/ducks/people';
import { SelectSearchPeople, SelectTowers } from '~/components';
import { setSelectedTower } from '~/store/ducks/towers';
import SoluterAllocationInfo from '../ExitInterviews/SoluterAllocationInfo';

const { TextArea } = Input;

export default function ExitInvolutaryInterviewForm({
  interview,
  isModalVisible,
  setModalVisible,
  customReload,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isAddingExitInterview } = useSelector(state => state.loading);
  const { selectedTowerName, towers } = useSelector(state => state.towers);
  const { soluter } = useSelector(state => state.people);
  const [searchDismissedSoluters, setSearchDismissedSoluters] = useState(false);

  useEffect(() => {
    if (interview && isModalVisible) {
      // confere se o motivo de saída está entre uma das opções pré-determinadas
      const isOtherReason = !Object.values(reasonsToLeaveInvolutary).includes(
        interview?.reason,
      );

      form.setFieldsValue({
        person: { value: interview?.employeeEmail, label: interview?.name },
        leader: interview?.leader,
        name: interview?.name,
        project: interview?.project,
        type: interview?.type,
        reason: isOtherReason
          ? reasonsToLeaveInvolutary.OTHER
          : interview?.reason,
        otherReason: isOtherReason ? interview?.reason : '',
        leaderComment: interview?.leaderComment,
        wouldReturn: interview?.wouldReturn,
        otherComment: interview?.otherComment,
        costCenter: interview?.team,
        practice: interview?.practice,
        exitDate: interview?.exitDate
          ? moment(interview?.exitDate, 'DD-MM-YYYY')
          : null,
        interviewDate: interview?.interviewDate
          ? moment(interview?.interviewDate, 'DD-MM-YYYY')
          : null,
        BP: interview?.BP,
        BPComments: interview?.BPComments,
      });
    }
  }, [form, interview, isModalVisible]);

  useEffect(() => {
    form.setFieldsValue({
      leader: soluter?.name,
      practice: soluter?.practice || 'Não identificada',
      costCenter: soluter?.team || 'Não identificado',
    });
  }, [form, soluter]);

  const handlePersonChange = value => {
    dispatch(clearSoluter());
    if (value) {
      const { name, email, practice, team } = value;
      if (name && email) {
        dispatch(
          getSoluter({
            name,
            email,
            practice,
            team,
          }),
        );
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      group: selectedTowerName,
    });
  }, [form, selectedTowerName]);

  useEffect(() => {
    if (isModalVisible && interview?.id && interview?.group && isModalVisible) {
      const selectedTower = towers.find(
        ({ name }) => name === interview?.group,
      );

      if (selectedTower) {
        const { _id, name } = selectedTower;
        dispatch(setSelectedTower({ value: _id, label: name }));
      }
    }
  }, [interview, towers, form, isModalVisible, dispatch]);

  useEffect(() => {
    if (!isModalVisible) {
      dispatch(setSelectedTower({ value: '', label: '' }));
      form.setFieldsValue({
        group: '',
      });
    }
  }, [dispatch, isModalVisible, form]);

  const handleSubmit = async values => {
    const formattedDate = values?.exitDate?.format('DD-MM-YYYY') || null;
    const formattedInterviewDate =
      values?.interviewDate?.format('DD-MM-YYYY') || null;
    const reason =
      values.reason === reasonsToLeaveInvolutary.OTHER
        ? values.otherReason
        : values.reason;

    try {
      if (!interview) {
        await dispatch(
          addSoluterExitInterviewInvoluntary(
            interview?.employeeEmail || soluter?.email,
            {
              ...values,
              exitDate: formattedDate,
              interviewDate: formattedInterviewDate,
              reason,
            },
          ),
        );
      } else {
        await dispatch(
          updateSoluterInvoluntaryExitInterview(
            interview?.employeeEmail,
            interview?.id,
            {
              ...values,
              exitDate: formattedDate,
              interviewDate: formattedInterviewDate,
              reason,
            },
          ),
        );
      }
      setModalVisible(false);
      form.resetFields();
    } catch (err) {
      console.log(err);
    } finally {
      customReload();
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(formValues => {
        handleSubmit(formValues);
        console.log(formValues);
      })
      .catch(console.error);
  };

  const validateMessages = {
    types: {
      email: 'Este não é um email válido',
    },
    string: {
      max: 'A resposta não deve ultrapassar ${max} caracteres.',
    },
    required: 'Este campo é obrigatório.',
  };

  const textAreaRules = {
    max: 500,
  };

  return (
    <Drawer
      visible={isModalVisible}
      onClose={() => setModalVisible(false)}
      title={
        interview
          ? `Editar entrevista de desligamento (${interview?.interviewDate})`
          : 'Adicionar entrevista de desligamento involutária'
      }
      width="75vw"
      footer={
        <Row justify="end">
          <Button onClick={() => setModalVisible(false)} className="mr-sm">
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleOk}
            loading={isAddingExitInterview}
          >
            Salvar
          </Button>
        </Row>
      }
      footerStyle={{
        position: 'relative',
      }}
    >
      <Form layout="vertical" form={form} validateMessages={validateMessages}>
        <Row gutter={16}>
          <Col>
            <Form.Item
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
              name="person"
              label="Líder Entrevistado: "
              initialValue={
                interview
                  ? {
                      value: interview?.employeeEmail,
                      label: interview?.leader,
                    }
                  : null
              }
              className="mb-0"
            >
              <SelectSearchPeople
                disabled={interview}
                customStyle={{ minWidth: 300, marginBottom: 0 }}
                onChange={handlePersonChange}
                testId="select-soluter-interview"
                searchDismissed={searchDismissedSoluters}
                shouldReturnLabelInValue
                shouldReturnSoluterInfo
              />
              {/*  <Input name="leader" value={interview?.leader} /> */}
            </Form.Item>
            {!interview && (
              <>
                <p className="mb-xs mt-md">Buscar na base de dados de:</p>
                <Radio.Group
                  onChange={({ target }) => {
                    setSearchDismissedSoluters(target.value);
                  }}
                  defaultValue={searchDismissedSoluters}
                >
                  <Space direction="horizontal">
                    <Radio value={false} checked>
                      Soluters Ativos
                    </Radio>
                    <Radio data-testid="only-fired-checkbox" value>
                      Soluters Desligados
                    </Radio>
                  </Space>
                </Radio.Group>
              </>
            )}
          </Col>
          <Col>
            <Form.Item
              name="exitDate"
              label="Data do Desligamento"
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={
                interview ? moment(interview?.exitDate, 'DD-MM-YYYY') : moment()
              }
            >
              <DatePicker
                locale={locale}
                format="DD/MM/YYYY"
                inputReadOnly
                defaultValue={
                  interview
                    ? moment(interview?.exitDate, 'DD-MM-YYYY')
                    : moment()
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="interviewDate"
              label="Data da Entrevista"
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={
                interview
                  ? moment(interview?.interviewDate, 'DD-MM-YYYY')
                  : moment()
              }
            >
              <DatePicker
                locale={locale}
                format="DD/MM/YYYY"
                inputReadOnly
                defaultValue={
                  interview
                    ? moment(interview?.interviewDate, 'DD-MM-YYYY')
                    : moment()
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              requiredMark
              rules={[
                {
                  required: true,
                  max: 100,
                },
              ]}
              name="BP"
              label="Business Partner - Nome do entrevistador: "
              initialValue={
                interview
                  ? { value: interview?.BP, label: interview?.BP }
                  : null
              }
              className="mb-0"
            >
              <Input name="BP" value={interview?.BP} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              requiredMark
              name="group"
              label="Torre:"
              initialValue=""
              className="mb-0"
            >
              <SelectTowers
                selector={state => state.towers}
                dispatchDuck={setSelectedTower}
                fieldName="group"
                emptyOptionText="Nenhuma torre selecionada"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="space-between" align="top" gutter={24}>
          <Col span={12}>
            <Form.Item
              label="1. Nome completo do Ex-Soluter:"
              name="name"
              initialValue={interview?.name}
              requiredMark
              rules={[
                {
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Input name="name" value={interview?.name || soluter?.name} />
            </Form.Item>
            <SoluterAllocationInfo soluter={soluter} />
            <Form.Item
              label="2. Nome completo do Líder:"
              name="leader"
              initialValue={interview?.leader || soluter?.name}
              requiredMark
              rules={[
                {
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Input name="name" value={interview?.name || soluter?.name} />
            </Form.Item>
            <Form.Item
              name="project"
              label="3. Projeto que atuava:"
              requiredMark
              rules={[{ max: 100, required: true }]}
            >
              <Input />
            </Form.Item>
            <Row>
              <Form.Item
                requiredMark
                rules={[{ required: true }]}
                name="type"
                label="4. Tipo do Desligamento"
              >
                <Radio.Group>
                  <Radio value="INVOLUNTARY" checked>
                    Involuntário (por solicitação da Solutis)
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Row>

            <Row align="middle" />
          </Col>
          <Col span={12}>
            {/* <Form.Item
              name="otherComment"
              label="4. Detalhamento do motivo do desligamento pelo líder:"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item> */}
            <Form.Item
              label="5. Qual o principal motivo do seu desligamento?"
              name="reason"
              className="mb-0"
              requiredMark
              rules={[{ required: true, max: 500 }]}
            >
              <Radio.Group
                style={{
                  maxWidth: 500,
                  minWidth: 150,
                  display: 'grid',
                  gridTemplateColumns: 'auto',
                }}
              >
                <Radio value={reasonsToLeaveInvolutary.END_OF_PROJECT}>
                  {reasonsToLeaveInvolutary.END_OF_PROJECT}
                </Radio>
                <Radio
                  value={reasonsToLeaveInvolutary.LACK_OF_EFFORT}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeaveInvolutary.LACK_OF_EFFORT}
                </Radio>
                <Radio
                  value={reasonsToLeaveInvolutary.LACK_OF_COMMITMENT}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeaveInvolutary.LACK_OF_COMMITMENT}
                </Radio>
                <Radio
                  value={reasonsToLeaveInvolutary.DOESNT_FIT_THE_CULTURE}
                  className="exit-interview-checkbox"
                >
                  {reasonsToLeaveInvolutary.DOESNT_FIT_THE_CULTURE}
                </Radio>
                <Radio
                  value={reasonsToLeaveInvolutary.OTHER}
                  style={{ width: '100%' }}
                >
                  <Form.Item
                    name="otherReason"
                    className="mb-md"
                    rules={[{ max: 500 }]}
                  >
                    <Input
                      placeholder={reasonsToLeaveInvolutary.OTHER}
                      style={{ width: '25vw' }}
                    />
                  </Form.Item>
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="wouldReturn"
              label="6. O líder recomenda o Soluter para retorno ?"
              requiredMark
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="leaderComment"
              label="7. Alguma ressalva do líder?"
              requiredMark
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="BPComments"
              label="8. Comentários do Business Partner"
              rules={[{ ...textAreaRules, required: true }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

ExitInvolutaryInterviewForm.propTypes = {
  interview: PropTypes.object,
  isModalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  customReload: PropTypes.func,
};

ExitInvolutaryInterviewForm.defaultProps = {
  interview: null,
  customReload: () => {},
};

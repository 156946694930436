import { Card, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setSoluterJourneyCategory } from '~/store/ducks/indicators';
import {
  getSoluterJourneyList,
  getSoluterJourneyGraph,
  getSolutersWithoutJourneyList,
  getSoluterJourneyTotalPeople,
} from '~/store/fetchActions/fetchIndicators';
import {
  SoluterJourneyHeader,
  GraphSoluterJourney,
  SoluterJourneyDrawer,
  SoluterJourneyLegend,
} from '~/container';
import { getTowerById } from '~/store/fetchActions/fetchTowers';
import { journeyCategories } from '~/constants/indicators';

export default function SoluterJourneyContainer({ onChangeSelectedTower }) {
  const dispatch = useDispatch();
  const {
    selectedTower,
    soluterJourney,
    selectedPractice,
    selectedCostCenter,
  } = useSelector(state => state.indicators);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  useEffect(() => {
    dispatch(getSoluterJourneyGraph(selectedTower));
    if (selectedTower !== '')
      dispatch(getTowerById(selectedTower, 'isLoadingTower', false));
    else
      dispatch(
        getSoluterJourneyTotalPeople(selectedPractice, selectedCostCenter),
      );
  }, [dispatch, selectedTower, selectedCostCenter, selectedPractice]);

  const handleOpenDrawer = category => {
    dispatch(setSoluterJourneyCategory(category));
    if (journeyCategories[category] !== null)
      dispatch(
        getSoluterJourneyList(selectedTower, journeyCategories[category]),
      );
    else dispatch(getSolutersWithoutJourneyList(selectedTower));
    setIsDrawerVisible(true);
  };

  return (
    <Card className="graph-card" style={{ minHeight: '554px' }}>
      <SoluterJourneyHeader
        handleOpenDrawer={handleOpenDrawer}
        onChangeSelectedTower={onChangeSelectedTower}
      />
      <Row align="middle">
        <Col span={5}>
          <SoluterJourneyLegend handleOpenDrawer={handleOpenDrawer} />
        </Col>
        <Col span={19}>
          <GraphSoluterJourney
            selectedTower={selectedTower}
            data={soluterJourney.data}
            amountOfSolutersWithJourney={
              soluterJourney.totalSolutersWithJourney
            }
          />
        </Col>
      </Row>
      <SoluterJourneyDrawer
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
      />
    </Card>
  );
}

SoluterJourneyContainer.propTypes = {
  onChangeSelectedTower: PropTypes.func.isRequired,
};

import { Empty, Spin, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import Bar from '@ant-design/charts/lib/bar';
import React from 'react';
import { moods } from '~/constants/';

function MoodMonthlyAverageGraph({
  setDrawerSkill,
  setDrawerVisible,
  isLoadingMonthlyAverage,
  data,
}) {
  const countTotalMoodAverageByCategory = category => {
    const arrayToSum = data?.filter(_item => _item.label === category);
    const total = arrayToSum?.reduce(
      (accumulator, currentItem) => accumulator + currentItem.value,
      0,
    );
    return total;
  };

  const config = {
    data: data?.monthlyMood || [],
    isStack: true,
    xField: 'value',
    yField: 'label',
    seriesField: 'type',
    dodgePadding: 4,
    legend: {
      layout: 'horizontal',
      position: 'top',
    },
    label: {
      position: 'middle',
      layout: [
        { value: 'interval-adjust-position' },
        { value: 'interval-hide-overlap' },
        { value: 'adjust-color' },
      ],
      formatter: function formatter({ value }) {
        return value || '';
      },
    },
    barStyle: {
      cursor: 'pointer',
    },
    /* tooltip: {
      formatter: item => {
        return {
          title: `Total: ${countTotalMoodAverageByCategory(item.label)}`,
          name: item.type,
          value: item.value,
        };
      },
    }, */
    tooltip: false,
    yAxis: {
      label: {
        formatter: function formatter(text, item, index) {
          return text;
        },
      },
    },
    xAxis: {
      grid: null,
      label: null,
    },
    color: ({ type }) => {
      return type ? moods.MOOD_COLOR_OBJ[type] : 'grey';
    },
  };
  return (
    <Spin spinning={isLoadingMonthlyAverage}>
      <Bar
        {...config}
        onEvent={(chart, event) => {
          if (event.value === 'click' && event.data) {
            setDrawerVisible(true);
            setDrawerSkill(event.data.data.label);
          }
        }}
      />
    </Spin>
  );
}

MoodMonthlyAverageGraph.propTypes = {
  skillsTags: PropTypes.array.isRequired,
  setDrawerVisible: PropTypes.func.isRequired,
  setDrawerSkill: PropTypes.func.isRequired,
  isLoadingMonthlyAverage: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
};

export default React.memo(MoodMonthlyAverageGraph);

import { useEffect } from 'react';
import { Typography, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { BuildOutlined, TeamOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { MemberList, DetailsCard } from '~/components';
import { selectDistrictsDetail, selectLoadingState } from '~/store/selectors';
import { getByDistrictId } from '~/store/fetchActions/fetchDistricts';

import './styles.css';

const { Title } = Typography;

export default function DistrictDetails({ districtId }) {
  const dispatch = useDispatch();
  const district = useSelector(selectDistrictsDetail);
  const { isLoadingMembers } = useSelector(selectLoadingState);

  useEffect(() => {
    dispatch(getByDistrictId(districtId));
  }, [dispatch, districtId]);

  return (
    <>
      {typeof district.id === 'undefined' ? (
        <Spin spinning={isLoadingMembers}>
          <div className="district-details--no-data" />
        </Spin>
      ) : (
        <div>
          <Spin spinning={isLoadingMembers}>
            <DetailsCard
              title={district?.name}
              image={district?.photo}
              isAvatar={false}
              description={
                <>
                  <TeamOutlined style={{ marginRight: 5 }} />
                  {`${district?.totalMembers} membros`}
                </>
              }
              icon={<BuildOutlined />}
              tabs={[
                {
                  title: 'Informações Gerais',
                  content: (
                    <>
                      <div className="district-details--container">
                        <div style={{ width: '48%', marginRight: '2%' }}>
                          <Title level={3}>Descrição:</Title>
                          <p style={{ textAlign: 'justify' }}>
                            {district?.description}
                          </p>
                        </div>
                        <div style={{ width: '50%' }}>
                          <Title level={3}>Membros:</Title>
                          <MemberList district={district} />
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </Spin>
        </div>
      )}
    </>
  );
}

DistrictDetails.propTypes = {
  districtId: PropTypes.string.isRequired,
};

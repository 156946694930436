import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { MoodTagList } from '~/components';
import {
  approvedTags as setApprovedTags,
  notApprovedTags as setNotApprovedTags,
} from '~/store/fetchActions/fetchMood';

export default function MoodWeeklyTagsDrawer({
  weekDay,
  startDay,
  endDay,
  isDrawerOpen,
  setIsDrawerOpen,
}) {
  const { selectedTower, weeklyTags } = useSelector(state => state.mood);
  const dispatch = useDispatch();
  const { isLoadingApprovedTags, isLoadingNotApprovedTags } = useSelector(
    state => state.loading,
  );
  const onlyVoted = true;
  const { isYearly } = useSelector(state => state.mood);

  useEffect(() => {
    if (startDay && endDay && isDrawerOpen) {
      const week = { start: startDay, end: endDay };
      dispatch(setApprovedTags(week, selectedTower, onlyVoted));
      dispatch(setNotApprovedTags(week, selectedTower, onlyVoted));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekDay, selectedTower, dispatch, onlyVoted, startDay, endDay]);

  return (
    <Drawer
      title={`Tags referente ao moods do ${isYearly ? 'mês: ' : 'dia: '} ${
        isYearly ? startDay.substr(3) : startDay
      }`}
      placement="right"
      closable={false}
      onClose={() => setIsDrawerOpen(false)}
      visible={isDrawerOpen}
      width={500}
    >
      <MoodTagList
        approvedTags
        tags={weeklyTags.approved?.tags}
        loader={isLoadingApprovedTags}
      />
      <MoodTagList
        tags={weeklyTags.notApproved?.tags}
        loader={isLoadingNotApprovedTags}
      />
    </Drawer>
  );
}

MoodWeeklyTagsDrawer.propTypes = {
  weekDay: PropTypes.string.isRequired,
  startDay: PropTypes.string.isRequired,
  endDay: PropTypes.string.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
};

/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Button, Row, Skeleton } from 'antd';
import { BankOutlined, TeamOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TitlePage, Button as CustomButton, DetailsCard } from '~/components';

import {
  ProjectsTable,
  AddTowerForm,
  ProjectsHeader,
  TowerGeneralInfo,
  BadgeGeneralInfo,
} from '~/container';

import {
  getBadgeById,
  deleteBadgeById,
  listUsersByBadge,
} from '~/store/fetchActions/fetchBadges';
import { getRole } from '~/config/environment';

import './styles.scss';
import UsersHeader from './UsersHeader';
import UsersTable from './UsersTable';
import AddBadgeForm from './AddBadgeForm';

export default function BadgeDetails({ badgeId }) {
  const dispatch = useDispatch();
  const { badge } = useSelector(state => state.badges);
  const { isLoadingTower, isLoadingTowerPhoto } = useSelector(
    state => state.loading,
  );
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [isAscOrder, setIsAscOrder] = useState(true);
  const [orderBy, setOrderBy] = useState('name');
  const { usersByBadge } = useSelector(state => state.badges);

  // carrega dados da torre a partir do id passado
  useEffect(() => {
    dispatch(getBadgeById(badgeId));
    dispatch(listUsersByBadge(badgeId));
  }, [dispatch, badgeId]);

  const handleDeleteBadge = id => {
    dispatch(deleteBadgeById(id));
  };

  return (
    <div>
      <TitlePage>Badge</TitlePage>
      <DetailsCard
        image={badge?.photo}
        icon={<BankOutlined />}
        title={badge?.title || ''}
        thumbLoading={isLoadingTowerPhoto || isLoadingTower}
        description={
          <>
            {usersByBadge?.total > 0 && (
              <p>Total de usuários: {usersByBadge?.total}</p>
            )}
            {typeof badge?.totalMembers !== 'undefined' && (
              <>
                <TeamOutlined style={{ marginRight: 5 }} />
                {`${badge?.totalMembers} membros`}
              </>
            )}
          </>
        }
        actions={
          <>
            {isLoadingTower ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton.Button active={isLoadingTower} />
                <Skeleton.Button
                  style={{ marginLeft: 10 }}
                  active={isLoadingTower}
                  shape="circle"
                />
                <Skeleton.Button
                  style={{ marginLeft: 10 }}
                  active={isLoadingTower}
                  shape="circle"
                />
                <Skeleton.Button
                  style={{ marginLeft: 10 }}
                  active={isLoadingTower}
                  shape="circle"
                />
              </div>
            ) : (
              <Row align="middle">
                <AddBadgeForm
                  button={
                    <CustomButton
                      type="edit"
                      style={{ fontSize: '20px', color: '#dfa211' }}
                      tower={badge}
                      tooltipIdentifier="Badge"
                      id={badge?.id}
                      disabled={getRole() !== 'admin'}
                    />
                  }
                  badge={badge}
                  name={badge?.name}
                  badgeId={badgeId}
                />
                <CustomButton
                  type="delete"
                  style={{ fontSize: '20px' }}
                  link="/badges/dashboard"
                  id={badge?.id}
                  title={badge?.name}
                  tooltipIdentifier="Badge"
                  handleDelete={handleDeleteBadge}
                  disabled={usersByBadge?.users?.length > 0}
                  tooltipTitleConfig="Deletar"
                />
              </Row>
            )}
          </>
        }
        tabs={[
          {
            title: 'Informações Gerais',
            content: <BadgeGeneralInfo badge={badge} />,
          },
          {
            title: 'Usuários associados',
            content: (
              <>
                <UsersHeader
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  isAscOrder={isAscOrder}
                  setIsAscOrder={setIsAscOrder}
                  year={year}
                  setYear={setYear}
                  badge={badge}
                  badgeId={badgeId}
                />
                <UsersTable
                  currentYear={year}
                  badge={badge}
                  badgeId={badgeId}
                  orderBy={orderBy}
                  isAsc={isAscOrder}
                />
              </>
            ),
          },
        ]}
      />
    </div>
  );
}

BadgeDetails.propTypes = {
  badgeId: PropTypes.string.isRequired,
};

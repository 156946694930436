import { Select, Row } from 'antd';
import PropTypes from 'prop-types';
import AddUserForm from './AddUserForm';
import SearchUser from './SearchUser';

export default function UsersHeader({ badgeId, badge }) {
  return (
    <Row align="middle" justify="space-between" className="mb-sm">
      <SearchUser badgeId={badgeId} />
      <AddUserForm badgeId={badgeId} badge={badge} />
    </Row>
  );
}

UsersHeader.propTypes = {
  badge: PropTypes.object.isRequired,
  badgeId: PropTypes.string.isRequired,
};

import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { validationMessages } from '~/constants';

export default function SimpleAddForm({
  onFinish,
  itemName,
  placeholderMessage,
  rules,
}) {
  const [simpleForm] = Form.useForm();

  return (
    <Form
      form={simpleForm}
      name="form"
      layout="inline"
      validateMessages={validationMessages.VALIDATION_MESSAGE}
      onFinish={values => onFinish(values, simpleForm)}
      style={{ width: '100%', height: '4rem' }}
    >
      <Form.Item name={itemName} style={{ width: '49.5%' }} rules={rules}>
        <Input
          data-testid="simple-form-input"
          placeholder={placeholderMessage}
          allowClear
        />
      </Form.Item>
      <Form.Item style={{ width: '90px' }}>
        <Button
          data-testid="simple-form-add-button"
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
        >
          Adicionar
        </Button>
      </Form.Item>
    </Form>
  );
}

SimpleAddForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  placeholderMessage: PropTypes.string,
  rules: PropTypes.array,
};

SimpleAddForm.defaultProps = {
  placeholderMessage: 'Adicionar Item',
  rules: [
    {
      type: 'string',
      min: 3,
      required: true,
    },
  ],
};

/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import { PrivateRoute } from '~/components';
import routes from '~/config/routes';

export const Authenticated = props => {
  const authenticatedRoutes = _.filter(
    routes,
    r => r.onlyAuthorized || r.onlyAuthorized === 'both',
  );
  return (
    <Switch>
      {authenticatedRoutes?.map((route, index) => (
        <PrivateRoute
          key={index.toString()}
          path={route.path}
          exact={route.exact}
          component={route.view}
          profiles={route.profilesAuthorized}
          pageInfo={route.pageInfo}
        />
      ))}
    </Switch>
  );
};

export const AuthenticatedRole = props => {
  const authenticatedRoutes = _.filter(
    routes,
    r => r.role.includes('leader') || r.onlyAuthorized === 'both',
  );

  return (
    <Switch>
      {authenticatedRoutes?.map((route, index) => (
        <PrivateRoute
          key={index.toString()}
          path={route.path}
          exact={route.exact}
          component={route.view}
          profiles={route.profilesAuthorized}
          pageInfo={route.pageInfo}
        />
      ))}
    </Switch>
  );
};

export const Anonymous = props => {
  const anonymousRoutes = _.filter(
    routes,
    r => !r.onlyAuthorized || r.onlyAuthorized === 'both',
  );
  return (
    <Switch>
      {anonymousRoutes?.map((route, index) => (
        <Route
          key={index.toString()}
          path={route.path}
          exact={route.exact}
          component={route.view}
        />
      ))}
    </Switch>
  );
};
export default { Authenticated, Anonymous, AuthenticatedRole };

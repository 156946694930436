import { createReducer, createAction } from '@reduxjs/toolkit';
import { people } from '~/constants';

const INITIAL_STATE = {
  people: [],
  person: {},
  total: 0,
  soluter: {
    competences: [],
    districts: [],
    projects: [],
    towers: [],
    interviews: [],
    badges: [],
  },
  week: { start: '', end: '' },
  page: 1,
  search: false,
  searchTitle: '',
  selectedCostCenter: [],
  selectedPractice: '',
  advancedSearch: {
    skills: [],
    seniorities: [],
    skillsOperator: 'or',
    leader: '',
    highPotential: '',
    onlyDismissed: false,
    competence: '',
    haveCompetence: false,
    primaryTech: [],
    primaryRole: '',
    notAllocated: false,
  },
  csvData: null,
};

export const get = createAction('PEOPLE_GET');
export const search = createAction('PEOPLE_SEARCH');
export const getSoluter = createAction('GET_SOLUTER');
export const clearSoluter = createAction('CLEAR_SOLUTER');
export const updateSoluterPhoto = createAction('UPDATE_SOLUTER_PHOTO');
export const setWeekInterval = createAction('PEOPLE_SET_INTERVAL');
export const setSelectedPractice = createAction('PEOPLE_SET_SELECTED_PRACTICE');
export const setSolutersCostCenters = createAction(
  'PEOPLE_SET_SOLUTERS_COST_CENTERS',
);
export const setSingleSoluterCostCenter = createAction(
  'PEOPLE_SET_SOLUTER_COST_CENTERS',
);
export const setSoluterCompetences = createAction(
  'PEOPLE_SET_SOLUTER_COMPETENCES',
);
export const setSoluterDistricts = createAction('PEOPLE_SET_SOLUTER_DISTRICTS');
export const setSoluterProjects = createAction('PEOPLE_SET_SOLUTER_PROJECTS');
export const setSoluterTowers = createAction('PEOPLE_SET_SOLUTER_TOWERS');
export const updateSoluterDistrictThumb = createAction(
  'PEOPLE_UPDATE_SOLUTER_DISTRICT_THUMB',
);
export const updateSoluterTowerPhoto = createAction(
  'PEOPLE_UPDATE_SOLUTER_TOWER_PHOTO',
);
export const setSelectedCostCenter = createAction(
  'PEOPLE_SET_SELECTED_COST_CENTER',
);
export const removeSelectedCostCenter = createAction(
  'PEOPLE_REMOVE_SELECTED_COST_CENTER',
);
export const setAdvancedSearchFilters = createAction(
  'PEOPLE_SET_ADVANCED_SEARCH',
);
export const clearAdvancedSearchFilters = createAction(
  'PEOPLE_CLEAR_ADVANCED_SEARCH',
);
export const removeAdvancedSearchFilter = createAction(
  'PEOPLE_REMOVE_ADVANCED_SEARCH_FILTER',
);
export const getCsvData = createAction('PEOPLE_GET_CSV_DATA');
export const getExitInterviews = createAction('PEOPLE_GET_SOLUTER_INTERVIEWS');
export const clearCsvData = createAction('PEOPLE_CLEAR_CSV_DATA');
export const updateSoluterBadgePhotoById = createAction(
  'PEOPLE_UPDATE_SOLUTER_PHOTO',
);

export default createReducer(INITIAL_STATE, {
  [get.type]: (state, action) => ({
    ...state,
    people: action.payload.content,
    total: action.payload.total,
    page: action.payload.page ? action.payload.page : state.page,
  }),
  [search.type]: (state, action) => ({
    ...state,
    search: action.payload.search,
    searchTitle: action.payload.searchTitle,
  }),
  [getSoluter.type]: (state, action) => ({
    ...state,
    soluter: { ...state.soluter, ...action.payload },
  }),
  [clearSoluter.type]: (state, action) => ({
    ...state,
    soluter: {
      competences: [],
      districts: [],
      projects: [],
      towers: [],
      interviews: [],
      badges: [],
    },
    week: { start: '', end: '' },
  }),
  [updateSoluterPhoto.type]: (state, action) => ({
    ...state,
    people: state?.people?.map(person =>
      person.email === action.payload.email
        ? { ...person, thumb: action.payload.thumb }
        : person,
    ),
  }),
  [setWeekInterval.type]: (state, action) => ({
    ...state,
    week: action.payload,
  }),
  [setSelectedCostCenter.type]: (state, action) => ({
    ...state,
    selectedCostCenter: action.payload,
  }),
  [setAdvancedSearchFilters.type]: (state, action) => ({
    ...state,
    advancedSearch: {
      ...state.advancedSearch,
      skills: action.payload?.skills ?? [...state.advancedSearch.skills],
      seniorities: action.payload?.seniorities ?? [
        ...state.advancedSearch.seniorities,
      ],
      skillsOperator:
        action.payload?.skillsOperator ?? state.advancedSearch.skillsOperator,
      leader: action.payload?.leader,
      highPotential: action.payload?.highPotential,
      onlyDismissed: action.payload?.onlyDismissed,
      competence: action.payload?.competence,
      haveCompetence: action.payload?.haveCompetence,
      primaryTech: action.payload?.primaryTech ?? [
        ...state.advancedSearch.primaryTech,
      ],
      primaryRole: action.payload?.primaryRole,
      notAllocated: action.payload?.notAllocated,
    },
  }),
  [clearAdvancedSearchFilters.type]: (state, action) => ({
    ...state,
    advancedSearch: {
      skills: [],
      seniorities: [],
      skillsOperator: 'or',
      leader: '',
      highPotential: '',
      onlyDismissed: false,
      competence: '',
      haveCompetence: false,
      primaryTech: [],
      primaryRole: '',
      notAllocated: false,
    },
  }),
  [removeAdvancedSearchFilter.type]: (state, action) => ({
    ...state,
    advancedSearch: {
      ...state.advancedSearch,
      skills: state.advancedSearch.skills?.filter(el => el !== action.payload),
      haveCompetence:
        action.payload === people.mapCompetenceFilterModes.ONLY_WITH_COMPETENCES
          ? false
          : state.advancedSearch.haveCompetence,
      primaryTech: state.advancedSearch.primaryTech?.filter(
        el => el !== action.payload,
      ),
      primaryRole:
        action.payload === state.advancedSearch.primaryRole
          ? ''
          : state.advancedSearch.primaryRole,
      notAllocated:
        action.payload === state.advancedSearch.notAllocated
          ? ''
          : state.advancedSearch.notAllocated,
      competence:
        action.payload === state.advancedSearch.competence
          ? ''
          : state.advancedSearch.competence,
      seniorities: state.advancedSearch.seniorities?.filter(
        el => el !== action.payload,
      ),
      leader:
        action.payload === people.categories.LEADER
          ? ''
          : state.advancedSearch.leader,
      highPotential:
        action.payload === people.categories.HIGH_POTENTIAL
          ? ''
          : state.advancedSearch.highPotential,
      onlyDismissed:
        action.payload === people.categories.DISMISSED
          ? false
          : state.advancedSearch.onlyDismissed,
    },
  }),
  [setSelectedPractice.type]: (state, action) => ({
    ...state,
    selectedPractice: action.payload,
  }),
  [removeSelectedCostCenter.type]: (state, action) => ({
    ...state,
    selectedCostCenter: state.selectedCostCenter.filter(
      el => el !== action.payload,
    ),
  }),
  [setSolutersCostCenters.type]: (state, action) => ({
    ...state,
    people: state?.people?.map(person => {
      const userCostCenter = action.payload.filter(
        costCenters => costCenters.id === person.email,
      )[0];

      return userCostCenter
        ? {
            ...person,
            costCenter: userCostCenter.costCenter,
            costCenterId: userCostCenter.costCenterId,
          }
        : person;
    }),
  }),
  [setSingleSoluterCostCenter.type]: (state, action) => ({
    ...state,
    soluter: {
      ...state.soluter,
      costCenter: action.payload[0].costCenter,
      costCenterId: action.payload[0].costCenterId,
    },
  }),
  [setSoluterCompetences.type]: (state, action) => ({
    ...state,
    soluter: {
      ...state.soluter,
      competences: action.payload,
    },
  }),
  [setSoluterDistricts.type]: (state, action) => ({
    ...state,
    soluter: {
      ...state.soluter,
      districts: action.payload,
    },
  }),
  [updateSoluterDistrictThumb.type]: (state, action) => ({
    ...state,
    soluter: {
      ...state.soluter,
      districts: state.soluter?.districts?.map(district =>
        district.id === action.payload.id
          ? { ...district, thumb: action.payload.thumb }
          : district,
      ),
    },
  }),
  [setSoluterProjects.type]: (state, action) => ({
    ...state,
    soluter: {
      ...state.soluter,
      projects: action.payload,
    },
  }),
  [updateSoluterTowerPhoto.type]: (state, action) => ({
    ...state,
    soluter: {
      ...state.soluter,
      towers: state.soluter?.towers?.map(tower =>
        tower.id === action.payload.id
          ? { ...tower, thumb: action.payload.thumb }
          : tower,
      ),
    },
  }),
  [setSoluterTowers.type]: (state, action) => ({
    ...state,
    soluter: {
      ...state.soluter,
      towers: action.payload,
    },
  }),
  [getCsvData.type]: (state, action) => ({
    ...state,
    csvData: action.payload,
  }),
  [clearCsvData.type]: (state, action) => ({
    ...state,
    csvData: null,
  }),
  [getExitInterviews.type]: (state, action) => ({
    ...state,
    soluter: { ...state.soluter, interviews: action.payload },
  }),
  [updateSoluterBadgePhotoById.type]: (state, action) => ({
    ...state,
    soluter: {
      ...state.soluter,
      badges: state.soluter?.badges.map(badge =>
        badge.badgeId === action.payload.badgeId ? action.payload : badge,
      ),
    },
  }),
});

/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { microsoftProvider } from '~/providers/microsoft';
import { selectLoadingState } from '~/store/selectors/loading';
import { setToken } from '~/config/environment';

import PassaporteLogo from '~/assets/images/passaporte_texto_logo.svg';
import SolutisLogo from '~/assets/images/logo_solutis.svg';
import MicrosoftColorido from '~/assets/images/login_microsoft_colorido.svg';
import MicrosoftBranco from '~/assets/images/login_microsoft_branco.svg';

import './Login.scss';

const LoginForm = () => {
  const [mouseOn, setMouseOn] = useState(false);
  const { isLoading } = useSelector(selectLoadingState);

  useEffect(() => {
    setToken('');
  }, []);

  const onClickLogin = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      window.location.href = microsoftProvider.buildAuthorizeUrl();
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Row className="login" type="flex" justify="center" align="middle">
        <Col className="login-container">
          <img
            src={PassaporteLogo}
            alt="Logo Passaporte"
            style={{ width: 275, marginTop: 85 }}
          />

          <Form
            onFinish={onClickLogin}
            className="login-form"
            layout="vertical"
          >
            <Form.Item>
              <Button
                htmlType="submit"
                onMouseEnter={() => setMouseOn(!mouseOn)}
                onMouseLeave={() => setMouseOn(!mouseOn)}
                style={{
                  height: 'auto',
                  padding: 10,
                  borderRadius: 15,
                  background: !mouseOn
                    ? 'white'
                    : '#1067FB 0% 0% no-repeat padding-box',
                }}
              >
                {isLoading ? (
                  <Spin spinning={isLoading} />
                ) : !mouseOn ? (
                  <img
                    src={MicrosoftColorido}
                    className="botao-login"
                    alt="Faça login com a Microsoft"
                  />
                ) : (
                  <img
                    src={MicrosoftBranco}
                    className="botao-login"
                    alt="Faça login com a Microsoft"
                  />
                )}
              </Button>
            </Form.Item>
          </Form>

          <div>
            <p style={{ fontSize: 12, color: '#596D86' }}>Powered by</p>
            <img
              src={SolutisLogo}
              alt="Logo Solutis"
              style={{ width: '60px' }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LoginForm;

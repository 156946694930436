import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  admissions: {
    MONTH: { total: '', users: [] },
    WEEK: { total: '', users: [] },
    LAST_MONTH: { total: '', users: [] },
  },
  solutersBySeniority: {
    groupingMode: 'role',
    unallocated: false,
    data: [],
    solutersList: [],
    solutersListAmount: 0,
  },
  solutersBySkills: {
    selectedSkills: null,
    skillsTags: [],
    skillsSearch: [],
    skillSoluters: [],
  },
  allocationIndexGraph: {
    index: 0,
    data: [],
    csvData: null,
  },
  passportUsage: { data: [], csvData: null },
  soluterJourney: {
    data: [],
    solutersList: [],
    solutersListAmount: 0,
    selectedCategory: 'Soluters',
    totalSolutersWithJourney: 0,
    currentCategoryTotal: 0,
    peopleTotal: 0,
  },
  solutersMap: { map: [], locationType: 'CITY', total: 0 },
  selectedCostCenter: '',
  selectedPractice: '',
  selectedTower: '',
  selectedTowerName: '',
};

export const getSolutersBySeniorityData = createAction(
  'GET_INDICATORS_SOLUTERS_BY_SENIORITY',
);
export const getSolutersBySeniorityList = createAction(
  'GET_INDICATORS_SOLUTERS_BY_SENIORITY_LIST',
);
export const setSolutersBySeniorityGrouping = createAction(
  'SET_INDICATORS_SOLUTERS_BY_SENIORITY_GROUPING',
);
export const setSolutersBySeniorityAllocated = createAction(
  'SET_INDICATORS_SOLUTERS_BY_SENIORITY_ALLOCATED',
);
export const getAllocationIndexGraph = createAction(
  'GET_INDICATORS_ALLOCATION_INDEX_GRAPH',
);
export const getAllocationCsvData = createAction(
  'GET_INDICATORS_ALLOCATION_CSV_DATA',
);
export const clearAllocationCsvData = createAction(
  'INDICATORS_CLEAR_ALLOCATION_CSV_DATA',
);
export const getPassportUsageCsvData = createAction(
  'GET_INDICATORS_PASSPORT_USAGE_CSV',
);
export const setPassportUsage = createAction('SET_INDICATORS_PASSPORT_USAGE');
export const clearPassportUsageCsvData = createAction(
  'INDICATORS_CLEAR_PASSPORT_USAGE_CSV_DATA',
);
export const getSoluterJourneyData = createAction(
  'GET_INDICATORS_SOLUTER_JOURNEY_DATA',
);
export const getSoluterJourneyList = createAction(
  'GET_INDICATORS_SOLUTER_JOURNEY_LIST',
);
export const setSoluterJourneyCategory = createAction(
  'SET_INDICATORS_SOLUTER_JOURNEY_CATEGORY',
);
export const setSoluterJourneyTotalPeople = createAction(
  'SET_INDICATORS_TOTAL_PEOPLE',
);
export const setSelectedCostCenter = createAction(
  'SET_INDICATORS_SELECTED_COST_CENTER',
);
export const removeSelectedCostCenter = createAction(
  'REMOVE_INCIDATORS_SELECT_COST_CENTER',
);
export const setSelectedTower = createAction('SET_INDICATORS_SELECTED_TOWER');
export const setSkillsTags = createAction('SET_SKILLS_TAGS');
export const setSkillsSearch = createAction('SET_SKILLS_SEARCH');
export const setSkillSoluters = createAction('SET_SKILL_SOLUTERS');
export const setSelectedPractice = createAction(
  'SET_INDICATORS_SELECTED_PRACTICE',
);
export const setAdmissions = createAction('SET_LAST_HIRED');
export const setSelectedSkills = createAction('SET_SELECTED_SKILLS');
export const setSolutersMap = createAction('SET_SOLUTERS_MAP');
export const setMapLocationType = createAction('SET_MAP_LOCATION_TYPE');

export default createReducer(INITIAL_STATE, {
  [getAllocationIndexGraph.type]: (state, action) => ({
    ...state,
    allocationIndexGraph: { ...state.allocationIndexGraph, ...action.payload },
  }),
  [getAllocationCsvData.type]: (state, action) => ({
    ...state,
    allocationIndexGraph: {
      ...state.allocationIndexGraph,
      csvData: action.payload,
    },
  }),
  [clearAllocationCsvData.type]: (state, action) => ({
    ...state,
    allocationIndexGraph: { ...state.allocationIndexGraph, csvData: null },
  }),
  [getSolutersBySeniorityData.type]: (state, action) => ({
    ...state,
    solutersBySeniority: { ...state.solutersBySeniority, data: action.payload },
  }),
  [getSolutersBySeniorityList.type]: (state, action) => ({
    ...state,
    solutersBySeniority: {
      ...state.solutersBySeniority,
      solutersList: action.payload ? action.payload.content : '',
      solutersListAmount: action.payload ? action.payload.total : 0,
    },
  }),
  [setSolutersBySeniorityGrouping.type]: (state, action) => ({
    ...state,
    solutersBySeniority: {
      ...state.solutersBySeniority,
      groupingMode: action.payload,
    },
  }),
  [setSolutersBySeniorityAllocated.type]: (state, action) => ({
    ...state,
    solutersBySeniority: {
      ...state.solutersBySeniority,
      unallocated: action.payload,
    },
  }),
  [setPassportUsage.type]: (state, action) => ({
    ...state,
    passportUsage: { ...state.passportUsage, data: action.payload },
  }),
  [getPassportUsageCsvData.type]: (state, action) => ({
    ...state,
    passportUsage: { ...state.passportUsage, csvData: action.payload },
  }),
  [clearPassportUsageCsvData.type]: (state, action) => ({
    ...state,
    passportUsage: { ...state.passportUsage, csvData: null },
  }),
  [getSoluterJourneyData.type]: (state, action) => ({
    ...state,
    soluterJourney: {
      ...state.soluterJourney,
      data: action.payload?.data,
      totalSolutersWithJourney: action.payload?.total,
    },
  }),
  [setSoluterJourneyTotalPeople.type]: (state, action) => ({
    ...state,
    soluterJourney: { ...state.soluterJourney, peopleTotal: action.payload },
  }),
  [getSoluterJourneyList.type]: (state, action) => ({
    ...state,
    soluterJourney: {
      ...state.soluterJourney,
      solutersList: action.payload ? action.payload.content : '',
      solutersListAmount: action.payload ? action.payload.total : 0,
    },
  }),
  [setSoluterJourneyCategory.type]: (state, action) => ({
    ...state,
    soluterJourney: {
      ...state.soluterJourney,
      selectedCategory: action.payload || '',
    },
  }),
  [setSelectedCostCenter.type]: (state, action) => ({
    ...state,
    selectedCostCenter: action.payload,
  }),
  [setSelectedPractice.type]: (state, action) => ({
    ...state,
    selectedPractice: action.payload,
  }),
  [setSelectedTower.type]: (state, action) => ({
    ...state,
    selectedTower: action.payload.value,
    selectedTowerName: action.payload.label,
  }),
  [setSkillsTags.type]: (state, action) => ({
    ...state,
    solutersBySkills: {
      ...state.solutersBySkills,
      skillsTags: action.payload,
    },
  }),
  [setSkillsSearch.type]: (state, action) => ({
    ...state,
    solutersBySkills: {
      ...state.solutersBySkills,
      skillsTags: action.payload.tags,
      skillsSearch: action.payload.search,
    },
  }),
  [setSkillSoluters.type]: (state, action) => ({
    ...state,
    solutersBySkills: {
      ...state.solutersBySkills,
      skillSoluters: action.payload,
    },
  }),
  [removeSelectedCostCenter.type]: (state, action) => ({
    ...state,
    selectedCostCenter: state.selectedCostCenter.filter(
      el => el !== action.payload,
    ),
  }),
  [setSelectedSkills.type]: (state, action) => ({
    ...state,
    solutersBySkills: {
      ...state.solutersBySkills,
      selectedSkills: action.payload,
    },
  }),
  [setAdmissions.type]: (state, action) => ({
    ...state,
    admissions: {
      ...state.admissions,
      [action.payload.period]: action.payload.payload,
    },
  }),
  [setSolutersMap.type]: (state, action) => ({
    ...state,
    solutersMap: {
      ...state.solutersMap,
      map: action.payload?.content,
      total: action.payload?.total,
    },
  }),
  [setMapLocationType.type]: (state, action) => ({
    ...state,
    solutersMap: { ...state.solutersMap, locationType: action.payload },
  }),
});

/* eslint-disable no-console */
import { Modal, Form, Button } from 'antd';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { validationMessages } from '~/constants';

export default function ModalForm({
  handleSubmit,
  children,
  button,
  title,
  okButtonText,
  buttonType,
}) {
  const [isVisible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const _handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const childrenWithProps = React.Children?.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { form });
    }
    return child;
  });

  return (
    <>
      <Button
        type={buttonType}
        onClick={e => {
          e.stopPropagation();
          setVisible(true);
        }}
      >
        {button}
      </Button>
      <Modal
        title={title}
        visible={isVisible}
        onCancel={_handleCancel}
        okText={okButtonText}
        cancelText="Cancelar"
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              setVisible(false);
              handleSubmit(values);
              form.resetFields();
            })
            .catch(console.error);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark
          onFinish={handleSubmit}
          validateMessages={validationMessages.VALIDATION_MESSAGE}
        >
          {childrenWithProps}
        </Form>
      </Modal>
    </>
  );
}

ModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  button: PropTypes.any,
  title: PropTypes.string,
  okButtonText: PropTypes.string,
  buttonType: PropTypes.string,
};

ModalForm.defaultProps = {
  button: 'Adicionar',
  title: '',
  okButtonText: 'Adicionar',
  buttonType: 'link',
};

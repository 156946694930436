import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as fetchBadges from '~/store/fetchActions/fetchBadges';
import * as fetchPractices from '~/store/fetchActions/fetchPractices';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import * as fetchSuggestions from '~/store/fetchActions/fetchSuggestions';
import {
  selectUpateBadgeNotification,
  selectBadgeNotification,
  selectSuggestionsState,
  selectPracticeCCNoGroup,
  selectPracticeCCNoBu,
  selectTowersNoBu,
} from '~/store/selectors';

export default function useNotificationData() {
  const usersNotifications = useSelector(selectBadgeNotification);
  const costCentersNoGroup = useSelector(selectPracticeCCNoGroup);
  const costCentersNoBu = useSelector(selectPracticeCCNoBu);
  const towersNoBu = useSelector(selectTowersNoBu);
  const suggestions = useSelector(selectSuggestionsState);
  const updateBadge = useSelector(selectUpateBadgeNotification);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBadges.getUnvalidatedUser());
  }, [dispatch, updateBadge]);

  useEffect(() => {
    dispatch(fetchPractices.getCostCentersNoBus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPractices.getCostCentersNoGroup());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTowers.getTowersNoBus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSuggestions.get());
  }, [dispatch]);

  const calcIndex = () => {
    let index = 0;

    if (costCentersNoBu?.length > 0) index += 1;
    if (costCentersNoGroup?.length > 0) index += 1;
    if (towersNoBu > 0) index += 1;
    if (suggestions?.length > 0) index += 1;
    if (usersNotifications?.length > 0) index += usersNotifications?.length;

    return index;
  };

  return {
    usersNotifications,
    costCentersNoBu,
    costCentersNoGroup,
    towersNoBu,
    suggestions,
    calcIndex,
  };
}

import { useRef, useEffect } from 'react';
import { Input, Button, Space, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { validationMessages } from '~/constants';

const { Search } = Input;

export default function SearchBar({
  title,
  actionGetByProperty,
  actionGetAll,
  actionGetAllProps,
  actionSearch,
  customStyles,
  hasValidationRules,
  currentSearch,
  shouldFetchOnSearch,
  isSimpleSearchBar,
  toggleClear,
  disabled,
}) {
  const [searchForm] = Form.useForm();
  const searchInput = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    searchInput.current.input.value = '';
    searchInput.current.state.value = '';
    searchForm.resetFields();
  }, [searchForm, toggleClear]);

  const handleClean = () => {
    searchInput.current.input.value = '';
    searchInput.current.state.value = '';
    searchForm.resetFields();

    if (shouldFetchOnSearch) dispatch(actionGetAll(...actionGetAllProps, 1));

    dispatch(
      actionSearch({
        search: false,
        searchTitle: '',
      }),
    );
  };

  const handleSearch = value => {
    if (value === '') handleClean();
    else if (hasValidationRules ? value?.length > 2 : value) {
      if (shouldFetchOnSearch)
        dispatch(actionGetByProperty(searchInput?.current?.state?.value, 1));

      dispatch(
        actionSearch({
          search: true,
          searchTitle: searchInput?.current?.state?.value,
        }),
      );
    }
  };

  return (
    <div
      className="search-popup"
      style={{
        padding: customStyles.padding,
        display: customStyles.display,
        marginBottom: customStyles.containerMarginBottom,
      }}
    >
      <Form
        form={searchForm}
        layout={customStyles.layout}
        validateMessages={validationMessages.VALIDATION_MESSAGE}
      >
        <Form.Item
          style={{
            marginBottom: customStyles.layout === 'horizontal' ? '0px' : '',
          }}
          name="O termo buscado"
          rules={[
            hasValidationRules
              ? {
                  type: 'string',
                  min: 3,
                }
              : {},
          ]}
        >
          {isSimpleSearchBar ? (
            <Search
              ref={searchInput}
              placeholder={title}
              defaultValue={currentSearch || null}
              data-testid="search-input"
              style={{
                width: customStyles.inputWidth,
                marginBottom: customStyles.inputMargin,
                marginRight: customStyles.inputMarginRight,
              }}
              allowClear
              onSearch={handleSearch}
              enterButton
              disabled={disabled}
            />
          ) : (
            <Input
              ref={searchInput}
              placeholder={title}
              defaultValue={currentSearch || null}
              data-testid="search-input"
              style={{
                width: customStyles.inputWidth,
                marginBottom: customStyles.inputMargin,
                marginRight: customStyles.inputMarginRight,
                display: 'block',
              }}
              onPressEnter={handleSearch}
            />
          )}{' '}
        </Form.Item>
        {!isSimpleSearchBar && (
          <Form.Item
            style={{
              marginBottom: customStyles.layout === 'horizontal' ? '0px' : '',
            }}
          >
            <Space style={{ width: '100%' }}>
              <Button
                shape="round"
                type="primary"
                data-testid="search-button"
                icon={<SearchOutlined />}
                onClick={handleSearch}
              >
                Pesquisar
              </Button>
              <Button
                shape="round"
                onClick={handleClean}
                data-testid="clear-search-button"
              >
                Limpar
              </Button>
            </Space>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

SearchBar.propTypes = {
  title: PropTypes.string,
  actionGetByProperty: PropTypes.func.isRequired,
  actionGetAll: PropTypes.func.isRequired,
  actionGetAllProps: PropTypes.array,
  actionSearch: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  currentSearch: PropTypes.string,
  hasValidationRules: PropTypes.bool,
  shouldFetchOnSearch: PropTypes.bool,
  isSimpleSearchBar: PropTypes.bool,
  toggleClear: PropTypes.bool,
  disabled: PropTypes.bool,
};

SearchBar.defaultProps = {
  title: 'Pesquisar',
  customStyles: {
    padding: 8,
    display: 'block',
    inputMargin: 8,
    inputMarginRight: 0,
    inputWidth: 188,
    containerMarginBottom: 8,
    layout: 'horizontal',
  },
  actionGetAllProps: [],
  currentSearch: null,
  hasValidationRules: false,
  shouldFetchOnSearch: true,
  isSimpleSearchBar: false,
  toggleClear: false,
  disabled: false,
};

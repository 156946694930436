import axios from 'axios';

const _instance = null;

const getInstance = () => {
  if (_instance !== null) return _instance;

  const newInstance = axios.create({
    baseURL:
      process.env.REACT_APP_API_BASE_URL ||
      'https://gateway-passport-ads.dev.internal.solutis.xyz/w/v1',
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 1000 * 30,
  });

  return newInstance;
};

export default getInstance();

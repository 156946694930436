/* eslint-disable no-console */
import { Link } from 'react-router-dom';
import { Button as AntdButton, Tooltip } from 'antd';
import { PlusCircleOutlined, CarryOutOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Button } from '~/components';
import { removeTeamMember } from '~/store/fetchActions/fetchTowers';
import './styles.scss';

export default function TableActions({
  record,
  currentProject,
  reload,
  selectedTower,
  addAllocation,
  showEditMemberForm,
  showVacationForm,
}) {
  return (
    <>
      <Link
        onClick={() => {
          showEditMemberForm(currentProject, record);
        }}
      >
        <Button
          title={record.person}
          id={currentProject}
          tooltipIdentifier="Membro"
          type="edit"
        />
      </Link>
      <Button
        handleDelete={async projectId => {
          const project = { groupId: selectedTower, _id: projectId };

          try {
            await removeTeamMember(project, record.id || record._id);
            reload();
          } catch (error) {
            console.log(error);
          }
        }}
        tooltipIdentifier="Membro"
        title={record.person}
        id={currentProject.id}
        style={{ marginLeft: 3 }}
        type="delete"
      />

      <Tooltip title="Adicionar Alocação" trigger="hover">
        <AntdButton
          data-testid="add-allocation-icon"
          icon={<PlusCircleOutlined />}
          className="add-allocation-project"
          type="text"
          onClick={() => {
            addAllocation({ ...record, projectId: currentProject.id }, []);
          }}
          style={{ marginLeft: 3 }}
        />
      </Tooltip>
      <Tooltip title="Registrar Férias" trigger="hover">
        <AntdButton
          data-testid="add-vacation-icon"
          icon={<CarryOutOutlined />}
          className="add-vacation-project"
          type="text"
          onClick={() => {
            showVacationForm(record, []);
          }}
          style={{ marginLeft: 3 }}
          disabled={!record?.personKey}
        />
      </Tooltip>
    </>
  );
}

TableActions.propTypes = {
  record: PropTypes.object.isRequired,
  currentProject: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  selectedTower: PropTypes.string.isRequired,
  addAllocation: PropTypes.func.isRequired,
  showEditMemberForm: PropTypes.func.isRequired,
  showVacationForm: PropTypes.func.isRequired,
};

import { RocketOutlined } from '@ant-design/icons';
import './styles.scss';
import PropTypes from 'prop-types';

export default function HighPotentialBadge({
  active,
  size,
  additionalClassNames,
}) {
  return (
    <RocketOutlined
      className={`soluter-profile__badge ${additionalClassNames} ${
        active
          ? 'soluter-profile__badge--high-potential'
          : 'soluter-profile__badge--inactive'
      }`}
      style={{
        width: size,
        height: size,
        fontSize: Math.ceil(size / 2) + 10,
      }}
    />
  );
}

HighPotentialBadge.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
  additionalClassNames: PropTypes.string,
};

HighPotentialBadge.defaultProps = {
  active: false,
  size: 40,
  additionalClassNames: '',
};

/* eslint-disable react/no-array-index-key */
import { Drawer, Col, List, Row, Empty, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag, EmptyErrorWrapper, SoluterName } from '~/components';
import { getSkillSoluters } from '~/store/fetchActions/fetchIndicators';
import { colorBySeniority, textColorBySeniority } from '~/constants/indicators';

export default function SkillSolutersDrawer({
  drawerSkill,
  setDrawerVisible,
  drawerVisible,
}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { skillSoluters } = useSelector(
    state => state.indicators.solutersBySkills,
  );
  const { isLoadingSkillSoluters } = useSelector(state => state.loading);
  const { selectedCostCenter, selectedPractice } = useSelector(
    state => state.indicators,
  );
  useEffect(() => {
    if (drawerVisible) setPage(1);
  }, [drawerVisible]);

  useEffect(() => {
    dispatch(
      getSkillSoluters(drawerSkill, page, selectedCostCenter, selectedPractice),
    );
  }, [dispatch, drawerSkill, page, selectedCostCenter, selectedPractice]);
  return (
    <Drawer
      title={drawerSkill}
      placement="right"
      closable={false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
      width="42vw"
    >
      <EmptyErrorWrapper
        data={skillSoluters}
        emptyCondition={skillSoluters}
        loaderTitle="isLoadingSolutersList"
        retryAction={getSkillSoluters}
        retryProps={[drawerSkill]}
        style={{ fontSize: 'x-small' }}
      >
        <Spin spinning={isLoadingSkillSoluters}>
          <List
            size="large"
            dataSource={skillSoluters.content}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Não há Soluters nesta categoria."
                />
              ),
            }}
            pagination={{
              current: page,
              pageSize: 10,
              pageSizeOptions: [],
              showSizeChanger: false,
              total: skillSoluters.total,
              hideOnSinglePage: true,
              onChange: _page => {
                setPage(_page);
              },
            }}
            renderItem={soluter => (
              <List.Item>
                <Row
                  style={{ width: '100%' }}
                  justify="space-between"
                  align="middle"
                >
                  <Col span={18}>
                    <SoluterName name={soluter.name} email={soluter.email} />
                  </Col>

                  <Col span={6}>
                    <Tag
                      tag={{
                        tag:
                          soluter.seniority === 'Senioridade não registrada'
                            ? 'Sem registro'
                            : soluter.seniority,
                      }}
                      backgroundColor={colorBySeniority[soluter.seniority]}
                      textColor={textColorBySeniority[soluter.seniority]}
                    />
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Spin>
      </EmptyErrorWrapper>
    </Drawer>
  );
}

SkillSolutersDrawer.propTypes = {
  drawerSkill: PropTypes.string.isRequired,
  setDrawerVisible: PropTypes.func.isRequired,
  drawerVisible: PropTypes.bool.isRequired,
};

import { Select, Row, Button, DatePicker } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { AddProjectForm } from '~/container';

const { Option } = Select;

export default function ProjectsHeader({
  orderBy,
  setOrderBy,
  isAscOrder,
  setIsAscOrder,
  year,
  setYear,
  tower,
  towerId,
}) {
  return (
    <Row align="middle" justify="end" className="mb-sm">
      <p className="mb-0 mr-sm">Ordenação:</p>
      <Select
        data-testid="select-projects-sort-by"
        value={orderBy}
        style={{ width: '195px' }}
        onChange={value => {
          setOrderBy(value);
        }}
      >
        <Option data-testid="sort-projects-by-name" value="name">
          Alfabética
        </Option>
        <Option data-testid="sort-projects-by-end-date" value="endDate">
          Data final do projeto
        </Option>
        <Option data-testid="sort-projects-by-start-date" value="startDate">
          Data inicial do projeto
        </Option>
      </Select>
      <Button
        data-testid="sort-projects-button"
        type="text"
        onClick={() => setIsAscOrder(!isAscOrder)}
        size="large"
        icon={isAscOrder ? <CaretUpOutlined /> : <CaretDownOutlined />}
      />
      <DatePicker
        onChange={value => {
          const currentYear = value ? moment(value).format('YYYY') : '';
          setYear(currentYear);
        }}
        data-testid="projects-year-picker"
        placeholder="Selecionar ano"
        defaultValue={moment(year.toString())}
        picker="year"
        style={{ marginRight: 10 }}
        inputReadOnly
      />
      <AddProjectForm towerId={towerId} tower={tower} year={year} />
    </Row>
  );
}

ProjectsHeader.propTypes = {
  orderBy: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  isAscOrder: PropTypes.bool.isRequired,
  setIsAscOrder: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  setYear: PropTypes.func.isRequired,
  tower: PropTypes.object.isRequired,
  towerId: PropTypes.string.isRequired,
};

/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { RightOutlined, LeftOutlined, CrownFilled } from '@ant-design/icons';
import { Table, Empty, Tooltip, Modal, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CustomAllocationForm, VacationForm } from '~/container';
import SchedulerBar from '../../Towers/Scheduler/SchedulerBar';
import TableActions from '../../Towers/Scheduler/TableActions';
import { colors, scheduler } from '~/constants';
import Allocation from '~/helpers/Allocation';
import { getRole } from '~/config/environment';
import { getBarStyles } from '~/helpers/scheduler';
import HeaderAddProject from '~/container/People/ComponentsProject/HeaderAddProject';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import EditProjectModal from '~/container/People/ComponentsProject/EditProjectModal';
import { getSoluterProjects } from '~/store/ducks/soluterProject';
import { Button, Tag } from '~/components';

export default function SoluterProjects({ soluter }) {
  let currentProject = '';
  let startDate = '';
  let endDate = '';
  const { selectedTower } = useSelector(state => state.towers);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [isLoadingScheduler, setIsLoadingScheduler] = useState(false);
  const [editProject, setEditProject] = useState({});
  const [editPerson, setEditPerson] = useState({});
  const [eventRecord, setEventRecord] = useState({});
  const [allocationS, setAllocation] = useState({});
  const [allocationVisible, setAllocationVisible] = useState(false);
  const [isVacationFormVisible, setVacationFormVisible] = useState(false);
  const [technologiesList, setTechnologiesList] = useState(false);
  const [userName, setUserName] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [res, setRes] = useState([]);
  const dispatch = useDispatch();

  const formatDate = date => {
    return new Intl.DateTimeFormat('pt-BR').format(date);
  };

  const handleViewTechnologies = value => {
    setTechnologiesList(value?.technologies || []);
    setUserName(value?.person || '');
    setVisible(true);
  };

  const reset = () => {
    setVisible(false);
  };

  const renderAllocationByMonth = (month, record, index) => {
    if (record?.team) {
      return <div />;
    }
    let allocations = []; // Array de alocações

    if (record?.schedule?.length > 0)
      allocations = record?.schedule?.map(scheduleObject => {
        const allocation = new Allocation(scheduleObject, month, year);
        return allocation;
      });
    const content = allocations?.map(currentAllocation => {
      if (
        !currentAllocation?.isMonthAllocated() || // Se a alocação não inclui esse mês
        (currentAllocation?.getEndMonth() === scheduler.DECEMBER && // ou se o mês final da alocação é dezembro
          currentAllocation?.getEndDay() <= scheduler.MAX_DAYS_IN_MONTH && // em uma data <= 30
          index === scheduler.GO_FORWARD_ARROW_INDEX) // e a coluna sendo renderizada é a seta (>)
      )
        return <></>; // Retorna vazio

      return (
        <SchedulerBar
          tooltipTitle={`${formatDate(
            currentAllocation?.startDate,
          )} - ${formatDate(currentAllocation?.endDate)} (${
            currentAllocation?.isVacation
              ? 'Férias'
              : `${currentAllocation?.amount}%`
          })`}
          currentAllocation={currentAllocation}
          showEditor={(_eventRecord, _allocation) => {
            setEventRecord(_eventRecord);
            setAllocation(_allocation);
            setAllocationVisible(true);
          }}
          showVacationForm={(_eventRecord, _allocation) => {
            setEventRecord(_eventRecord);
            setAllocation(_allocation);
            setVacationFormVisible(true);
          }}
          customStyle={getBarStyles(currentAllocation, record?.active, index)}
          key={index}
          record={{
            ...record,
            projectId: currentProject?.id,
            startDate,
            endDate,
          }}
          columnIndex={index}
        />
      );
    });
    return <>{content}</>;
  };

  const getSchedule = useCallback(
    async (soluterEmail, _year, _activeMembers) => {
      setIsLoadingScheduler(true);
      let result = null;
      try {
        if (soluterEmail) {
          result = await fetchTowers.getScheduleSoluter(soluterEmail, _year);
          dispatch(getSoluterProjects(result));
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
      setRes(result);
      setIsLoadingScheduler(false);
    },
    [dispatch],
  );

  useEffect(() => {
    getSchedule(soluter.email, year);
  }, [year, getSchedule, soluter]);

  const customReload = () => {
    getSchedule(soluter.email, year);
  };
  const columns = [
    {
      title: 'Torre / Squad',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '255px',
      render(text, record, index) {
        const content =
          record?.personKey && getRole() === 'admin' ? (
            <Link to={`/soluters/${record?.personKey}`}>{record?.person}</Link>
          ) : (
            <p className="no-margin">{record?.person}</p>
          );

        if (!record?.team) {
          return (
            <div className="scheduler__name-column">
              {content}
              {record?.isLeader && (
                <CrownFilled
                  key="crown"
                  className="scheduler__crown"
                  data-testid="crown-filled-antd"
                />
              )}
            </div>
          );
        }

        return (
          <Tooltip
            key="name"
            title={`${moment(record?.startDate).format(
              'DD/MM/YYYY',
            )} - ${moment(record?.endDate).format('DD/MM/YYYY')}`}
          >
            {record?.group} / {record?.name}
          </Tooltip>
        );
      },
    },
    {
      title: 'Papel',
      dataIndex: 'mainRole',
      key: 'mainRole',
      fixed: 'left',
      width: '175px',
      render(text, record, index) {
        if (!record.team) {
          return record.mainRole || '-';
        }
        return text;
      },
    },
    {
      title: 'Tecnologias',
      dataIndex: 'technologies',
      key: 'technologies',
      fixed: 'left',
      width: '175px',
      render(text, record, index) {
        if (!record.team) {
          return (
            <>
              {record &&
              record?.technologies &&
              record?.technologies?.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <>
                    <Space size={[8, 16]} wrap>
                      <Tag
                        tag={{ tag: record?.technologies[0] }}
                        backgroundColor={colors.TECHNOLOGIES}
                        textColor="white"
                      />
                    </Space>

                    {record.technologies.length > 1 && (
                      <Link
                        onClick={() => handleViewTechnologies(record)}
                        data-testid="view-technologies"
                      >
                        <Button
                          type="view"
                          tooltipIdentifier="mais"
                          data-testid="view-technologies-button"
                        />
                      </Link>
                    )}
                  </>
                </div>
              ) : (
                '-'
              )}
            </>
          );
        }
        return text;
      },
    },
    {
      title: 'Ações',
      width: '120px',
      align: 'center',
      fixed: 'left',
      render(text, record, index) {
        if (record.team) return '';
        return (
          <div className="scheduler__table-actions">
            <TableActions
              key="action"
              record={{ ...record, startDate, endDate }}
              currentProject={currentProject}
              reload={customReload}
              selectedTower={selectedTower}
              addAllocation={(_eventRecord, _allocation) => {
                setEventRecord(_eventRecord);
                setAllocation(_allocation);
                setAllocationVisible(true);
              }}
              showEditMemberForm={(project, _record) => {
                setEditProject(project);
                setEditPerson(_record);
              }}
              showVacationForm={(_eventRecord, _allocation) => {
                setEventRecord(_eventRecord);
                setAllocation(_allocation);
                setVacationFormVisible(true);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Tooltip key="previous-year" title="Ano Anterior">
          <a
            data-testid="previous-year"
            onClick={() => {
              const nextYear = parseInt(year, 10) - 1;
              setYear(nextYear.toString());
            }}
          >
            <LeftOutlined />
          </a>
        </Tooltip>
      ),
      key: 'goBack',
      width: '20px',
      dataIndex: 'goBack',
      className: 'scheduler-cell scheduler-cell--arrow',
    },
    {
      title: 'Jan',
      align: 'center',
      dataIndex: 'Jan',
      key: 'Jan',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Jan', record);
      },
    },
    {
      title: 'Fev',
      align: 'center',
      dataIndex: 'Fev',
      key: 'Fev',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Fev', record);
      },
    },
    {
      title: 'Mar',
      align: 'center',
      dataIndex: 'Mar',
      key: 'Mar',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Mar', record);
      },
    },
    {
      title: 'Abr',
      align: 'center',
      dataIndex: 'Abr',
      key: 'Abr',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Abr', record);
      },
    },
    {
      title: 'Mai',
      align: 'center',
      dataIndex: 'Mai',
      key: 'Mai',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Mai', record);
      },
    },
    {
      title: 'Jun',
      align: 'center',
      dataIndex: 'Jun',
      key: 'Jun',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Jun', record);
      },
    },
    {
      title: 'Jul',
      align: 'center',
      dataIndex: 'Jul',
      key: 'Jul',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Jul', record);
      },
    },
    {
      title: 'Ago',
      align: 'center',
      dataIndex: 'Ago',
      key: 'Ago',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Ago', record);
      },
    },
    {
      title: 'Set',
      align: 'center',
      dataIndex: 'Set',
      key: 'Set',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Set', record);
      },
    },
    {
      title: 'Out',
      align: 'center',
      dataIndex: 'Out',
      key: 'Out',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Out', record);
      },
    },
    {
      title: 'Nov',
      align: 'center',
      dataIndex: 'Nov',
      key: 'Nov',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Nov', record);
      },
    },
    {
      title: 'Dez',
      align: 'center',
      dataIndex: 'Dez',
      key: 'Dez',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Dez', record);
      },
    },
    {
      title: (
        <Tooltip key="next-yeart" title="Ano Seguinte">
          <a
            key="next-year"
            data-testid="next-year"
            onClick={() => {
              const nextYear = parseInt(year, 10) + 1;
              setYear(nextYear.toString());
            }}
          >
            <RightOutlined />
          </a>
        </Tooltip>
      ),
      key: 'goForward',
      width: '30px',
      dataIndex: 'goForward',
      className: 'scheduler-cell scheduler-cell--arrow',
      render(text, record, index) {
        return renderAllocationByMonth('Dez', record);
      },
    },
  ];

  return (
    <>
      <HeaderAddProject
        selectedTower={selectedTower}
        reload={customReload}
        year={year}
        setYear={setYear}
        soluter={soluter}
      />

      <Table
        style={{ overflowX: 'hidden' }}
        scroll={{ x: 2300 }}
        rowKey="id"
        sticky
        columns={columns}
        loading={isLoadingScheduler}
        dataSource={res ? [...res] : []}
        pagination={false}
        expandable={{ childrenColumnName: 'team' }}
        onRow={(record, rowIndex) => {
          if (record.team) {
            currentProject = {
              name: record.name,
              id: record.id,
              projectId: record.groupId,
              team: record.team,
            };
            startDate = record?.startDate;
            endDate = record?.endDate;
          }
        }}
        locale={{
          emptyText: (
            <>
              <Empty
                description="Nenhum projeto registrado (ainda)."
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </>
          ),
        }}
        rowClassName={(record, index) => {
          return `custom-scheduler ${
            !('active' in record) || record.active || record.team
              ? ''
              : 'inactive-member'
          }`;
        }}
      />
      <CustomAllocationForm
        allocationVisible={allocationVisible}
        setAllocationVisible={setAllocationVisible}
        record={eventRecord}
        allocation={allocationS}
        reload={customReload}
      />
      <VacationForm
        personName={eventRecord?.person || ''}
        personEmail={eventRecord?.personKey || ''}
        isModalVisible={isVacationFormVisible}
        setModalVisible={setVacationFormVisible}
        allocation={allocationS}
        reload={customReload}
      />
      <EditProjectModal
        project={editProject}
        record={editPerson}
        reload={customReload}
      />
      <Modal
        title={`Tecnologias - ${userName}`}
        visible={isVisible}
        onCancel={reset}
        onOk={reset}
        footer=""
      >
        <Space size={[8, 16]} wrap>
          {technologiesList && technologiesList?.length > 0 ? (
            technologiesList?.map(tech => (
              <Tag
                tag={{ tag: tech }}
                backgroundColor={colors.TECHNOLOGIES}
                textColor="white"
              />
            ))
          ) : (
            <></>
          )}
        </Space>
      </Modal>
    </>
  );
}

SoluterProjects.propTypes = {
  soluter: PropTypes.object,
};

SoluterProjects.defaultProps = {
  soluter: '',
};

import React, { useCallback, useEffect, useMemo } from 'react';

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { message, Modal, Table, Typography } from 'antd';
import moment from 'moment';
import { getRole } from '~/config/environment';

import useCompetenceTableActions from './hooks/useCompetenceTableActions';
import { selectCompetenceState } from '~/store/selectors/competence';
import { getCompetences } from '~/store/fetchActions/fetchCompetences';
import { IconButton } from '~/components';
import AddEditCompetenceModal from './modal/AddEditCompetenceModal';
import { validationMessages } from '~/constants';

export default function CompetencesTable() {
  const { competences, loading, filter, total } = useSelector(
    selectCompetenceState,
  );
  const {
    businessUnit,
    tower,
    soluter,
    competence: filteredCompetenceName,
    valid,
  } = filter;

  const [modal, modalContextHolder] = Modal.useModal();
  const dispatch = useDispatch();
  const {
    competence: currentCompetence,
    isEditing,
    handleEditCompetence,
    handleDownloadCompetence,
    handleDeleteCompetence,
    clearCurrentCompetence,
  } = useCompetenceTableActions();

  const refreshCompetences = useCallback(() => {
    dispatch(
      getCompetences(
        businessUnit,
        tower,
        soluter,
        filteredCompetenceName,
        valid,
      ),
    );
  }, [dispatch, businessUnit, tower, soluter, filteredCompetenceName, valid]);

  const handleCloseCompetenceModal = useCallback(
    (hasSubmitted = false) => {
      clearCurrentCompetence();
      if (hasSubmitted === true) refreshCompetences();
    },
    [clearCurrentCompetence, refreshCompetences],
  );

  const handleClickDeleteCompetence = useCallback(
    competence => {
      const { title, user, id: competenceId } = competence;
      modal.confirm({
        title: 'Excluir Certificação',
        content: `Tem certeza que deseja excluir a certificação "${title}" de ${user}? Essa ação não poderá ser desfeita.`,
        okText: 'Sim, desejo excluir',
        cancelText: 'Cancelar',
        onOk: () =>
          handleDeleteCompetence(competenceId).then(() => {
            message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
            refreshCompetences();
          }),
        okButtonProps: {
          danger: true,
        },
      });
    },
    [modal, handleDeleteCompetence, refreshCompetences],
  );

  useEffect(() => {
    refreshCompetences();
  }, [refreshCompetences]);

  const COLUMNS = useMemo(
    () => [
      {
        title: 'Soluter',
        dataIndex: 'user',
        key: 'user',
      },
      {
        title: 'Certificação',
        dataIndex: 'title',
        key: 'title',
        render: value => (
          <Typography.Text ellipsis style={{ maxWidth: 800 }}>
            {value}
          </Typography.Text>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'valid',
        key: 'valid',
        render: value => (
          <Typography.Text ellipsis style={{ textTransform: 'lowercase' }}>
            {value}
          </Typography.Text>
        ),
      },
      {
        title: 'Obtida em',
        dataIndex: 'date',
        key: 'date',
        render: value => <span>{moment(value).format('DD/MM/YYYY')}</span>,
      },
      {
        title: 'Expira em',
        dataIndex: 'expirationDate',
        key: 'expirationDate',
        render: value => {
          if (value === undefined || value === null) {
            return <span>-</span>;
          }
          return value && moment() > moment(value) ? (
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <InfoCircleOutlined
                style={{ color: '#dfa211', marginRight: 5 }}
                title="Certificação Expirada"
              />
              {moment(value).format('DD/MM/YYYY')}
            </span>
          ) : (
            <span>{moment(value).format('DD/MM/YYYY')}</span>
          );
        },
      },
      {
        title: '',
        dataIndex: 'expirationDate',
        render: (_, competence) => (
          <div className="competence-table-actions">
            {getRole() !== 'leader' && (
              <IconButton
                onClick={() => handleEditCompetence(competence)}
                icon={<EditOutlined />}
                title="Editar Certificação"
                style={{ color: '#dfa211' }}
                testId={`edit-${competence.id}`}
              />
            )}

            {competence?.filename && (
              <IconButton
                onClick={() => handleDownloadCompetence(competence)}
                icon={<DownloadOutlined />}
                title="Baixar Certificação"
                testId={`download-${competence.id}`}
                style={{ color: '#1890ff' }}
              />
            )}
            {getRole() !== 'leader' && (
              <IconButton
                onClick={() => handleClickDeleteCompetence(competence)}
                icon={<DeleteOutlined />}
                title="Remover Certificação"
                style={{ color: '#f21f15' }}
                testId={`delete-${competence.id}`}
              />
            )}
          </div>
        ),
      },
    ],
    [
      handleEditCompetence,
      handleClickDeleteCompetence,
      handleDownloadCompetence,
    ],
  );

  return (
    <>
      {modalContextHolder}
      <Table
        columns={COLUMNS}
        dataSource={competences}
        loading={loading}
        className="competence-table"
      />
      {total && <span style={{ float: 'right' }}>Total: {total}</span>}
      <AddEditCompetenceModal
        visible={Boolean(isEditing && currentCompetence)}
        handleClose={handleCloseCompetenceModal}
        competence={currentCompetence}
        editing={isEditing}
      />
    </>
  );
}

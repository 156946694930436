/* eslint-disable no-console */
import {
  Modal,
  Form,
  DatePicker,
  Input,
  Row,
  Alert,
  message,
  Button,
} from 'antd';
import { useEffect } from 'react';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import moment from 'moment';
import PropTypes from 'prop-types';
import { addVacation, deleteVacation } from '~/store/fetchActions/fetchTowers';
import { DeleteDialog } from '~/components';

export default function VacationForm({
  personName,
  personEmail,
  isModalVisible,
  setModalVisible,
  allocation,
  reload,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      personName,
      startDate: allocation ? moment(allocation.startDate) : new Date(),
      endDate: allocation ? moment(allocation.endDate) : new Date(),
    });
  }, [allocation, form, personName]);

  const hasAllocation = allocation?.length !== 0;

  async function handleSubmit(formValues) {
    if (
      formValues.startDate.startOf('day') > formValues.endDate.startOf('day')
    ) {
      message.warn(
        'A data de início do período não pode ser maior que a data de fim',
      );
      return;
    }

    const formStartDate = formValues.startDate.format('DD-MM-YYYY');
    const formEndDate = formValues.endDate.format('DD-MM-YYYY');

    try {
      await addVacation({
        email: personEmail,
        startDate: formStartDate,
        endDate: formEndDate,
      });
    } catch (error) {
      console.log(error);
    }

    setModalVisible(false);
    reload();
  }

  const handleOk = () => {
    form
      .validateFields()
      .then(formValues => handleSubmit(formValues))
      .catch(console.error);
  };

  const handleDelete = async () => {
    try {
      await deleteVacation(allocation?.id);
    } catch (err) {
      console.log(err);
    }
    setModalVisible(false);
    reload();
  };

  const modalFooter = (
    <Row justify="end">
      {!hasAllocation ? (
        <>
          <Button onClick={() => setModalVisible(false)}>Cancelar</Button>
          <Button type="primary" onClick={() => handleOk()}>
            Salvar
          </Button>
        </>
      ) : (
        <DeleteDialog
          currentKey="key"
          handleDelete={handleDelete}
          item={{ title: `as férias de ${personName}`, id: '' }}
          subText=""
          content={
            <Button type="primary" danger>
              Deletar
            </Button>
          }
        />
      )}
    </Row>
  );

  return (
    <Modal
      visible={isModalVisible}
      onCancel={() => setModalVisible(false)}
      title={`${
        !hasAllocation ? 'Registrar' : ''
      } Período de Férias/Ausência Planejada`}
      footer={modalFooter}
    >
      <Form layout="vertical" form={form}>
        <Form.Item name="personName" label="Pessoa">
          <Input disabled />
        </Form.Item>
        <Row>
          <Form.Item name="startDate" label="Início do Período">
            <DatePicker
              locale={locale}
              format="DD/MM/YYYY"
              allowClear={false}
              inputReadOnly
              className="mr-md"
              disabled={hasAllocation}
              data-testid="start-vacation-date"
            />
          </Form.Item>
          <Form.Item name="endDate" label="Fim do Período">
            <DatePicker
              locale={locale}
              format="DD/MM/YYYY"
              allowClear={false}
              inputReadOnly
              disabled={hasAllocation}
              data-testid="end-vacation-date"
            />
          </Form.Item>
        </Row>
      </Form>
      {!hasAllocation && (
        <Alert
          message={`Obs.: O período de férias será registrado para todos os projetos dos quais ${personName} faz parte`}
        />
      )}
    </Modal>
  );
}

VacationForm.propTypes = {
  personName: PropTypes.string.isRequired,
  personEmail: PropTypes.string.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  allocation: PropTypes.any.isRequired,
};

const {
  REACT_APP_BASE_URL,
  REACT_APP_CLIENT_ID_AZURE,
  REACT_APP_TENANT_ID_AZURE,
} = process.env;

const scopes_web = `openid+api://ads.solutis.digital/passport.read+offline_access`;
const clientId = REACT_APP_CLIENT_ID_AZURE;
const tenantId = REACT_APP_TENANT_ID_AZURE;
const tokenPrefsKey = 'shared_prefs_key_token';
const urlBase = REACT_APP_BASE_URL || 'http://localhost:3000/login';
const azureLogout =
  'https://login.microsoftonline.com/$tenantId/oauth2/v2.0/logout?post_logout_redirect_uri=http://localhost/myapp/';

export const microsoftProvider = {
  buildAuthorizeUrl: () => {
    return `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?
                client_id=${clientId}
                &response_type=code
                &redirect_uri=${encodeURIComponent(urlBase)}
                &scope=${scopes_web}
                `;
  },
  extractError: redirectUrl => {
    const errorMatch = redirectUrl.match(/error=([^&]+)/);
    if (!errorMatch) {
      return undefined;
    }

    const errorReason = errorMatch[1];
    const errorDescriptionMatch = redirectUrl.match(
      /error_description=([^&]+)/,
    );
    const errorDescription = errorDescriptionMatch
      ? errorDescriptionMatch[1]
      : '';
    return new Error(
      `Error during login. Reason: ${errorReason} Description: ${errorDescription}`,
    );
  },
  extractSession: redirectUrl => {
    let code = null;
    const codeMatch = redirectUrl.match(/code=([^&]+)/);

    if (codeMatch) {
      code = codeMatch[1];
    }

    return {
      code,
    };
  },
  validateSession: session => {
    const now = new Date().getTime() / 1000;

    // With normal JWT tokens you can inspect the `exp` Expiration claim; however,
    // AAD V2 tokens are opaque and we must use the token meta about expiration time
    // "When you request an access token from the v2.0 endpoint, the v2.0 endpoint also returns metadata about the access token for your app to use."
    // See: https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-v2-tokens
    // Here we are leveraging the fact that the access token was issued at the same
    // time as the ID token and can use its `iat` Issued At claim + the duration
    // to compute an absolute expiration time
    const expiration =
      session.decodedIdToken.iat + session.expireDurationSeconds;

    // 15 minutes minimum duration until token expires
    const minimumDuration = 60 * 15;
    return expiration - now > minimumDuration;
  },
  getAccessToken: (session, resourceId) => {
    return session.accessToken;
  },
  getSignOutUrl: redirectUrl => {
    return `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/logout?
                &client_id=${clientId}
                &post_logout_redirect_uri=${encodeURIComponent(redirectUrl)}`;
  },
};

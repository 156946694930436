import { Form, Checkbox, Button, Empty } from 'antd';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function DropdownFilter({
  filters,
  handleSubmit,
  clearAll,
  filterName,
}) {
  const dispatch = useDispatch();
  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    setActiveFilters([]);
  }, [clearAll]);

  const handleChange = e => {
    setActiveFilters(e);
  };

  const handleClear = e => {
    setActiveFilters([]);
    dispatch(handleSubmit([]));
  };

  return filters?.length > 0 ? (
    <Form
      style={{ padding: 8 }}
      data-testid={`capacity-dropdown-filter-${filterName}`}
    >
      <Form.Item style={{ marginBottom: 0 }}>
        <Checkbox.Group
          style={{
            maxWidth: 500,
            minWidth: 150,
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
          }}
          value={activeFilters}
          options={filters}
          onChange={handleChange}
        />
      </Form.Item>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 8,
        }}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            data-testid="clear-filters-button"
            size="small"
            type="default"
            onClick={handleClear}
          >
            Limpar
          </Button>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            size="small"
            type="primary"
            onClick={() => dispatch(handleSubmit(activeFilters))}
            data-testid="apply-filters-button"
          >
            Aplicar
          </Button>
        </Form.Item>
      </div>
    </Form>
  ) : (
    <div style={{ padding: 5 }}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={`Não há ${filterName} para filtrar`}
        imageStyle={{
          height: 30,
        }}
      />
    </div>
  );
}

DropdownFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  clearAll: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  filterName: PropTypes.string,
};

DropdownFilter.defaultProps = {
  filterName: 'atributos',
};

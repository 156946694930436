import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  districts: [],
  district: {},
  members: { content: [], total: 0 },
  events: [],
  event: {},
  search: false,
  searchTitle: '',
  membersCsvData: null,
};

export const addDistrict = createAction('DISTRICTS_ADD');
export const getDistricts = createAction('DISTRICTS_GET');
export const getDistrictDetail = createAction('DISTRICTS_GET_DETAIL');

export const editDistrictPhoto = createAction('DISTRICTS_EDIT_PHOTO');
export const getDistrictMembers = createAction('DISTRICTS_GET_MEMBERS');
export const getDistrictEvents = createAction('DISTRICTS_GET_EVENTS');
export const addDistrictEvents = createAction('DISTRICTS_ADD_EVENT');
export const getDistrictEvent = createAction('DISTRICTS_GET_EVENT');
export const removeDistrictEvent = createAction('DISTRICTS_REMOVE_EVENT');

export const selectEditDistrict = createAction('DISTRICTS_SELECT_EDIT');
export const editDistrict = createAction('DISTRICTS_EDIT');
export const removeDistrict = createAction('DISTRICTS_REMOVE');
export const searchDistrict = createAction('DISTRICTS_SEARCH');
export const cleanDistrictById = createAction('CLEAN_DISTRICT_BY_ID');

export const getMembersCsvData = createAction('DISTRICTS_GET_MEMBERS_CSV_DATA');
export const clearMembersCsvData = createAction('DISTRICTS_CLEAR_CSV_DATA');

export default createReducer(INITIAL_STATE, {
  [addDistrict.type]: (state, action) => ({
    ...state,
  }),
  [getDistricts.type]: (state, action) => ({
    ...state,
    districts: action.payload,
  }),
  [getDistrictDetail.type]: (state, action) => ({
    ...state,
    district: action.payload,
  }),
  [getDistrictMembers.type]: (state, action) => ({
    ...state,
    members: action.payload,
  }),
  [getDistrictEvents.type]: (state, action) => ({
    ...state,
    events: action.payload,
  }),
  [addDistrictEvents.type]: (state, action) => ({
    ...state,
    event: action.payload,
  }),
  [getDistrictEvent.type]: (state, action) => ({
    ...state,
    event: action.payload,
  }),
  [removeDistrictEvent.type]: (state, action) => ({
    ...state,
    event: action.payload,
  }),
  [selectEditDistrict.type]: (state, action) => ({
    ...state,
    selectedDistrict: action.payload,
  }),
  [editDistrict.type]: (state, action) => ({
    ...state,
    district: action.payload,
  }),
  [removeDistrict.type]: state => ({ ...state }),
  [searchDistrict.type]: (state, action) => ({
    ...state,
    search: action.payload.search,
    searchTitle: action.payload.searchTitle,
  }),
  [cleanDistrictById.type]: (state, action) => ({
    ...state,
    district: {},
    members: { content: [], total: 0 },
  }),
  [getMembersCsvData.type]: (state, action) => ({
    ...state,
    membersCsvData: action.payload,
  }),
  [clearMembersCsvData.type]: (state, action) => ({
    ...state,
    membersCsvData: null,
  }),
});

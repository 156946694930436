import { useState } from 'react';
import { Col, Row, Card, Typography } from 'antd';
import { period } from '~/constants/indicators';
import AdmissionCard from './AdmissionCard';
import AdmissionDrawer from './AdmissionDrawer';

const { Title } = Typography;

export default function AdmissionContainer() {
  const [isAdmissionsDrawerVisible, setAdmissionsDrawerVisible] = useState(
    false,
  );
  const [currentPeriod, setCurrentPeriod] = useState(period.month);

  const handleOpenAdmissionDrawer = selectedPeriod => {
    setCurrentPeriod(selectedPeriod);
    setAdmissionsDrawerVisible(true);
  };

  return (
    <>
      <Card className="graph-card">
        <Title level={5}> Admissões </Title>
        <Row>
          <Col style={{ marginRight: 40 }}>
            <AdmissionCard
              period={period.lastMonth}
              handleOpenDrawer={handleOpenAdmissionDrawer}
            />
          </Col>
          <Col style={{ marginRight: 40 }}>
            <AdmissionCard
              period={period.month}
              handleOpenDrawer={handleOpenAdmissionDrawer}
            />
          </Col>
          <Col>
            <AdmissionCard
              period={period.week}
              handleOpenDrawer={handleOpenAdmissionDrawer}
            />
          </Col>
        </Row>
      </Card>
      <AdmissionDrawer
        period={currentPeriod}
        isDrawerVisible={isAdmissionsDrawerVisible}
        setDrawerVisible={setAdmissionsDrawerVisible}
      />
    </>
  );
}

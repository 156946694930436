import { Row, Form, Button } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SelectSkills, TagRender, ActiveFilters } from '~/components';
import { setSelectedSkills } from '~/store/ducks/indicators/index';

export default function SolutersBySkillsHeader({ tags }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { selectedSkills, skillsTags } = useSelector(
    state => state.indicators.solutersBySkills,
  );

  const onFinish = value => {
    dispatch(setSelectedSkills([...tags, ...value]));
    form.resetFields();
  };

  const onCloseTag = deleteTag => {
    const tagsArray = tags.filter(tag => tag !== deleteTag);
    dispatch(setSelectedSkills(tagsArray));
  };

  return (
    <div style={{ marginBottom: 25 }}>
      <Row justify="center" style={{ marginBottom: 25 }}>
        <Form layout="inline" onFinish={e => onFinish(e.skills)} form={form}>
          <Form.Item name="skills">
            <SelectSkills
              fieldName="skills"
              fieldLabel="Habilidades"
              required={false}
              selectMode="multiple"
              tagRenderFunc={props => TagRender(props)}
              style={{ width: 400 }}
              defaultValue={[]}
              testId="select-skills"
              isTechnology
            />
          </Form.Item>
          <Form.Item>
            <Button data-testid="search-button" htmlType="submit">
              Pesquisar
            </Button>
          </Form.Item>
        </Form>
      </Row>
      <Row justify="center">
        <ActiveFilters
          filters={selectedSkills?.length > 0 ? selectedSkills : skillsTags}
          handleClear={() => dispatch(setSelectedSkills([]))}
          handleTagClose={onCloseTag}
          isTagClosable
          label="Habilidades Selecionadas"
        />
      </Row>
    </div>
  );
}

SolutersBySkillsHeader.propTypes = {
  tags: PropTypes.array.isRequired,
};

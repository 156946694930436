import locale from 'antd/lib/date-picker/locale/pt_BR';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DatePicker, Button } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { setYearlyTrue, setYearlyFalse } from '~/store/ducks/mood';

moment.locale('pt-BR');

export default function MoodFilter({
  weekSelector,
  setWeekInterval,
  typeFilter,
}) {
  const dispatch = useDispatch();
  const week = useSelector(weekSelector);
  const { isYearly } = useSelector(state => state.mood);

  useEffect(() => {
    if (week.start === '' && week.end === '') {
      dispatch(
        setWeekInterval({
          start: moment().startOf(typeFilter).format('DD-MM-YYYY'),
          end: moment().endOf(typeFilter).format('DD-MM-YYYY'),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function handleSetYearlyTrue() {
    dispatch(setYearlyTrue());
    dispatch(
      setWeekInterval({
        start: moment().startOf('year').format('DD-MM-YYYY'),
        end: moment().endOf('year').format('DD-MM-YYYY'),
      }),
    );
  }

  function handleSetYearlyFalse() {
    dispatch(setYearlyFalse());
    dispatch(
      setWeekInterval({
        start: moment().startOf('month').format('DD-MM-YYYY'),
        end: moment().endOf('month').format('DD-MM-YYYY'),
      }),
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <DatePicker
        onChange={date => {
          dispatch(
            setWeekInterval({
              start: date.startOf(typeFilter).format('DD-MM-YYYY'),
              end: date.endOf(typeFilter).format('DD-MM-YYYY'),
            }),
          );
        }}
        picker={typeFilter}
        inputReadOnly
        locale={locale}
        defaultValue={week.start ? moment(week.start, 'DD-MM-YYYY') : moment()}
        allowClear={false}
        data-testid="mood-month-picker"
      />
      <Button
        type={!isYearly ? 'primary' : 'default'}
        onClick={handleSetYearlyFalse}
      >
        Mensal
      </Button>
      <Button
        type={isYearly ? 'primary' : 'default'}
        onClick={handleSetYearlyTrue}
      >
        Anual
      </Button>
    </div>
  );
}

MoodFilter.propTypes = {
  weekSelector: PropTypes.func.isRequired,
  setWeekInterval: PropTypes.func.isRequired,
  typeFilter: PropTypes.func.isRequired,
};

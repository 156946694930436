import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
import { EmptyErrorWrapper, IconWithCaption } from '~/components';
import {
  getSoluterDistricts,
  getSoluterProjectsAndTowers,
} from '~/store/fetchActions/fetchPeople';
import './styles.scss';

const { Title, Text } = Typography;

export default function SoluterGroups({ email }) {
  const { districts, projects, towers } = useSelector(
    state => state.people.soluter,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSoluterDistricts(email));
    dispatch(getSoluterProjectsAndTowers(email));
  }, [dispatch, email]);

  return (
    <Row gutter={16}>
      <Col span={8} className="soluter-groups__col ">
        <Title align="middle" className="soluter-groups__title" level={5}>
          Distritos
        </Title>
        <EmptyErrorWrapper
          data={districts}
          emptyCondition={districts?.length > 0}
          emptyMessage="Este Soluter ainda não pertence a um distrito."
          retryAction={getSoluterDistricts}
          retryProps={[email]}
          loaderTitle="isLoadingSolutersDistricts"
        >
          <Row justify="center">
            {districts?.map(district => (
              <Col sm={8} md={8} lg={8} xl={6} xxl={4} key={district.id}>
                <IconWithCaption
                  imageSrc={district?.thumb}
                  isLoading={!district?.thumb || district?.thumb?.length < 60}
                  redirectUrl={`/districts/details/${district.id}`}
                  altText={`Ícone do distrito ${district?.name}`}
                  captionText={district?.name}
                />
              </Col>
            ))}
          </Row>
        </EmptyErrorWrapper>
      </Col>
      <Col span={8} className="soluter-groups__col">
        <Title align="middle" className="soluter-groups__title" level={5}>
          Torres
        </Title>
        <EmptyErrorWrapper
          data={towers}
          emptyCondition={towers?.length > 0}
          emptyMessage="Este Soluter ainda não pertence a uma torre."
          retryAction={getSoluterProjectsAndTowers}
          retryProps={[email]}
          loaderTitle="isLoadingSolutersProjectsAndTowers"
        >
          <Row justify="center">
            {towers?.map(tower => (
              <Col sm={8} md={8} lg={8} xl={6} xxl={4} key={tower.id}>
                <IconWithCaption
                  useAvatar
                  imageSrc={tower?.thumb}
                  isLoading={!tower?.thumb}
                  redirectUrl={`/towers/details/${tower?.id}`}
                  captionText={tower?.name}
                />
              </Col>
            ))}
          </Row>
        </EmptyErrorWrapper>
      </Col>
      <Col span={8} className="soluter-groups__col">
        <Title className="soluter-groups__title" align="middle" level={5}>
          Projetos
        </Title>
        <EmptyErrorWrapper
          data={towers}
          emptyCondition={towers?.length > 0}
          emptyMessage="Este Soluter ainda não pertence a um projeto."
          retryAction={getSoluterProjectsAndTowers}
          retryProps={[email]}
          loaderTitle="isLoadingSolutersProjectsAndTowers"
        >
          <div className="soluter-groups__projects">
            {projects?.map(project => (
              <Text
                key={project.id}
                align="middle"
                className="soluter-groups__project"
              >
                {project}
              </Text>
            ))}
          </div>
        </EmptyErrorWrapper>
      </Col>
    </Row>
  );
}

SoluterGroups.propTypes = {
  email: PropTypes.string.isRequired,
};

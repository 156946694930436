import { List, Space, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Comment, Tag } from '~/components';
import {
  iconsByMood,
  altTextByMood,
  APPROVED_COLOR_DARK,
  NOT_APPROVED_COLOR_DARK,
} from '~/constants/moods';

export default function SoluterMoodItem({ mood, hasAuthor }) {
  const mapTags = (tags, color) => {
    return (
      <>
        {tags?.map(tag => (
          <Tag
            tag={{ tag: tag.tag }}
            backgroundColor={color}
            textColor="white"
            key={tag}
          />
        ))}
      </>
    );
  };

  return (
    <List.Item key={mood?.personEmail + mood?.date}>
      <Skeleton loading={hasAuthor && !mood?.userName} paragraph={{ rows: 2 }}>
        <div>
          <Comment
            comment={mood?.comment}
            date={mood?.date || ''}
            author={
              hasAuthor ? (
                <Link to={`/soluters/${mood?.userEmail}`}>
                  {mood?.userName}
                </Link>
              ) : null
            }
          />
          <div
            style={{
              display: 'flex',
            }}
          >
            <img
              src={iconsByMood[mood?.mood]}
              alt={altTextByMood[mood?.mood]}
              className="soluter-mood__mood-icon"
            />
            <div className="soluter-mood__mood-card">
              <Space size={[8, 8]} wrap>
                {mapTags(mood?.approved, APPROVED_COLOR_DARK)}
                {mapTags(mood?.notApproved, NOT_APPROVED_COLOR_DARK)}
                {mood?.approved?.length === 0 &&
                  mood?.notApproved?.length === 0 && (
                    <p style={{ color: '#949494' }}>Nenhuma tag selecionada.</p>
                  )}
              </Space>
            </div>
          </div>
        </div>
      </Skeleton>
    </List.Item>
  );
}

SoluterMoodItem.propTypes = {
  mood: PropTypes.object.isRequired,
  hasAuthor: PropTypes.bool,
};

SoluterMoodItem.defaultProps = {
  hasAuthor: false,
};

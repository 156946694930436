/* eslint-disable no-console */
import { message } from 'antd';
import api from '~/config/httpRequest';
import * as experienceAction from '~/store/ducks/experiences';
import { validationMessages } from '~/constants';
import { startLoading, endLoading } from '../ducks/loading';

export const get = page => {
  return dispatch => {
    api
      .get(`/jobs/experiences/pagination?page=${page}&term`)
      .then(({ data: payload }) => {
        dispatch(
          experienceAction.get({
            experiences: payload ? payload.content : [],
            total: payload ? payload.total : 0,
            page,
          }),
        );
      })
      .catch(e => {
        console.log('fetchExperiences - get', e);
        dispatch(
          experienceAction.get({
            experiences: null,
            total: 0,
            page: 1,
          }),
        );
        // message.error("Falha ao buscar os dados");
      });
  };
};

export const getExperiences = (page, searchTerm) => {
  const search = /^[0-9]+$/.test(searchTerm)
    ? `termId=${searchTerm}`
    : `term=${searchTerm}`;
  return async dispatch => {
    dispatch(startLoading('isLoadingExperiences'));
    api
      .get(`/jobs/experiences/pagination?page=${page}&term=${search}`)
      .then(({ data: payload }) => {
        dispatch(experienceAction.setExperiences(payload));
        dispatch(endLoading('isLoadingExperiences'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingExperiences'));
      });
  };
};

export const getByTitle = (title, page = 1) => {
  return dispatch => {
    dispatch(startLoading('isLoadingExperiences'));
    api
      .get(`/jobs/experiences/pagination?page=${page}&term=${title}`)
      .then(({ data: payload }) => {
        dispatch(
          experienceAction.get({
            experiences: payload.content || [],
            total: payload.total || 0,
            page,
          }),
        );
        dispatch(endLoading('isLoadingExperiences'));
      })
      .catch(err => {
        console.log(err);
        dispatch(
          experienceAction.get({
            experiences: null,
            total: 0,
            page: 1,
          }),
        );
        dispatch(endLoading('isLoadingExperiences'));
      });
  };
};

export const add = (searching, searchTerm, title, page) => {
  return dispatch => {
    api
      .post(`/jobs/experiences`, { title })
      .then(() => {
        dispatch(experienceAction.add(title));
        message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
        if (searching) dispatch(getByTitle(searchTerm, page));
        else dispatch(get(page));
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const edit = (searching, searchTerm, title, id, page) => {
  return dispatch => {
    api
      .put(`/jobs/experiences/${id}`, { id, title })
      .then(({ data: payload }) => {
        dispatch(experienceAction.edit(payload));
        message.success(validationMessages.HTTP_EDIT_SUCCESS_MESSAGE);
        if (searching) dispatch(getByTitle(searchTerm, page));
        else dispatch(get(page));
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const remove = (searching, searchTerm, id, page, onlyOne) => {
  return dispatch => {
    api
      .delete(`/jobs/experiences/${id}`)
      .then(() => {
        dispatch(experienceAction.remove(id));
        message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
        if (onlyOne) {
          if (searching)
            dispatch(getByTitle(searchTerm, page > 1 ? page - 1 : 1));
          else dispatch(get(page > 1 ? page - 1 : 1));
        } else if (searching) {
          dispatch(getByTitle(searchTerm, page));
        } else {
          dispatch(get(page));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
};

import { Card, Row, Col } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EmptyErrorWrapper } from '~/components';
import GraphSoluterBySeniority from './GraphSoluterBySeniority';
import SolutersBySeniorityHeader from './SolutersBySeniorityHeader';
import SolutersBySeniorityDrawer from './SolutersBySeniorityDrawer';
import { getSolutersBySeniority } from '~/store/fetchActions/fetchIndicators';

export default function SolutersBySeniorityContainer() {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const { groupingMode, unallocated, data } = useSelector(
    state => state.indicators.solutersBySeniority,
  );
  const [uniqueValuesCount, setUniqueValuesCount] = useState([]);
  const { selectedTower, selectedPractice } = useSelector(
    state => state.indicators,
  );

  useEffect(() => {
    if (data)
      setUniqueValuesCount(
        data
          ?.map(item => item.label)
          .filter((value, index, self) => {
            return self.indexOf(value) === index && value !== '';
          }).length,
      );
  }, [data]);

  useEffect(() => {
    dispatch(
      getSolutersBySeniority(
        groupingMode,
        unallocated,
        selectedPractice,
        selectedTower,
      ),
    );
  }, [dispatch, groupingMode, unallocated, selectedPractice, selectedTower]);

  const handleOpenDrawer = useCallback(category => {
    setSelectedCategory(category);
    setIsDrawerVisible(true);
  }, []);

  return (
    <Card style={{ minHeight: '60vh' }}>
      <SolutersBySeniorityHeader />
      <Row>
        <Col span={24}>
          <EmptyErrorWrapper
            data={data}
            emptyCondition={data?.length > 0}
            loaderTitle="isLoadingIndicators"
            retryAction={getSolutersBySeniority}
            retryProps={[groupingMode, unallocated]}
            style={{ height: '50vh' }}
            emptyMessage="Não há dados registrados que se encaixem nesta categoria."
          >
            <GraphSoluterBySeniority
              handleOpenDrawer={handleOpenDrawer}
              data={data}
              unallocated={unallocated}
              uniqueValuesCount={uniqueValuesCount}
            />
          </EmptyErrorWrapper>
        </Col>
      </Row>
      <SolutersBySeniorityDrawer
        selectedCategory={selectedCategory}
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
      />
    </Card>
  );
}

import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  roles: [],
  role: {},
  total: 0,
  page: 1,
  selectedRole: -1,
  search: false,
  searchTitle: '',
};

export const add = createAction('ROLES_ADD');
export const get = createAction('ROLES_GET');
export const selectEdit = createAction('ROLES_SELECT_EDIT');
export const edit = createAction('ROLES_EDIT');
export const remove = createAction('ROLES_REMOVE');
export const search = createAction('ROLES_SEARCH');

export default createReducer(INITIAL_STATE, {
  [add.type]: (state, action) => ({
    ...state,
    role: { title: action.payload },
  }),
  [get.type]: (state, action) => ({
    ...state,
    roles: action.payload.roles,
    total: action.payload.total,
    page: action.payload.page,
  }),
  [selectEdit.type]: (state, action) => ({
    ...state,
    selectedRole: action.payload,
  }),
  [edit.type]: (state, action) => ({
    ...state,
    role: { title: action.payload },
  }),
  [remove.type]: state => ({ ...state }),
  [search.type]: (state, action) => ({
    ...state,
    search: action.payload.search,
    searchTitle: action.payload.searchTitle,
  }),
});

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Row, Button } from 'antd';
import {
  AddEditPracticeForm,
  AddCentroCustoSemBus,
  PracticesList,
  PracticeDetails,
} from '~/container';
import { getPractices } from '~/store/fetchActions/fetchPractices';

const { Title } = Typography;

export default function Practices() {
  const dispatch = useDispatch();
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [isAddEditModalVisible, setAddEditModalVisible] = useState(false);
  const [isAddCentroCustoSemBus, setAddCentroCustoSemBus] = useState(false);
  const { isLoadingPractices } = useSelector(state => state.loading);
  const [currentPractice, setCurrentPractice] = useState(null);

  useEffect(() => {
    dispatch(getPractices());
  }, [dispatch]);

  const handleOpenDetailsModal = practice => {
    setCurrentPractice(practice);
    setDetailsModalVisible(true);
  };

  const handleOpenEditModal = practice => {
    setCurrentPractice(practice);
    setAddEditModalVisible(true);
  };

  return (
    <div>
      <Title>Business Units</Title>
      <Row justify="end" style={{ marginBottom: 40 }}>
        <Button
          data-testid="add-practice-button"
          type="primary"
          onClick={e => {
            e.stopPropagation();
            setCurrentPractice(null);
            setAddEditModalVisible(true);
          }}
        >
          Adicionar BU
        </Button>
        <Button
          data-testid="add-practice-button"
          type="primary"
          style={{ marginLeft: 10 }}
          loading={isLoadingPractices}
          onClick={e => {
            e.stopPropagation();
            setAddCentroCustoSemBus(true);
          }}
        >
          Associar Centros de Custo
        </Button>
      </Row>
      <Row>
        <PracticesList
          handleOpenDetailsModal={handleOpenDetailsModal}
          handleOpenEditModal={handleOpenEditModal}
        />
      </Row>
      <PracticeDetails
        practice={currentPractice}
        isVisible={isDetailsModalVisible}
        setVisible={setDetailsModalVisible}
      />
      <AddEditPracticeForm
        practice={currentPractice}
        isVisible={isAddEditModalVisible}
        setVisible={setAddEditModalVisible}
      />
      <AddCentroCustoSemBus
        isVisible={isAddCentroCustoSemBus}
        setVisible={setAddCentroCustoSemBus}
      />
    </div>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
// DOCS: https://gitlab.solutis.digital/ads/passport/passport-back-office/wikis/Planejamento
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { CarryOutFilled } from '@ant-design/icons';
import { differenceBetweenDatesInDays } from '~/helpers/daysOfWeek';
import { scheduler } from '~/constants';
import './styles.scss';

export default function SchedulerBar({
  currentAllocation,
  tooltipTitle,
  customStyle,
  record,
  showEditor,
  showVacationForm,
  columnIndex,
}) {
  const handleDate = () => {
    if (
      currentAllocation.startDate.getFullYear ===
      currentAllocation.endDate.getFullYear
    ) {
      if (currentAllocation.getStartMonth() !== currentAllocation.getEndMonth())
        return (
          scheduler.MAX_DAYS_IN_MONTH - currentAllocation.startDate.getDate()
        );
    }
    return differenceBetweenDatesInDays(
      currentAllocation.startDate,
      currentAllocation.endDate,
    );
  };

  const shouldShowAmount = isVacation => {
    if (columnIndex === scheduler.GO_FORWARD_ARROW_INDEX) return false;
    if (
      currentAllocation.isSecondMonth() &&
      differenceBetweenDatesInDays(
        currentAllocation.getStartOfSecondMonth(),
        currentAllocation.endDate,
      ) >= (isVacation ? 5 : 9)
    )
      return true;
    if (isVacation && currentAllocation.isStartMonth() && handleDate() >= 4)
      return true;
    if (currentAllocation.isStartMonth() && handleDate() >= 9) return true;
    if (currentAllocation.isSecondYear() && !currentAllocation.isVacation)
      return true;
    return false;
  };

  return (
    <Tooltip
      id={columnIndex}
      placement="top"
      title={tooltipTitle}
      key={currentAllocation.id}
    >
      <div
        key={currentAllocation.id}
        onClick={() => {
          if (!currentAllocation.isVacation)
            showEditor(record, currentAllocation);
          else showVacationForm(record, currentAllocation);
        }}
        style={customStyle}
        className={`schedule-allocation ${
          currentAllocation.isStartMonth() &&
          columnIndex !== scheduler.GO_FORWARD_ARROW_INDEX &&
          'schedule-allocation--start'
        } ${currentAllocation.isEndMonth() && 'schedule-allocation--end'}`}
      >
        <p
          className="scheduler-bar__label"
          style={{
            color: currentAllocation.isVacation ? 'white' : 'black',
          }}
        >
          {shouldShowAmount(currentAllocation.isVacation) &&
            (currentAllocation.isVacation ? (
              handleDate() >= 8 ? (
                'Férias'
              ) : (
                <CarryOutFilled />
              )
            ) : (
              `${currentAllocation.amount}%`
            ))}
        </p>
      </div>
    </Tooltip>
  );
}

SchedulerBar.propTypes = {
  currentAllocation: PropTypes.object.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
  record: PropTypes.object.isRequired,
  showEditor: PropTypes.func.isRequired,
  showVacationForm: PropTypes.func.isRequired,
  columnIndex: PropTypes.number,
};

SchedulerBar.defaultProps = {
  customStyle: {
    background: 'rgb(77, 214, 0)',
    height: 25,
  },
  columnIndex: 0,
};

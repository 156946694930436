/* eslint-disable no-console */
import api from '~/config/httpRequest';
import { getCsvData, setInterviews } from '../ducks/interviews';
import { startLoading, endLoading } from '~/store/ducks/loading';

export const getInterviewsCsv = (
  startDate,
  endDate,
  practiceId,
  term,
  typeInterview,
) => {
  return async dispatch => {
    api
      .get(`/soluters/users/exit-interview/export`, {
        params: { startDate, endDate, practiceId, term, typeInterview },
      })
      .then(({ data }) => {
        dispatch(getCsvData(data));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const getExitInterviewsByDate = (
  startDate,
  endDate,
  practiceId,
  term,
) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingExitInterviews'));

    api
      .get(`/soluters/users/exit-interview`, {
        params: { startDate, endDate, practiceId, term },
      })
      .then(({ data: payload }) => {
        dispatch(setInterviews(payload));
        dispatch(endLoading('isLoadingExitInterviews'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingExitInterviews'));
      });
  };
};

import { Form, Button, DatePicker, Checkbox } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddEditPeopleProjectForm from './AddEditPeopleProjectForm';
import { SelectTowers } from '~/components';
import { setSelectedTower } from '~/store/ducks/towers';
import './styles.scss';

export default function TowerScheduleHeader({
  selectedTower,
  activeMembers,
  setActiveMembers,
  reload,
  year,
  setYear,
  setActiveTowers,
  activeTowers,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const currentYear = new Date().getFullYear();

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="tower-schedule-header">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Form className="tower-form">
          <Form.Item
            rules={[
              {
                type: 'string',
                min: 3,
              },
            ]}
          >
            <SelectTowers
              selector={state => state.towers}
              dispatchDuck={setSelectedTower}
              disableEmptyOption
            />
          </Form.Item>
        </Form>
        <DatePicker
          onChange={value => {
            const _year = value ? moment(value).format('YYYY') : '';
            setYear(_year);
          }}
          value={moment(year)}
          allowClear={false}
          allowEmpty={false}
          data-testid="date-picker"
          placeholder="Selecionar ano"
          defaultValue={moment(currentYear.toString())}
          picker="year"
          style={{ marginLeft: 10 }}
          inputReadOnly
        />
        <Checkbox
          style={{ marginLeft: 10 }}
          checked={activeMembers}
          onChange={() => {
            setActiveMembers(_activeMembers => !_activeMembers);
          }}
          data-testid="checkbox-only-active-members"
        >
          Membros ativos
        </Checkbox>
        <Checkbox
          style={{ marginLeft: 10 }}
          checked={activeTowers}
          onChange={() => {
            setActiveTowers(_activeTowers => !_activeTowers);
          }}
          data-testid="checkbox-only-active-towers"
        >
          Projetos ativos
        </Checkbox>
      </div>
      <Button
        type="primary"
        onClick={showModal}
        data-testid="add-people-project-button"
      >
        Adicionar Pessoa ao Projeto
      </Button>
      <AddEditPeopleProjectForm
        isVisible={isModalVisible}
        setVisible={setIsModalVisible}
        towerId={selectedTower}
        reload={reload}
      />
    </div>
  );
}

TowerScheduleHeader.propTypes = {
  selectedTower: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  setYear: PropTypes.func.isRequired,
  activeMembers: PropTypes.bool.isRequired,
  setActiveMembers: PropTypes.func.isRequired,
  setActiveTowers: PropTypes.func.isRequired,
  activeTowers: PropTypes.bool.isRequired,
};

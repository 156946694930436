export const startOfWeek = date => {
  const diff = date.getDate() - date.getDay();

  return formatDate(new Date(date.setDate(diff)));
};

export const endOfWeek = date => {
  const diff = date.getDate() - (date.getDay() === 0 ? 6 : date.getDay()) + 6;

  return formatDate(new Date(date.setDate(diff)));
};

export const formatDate = date =>
  new Intl.DateTimeFormat('pt-BR').format(date).split('/').join('-');

export const formatDateSlash = date => {
  return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(date);
};

export const startOfWeekUnformatted = date => {
  const diff = date.getDate() - date.getDay();

  return new Date(date.setDate(diff));
};

export const endOfWeekUnformatted = date => {
  const diff = date.getDate() - (date.getDay() === 0 ? 6 : date.getDay()) + 6;

  return new Date(date.setDate(diff));
};

export const differenceBetweenDatesInDays = (startDate, endDate) => {
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Statistic } from 'antd';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '~/components';
import { getAdmissions } from '~/store/fetchActions/fetchIndicators';
import { periodTitles } from '~/constants/indicators';

export default function AdmissionCard({ period, handleOpenDrawer }) {
  const dispatch = useDispatch();
  const { admissions, selectedCostCenter, selectedPractice } = useSelector(
    state => state.indicators,
  );
  const loading = useSelector(state => state.loading);

  useEffect(() => {
    dispatch(getAdmissions(period));
  }, [period, selectedCostCenter, selectedPractice]);

  return (
    <Statistic
      suffix={
        <Button
          style={{ marginLeft: 5, fontSize: 16 }}
          type="view"
          isButton
          tooltipIdentifier="Soluters"
          handleClick={() => {
            handleOpenDrawer(period);
          }}
        />
      }
      title={periodTitles[period]}
      value={admissions?.[period]?.total}
      loading={loading[`isLoadingAdmissions${period}`]}
      valueStyle={{ textAlign: 'center', color: '#0ca147' }}
    />
  );
}

AdmissionCard.propTypes = {
  period: PropTypes.string.isRequired,
  handleOpenDrawer: PropTypes.func.isRequired,
};

import { Radio, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSolutersBySeniorityAllocated,
  setSolutersBySeniorityGrouping,
} from '~/store/ducks/indicators';

export default function SolutersBySeniorityHeader() {
  const { groupingMode, unallocated } = useSelector(
    state => state.indicators.solutersBySeniority,
  );
  const dispatch = useDispatch();

  return (
    <Row justify="space-between" align="middle">
      <Radio.Group
        defaultValue={groupingMode}
        onChange={({ target }) =>
          dispatch(setSolutersBySeniorityGrouping(target.value))
        }
      >
        <Radio.Button value="role">Papel Principal</Radio.Button>
        <Radio.Button value="technology">Tecnologia Primária</Radio.Button>
      </Radio.Group>
      <Radio.Group
        value={unallocated}
        onChange={({ target }) => {
          dispatch(setSolutersBySeniorityAllocated(target.value));
        }}
      >
        <Radio
          checked
          key="1"
          value={false}
          style={{ marginLeft: 10 }}
          data-testid="defined-members-radio"
        >
          Membros Definidos
        </Radio>
        <Radio
          key="2"
          value
          style={{ marginLeft: 10 }}
          data-testid="undefined-members-radio"
        >
          Membros Não Definidos
        </Radio>
      </Radio.Group>
    </Row>
  );
}

import { Badge, Card, Avatar, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import Icon, { RocketOutlined, UserOutlined } from '@ant-design/icons';
import { ReactComponent as LeaderSvg } from '~/assets/images/lider.svg';

const { Meta } = Card;

export default function SoluterCard({
  soluter,
  isLoadingCostCenter,
  thumbLoading,
}) {
  const applyLeaderBadge = (content, isLeader) => {
    return isLeader ? (
      <Badge.Ribbon
        text={
          <div className="d-flex soluter-badge soluter-badge--leader">
            <Icon
              component={LeaderSvg}
              style={{ marginTop: 0, fontSize: 30 }}
            />
          </div>
        }
        className="soluter-badge"
        style={{
          background: 'linear-gradient(71deg, #fea60f, #ffcb07)',
        }}
        color="gold"
      >
        {content}
      </Badge.Ribbon>
    ) : (
      content
    );
  };

  const applyHighPotentialBadge = (content, isHighPotential, isLeader) => {
    return isHighPotential ? (
      <Badge.Ribbon
        text={
          <div className="d-flex soluter-badge soluter-badge--high-potential">
            <RocketOutlined />
          </div>
        }
        className="soluter-badge"
        style={{
          marginTop: isLeader ? 28 : 0,
          zIndex: 2,
          background:
            'linear-gradient(42deg, rgb(47, 84, 235), rgb(21 178 225))',
          minWidth: 45,
          display: 'flex',
          justifyContent: 'center',
        }}
        color="geekblue"
      >
        {content}
      </Badge.Ribbon>
    ) : (
      content
    );
  };

  const cardContent = (
    <Card className="soluter-card">
      <Link
        to={{
          pathname: `/soluters/${soluter.email}`,
          state: { soluter },
        }}
      >
        <Meta
          avatar={
            thumbLoading ? (
              <Skeleton.Avatar
                loading={thumbLoading}
                avatar
                active={thumbLoading}
                size={48}
              />
            ) : (
              <Avatar src={soluter.thumb} icon={<UserOutlined />} size={48} />
            )
          }
          title={soluter.name}
          description={
            <>
              <p className="mb-0">{soluter.jobTitle}</p>
              <Skeleton
                className="soluter-card__skeleton"
                paragraph={{ rows: 0 }}
                title={{ width: 150 }}
                loading={isLoadingCostCenter}
                active
              >
                {soluter.costCenter && (
                  <p>{`${soluter.costCenterId} - ${soluter.costCenter}`}</p>
                )}
              </Skeleton>
            </>
          }
        />
      </Link>
    </Card>
  );

  return applyLeaderBadge(
    applyHighPotentialBadge(
      cardContent,
      soluter?.highPotential,
      soluter?.leader,
    ),
    soluter?.leader,
  );
}

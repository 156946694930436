import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { SelectSkills } from '~/components';
import {
  addRoleFamilies,
  addSubFamilies,
  updateRoleFamilies,
} from '~/store/fetchActions/fetchFamilies';
import { selectFamilies } from '~/store/selectors';

const { Option } = Select;

export default function AddRoleOrSubFamily({
  isVisible,
  setVisible,
  familyId,
  isRole,
  role,
  familyList,
}) {
  const [type, setType] = useState('');
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { buId } = useParams();
  const { families } = useSelector(selectFamilies);

  useEffect(() => {
    form.resetFields();
    if (isVisible && role)
      form.setFieldsValue({
        ...role,
      });
  }, [form, isVisible, role, familyId, dispatch]);

  const handleCancel = useCallback(() => {
    setVisible(false);
    form.resetFields();
    setType('');
  }, [setVisible, form]);

  const handleChangeType = $event => {
    setType($event);
  };

  const handleOk = useCallback(async () => {
    if (role) {
      return form.validateFields().then(({ name }) => {
        if (role) {
          dispatch(updateRoleFamilies(name, role.id, buId));
        }

        setVisible(false);
        form.resetFields();
      });
    }
    return form
      .validateFields()
      .then(({ name, selectRoleSubFamily, skills }) => {
        if (selectRoleSubFamily === 'role') {
          const hierarchy = familyList.roles.length + 1;
          dispatch(addRoleFamilies(name, familyId, buId, hierarchy));
        } else dispatch(addSubFamilies(name, skills, familyId, buId));
        setVisible(false);
        form.resetFields();
      });
  }, [form, familyId, buId, setVisible, role, familyList, dispatch]);

  return (
    <Modal
      visible={isVisible}
      title={role ? 'Editar Cargo' : 'Adicionar Cargos ou Sub-Famílias'}
      okText="Salvar"
      cancelText="Cancelar"
      data-testid="business-unit-edit-family-modal"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          data-testid="edit-bu-save-btn"
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" requiredMark>
        {!role ? (
          <Form.Item label="Tipo" name="selectRoleSubFamily">
            <Select
              data-testid="select-role-subFamily"
              placeholder="Selecione o tipo"
              onChange={$event => handleChangeType($event)}
            >
              <Option
                data-testid="select-option-or"
                value="role"
                selected
                disabled={families?.subFamiliesIds?.length > 0}
              >
                Cargos
              </Option>
              {!isRole ? (
                <Option
                  data-testid="select-option-and"
                  value="subFamily"
                  disabled={families?.rolesIds?.length > 0}
                >
                  Sub-Famílias
                </Option>
              ) : (
                <></>
              )}
            </Select>
          </Form.Item>
        ) : (
          <></>
        )}
        {type || role ? (
          <Form.Item label="Nome" name="name">
            <Input
              type="text"
              data-testid="name"
              key="name"
              placeholder="Digite um nome"
            />
          </Form.Item>
        ) : (
          <></>
        )}
        {type === 'subFamily' ? (
          <SelectSkills
            tagRenderFunc={({ label, ...props }) => (
              <Tag
                data-testid={`skill-${label}`}
                style={{ margin: 2 }}
                color="blue"
                {...props}
              >
                {label}
              </Tag>
            )}
            fieldLabel="Objetivos Específicos da Sub-Família"
            placeholder="Buscar objetivo específico (ex.: Análise de indicadores, React)"
            testId="select-family-skills"
            selectMode="multiple"
            fieldName="skills"
          />
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
}

AddRoleOrSubFamily.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  familyId: PropTypes.string,
  isRole: PropTypes.bool,
  role: PropTypes.objectOf(PropTypes.any),
  familyList: PropTypes.objectOf(PropTypes.any),
};

AddRoleOrSubFamily.defaultProps = {
  familyId: '',
  isRole: true,
  role: {},
  familyList: {},
};

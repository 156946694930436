/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserOutlined,
  EnvironmentOutlined,
  ThunderboltFilled,
} from '@ant-design/icons';
import { Skeleton, Checkbox, Spin, message } from 'antd';
import {
  DetailsCard,
  HighPotentialBadge,
  LeaderBadge,
  DeleteDialog,
} from '~/components';
import { getSoluter, clearSoluter } from '~/store/ducks/people';
import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import SoluterMood from './SoluterMood';
import SoluterBasicInfo from './SoluterBasicInfo';
import SoluterTechnicalInfo from './SoluterTechnicalInfo';
import SoluterJourney from './SoluterJourney';
import { people, validationMessages } from '~/constants';
import { getRole } from '~/config/environment';
import { removeSoluter } from '~/store/fetchActions/fetchPeople';
import DeleteBadge from '~/components/DeleteBadge/DeleteBadge';
import SoluterProjects from './SoluterProjects';

export default function SoluterProfile() {
  const { email } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const soluter = useSelector(state => state.people.soluter);
  const history = useHistory();
  const {
    isLoadingSoluterPhoto,
    isLoadingSolutersCostCenters,
    isLoadingSoluterBasicData,
    isLoadingleader,
    isLoadinghighPotential,
  } = useSelector(state => state.loading);
  const [isLeader, setLeader] = useState(soluter.leader);
  const [isHighPotential, setHighPotential] = useState(soluter.highPotential);
  const skeletonConfig = {
    className: 'soluter-card__skeleton',
    loading: isLoadingSoluterBasicData || isLoadingSolutersCostCenters,
    paragraph: { rows: 0 },
    title: { width: 400 },
    style: { marginTop: 0 },
  };
  useEffect(() => {
    dispatch(getSoluter(location?.state?.soluter));
    // eslint-disable-next-line
  }, [location?.state?.soluter]);

  useEffect(() => {
    dispatch(clearSoluter());
    dispatch(fetchPeople.getSoluterBasicData(email));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    setLeader(soluter?.leader);
    setHighPotential(soluter?.highPotential);
  }, [soluter?.leader, soluter?.highPotential]);

  const handleDelete = () => {
    return new Promise(() => {
      removeSoluter(soluter?.email).then(() => {
        message.success(validationMessages.HTTP_DELETE_SUCCESS_MESSAGE);
        history.replace('/soluters', {});
      });
    });
  };

  const tabsAdmin = [
    {
      title: 'Mini Currículo',
      content: <SoluterBasicInfo email={email} />,
    },
    {
      title: 'Jornada',
      content: <SoluterJourney email={email} />,
    },
    { title: 'Mood', content: <SoluterMood email={email} /> },
    {
      title: 'Informações Técnicas',
      content: <SoluterTechnicalInfo />,
    },
    {
      title: 'Projetos',
      content: <SoluterProjects soluter={soluter} />,
    },
    /* {
      title: 'Entrevista de Desligamento',
      content: <SoluterExitInterview soluter={soluter} />,
    }, */
  ];

  const tabsLeader = [
    {
      title: 'Mini Currículo',
      content: <SoluterBasicInfo email={email} />,
    },
    {
      title: 'Jornada',
      content: <SoluterJourney email={email} />,
    },
    {
      title: 'Informações Técnicas',
      content: <SoluterTechnicalInfo />,
    },
    {
      title: 'Projetos',
      content: <SoluterProjects soluter={soluter} />,
    },
    /* {
      title: 'Entrevista de Desligamento',
      content: <SoluterExitInterview soluter={soluter} />,
    }, */
  ];

  return (
    <DetailsCard
      image={soluter?.thumb}
      icon={<UserOutlined />}
      title={soluter.name || ''}
      titleSize={4}
      thumbLoading={isLoadingSoluterPhoto}
      description={
        <>
          <Skeleton {...skeletonConfig}>
            <p>
              <ThunderboltFilled style={{ color: 'orange' }} />{' '}
              {soluter.jobTitle} | {soluter.team} -{''}
              {soluter.costCenterId}
            </p>
          </Skeleton>
          {soluter.city && (
            <p>
              <EnvironmentOutlined /> {soluter.city}, {soluter.state}
            </p>
          )}
        </>
      }
      actions={
        <div className="d-flex">
          <div className="soluter-profile__container mr-md">
            <LeaderBadge
              active={isLeader}
              size={48}
              iconStyles={{ fontSize: 35 }}
            />
            <Spin
              spinning={isLoadingleader === true || isLoadingSoluterBasicData}
              size="small"
            >
              <Checkbox
                data-testid="leader-checkbox"
                defaultChecked={soluter.leader}
                checked={isLeader}
                onChange={async ({ target }) => {
                  try {
                    await fetchPeople.updateLeadershipOrHighPotential(
                      soluter.email,
                      'leader',
                      target.checked,
                      dispatch,
                    );
                    setLeader(target.checked);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {people.categories.LEADER}
              </Checkbox>
            </Spin>
          </div>
          <div className="soluter-profile__container">
            <HighPotentialBadge active={isHighPotential} size={48} />
            <Spin
              spinning={
                isLoadinghighPotential === true || isLoadingSoluterBasicData
              }
              size="small"
            >
              <Checkbox
                data-testid="high-potential-checkbox"
                defaultChecked={soluter?.highPotential}
                checked={isHighPotential}
                onChange={async ({ target }) => {
                  try {
                    await fetchPeople.updateLeadershipOrHighPotential(
                      soluter?.email,
                      'highPotential',
                      target.checked,
                      dispatch,
                    );
                    setHighPotential(target.checked);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {people.categories.HIGH_POTENTIAL}
              </Checkbox>
            </Spin>
          </div>
          <DeleteDialog
            currentKey="deletDialogKey"
            handleDelete={handleDelete}
            item={{ title: `o usuário ${email}`, id: '' }}
            subText=""
            content={<DeleteBadge size={48} />}
          />
        </div>
      }
      tabs={getRole() === 'admin' ? tabsAdmin : tabsLeader}
    />
  );
}

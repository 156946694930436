import { Card } from 'antd';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AllocationIndexHeader from './AllocationIndexHeader';
import GraphAllocationIndex from './GraphAllocationIndex';
import * as fetchIndicators from '~/store/fetchActions/fetchIndicators';

function AllocationIndexContainer() {
  const dispatch = useDispatch();
  const { allocationIndexGraph } = useSelector(state => state.indicators);
  const { selectedTower, selectedPractice } = useSelector(
    state => state.indicators,
  );
  useEffect(() => {
    dispatch(
      fetchIndicators.getAllocationGraphData(selectedPractice, selectedTower),
    );
  }, [dispatch, selectedTower, selectedPractice]);

  return (
    <>
      <Card className="graph-card">
        <AllocationIndexHeader />
        <GraphAllocationIndex data={allocationIndexGraph?.data} />
      </Card>
    </>
  );
}

export default React.memo(AllocationIndexContainer);

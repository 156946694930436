import { Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleAddForm, BasicTable, EmptyErrorWrapper } from '~/components';
import * as fetchRoles from '~/store/fetchActions/fetchRoles';
import { search as searchAction, selectEdit } from '~/store/ducks/roles';

const { Title } = Typography;

export default function Roles() {
  const { search, searchTitle, page, selectedRole, roles } = useSelector(
    state => state.roles,
  );
  const dispatch = useDispatch();

  const onFinish = (values, form) => {
    if (values.role) {
      dispatch(fetchRoles.add(search, searchTitle, values.role, page));
      form.resetFields();
    }
  };

  return (
    <>
      <Title>Papéis</Title>
      <SimpleAddForm
        onFinish={onFinish}
        itemName="role"
        placeholderMessage="Adicionar Papel"
        rules={[
          {
            type: 'string',
            max: 30,
            required: true,
            message:
              'O papel deve ter no máximo 30 caracteres e não deve conter apenas espaços vazios',
            whitespace: true,
          },
        ]}
      />
      <EmptyErrorWrapper
        data={roles}
        emptyCondition={roles !== null}
        retryAction={fetchRoles.get}
        retryProps={[page]}
        loaderTitle={roles ? '' : 'isLoading'}
      >
        <BasicTable
          stateSelector={state => state.roles}
          data={roles}
          title="Papel"
          editingItem={selectedRole}
          fetchActions={fetchRoles}
          searchAction={searchAction}
          selectEdit={selectEdit}
          rulesTooltip="O papel deve ter no mínimo 3 letras"
        />
      </EmptyErrorWrapper>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Space, Typography, Skeleton, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import {
  IdcardOutlined,
  StarOutlined,
  AlertOutlined,
  FormOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import { selectLoadingState } from '~/store/selectors';
import SoluterCompetences from './SoluterCompetences';

import { Button as CustomButton, Tag } from '~/components';
import { colors } from '~/constants';
import './styles.scss';
import AddEditExperiences from '~/container/Experiences/AddEditExperiences';

const { Title } = Typography;

export default function SoluterBasicInfo({ email }) {
  const {
    isLoadingSoluterBasicData,
    isLoadingSoluterCompetences,
    isLoadingBadgesPhotos,
  } = useSelector(selectLoadingState);
  const {
    skills,
    interests,
    minibio,
    competences,
    experiences,
    badges,
  } = useSelector(state => state.people.soluter);

  const [isVisible, setIsVisible] = useState(false);
  const handleClickIsVisible = () => {
    setIsVisible(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPeople.getSoluterJourney(email));
    dispatch(fetchPeople.getSoluterCompetences(email));
  }, [dispatch, email]);

  useEffect(() => {
    if (badges && !badges[0]?.thumb) {
      badges.map(item => dispatch(fetchPeople.getBadgePhotoById(item)));
    }
  }, [badges, dispatch]);

  return (
    <div>
      <Row>
        <Col span={24}>
          <div>
            <Title level={5}>Quem eu sou</Title>
            <div style={{ display: 'flex' }}>
              <Skeleton
                paragraph={{ rows: 0 }}
                loading={isLoadingSoluterBasicData}
              >
                {minibio ? (
                  <p>{minibio}</p>
                ) : (
                  <p className="soluter-profile__missing-info">
                    <IdcardOutlined /> Este usuário ainda não escreveu sobre si.
                  </p>
                )}
              </Skeleton>
            </div>
          </div>
          <div className="soluter-profile__item">
            <div className="soluter-profile__experience">
              <Title level={5}>Experiências</Title>
              <AddEditExperiences
                experienceList={experiences}
                isVisible={isVisible}
                setVisible={setIsVisible}
              />

              <CustomButton
                style={{ marginLeft: 5, fontSize: 16, marginBottom: 6 }}
                type={experiences?.length ? 'edit' : 'add'}
                isButton
                tooltipIdentifier="Experiências"
                handleClick={handleClickIsVisible}
              />
            </div>

            <div style={{ display: 'flex' }}>
              <Skeleton
                paragraph={{ rows: 0 }}
                loading={isLoadingSoluterBasicData}
              >
                <Space size={[8, 16]} wrap>
                  {experiences && experiences?.length > 0 ? (
                    experiences?.map(experience => (
                      <Tag
                        tag={{ tag: experience }}
                        backgroundColor={colors.EXPERIENCES}
                        textColor="white"
                      />
                    ))
                  ) : (
                    <p className="soluter-profile__missing-info">
                      <StarOutlined /> Nenhuma experiência registrada (ainda).
                    </p>
                  )}
                </Space>
              </Skeleton>
            </div>
          </div>
          <div className="soluter-profile__item">
            <Title level={5}>Habilidades</Title>
            <div style={{ display: 'flex' }}>
              <Skeleton
                paragraph={{ rows: 0 }}
                loading={isLoadingSoluterBasicData}
              >
                <Space size={[8, 16]} wrap>
                  {skills && skills?.length > 0 ? (
                    skills?.map(skill => (
                      <Tag
                        tag={{ tag: skill }}
                        backgroundColor={colors.SKILLS}
                        textColor="white"
                      />
                    ))
                  ) : (
                    <p className="soluter-profile__missing-info">
                      <StarOutlined /> Nenhuma habilidade registrada (ainda).
                    </p>
                  )}
                </Space>
              </Skeleton>
            </div>
          </div>
          <div className="soluter-profile__item">
            <Title level={5}>Interesses</Title>
            <Skeleton
              paragraph={{ rows: 0 }}
              loading={isLoadingSoluterBasicData}
            >
              <Space size={[8, 16]} wrap>
                {interests && interests?.length > 0 ? (
                  interests?.map(interest => (
                    <Tag
                      tag={{ tag: interest }}
                      backgroundColor={colors.TECH}
                      textColor="white"
                    />
                  ))
                ) : (
                  <p className="soluter-profile__missing-info">
                    <AlertOutlined /> Nenhum interesse registrado (ainda).
                  </p>
                )}
              </Space>
            </Skeleton>
          </div>
          <div className="soluter-profile__item">
            <div className="certification-title">
              <Title level={5}>Certificações</Title>
            </div>
            <Skeleton
              paragraph={{ rows: 0 }}
              loading={isLoadingSoluterCompetences}
            >
              {competences?.length > 0 ? (
                <SoluterCompetences competences={competences} />
              ) : (
                <p className="soluter-profile__missing-info">
                  <FormOutlined /> Nenhuma competência registrada (ainda).
                </p>
              )}
            </Skeleton>
          </div>
          <div className="soluter-profile__item">
            <div className="badges-title">
              <Title level={5}>Badges</Title>
            </div>
            <Skeleton paragraph={{ rows: 0 }} loading={isLoadingBadgesPhotos}>
              <div className="badges-wrapper">
                {badges?.length > 0 ? (
                  badges?.map(badge => (
                    <Link
                      className="badges-card"
                      to={`/badges/edit/${badge?.badgeId}`}
                    >
                      <div className="badges-header">
                        <span>
                          <img
                            src={badge?.thumb}
                            alt="imagem"
                            className="details-card__img"
                          />
                        </span>
                        <strong>{badge?.title}</strong>
                      </div>
                    </Link>
                  ))
                ) : (
                  <p className="soluter-profile__missing-info">
                    <FormOutlined /> Nenhuma badge registrada (ainda).
                  </p>
                )}
              </div>
            </Skeleton>
          </div>
        </Col>
      </Row>
    </div>
  );
}

SoluterBasicInfo.propTypes = {
  email: PropTypes.string.isRequired,
};

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Typography, Button } from 'antd';
import { MoodProgress } from '~/container';
import { getMood } from '~/store/fetchActions/fetchMood';
import ColumnGraph from './ColumnGraph';
import MoodEmojiTagsDrawer from './MoodEmojiTagsDrawer';
import MoodWeeklyTagsDrawer from './MoodWeeklyTagsDrawer';
import useMoodData from '~/hooks/useMoodData';
import { selectMoodWeekInterval } from '~/store/selectors/mood';
import { EmptyErrorWrapper } from '~/components';

const { Title } = Typography;

export default function MoodDistribution() {
  const dispatch = useDispatch();
  const { selectedTower, typeFilter } = useSelector(state => state.mood);
  const week = useSelector(selectMoodWeekInterval);
  const { isLoadingMoods } = useSelector(state => state.loading);
  const [isEmojiDrawerOpen, setIsEmojiDrawerOpen] = useState(false);
  const [isWeeklyDrawerOpen, setIsWeeklyDrawerOpen] = useState(false);
  const [weekDay, setWeekDay] = useState('');
  const [startDay, setStartDay] = useState('');
  const [endDay, setEndDay] = useState('');
  const [moodIndex, setMoodIndex] = useState('');
  const emptyList = [0, 0, 0, 0, 0];

  const handleOpenDrawer = mood => {
    setMoodIndex(mood);
    setIsEmojiDrawerOpen(true);
  };

  const data = useMoodData(
    'moods',
    getMood,
    selectMoodWeekInterval,
    null,
    selectedTower,
  );

  return (
    <Row>
      <Col span={17}>
        <Title className="mood-title mood-title-mood-progress" level={4}>
          Distribuição de Sentimentos
        </Title>
        <EmptyErrorWrapper
          data={data}
          emptyCondition={data?.periodicMoods}
          loaderTitle="isLoadingMoods"
          retryAction={getMood}
          retryProps={[week, selectedTower]}
          emptyMessage="Não há moods registrados no período selecionado."
          style={{ height: 350 }}
        >
          <ColumnGraph
            data={data}
            setIsWeeklyDrawerOpen={setIsWeeklyDrawerOpen}
            setWeekDay={setWeekDay}
            setStartDay={setStartDay}
            setEndDay={setEndDay}
          />
        </EmptyErrorWrapper>
      </Col>
      <Col span={5} offset={2}>
        <MoodProgress
          progressList={data?.averageMood || emptyList}
          title="Média de Reações do Período"
          margin={58}
          loaderTitle="isLoadingMoods"
          handleOpenDrawer={handleOpenDrawer}
          isLoading={isLoadingMoods}
        />
        <MoodEmojiTagsDrawer
          moodIndex={moodIndex}
          isDrawerOpen={isEmojiDrawerOpen}
          setIsDrawerOpen={setIsEmojiDrawerOpen}
        />
        <MoodWeeklyTagsDrawer
          isDrawerOpen={isWeeklyDrawerOpen}
          setIsDrawerOpen={setIsWeeklyDrawerOpen}
          weekDay={weekDay}
          startDay={startDay}
          endDay={endDay}
        />
      </Col>
    </Row>
  );
}

/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { selectAuthState } from '~/store/selectors';

function PrivateRoute({ children, ...rest }) {
  const { authorized } = useSelector(selectAuthState);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default withRouter(PrivateRoute);

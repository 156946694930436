/* eslint-disable no-console */
import { message } from 'antd';
import { validationMessages } from '~/constants';
import {
  getCapacity as getTowerCapacity,
  getTowers,
  getProjects,
  getTowerPeople,
  getTower,
  getBusinessUnits,
  setRoleFilters,
  setTechFilters,
  setSeniorityFilters,
  getCapacityCSVData,
  updateTeamMemberPhoto,
  updateTowerById,
  updateTowerContentById,
  getTowersPaged,
  setTowersNoBu,
} from '~/store/ducks/towers';
import { startLoading, endLoading } from '~/store/ducks/loading';
import imageHelper from '~/helpers/imageHelper';

import api from '~/config/httpRequest';

export function getCapacityCSV(startDate, endDate, towerId) {
  return async dispatch => {
    api
      .get('/rpm/capacity/export', {
        params: {
          startDate,
          endDate,
          groupId: towerId,
        },
      })
      .then(({ data }) => {
        dispatch(getCapacityCSVData(data));
      })
      .catch(err => console.err);
  };
}

export function getCapacity(
  startDate,
  endDate,
  roleFilters = [],
  techFilters = [],
  seniorityFilters = [],
  onlyAllocated,
  towerId,
  groupingMode = 'projects',
) {
  return dispatch => {
    dispatch(startLoading('isLoadingCapacity'));
    api
      .get(`/rpm/capacity/${groupingMode === 'projects' ? '' : 'people'}`, {
        params: {
          startDate,
          endDate,
          role: roleFilters.toString(),
          seniority: seniorityFilters.toString(),
          technology: techFilters.toString(),
          onlyAllocated,
          groupId: towerId,
        },
      })
      .then(({ data: payload }) => {
        if (payload) {
          if (
            roleFilters?.length === 0 &&
            seniorityFilters?.length === 0 &&
            techFilters?.length === 0
          ) {
            const people = [];
            payload?.forEach(project => {
              project?.[
                groupingMode === 'projects' ? 'team' : 'projects'
              ]?.forEach(team => {
                people.push(team);
              });
            });

            dispatch(
              setRoleFilters(
                people
                  ?.map(item => {
                    if (item.role) return item.role;
                    return '';
                  })
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index && value !== '';
                  }),
              ),
            );

            dispatch(
              setTechFilters(
                people
                  ?.map(item => {
                    if (item.technology) return item.technology;
                    return '';
                  })
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index && value !== '';
                  }),
              ),
            );

            dispatch(
              setSeniorityFilters(
                [].map
                  .call(
                    groupingMode === 'projects' ? people : payload,
                    item => {
                      if (item.seniority) return item.seniority;
                      return '';
                    },
                  )
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index && value !== '';
                  }),
              ),
            );
          }

          payload?.forEach(item => {
            item[groupingMode === 'projects' ? 'team' : 'projects'].push({
              _id: `${item._id}_totalHours`,
              name: 'Total',
              totalHoursPerGrouping: parseFloat(item.totalHours).toFixed(2),
            });
          });
        } else {
          dispatch(setRoleFilters([]));
          dispatch(setTechFilters([]));
          dispatch(setSeniorityFilters([]));
        }

        dispatch(getTowerCapacity(payload));
        dispatch(endLoading('isLoadingCapacity'));
      })
      .catch(err => {
        console.error(err);
        dispatch(getTowerCapacity(null));
        dispatch(endLoading('isLoadingCapacity'));
      });
  };
}

export const listTowers = (
  photo = false,
  allGroups = true,
  groupName = '',
  CE = '',
  BUS = [],
) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingTowers'));
    api
      .get(`/rpm/groups`, {
        params: {
          allGroups,
          groupName,
          emailCE: CE,
          BUs: formatBusToFilter(BUS),
        },
      })
      .then(async ({ data: payload }) => {
        await dispatch(getTowers(payload));
        if (photo && payload) getAllTowersPhoto(payload, dispatch);
        dispatch(endLoading('isLoadingTowers'));
      })
      .catch(err => {
        console.error(err);
        dispatch(endLoading('isLoadingTowers'));
        dispatch(getTowers(null));
      });
  };
};

export const listTowersPaged = (
  photo = false,
  allGroups = true,
  groupName = '',
  CE = '',
  BUS = [],
  page,
  pageSize,
) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingTowers'));
    api
      .get(`/rpm/groups/paged`, {
        params: {
          allGroups,
          groupName,
          emailCE: CE,
          BUs: formatBusToFilter(BUS),
          page,
          pageSize,
        },
      })
      .then(async ({ data: payload }) => {
        await dispatch(getTowersPaged({ towersPaged: payload, page }));
        if (photo && payload?.content)
          getAllTowersPagedPhoto(payload?.content, dispatch);

        dispatch(endLoading('isLoadingTowers'));
      })
      .catch(err => {
        console.error(err);
        dispatch(endLoading('isLoadingTowers'));
        dispatch(getTowers(null));
      });
  };
};

export const getTowerById = (
  towerId,
  loadingString = 'isLoadingTower',
  shouldLoadPhoto = true,
) => {
  return async dispatch => {
    dispatch(startLoading(loadingString));
    api
      .get(`/rpm/groups/${towerId}`)
      .then(async ({ data: payload }) => {
        if (payload && shouldLoadPhoto) {
          try {
            payload.photo = await getTowerPhoto(towerId);
          } catch (error) {
            console.log(
              `Não foi possível carregar o foto da torre: ${payload.name}`,
              error,
            );
          }
        }
        dispatch(endLoading(loadingString));
        dispatch(getTower({ ...payload, _id: towerId }));
      })
      .catch(err => {
        console.error(err);
        dispatch(endLoading(loadingString));
      });
  };
};

export function addTower(tower, allGroups = true, costCenter) {
  const payload = !costCenter
    ? { ...tower, photo: '' }
    : { ...tower, photo: '', id: tower._id };

  if (payload.vertical !== undefined) {
    switch (payload.vertical) {
      case 'PSU - Setor Público e Utilities' || 'PSU':
        payload.vertical = 'PSU';
        break;
      case 'FS - Setor Financeiro' || 'FS':
        payload.vertical = 'FS';
        break;
      case 'CPR - Varejo e Bens de Consumo' || 'CPR':
        payload.vertical = 'CPR';
        break;
      case 'TM - Telecom e Mídia' || 'TM':
        payload.vertical = 'TM';
        break;
      default:
        break;
    }
  }

  return async dispatch => {
    api
      .post(`/rpm/groups`, payload)
      .then(res => {
        if (tower.photo?.file) {
          const formDataPhoto = new FormData();
          formDataPhoto.append('file', tower.photo.file);
          dispatch(
            addTowerPhoto(res.data._id, formDataPhoto, tower.id, allGroups),
          );
        } else if (tower.id) {
          dispatch(getTowerById(tower.id));
        } else if (costCenter) {
          message.success(validationMessages.HTTP_ADD_SUCCESS_MESSAGE);
        } else dispatch(listTowers(true, allGroups));
      })
      .catch(console.error);
  };
}

export function deleteTower(towerId, allGroups = true) {
  return async dispatch => {
    api
      .delete(`/rpm/groups/${towerId}`)
      .then(() => {
        dispatch(listTowers(true, allGroups));
        // message.success
      })
      .catch(console.error);
  };
}

export function getTowerProjects(
  towerId,
  shouldGetMembersPhotos = true,
  year = new Date().getFullYear().toString(),
) {
  let startDate = '';
  let endDate = '';
  if (year) {
    startDate = `01-01-${year}`;
    endDate = `31-12-${year}`;
  }
  return async dispatch => {
    dispatch(startLoading('isLoadingProjects'));
    api
      .get(`/rpm/groups/${towerId}/projects`, {
        params: {
          startDate,
          endDate,
          onlyActive: false,
        },
      })
      .then(({ data: payload }) => {
        dispatch(getProjects(payload));
        dispatch(endLoading('isLoadingProjects'));
        if (shouldGetMembersPhotos && payload) {
          payload?.map(project => {
            if (project?.team?.length > 0)
              imageHelper.getUserPhotos(
                dispatch,
                project.team,
                updateTeamMemberPhoto,
                'personKey',
                'photo',
              );
            return project;
          });
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(getProjects(null));
        dispatch(endLoading('isLoadingProjects'));
      });
  };
}

export function addProject(project, year) {
  return async dispatch => {
    api
      .post(`/rpm/projects`, project)
      .then(({ data: payload }) => {
        dispatch(getTowerById(project.groupId, 'isLoadingProjectTower'));
        dispatch(getTowerProjects(project.groupId, true, year));
      })
      .catch(console.error);
  };
}

export function deleteProject(projectId, towerId, year) {
  return async dispatch => {
    api
      .delete(`/rpm/projects/${projectId}`)
      .then(({ data: payload }) => {
        dispatch(getTowerProjects(towerId, true, year));
      })
      .catch(console.error);
  };
}

export function getPeopleTower(query = '') {
  return async dispatch => {
    dispatch(startLoading('isLoadingPeople'));
    api
      .get(`/soluters/users`, {
        params: {
          name: query,
          page: 1,
          pageSize: 10,
        },
      })
      .then(({ data: payload }) => {
        dispatch(getTowerPeople(payload.content));
        dispatch(endLoading('isLoadingPeople'));
      })
      .catch(err => {
        console.error(err);
        dispatch(endLoading('isLoadingPeople'));
      });
  };
}

export function getDismissedSoluters(query = '') {
  return async dispatch => {
    dispatch(startLoading('isLoadingPeople'));
    api
      .get(`/soluters/journey/users/dismissed-employees`, {
        params: {
          term: query,
          page: 1,
          pageSize: 10,
        },
      })
      .then(({ data: payload }) => {
        dispatch(getTowerPeople(payload.content));
        dispatch(endLoading('isLoadingPeople'));
      })
      .catch(err => {
        console.error(err);
        dispatch(endLoading('isLoadingPeople'));
      });
  };
}

export function createTeamMember(projectId, allocation) {
  return new Promise((resolve, reject) => {
    api
      .post(`/rpm/schedule/project/${projectId}/teamMember`, allocation)
      .then(({ data: res }) => {
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function updateTeamMember(projectId, teamMemberId, body) {
  return new Promise((resolve, reject) => {
    api
      .put(
        `/rpm/schedule/project/${projectId}/teamMember/${teamMemberId}`,
        body,
      )
      .then(({ data: res }) => {
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function removeTeamMember(project, allocationId) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/rpm/schedule/project/${project._id}/teamMember/${allocationId}`)
      .then(({ data: res }) => {
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function listBusinessUnits() {
  return async (dispatch, getState) => {
    api
      .get(`/rpm/businessUnits`)
      .then(({ data: payload }) => {
        dispatch(getBusinessUnits(payload));
      })
      .catch(console.error);
  };
}

export function getSchedule(
  towerId,
  year = new Date().getFullYear().toString(),
  onlyActive = false,
) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/rpm/groups/${towerId}/projects?startDate=01-01-${year}&endDate=31-12-${year}&onlyActive=${onlyActive}`,
      )
      .then(response => resolve(response.data))
      .catch(erro => reject(erro));
  });
}

export function getScheduleSoluter(
  soluterEmail,
  year = new Date().getFullYear().toString(),
  onlyActive = false,
) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/soluters/users/${soluterEmail}/projects?startDate=01-01-${year}&endDate=31-12-${year}&onlyActive=${onlyActive}`,
      )
      .then(response => resolve(response.data))
      .catch(erro => reject(erro));
  });
}

export function getProjectLeader(projectId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/rpm/projects/${projectId}/leader`)
      .then(response => resolve(response.data))
      .catch(erro => {
        reject(erro);
      });
  });
}

export function addAllocation(allocation) {
  return new Promise((resolve, reject) => {
    api
      .post(`/rpm/schedule/sync`, {
        events: {
          added: [allocation],
        },
      })
      .then(response => resolve(response.data))
      .catch(erro => reject(erro));
  });
}

export function updateAllocation(allocation) {
  return new Promise((resolve, reject) => {
    api
      .post(`/rpm/schedule/sync`, {
        events: {
          updated: [allocation],
        },
      })
      .then(response => resolve(response.data))
      .catch(erro => reject(erro));
  });
}

export function removeAllocation(allocation) {
  return new Promise((resolve, reject) => {
    api
      .post(`/rpm/schedule/sync`, {
        events: {
          removed: [allocation],
        },
      })
      .then(response => resolve(response.data))
      .catch(erro => reject(erro));
  });
}

export const addTowerPhoto = (towerId, photo, hasId, allGroups = true) => {
  return dispatch => {
    dispatch(startLoading('isLoadingTowerPhoto'));
    api
      .post(`/rpm/groups/${towerId}/image`, photo)
      .then(({ data: payload }) => {
        if (hasId) dispatch(getTowerById(towerId));
        else dispatch(listTowers(true, allGroups));
        dispatch(endLoading('isLoadingTowerPhoto'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingTowerPhoto'));
      });
  };
};

const getTowerPhoto = towerId => {
  return new Promise((resolve, reject) => {
    api
      .get(`/rpm/groups/${towerId}/image`, imageHelper.responseType())
      .then(response => imageHelper.convertBytesToBase64(response, resolve))
      .catch(reject);
  });
};

const getAllTowersPhoto = async (towers, dispatch) => {
  towers.forEach(tower => {
    const { _id, name } = tower;
    getTowerPhoto(_id)
      .then(thumb => dispatch(updateTowerById({ ...tower, thumb })))
      .catch(error => {
        console.log(
          `Não foi possível carregar a imagem da torre: ${name}`,
          error,
        );
        dispatch(updateTowerById({ ...tower, thumb: 'no photo' }));
      });
  });
};

const getAllTowersPagedPhoto = async (towers, dispatch) => {
  towers.forEach(tower => {
    const { _id, name } = tower;
    getTowerPhoto(_id)
      .then(thumb => dispatch(updateTowerContentById({ ...tower, thumb })))
      .catch(error => {
        console.log(
          `Não foi possível carregar a imagem da torre: ${name}`,
          error,
        );
        dispatch(updateTowerContentById({ ...tower, thumb: 'no photo' }));
      });
  });
};

export const getTowersNoBus = () => dispatch => {
  api
    .get('/soluters/users/no-group/notification')
    .then(({ data }) => {
      dispatch(setTowersNoBu(data?.total));
    })
    .catch(err => {
      console.log(err);
    });
};

export function addVacation(vacation) {
  return new Promise((resolve, reject) => {
    api
      .post(`/rpm/capacity/vacations`, vacation)
      .then(response => resolve(response.data))
      .catch(erro => reject(erro));
  });
}

export function getVacation(userEmail) {
  return new Promise((resolve, reject) => {
    api
      .get(`/rpm/capacity/vacations/${userEmail}`)
      .then(response => resolve(response.data))
      .catch(erro => reject(erro));
  });
}

export function deleteVacation(vacationId) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/rpm/capacity/vacations/${vacationId}`)
      .then(response => resolve(response.data))
      .catch(erro => reject(erro));
  });
}

export function disableTower(towerId, activeStatus, allGroups = true) {
  return async dispatch => {
    api
      .put(`/rpm/groups/disable/${towerId}?active=${activeStatus}`)
      .then(({ data: payload }) => {
        message.success(
          activeStatus
            ? 'A torre foi habilitada, agora suas informações serão exibidas no aplicativo do Passaporte!'
            : 'A torre foi desabilitada e não será mais exibida no aplicativo do Passaporte.',
        );
        dispatch(listTowers(true, allGroups));
      })
      .catch(console.error);
  };
}

export function getAllocated(userId) {
  return new Promise((resolve, reject) => {
    if (userId !== undefined) {
      api
        .get(`/rpm/allocated/${userId}/projects`)
        .then(response => {
          resolve(response.data);
        })
        .catch(erro => reject(erro));
    }
  });
}

function formatBusToFilter(bus) {
  if (bus) {
    return bus.join(',');
  }

  return '';
}

import { Form, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCostCenters } from '~/store/fetchActions/fetchPractices';

const { Option } = Select;

export default function CostCenterFilter({
  selectedCostCenter,
  duck,
  formLayout,
}) {
  const [error, setError] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleSubmit = () => {};
  const { costCenters } = useSelector(state => state.practices);

  useEffect(() => {
    if (selectedCostCenter)
      form.setFieldsValue({
        costCenter: selectedCostCenter,
      });
  }, [form, selectedCostCenter]);

  useEffect(() => {
    dispatch(getCostCenters('ab'));
  }, [dispatch]);

  const onSearch = value => {
    if (value?.length >= 2) {
      dispatch(getCostCenters(value));
      setError(false);
    } else {
      setError(true);
    }
  };

  const onChange = value => {
    if (value === undefined) value = '';
    dispatch(duck(value));
  };

  return (
    <Form form={form} layout={formLayout} requiredMark onFinish={handleSubmit}>
      <Form.Item name="costCenter" style={{ marginBottom: 28 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            placeholder="Buscar por Centro de Custo"
            showSearch
            allowClear
            data-testid="select-cost-center"
            defaultValue={selectedCostCenter !== '' ? selectedCostCenter : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChange}
            onBlur={() => {
              setError(false);
            }}
            style={{ width: 300 }}
          >
            {costCenters?.map(costCenter => (
              <Option
                key={costCenter.id}
                value={costCenter.id}
                data-testid={`cost-center-option-${costCenter.id}`}
              >
                {`${costCenter.id} - ${costCenter.title}`}
              </Option>
            ))}
          </Select>
          <p
            style={{
              color: 'red',
              marginLeft: '10px',
              marginBottom: 0,
              visibility: error ? 'visible' : 'hidden',
            }}
          >
            Busca: mínimo de 2 caracteres
          </p>
        </div>
      </Form.Item>
    </Form>
  );
}

CostCenterFilter.propTypes = {
  selectedCostCenter: PropTypes.number.isRequired,
  duck: PropTypes.func.isRequired,
  formLayout: PropTypes.string,
};

CostCenterFilter.defaultProps = {
  formLayout: 'vertical',
};

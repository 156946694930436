import { useParams } from 'react-router-dom';
import { BadgeDetails as Details } from '~/container';

export default function TowerDetails() {
  const { badgeId } = useParams();
  return (
    <div>
      <Details badgeId={badgeId} />
    </div>
  );
}

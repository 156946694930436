import React from 'react';

import { Input, Space, Select, Row } from 'antd';

import ControlledPracticeField from '~/components/PracticeFilter/ControlledPracticeField';
import { SelectSearchPeople } from '~/components';

import ControlledSelectTowers from '~/components/SelectTowers/ControlledSelectTowers';
import { useCompetenceFilter } from './hooks/useCompetenceFilter';

const { Search } = Input;

export default function CompetenceFilter() {
  const {
    soluter,
    businessUnit,
    tower,
    handleChangeTower,
    handleChangeBU,
    handleChangeSoluter,
    handleChangeValid,
    handleChangeCompetence,
  } = useCompetenceFilter();

  const { Option } = Select;

  return (
    <>
      <Row width={100} style={{ width: '100%' }} align="top">
        <div>
          <Select
            placeholder="Selecione um Status"
            onChange={e => {
              handleChangeValid(e);
            }}
            filterOption={false}
            allowClear
            data-testid="competence-select"
            style={{ width: 250, marginBottom: 20 }}
          >
            <Option data-testid="select-option-or" value="VALIDADO">
              Validado
            </Option>
            <Option data-testid="select-option-and" value="INVALIDADO">
              Invalidado
            </Option>
            <Option data-testid="select-option-and" value="PENDENTE DE ANÁLISE">
              Pendente de Análise
            </Option>
            <Option data-testid="select-option-and" value="PENDENTE ARQUIVO">
              Pendente Arquivo
            </Option>
          </Select>
        </div>
      </Row>
      <div className="competence-filter">
        <Space>
          <ControlledPracticeField
            onChangePractice={handleChangeBU}
            practice={businessUnit}
            style={{ minWidth: 250 }}
          />
          <ControlledSelectTowers
            onChangeTower={handleChangeTower}
            tower={tower}
          />
          <SelectSearchPeople
            testId="people-select"
            value={soluter || null}
            onChange={handleChangeSoluter}
            customStyle={{ minWidth: 300 }}
          />
        </Space>
        <Search
          placeholder="Buscar por Certificação"
          className="competence-name"
          data-testid="competence-field"
          onSearch={handleChangeCompetence}
          style={{ marginLeft: '8px' }}
          enterButton
          allowClear
        />
      </div>
    </>
  );
}

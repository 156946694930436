/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { List, Avatar, Skeleton, Input, Row, Empty, Modal } from 'antd';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { selectLoadingState } from '~/store/selectors';
import {
  getMembersByDistrictId,
  getMembersCsv,
} from '~/store/fetchActions/fetchDistricts';
import { clearMembersCsvData } from '~/store/ducks/districts';
import { DownloadButton } from '~/components';

const { Search } = Input;
export default function MemberList({ district, isEditing }) {
  const dispatch = useDispatch();
  const { members, membersCsvData } = useSelector(state => state.districts);
  const { isLoading } = useSelector(selectLoadingState);
  const pageSize = 5;
  const [page, setPage] = useState(1);
  const [searchTitle, setSearchTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!(typeof district.districtId === 'undefined'))
      dispatch(getMembersByDistrictId(district?.districtId, 1, pageSize));
  }, [dispatch, district]);

  const handleCsvDownload = () => {
    dispatch(getMembersCsv(district?.districtId));
  };

  const handleSearch = value => {
    if (searchTitle === '' || value === '') setPage(1);
    setSearchTitle(value);
    dispatch(
      getMembersByDistrictId(
        district?.districtId,
        searchTitle === '' || value === '' ? 1 : page,
        pageSize,
        value,
      ),
    );
  };
  const handleChangeModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <div>
      {!isEditing && (
        <Row justify="space-between" align="top">
          <Search
            placeholder="Pesquisar Membro"
            data-testid="search-member-input"
            allowClear
            onSearch={handleSearch}
            enterButton
            style={{ width: '60%' }}
            className="mb-md"
          />
          <DownloadButton
            exportCsvLayout
            data={membersCsvData || null}
            requestData={handleCsvDownload}
            clearData={clearMembersCsvData}
            fileName={`Distrito-${district?.name}-membros.csv`}
            label="Exportar CSV"
          />
        </Row>
      )}
      <div className="district-members-infinite-scroll">
        <List
          loading={isLoading}
          dataSource={members?.content || []}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Não há membros do distrito que correspondem à busca"
              />
            ),
          }}
          pagination={{
            current: page,
            pageSize,
            pageSizeOptions: [],
            showSizeChanger: false,
            total: members?.total || 0,
            hideOnSinglePage: true,
            onChange: _page => {
              setPage(_page);
              dispatch(
                getMembersByDistrictId(
                  district?.districtId,
                  _page,
                  pageSize,
                  searchTitle,
                ),
              );
            },
          }}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={
                  item?.photo ? (
                    <Avatar src={item.photo} />
                  ) : (
                    <Skeleton.Avatar
                      loading={!item?.photo}
                      avatar
                      active={!item?.photo}
                    />
                  )
                }
                title={
                  item.userProfile ? (
                    <Link to={`/soluters/${item.email}`}>{item.name}</Link>
                  ) : (
                    <a href="#!" onClick={handleChangeModal}>
                      {item.name}
                    </a>
                  )
                }
                description={item.email}
              />
            </List.Item>
          )}
        />
      </div>
      <Modal
        title="Aviso Informativo"
        onCancel={handleChangeModal}
        centered
        visible={isModalOpen}
        onOk={handleChangeModal}
        footer={
          <button
            type="button"
            className="ant-btn ant-btn-primary"
            onClick={handleChangeModal}
          >
            <span>Ok</span>
          </button>
        }
      >
        <p>Ops..., não foi possivel visualizar o perfil soluter.</p>
      </Modal>
    </div>
  );
}

MemberList.propTypes = {
  district: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
};

MemberList.defaultProps = {
  isEditing: false,
};

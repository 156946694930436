import { useDispatch, useSelector } from 'react-redux';
import { SimpleAddForm, BasicTable, EmptyErrorWrapper } from '~/components';
import { selectSkillsState } from '~/store/selectors';
import * as fetchSkills from '~/store/fetchActions/fetchSkills';
import { search as searchAction, selectEdit } from '~/store/ducks/skills';

export default function SkillsSuggestions() {
  const { search, searchTitle, page, selectedSkill, skills } = useSelector(
    selectSkillsState,
  );
  const dispatch = useDispatch();

  const onFinish = (values, form) => {
    if (values.skill) {
      dispatch(fetchSkills.add(search, searchTitle, values.skill, page));
      form.resetFields();
    }
  };
  return (
    <>
      <SimpleAddForm
        onFinish={onFinish}
        itemName="skill"
        placeholderMessage="Adicionar Habilidade"
        rules={[
          {
            type: 'string',
            min: 1,
            max: 30,
            required: true,
            message: 'A habilidade deve ter no máximo 30 caracteres',
          },
        ]}
      />
      <EmptyErrorWrapper
        data={skills}
        emptyCondition={skills !== null}
        retryAction={fetchSkills.get}
        retryProps={[page]}
        loaderTitle=""
      >
        <BasicTable
          stateSelector={state => state.skills}
          data={skills}
          title="Habilidades"
          editingItem={selectedSkill}
          fetchActions={fetchSkills}
          searchAction={searchAction}
          selectEdit={selectEdit}
          rulesTooltip="A nova habilidade deve ter 3 caracteres ou mais"
        />
      </EmptyErrorWrapper>
    </>
  );
}

import { List, Empty } from 'antd';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as fetchDistricts from '~/store/fetchActions/fetchDistricts';
import { GenericCard, EmptyErrorWrapper } from '~/components';
import { selectDistrictsState } from '~/store/selectors';

export default function DistrictsList() {
  const dispatch = useDispatch();
  const { districts: data } = useSelector(selectDistrictsState);

  useEffect(() => {
    dispatch(fetchDistricts.get());
  }, [dispatch]);

  const handleDelete = id => {
    /* dispatch(fetchDistricts.remove(id)); */
  };

  return (
    <EmptyErrorWrapper
      data={data}
      emptyCondition={data}
      loaderTitle="isLoading"
      retryAction={fetchDistricts.get}
    >
      <List
        grid={{
          column: 3,
          sm: 1,
          md: 2,
        }}
        pagination={{
          position: 'bottom',
          pageSize: 6,
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: (
            <Empty
              data-testid="empty-districts-list"
              description="Não há distritos registrados."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        dataSource={data}
        renderItem={district => (
          <List.Item>
            <GenericCard
              prefix="districts"
              item={district}
              handleDelete={handleDelete}
              backgroundColor={district.color}
            />
          </List.Item>
        )}
      />
    </EmptyErrorWrapper>
  );
}

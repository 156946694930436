const colors = {
  DEFAULT_COLOR: '#1976d2',
  COLOR_PICKER_OPTIONS: [
    '#1976d2',
    '#12A6F9',
    '#F6457B',
    '#AB5EE9',
    '#60E8DA',
    '#FF7B00',
    '#FBD034',
    '#000000',
  ],
  // Colors that are common or explicitly defined
  PRIMARY: '#1067FB', // Blue used for most of the text and buttons
  SKILLS: '#BA7CED', // Purple used for skills
  EXPERIENCES: '#60E8DA', // Green used for experiences
  TECH: '#FF7B00', // Also used for interests
  TECHNOLOGIES: '#1890ff', // Blue used for technologies
};

export default colors;

import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  participation: [],
  week: { start: '', end: '' },
  moods: [],
  comments: {
    data: [],
    page: 1,
    total: 0,
  },
  tags: [],
  smileTags: { approved: [], notApproved: [] },
  weeklyTags: { approved: [], notApproved: [] },
  approvedTags: [],
  notApprovedTags: [],
  csvData: null,
  total: 0,
  page: 0,
  search: false,
  searchTitle: '',
  selectedTower: '',
  selectedTowerName: '',
  selectedPractice: '',
  selectedCostCenters: [],
  selectedPractices: [],
  selectedTowers: [],
  monthlyAverage: [],
  isMonthlyAverage: false,
  isYearly: false,
};

export const setIsMonthlyAverageTrue = createAction('SET_MONTHLY_AVERAGE_TRUE');
export const setIsMonthlyAverageFalse = createAction(
  'SET_MONTHLY_AVERAGE_FALSE',
);

export const setYearlyTrue = createAction('SET_YEARLY_TRUE');
export const setYearlyFalse = createAction('SET_YEARLY_FALSE');

export const getAllTags = createAction('MOOD_GET_TAGS');
export const addTag = createAction('MOOD_ADD_TAG');
export const removeTag = createAction('MOOD_REMOVE_TAG');
export const getCsvData = createAction('MOOD_GET_CSV_DATA');
export const clearCsvData = createAction('MOOD_CLEAR_CSV_DATA');

export const setWeekInterval = createAction('MOOD_SET_INTERVAL');
export const getParticipation = createAction('MOOD_GET_PARTICIPATION');
export const getMonthlyAverageAction = createAction('MOOD_GET_MONTHLY_AVERAGE');
export const getApprovedTags = createAction('MOOD_GET_APPROVED_TAGS');
export const getNotApprovedTags = createAction('MOOD_GET_NOT_APPROVED_TAGS');
export const getApprovedSmileTags = createAction(
  'MOOD_GET_APPROVED_SMILE_TAGS',
);
export const getNotApprovedSmileTags = createAction(
  'MOOD_GET_NOT_APPROVED_SMILE_TAGS',
);
export const getApprovedWeeklyTags = createAction(
  'MOOD_GET_APPROVED_WEEKLY_TAGS',
);
export const getNotApprovedWeeklyTags = createAction(
  'MOOD_GET_NOT_APPROVED_WEEKLY_TAGS',
);
export const getAllMoods = createAction('MOOD_GET_ALL');
export const getMoodComments = createAction('MOOD_GET_COMMENTS');
export const updateUserName = createAction('MOOD_UPDATE_USER_NAME');
export const setCommentsCurrentPage = createAction(
  'MOOD_SET_COMMENTS_CURRENT_PAGE',
);
export const setSelectedTower = createAction('MOOD_SET_SELECTED_TOWER');
export const setSelectedPractice = createAction('MOOD_SET_SELECTED_PRACTICE');
export const setSelectedCostCenters = createAction(
  'MOOD_SET_SELECTED_COST_CENTERS',
);
export const removeSelectedCostCenter = createAction(
  'MOOD_REMOVE_SELECTED_COST_CENTER',
);
export const setSelectedTowers = createAction('MOOD_SET_SELECTED_TOWERS');
export const removeSelectedTower = createAction('MOOD_REMOVE_SELECTED_TOWER');

export const setSelectedPractices = createAction('MOOD_SET_SELECTED_PRACTICES');
export const removeSelectedPractices = createAction(
  'MOOD_REMOVE_SELECTED_PRACTICES',
);

export default createReducer(INITIAL_STATE, {
  [addTag.type]: (state, action) => ({
    ...state,
  }),
  [getAllTags.type]: (state, action) => ({
    ...state,
    tags: action.payload,
  }),
  [getMonthlyAverageAction.type]: (state, action) => ({
    ...state,
    monthlyAverage: action.payload,
  }),
  [removeTag.type]: (state, action) => ({
    ...state,
  }),
  [setWeekInterval.type]: (state, action) => ({
    ...state,
    week: action.payload,
  }),
  [getParticipation.type]: (state, action) => ({
    ...state,
    participation: action.payload,
  }),
  [getAllMoods.type]: (state, action) => ({
    ...state,
    moods: action.payload,
  }),
  [getMoodComments.type]: (state, action) => ({
    ...state,
    comments: {
      ...state.comments,
      data: action.payload?.data,
      total: action.payload?.total || 0,
    },
  }),
  [getApprovedTags.type]: (state, action) => ({
    ...state,
    approvedTags: action.payload,
  }),
  [getNotApprovedTags.type]: (state, action) => ({
    ...state,
    notApprovedTags: action.payload,
  }),
  [getApprovedSmileTags.type]: (state, action) => ({
    ...state,
    smileTags: { ...state.smileTags, approved: action.payload },
  }),
  [getNotApprovedSmileTags.type]: (state, action) => ({
    ...state,
    smileTags: { ...state.smileTags, notApproved: action.payload },
  }),
  [getApprovedWeeklyTags.type]: (state, action) => ({
    ...state,
    weeklyTags: { ...state.weeklyTags, approved: action.payload },
  }),
  [getNotApprovedWeeklyTags.type]: (state, action) => ({
    ...state,
    weeklyTags: { ...state.weeklyTags, notApproved: action.payload },
  }),
  [getCsvData.type]: (state, action) => ({
    ...state,
    csvData: action.payload,
  }),
  [clearCsvData.type]: (state, action) => ({
    ...state,
    csvData: null,
  }),
  [setSelectedTower.type]: (state, action) => ({
    ...state,
    selectedTower: action.payload.value,
    selectedTowerName: action.payload.label,
  }),
  [setSelectedPractice.type]: (state, action) => ({
    ...state,
    selectedPractice: action.payload,
  }),
  [setSelectedCostCenters.type]: (state, action) => ({
    ...state,
    selectedCostCenters: action.payload,
  }),
  [removeSelectedCostCenter.type]: (state, action) => ({
    ...state,
    selectedCostCenters: state.selectedCostCenters.filter(
      el => el !== action.payload,
    ),
  }),
  [setSelectedTowers.type]: (state, action) => ({
    ...state,
    selectedTowers: action.payload,
  }),
  [removeSelectedTower.type]: (state, action) => ({
    ...state,
    selectedTowers: state.selectedTowers.filter(el => {
      return el.label !== action.payload;
    }),
  }),
  [setSelectedPractices.type]: (state, action) => ({
    ...state,
    selectedPractices: action.payload,
  }),
  [removeSelectedPractices.type]: (state, action) => ({
    ...state,
    selectedPractices: state.selectedPractices.filter(
      el => el.label !== action.payload,
    ),
  }),
  [setCommentsCurrentPage.type]: (state, action) => ({
    ...state,
    comments: {
      ...state.comments,
      page: action.payload,
    },
  }),
  [updateUserName.type]: (state, action) => ({
    ...state,
    comments: {
      ...state.comments,
      data: state?.comments?.data?.map(mood =>
        mood.userEmail === action.payload.userEmail
          ? { ...mood, userName: action.payload.userName }
          : mood,
      ),
    },
  }),
  [setIsMonthlyAverageTrue.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'isMonthyAverage' : payload]: true,
    };
  },
  [setIsMonthlyAverageFalse.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'isMonthyAverage' : payload]: false,
    };
  },
  [setYearlyTrue.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'isYearly' : payload]: true,
    };
  },
  [setYearlyFalse.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'isYearly' : payload]: false,
    };
  },
});

import { Table, Space, Typography, Empty, Button } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DropdownFilter, ActiveFilters, ErrorHandler } from '~/components';
import { getRole } from '~/config/environment';
import {
  setRoleActiveFilters,
  setSeniorityActiveFilters,
  setTechActiveFilters,
  clearAllFilters,
} from '~/store/ducks/towers';
import InfoButton from './InfoButton';
import './styles.scss';
import { getCapacity } from '~/store/fetchActions/fetchTowers';

const { Text } = Typography;

export default function CapacityTable({ retryProps }) {
  const dispatch = useDispatch();

  const { capacity, selectedTower } = useSelector(state => state.towers);
  const { isLoadingCapacity } = useSelector(state => state.loading);
  const [childrenName, setChildrenName] = useState('team');
  const [clearAll, setClearAll] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  let currentRecord = {};
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    setClearAll(clear => !clear);
    dispatch(clearAllFilters());
  }, [dispatch, selectedTower]);

  const handleClearAll = () => {
    setClearAll(clear => !clear);
    dispatch(clearAllFilters());
  };

  useEffect(() => {
    setChildrenName(
      capacity?.groupingMode === 'projects' ? 'team' : 'projects',
    );
  }, [capacity.groupingMode]);

  useEffect(() => {
    setExpandedRows(
      expandAll && capacity?.data ? capacity?.data?.map(item => item._id) : [],
    );
  }, [capacity.data, expandAll]);

  const columns = [
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {capacity.data && (
            <Button
              data-testid="capacity-expand-all-button"
              className="capacity__button--expand"
              onClick={() => setExpandAll(shouldExpand => !shouldExpand)}
            >
              {expandAll && expandedRows !== [] ? '-' : '+'}
            </Button>
          )}
          Nome
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (text, record, index) => {
        if (record.totalHoursPerGrouping)
          return <b className="dark-grey-text">Total</b>;
        if (record.team || !record.email || getRole() !== 'admin') return text;
        return <Link to={`/soluters/${record.email}`}>{record.name}</Link>;
      },
    },
    {
      title: 'Papel',
      dataIndex: 'role',
      key: 'role',
      width: '20%',
      align: 'center',
      render: (text, record, index) => {
        if (record.totalHoursPerGrouping) return <b>-</b>;
        return text;
      },
      filterDropdown: () => {
        return (
          <DropdownFilter
            filters={capacity.roleFilters}
            handleSubmit={setRoleActiveFilters}
            clearAll={clearAll}
            filterName="papéis"
          />
        );
      },
    },
    {
      title: 'Senioridade',
      dataIndex: 'seniority',
      width: '15%',
      align: 'center',
      key: 'seniority',
      render: (text, record, index) => {
        if (record.projects) return '';
        if (record.totalHoursPerGrouping) return <b>-</b>;
        if (capacity.groupingMode === 'people') return currentRecord.seniority;
        return text || '-';
      },
      filterDropdown: () => {
        return (
          <DropdownFilter
            filters={capacity.seniorityFilters}
            handleSubmit={setSeniorityActiveFilters}
            clearAll={clearAll}
            filterName="senioridades"
          />
        );
      },
    },
    {
      title: 'Tecnologia',
      dataIndex: 'technology',
      width: '20%',
      align: 'center',
      key: 'tech',
      render: (text, record, index) => {
        if (record[childrenName]) return '';
        if (record.technology) return text;
        if (record.totalHoursPerGrouping) return <b>-</b>;
        return '-';
      },
      filterDropdown: () => {
        return (
          <DropdownFilter
            filters={capacity.techFilters}
            handleSubmit={setTechActiveFilters}
            clearAll={clearAll}
            filterName="tecnologias"
          />
        );
      },
    },
    {
      title: 'Horas',
      align: 'center',
      dataIndex: 'totalHours',
      width: '10%',
      key: 'hours',
      render: (text, record, index) => {
        if (record[childrenName])
          return expandedRows.includes(record._id) ? '' : record.totalHours;
        if (record.totalHoursPerGrouping)
          return (
            <b className="dark-grey-text">{`${record.totalHoursPerGrouping}h`}</b>
          );

        return (
          <div>
            {text}h
            <InfoButton
              personId={record.email}
              schedule={record.schedule}
              monthlyHours={
                capacity.groupingMode === 'projects'
                  ? record.monthlyHours
                  : currentRecord.monthlyHours
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Custo Médio',
      align: 'center',
      dataIndex: 'averageCPHPA',
      width: '15%',
      key: 'hours',
      render: (text, record, index) => {
        if (record.averageCPHPA)
          return expandedRows.includes(record.averageCPHPA)
            ? ''
            : `${formatter.format(record.averageCPHPA)}`;
        /* if (record.totalHoursPerGrouping)
          return <b>{`${record.averageCPHPA}`}</b>; */
        return <div> - </div>;
      },
    },
  ];

  return (
    <>
      <ActiveFilters
        filters={[
          ...capacity.roleActiveFilters,
          ...capacity.techActiveFilters,
          ...capacity.seniorityActiveFilters,
        ]}
        handleClear={handleClearAll}
      />
      <Space align="center" style={{ marginBottom: 16 }} />
      <Table
        indentSize={1}
        style={{
          boxShadow: '2px 1px 10px rgb(189, 189, 189)',
        }}
        expandable={{ childrenColumnName: childrenName }}
        loading={isLoadingCapacity}
        columns={columns}
        dataSource={capacity?.data}
        className="custom-scheduler capacity-table"
        rowKey="_id"
        locale={{
          emptyText:
            capacity?.data !== null ? (
              <>
                <Empty
                  description="Nenhum projeto registrado."
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </>
            ) : (
              <ErrorHandler action={getCapacity} props={retryProps} />
            ),
        }}
        onRow={(record, rowIndex) => {
          if (record[childrenName]) {
            currentRecord = {
              seniority: record.seniority,
              monthlyHours: record.monthlyHours,
            };
          }
        }}
        pagination={{
          hideOnSinglePage: true,
        }}
        expandedRowKeys={expandedRows}
        onExpandedRowsChange={_expandedRows => {
          setExpandedRows(_expandedRows);
        }}
        rowClassName={(record, index) => {
          return `custom-scheduler ${
            !record.totalHoursPerGrouping ? '' : 'capacity-table__row--total'
          }`;
        }}
        summary={pageData => {
          let totalHours = 0;
          if (capacity?.data)
            capacity?.data?.forEach(record => {
              if (record.totalHours) totalHours += record.totalHours;
            });

          return (
            <>
              <Table.Summary.Row className="custom-summary" key="totalHours">
                <Table.Summary.Cell>
                  <b className="dark-grey-text">Total de Horas da Torre</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell className="align-center">
                  <Text data-testid="capacity-tower-total-hours">
                    <b className="dark-grey-text">
                      {parseFloat(totalHours, 10).toFixed(2)}h
                    </b>
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell />
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
}

CapacityTable.propTypes = {
  retryProps: PropTypes.array.isRequired,
};

/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { Select, Skeleton, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import { getRole } from '~/config/environment';

export default function SelectMultipleTowers({
  selector,
  dispatchDuck,
  disableEmptyOption,
  emptyOptionText,
  defaultValue,
  onChange,
  style,
  fieldName,
  fieldLabel,
}) {
  const dispatch = useDispatch();
  const { towers } = useSelector(state => state.towers);
  const { isLoadingTowers } = useSelector(state => state.loading);
  const { selectedTower, selectedTowerName } = useSelector(selector);
  const { Option } = Select;

  useEffect(() => {
    dispatch(fetchTowers.listTowers());
  }, [dispatch]);

  useEffect(() => {
    if (disableEmptyOption && towers?.length > 0 && selectedTower === '')
      dispatch(dispatchDuck({ value: towers[0]?._id, label: towers[0]?.name }));
  }, [towers, dispatch, dispatchDuck, selectedTower, disableEmptyOption]);

  const onSearch = value => {
    if (value?.length >= 2) {
      dispatch(fetchTowers.listTowers(value));
    }
  };
  return (
    <Form.Item
      name={fieldName}
      style={{ ...style }}
      label={fieldLabel}
      rules={[
        {
          type: 'array',
          whitespace: true,
        },
      ]}
    >
      {/* {isLoadingTowers && towers?.length === 0 && (
        <Skeleton.Input
          paragraph={{ rows: 0 }}
          style={{ width: 250, margin: 0, padding: 0 }}
          loading
          active
        />
      )} */}
      {towers?.length > 0 && (
        <Select
          placeholder="Selecione as Torres"
          showSearch
          mode="multiple"
          allowClear
          onChange={onChange}
          optionFilterProp="children"
          onSearch={onSearch}
          style={{ width: 250 }}
          labelInValue
          data-testid="select-towers"
        >
          <Option value={towers?.map(tower => tower?._id)}>
            Selecionar Todas as Torres
          </Option>
          {getRole() !== 'admin' && (
            <Option
              value="smallGroups"
              key="smallGroups"
              data-testid="select-towers-option-smallGroups"
            >
              Torres com menos de três membros
            </Option>
          )}
          {towers?.map((tower, idx) => (
            <Option
              value={tower?._id}
              key={tower?._id}
              data-testid={`select-towers-option-${idx}`}
            >
              {tower?.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
}

SelectMultipleTowers.propTypes = {
  selector: PropTypes.func.isRequired,
  dispatchDuck: PropTypes.func.isRequired,
  disableEmptyOption: PropTypes.bool,
  emptyOptionText: PropTypes.string,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string,
  style: PropTypes.object,
};

SelectMultipleTowers.defaultProps = {
  disableEmptyOption: false,
  emptyOptionText: 'Todos os Soluters',
  defaultValue: null,
  fieldLabel: '',
  style: { width: 300 },
  onChange: () => {},
};

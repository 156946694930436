import { Spin, Modal, Typography, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { clearAllocationCsvData } from '~/store/ducks/indicators';
import { formatDate } from '~/helpers/daysOfWeek';
import IndexInfo from './IndexInfo';
import * as fetchIndicators from '~/store/fetchActions/fetchIndicators';
import { DownloadButton } from '~/components';
import { APP_NAME } from '~/constants/app';

const { Title } = Typography;

export default function AllocationIndexHeader() {
  const { isLoadingGraph } = useSelector(state => state.loading);
  const dispatch = useDispatch();

  const { allocationIndexGraph, selectedTower, selectedPractice } = useSelector(
    state => state.indicators,
  );

  const handleCsvDownload = () => {
    Modal.info({
      title: 'Atenção',
      content: (
        <div>
          <p>
            Os dados gerados são relativos apenas aos Soluters que possuem
            alocações cadastradas no {APP_NAME}.
          </p>
          <p>
            Para alocar um Soluter em um projeto, se dirija à tela de{' '}
            <a href="/towers/schedule" target="_blank">
              Planejamento
            </a>
            .
          </p>
        </div>
      ),
      okText: 'Ok',
      onOk() {
        dispatch(
          fetchIndicators.getAllocationCsv(selectedTower, selectedPractice),
        );
      },
    });
  };

  return (
    <Row style={{ marginBottom: 20 }} justify="space-between">
      <Title level={5}>
        Índice de alocação:{' '}
        {!allocationIndexGraph?.index && isLoadingGraph ? (
          <Spin style={{ marginLeft: 8 }} size="small" />
        ) : (
          <IndexInfo allocationIndexGraph={allocationIndexGraph} />
        )}
      </Title>
      <DownloadButton
        exportCsvLayout
        data={allocationIndexGraph?.csvData || null}
        label="Exportar CSV"
        requestData={handleCsvDownload}
        clearData={clearAllocationCsvData}
        fileName={`relatorio_alocação_soluters_${formatDate(new Date())}.csv`}
      />
    </Row>
  );
}

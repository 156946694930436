import { Select, Spin, Form } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import handleSearch from '~/helpers/handleSearch';
import { getByTitle, get } from '~/store/fetchActions/fetchRoles';

const { Option } = Select;

export default function SelectRole({
  defaultValueSoluter,
  selectMode,
  fieldName,
  tagRenderFunc,
  fieldLabel,
  required,
  style,
  testId,
  handleChange,
}) {
  const { roles } = useSelector(state => state.roles);
  const { isLoadingRoles } = useSelector(state => state.loading);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get());
  }, [dispatch]);

  return (
    <Form.Item
      name={fieldName}
      style={style}
      required={required}
      label={fieldLabel}
      initialValue={defaultValueSoluter}
      rules={
        required
          ? [
              {
                type: 'string',
                required: true,
                max: 30,
                message: 'O papel é obrigatório',
                whitespace: true,
              },
            ]
          : []
      }
    >
      <Select
        defaultValue={defaultValueSoluter}
        showSearch
        allowClear
        onChange={handleChange}
        mode={selectMode}
        tagRender={tagRenderFunc}
        placeholder="Buscar Papel (ex.: Dev Frontend)"
        data-testid={testId}
        onSearch={value =>
          handleSearch(
            value,
            getByTitle,
            searchTimeout,
            setSearchTimeout,
            dispatch,
          )
        }
        notFoundContent={isLoadingRoles ? <Spin size="small" /> : null}
      >
        {roles?.map((role, idx) => (
          <Option
            value={role.title}
            key={role.id}
            data-testid={`select-role-option-${idx}`}
          >
            {role.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

SelectRole.propTypes = {
  defaultValueSoluter: PropTypes.string,
  selectMode: PropTypes.string,
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  tagRenderFunc: PropTypes.func,
  style: PropTypes.object,
  required: PropTypes.bool,
  testId: PropTypes.string,
  handleChange: PropTypes.func,
};

SelectRole.defaultProps = {
  defaultValueSoluter: '',
  selectMode: null,
  fieldName: 'role',
  fieldLabel: 'Papel',
  tagRenderFunc: () => {
    /* skip */
  },
  style: { width: '100%' },
  required: true,
  testId: 'select-role',
  handleChange: () => {
    /* skip */
  },
};

import { Progress, Typography, Space, Spin, Button, Alert } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { moods } from '~/constants/';
import { iconsByMood, altTextByMood } from '~/constants/moods';

function MoodProgress({
  progressList,
  title,
  margin,
  isLoading,
  soluterMood,
  handleOpenDrawer,
}) {
  const { Title } = Typography;
  return (
    <>
      <Title level={5} style={{ textAlign: 'center', marginBottom: 25 }}>
        {title}
      </Title>
      <Spin spinning={isLoading || false}>
        <Space direction="vertical" size={margin} style={{ width: '100%' }}>
          {progressList?.map((percentage, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} style={{ display: 'flex' }}>
                {soluterMood ? (
                  <img
                    src={iconsByMood[progressList?.length - index]}
                    alt={altTextByMood[progressList?.length - index]}
                    style={{ width: 30, height: 30 }}
                  />
                ) : (
                  <Button
                    type="link"
                    onClick={() => {
                      handleOpenDrawer(progressList?.length - index);
                    }}
                    icon={
                      <img
                        src={iconsByMood[progressList?.length - index]}
                        alt={altTextByMood[progressList?.length - index]}
                        style={{ width: 30, height: 30 }}
                        data-testid={`mood-emoji-icon-${
                          progressList?.length - index
                        }`}
                      />
                    }
                  />
                )}

                <Progress
                  percent={percentage}
                  status="active"
                  strokeColor={moods.MOOD_COLOR_LIST[index]}
                  index={index}
                  style={{ marginLeft: 10, width: '75%' }}
                />
              </div>
            );
          })}
          {!soluterMood && (
            <Alert
              description="Experimente clicar nos emojis ou nas colunas do gráfico :)"
              type="info"
              closable
            />
          )}
        </Space>
      </Spin>
    </>
  );
}

MoodProgress.propTypes = {
  progressList: PropTypes.array.isRequired,
  title: PropTypes.string,
  margin: PropTypes.number,
  isLoading: PropTypes.bool,
  soluterMood: PropTypes.bool,
  handleOpenDrawer: PropTypes.func,
};

MoodProgress.defaultProps = {
  title: 'Média de Reações',
  margin: 30,
  isLoading: false,
  soluterMood: false,
  handleOpenDrawer: val => val,
};

export default React.memo(MoodProgress, (prevState, nextState) => {
  return (
    prevState.progressList === nextState.progressList &&
    prevState.isLoading === nextState.isLoading
  );
});

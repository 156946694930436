import React, { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import PropTypes from 'prop-types';
import ManageSoluterForm from './ManageSoluterForm';

export default function AddSoluterModal({
  email,
  visible,
  handleClose,
  handleSubmit,
}) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      title="Adicionar Soluter"
      visible={visible}
      onCancel={handleClose}
      data-testid="add-soluter-modal"
      cancelText="Cancelar"
      okText="Adicionar"
      okButtonProps={{
        form: 'add-soluter-form',
        htmlType: 'submit',
        icon: <PlusOutlined />,
      }}
      key="add-soluter-modal"
      confirmLoading={isLoading}
    >
      <ManageSoluterForm
        handleClose={handleClose}
        email={email}
        formId="add-soluter-form"
      />
    </Modal>
  );
}

AddSoluterModal.propTypes = {
  email: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
};

AddSoluterModal.defaultProps = {
  email: undefined,
  handleSubmit: () => {},
};

export const periodTitles = {
  LAST_MONTH: 'Mês Passado',
  MONTH: 'Mês Atual',
  WEEK: 'Última Semana',
};

export const period = {
  lastMonth: 'LAST_MONTH',
  month: 'MONTH',
  week: 'WEEK',
};

export const journeyCategories = {
  'Já estão no norte': 'north',
  'Ajustando a direção': 'near',
  'Distantes do norte': 'far',
  'Não possuem norte registrado': null,
};

export const colorBySeniority = {
  SR: '#1266CA', // '#FF3104',
  PL: '#2998FF', // '#FE6B00',
  JR: '#53E0FF', // '#FFA800',
  Trainee: '#B5F5FE', // '#FACD2F',
  'Não se aplica': 'lightgrey',
  'Sem senioridade': 'lightgrey',
};

export const textColorBySeniority = {
  SR: 'white',
  PL: 'white',
  JR: 'black',
  Trainee: 'black',
  'Não se aplica': 'black',
  'Senioridade não registrada': 'white',
};

export const soluterJourneyColors = {
  'Já estão no norte': '#FDB422',
  'Ajustando a direção': '#FE7819',
  'Distantes do norte': '#FF3104',
};

export const solutersMapColors = {
  one: '#2998FF',
  leq10: 'rgb(181, 241, 255)',
  gt10: 'rgb(133, 222, 243)',
  gt50: 'rgb(98, 182, 243)',
  gt100: '#1677ff',
};

export const WORLD_GEOJSON_URL =
  'https://gw.alipayobjects.com/os/antvdemo/assets/data/world.geo.json';
export const BRAZIL_GEOJSON_URL =
  'https://servicodados.ibge.gov.br/api/v3/malhas/paises/BR?formato=application/vnd.geo+json&qualidade=maxima&intrarregiao=UF';

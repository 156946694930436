import { Typography, Skeleton, Space } from 'antd';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as fetchMood from '~/store/fetchActions/fetchMood';
import { selectMoodTags } from '~/store/selectors/mood';
import { Tag, SimpleAddForm, EmptyErrorWrapper } from '~/components';
import './styles.scss';

const { Title } = Typography;

export default function ManageTags() {
  const tags = useSelector(selectMoodTags);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.loading);

  useEffect(() => {
    dispatch(fetchMood.getTags());
  }, [dispatch]);

  const handleAddButtonPressed = (values, form) => {
    dispatch(fetchMood.addTag(values));
    form.resetFields();
  };

  const handleDelete = name => {
    dispatch(fetchMood.deleteTag(name));
  };

  const subHeader = (
    <>
      <p className="tags__observation">
        Adicione ou remova as tags que poderão ser selecionadas pelos Soluters
        no momento de registro do humor semanal
      </p>
      <p className="tags__observation--smaller">
        Tente ser o mais genérico possível, os aspectos listados poderão ser
        avaliados tanto positivamente quanto negativamente.
      </p>
      <SimpleAddForm
        onFinish={handleAddButtonPressed}
        itemName="tag"
        placeholderMessage="Adicionar Tag"
      />
    </>
  );

  return (
    <>
      <Title>Gerenciar Tags</Title>
      {subHeader}
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 20 }}>
        <Skeleton paragraph={{ rows: 2 }} title={false} loading={isLoading}>
          <EmptyErrorWrapper
            data={tags}
            emptyCondition={tags?.length > 0}
            emptyMessage="Não há tags registradas (ainda)."
            retryAction={fetchMood.getTags}
          >
            <Space size={[8, 16]} wrap>
              {tags &&
                tags?.map(tag => (
                  <Tag
                    isRemovable
                    handleDelete={handleDelete}
                    tag={tag}
                    backgroundColor="#ffbf26"
                    key={tag}
                  />
                ))}
            </Space>
          </EmptyErrorWrapper>
        </Skeleton>
      </div>
    </>
  );
}

import { Select, Spin, Form } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import handleSearch from '~/helpers/handleSearch';
import { getByTitle, get } from '~/store/fetchActions/fetchExperiences';

const { Option } = Select;

export default function SelectExperiences({
  selectMode,
  defaultValue,
  fieldName,
  tagRenderFunc,
  fieldLabel,
  required,
  style,
  disabled,
  testId,
  placeholder,
}) {
  const { experiences } = useSelector(state => state.experiences);
  const { isLoadingExperiences } = useSelector(state => state.loading);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get(1));
  }, [dispatch]);
  // Função para lidar com a mudança no elemento select
  const handleSelectChange = value => {
    // Verifique se o item já está no array antes de adicioná-lo
    if (!selectedItems.includes(value)) {
      setSelectedItems([...selectedItems, value]);
    }
  };
  return (
    <Form.Item
      name={fieldName}
      style={{ ...style }}
      required={required}
      label={fieldLabel}
      initialValue={defaultValue}
      rules={
        required
          ? [
              {
                type: selectMode === 'multiple' ? 'array' : 'string',
                required,
                max: 30,
                message: 'A tecnologia é obrigatória',
                whitespace: true,
              },
            ]
          : []
      }
      key="select-experience-field"
    >
      <Select
        onChange={handleSelectChange}
        disabled={disabled}
        showSearch
        allowClear
        defaultValue={defaultValue}
        mode={selectMode}
        tagRender={tagRenderFunc}
        data-testid={testId}
        placeholder={placeholder}
        onSearch={value =>
          handleSearch(
            value,
            getByTitle,
            searchTimeout,
            setSearchTimeout,
            dispatch,
          )
        }
        notFoundContent={isLoadingExperiences ? <Spin size="small" /> : null}
      >
        {experiences?.map(experience => (
          <Option
            key={experience.id}
            value={experience.title}
            data-testid={`cost-center-option-${experience.id}`}
          >
            {experience.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

SelectExperiences.propTypes = {
  selectMode: PropTypes.string,
  defaultValue: PropTypes.array,
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  tagRenderFunc: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

SelectExperiences.defaultProps = {
  selectMode: null,
  defaultValue: null,
  fieldName: 'experience',
  fieldLabel: 'Experiências',
  placeholder: 'Buscar tecnologia (ex.: Android)',
  tagRenderFunc: () => {
    /* skip */
  },
  style: { width: '100%' },
  required: false,
  disabled: false,
  testId: 'select-experience-input',
};

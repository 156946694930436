import './styles.scss';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';

export default function DeleteBadge({ active, size, additionalClassNames }) {
  return (
    <div className="container-delete">
      <DeleteOutlined
        className={`soluter-profile__delete ${additionalClassNames}`}
        style={{
          width: size,
          height: size,
          fontSize: Math.ceil(size / 2) + 10,
        }}
      />
      <p>Remover</p>
    </div>
  );
}

DeleteBadge.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
  additionalClassNames: PropTypes.string,
};

DeleteBadge.defaultProps = {
  active: false,
  size: 40,
  additionalClassNames: '',
};

import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  experiences: [],
  experience: {},
  total: 0,
  page: 1,
  selectedExperience: -1,
  search: false,
  searchTitle: '',
};

export const add = createAction('EXPERIENCES_ADD');
export const get = createAction('EXPERIENCES_GET');
export const selectEdit = createAction('EXPERIENCES_SELECT_EDIT');
export const edit = createAction('EXPERIENCES_EDIT');
export const remove = createAction('EXPERIENCES_REMOVE');
export const search = createAction('EXPERIENCES_SEARCH');
export const setExperiences = createAction('SET_EXPERIENCES');

export default createReducer(INITIAL_STATE, {
  [add.type]: (state, action) => ({
    ...state,
    experience: { title: action.payload },
  }),
  [get.type]: (state, action) => ({
    ...state,
    experiences: action.payload.experiences,
    total: action.payload.total,
    page: action.payload.page,
  }),
  [selectEdit.type]: (state, action) => ({
    ...state,
    selectedExperience: action.payload,
  }),
  [edit.type]: (state, action) => ({
    ...state,
    experience: { title: action.payload },
  }),
  [remove.type]: state => ({ ...state }),
  [search.type]: (state, action) => ({
    ...state,
    search: action.payload.search,
    searchTitle: action.payload.searchTitle,
  }),
});

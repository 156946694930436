import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './styles.css';
import PropTypes from 'prop-types';

export default function FormButton({ link, type, text, htmlType, isLoading }) {
  return (
    <>
      {link ? (
        <Link to={link}>
          <Button type={type} className="form-button" htmlType={htmlType}>
            {text}
          </Button>
        </Link>
      ) : (
        <Button
          type={type}
          className="form-button"
          htmlType={htmlType}
          loading={isLoading}
        >
          {text}
        </Button>
      )}
    </>
  );
}

FormButton.propTypes = {
  link: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  htmlType: PropTypes.string,
  isLoading: PropTypes.bool,
};

FormButton.defaultProps = {
  link: null,
  type: 'primary',
  htmlType: 'button',
  isLoading: false,
};

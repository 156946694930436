import { List } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { EmptyErrorWrapper, Button, GenericCard } from '~/components';
import {
  getPractices,
  deletePractice,
} from '~/store/fetchActions/fetchPractices';
import './styles.scss';

export default function PracticesList({
  handleOpenDetailsModal,
  handleOpenEditModal,
}) {
  const { practices } = useSelector(state => state.practices);
  const dispatch = useDispatch();

  const handleDelete = practiceId => {
    dispatch(deletePractice(practiceId));
  };

  const cardActions = practice => {
    return [
      <Button
        type="view"
        isButton
        tooltipIdentifier="BU"
        handleClick={() => {
          handleOpenDetailsModal(practice);
        }}
        buttonStyle={{ fontSize: 16, height: 22 }}
      />,
      <Button
        type="edit"
        isButton
        tooltipIdentifier="BU"
        handleClick={() => {
          handleOpenEditModal(practice);
        }}
        buttonStyle={{ fontSize: 16, height: 22 }}
      />,
      <Button
        type="delete"
        id={practice?.id}
        tooltipIdentifier="BU"
        handleDelete={handleDelete}
        title={practice?.name}
      />,
    ];
  };

  return (
    <EmptyErrorWrapper
      data={practices}
      emptyCondition={practices?.length > 0}
      style={{ minHeight: '200px' }}
      retryAction={getPractices}
      emptyMessage="Não há BUs registradas."
      loaderTitle="isLoadingPractices"
    >
      <List
        grid={{
          column: 3,
          sm: 1,
          md: 2,
        }}
        pagination={{
          position: 'bottom',
          pageSize: 6,
          hideOnSinglePage: true,
        }}
        dataSource={practices}
        renderItem={practice => {
          return (
            <List.Item>
              <GenericCard
                item={practice}
                displayPhoto={false}
                disabledDelete={false}
                customActions={cardActions}
                handleDelete={() => {}}
              />
            </List.Item>
          );
        }}
      />
    </EmptyErrorWrapper>
  );
}

PracticesList.propTypes = {
  handleOpenDetailsModal: PropTypes.func.isRequired,
  handleOpenEditModal: PropTypes.func.isRequired,
};

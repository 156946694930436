import React from 'react';
import Column from '@ant-design/charts/lib/column';
import PropTypes from 'prop-types';
import { moods } from '~/constants/';
import { descriptionByMood } from '~/constants/moods';

function ColumnGraph({
  data,
  setIsWeeklyDrawerOpen,
  setWeekDay,
  setStartDay,
  setEndDay,
}) {
  const { innerWidth: width, innerHeight: height } = window;

  const config = {
    data: data?.periodicMoods || [],
    xField: 'startDate',
    yField: 'votes',
    seriesField: 'mood',
    isPercent: true,
    isStack: true,
    columnStyle: {
      cursor: 'pointer',
    },
    label: {
      position: 'middle',
      content: function content(item) {
        return item.votes > 0 ? `${(item.votes * 100).toFixed(2)}%` : '';
      },
      style: { fill: 'black', fontSize: width > 1366 ? 12 : 10 },
    },
    tooltip: {
      formatter: item => {
        const moodVotesPerDate = (startDate, mood) => {
          const filteredMood = data?.periodicMoods?.filter(
            _mood => _mood.startDate === startDate && _mood.mood === mood,
          );

          return filteredMood ? filteredMood[0].votes : '';
        };

        const countTotal = () => {
          const arrayToSum = data?.periodicMoods?.filter(
            _item => _item.startDate === item.startDate,
          );
          let total = 0;
          arrayToSum?.forEach(_item => {
            total += _item.votes;
          });
          return total;
        };

        return {
          title: `Total de votos: ${countTotal()}`,
          name: descriptionByMood[item.mood],
          value: `${(item.votes * 100).toFixed(2)}% (${moodVotesPerDate(
            item.startDate,
            item.mood,
          )} votos)`,
        };
      },
    },
    statistic: {
      style: {
        fontSize: 8,
      },
    },
    meta: {
      mood: {
        formatter: value => {
          return descriptionByMood[value];
        },
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(item) {
          return `${item * 100}`;
        },
      },
    },
    color: ({ mood }) => {
      return mood ? moods.MOOD_COLOR_OBJ[mood] : 'grey';
    },
  };

  return (
    <Column
      {...config}
      onEvent={(chart, event) => {
        if (event.type === 'click' && event.data) {
          setIsWeeklyDrawerOpen(true);
          setWeekDay(event.data.data.startDate);
          setStartDay(event.data.data.startDate);
          setEndDay(event.data.data.endDate);
        }
      }}
    />
  );
}

ColumnGraph.propTypes = {
  data: PropTypes.object.isRequired,
  setIsWeeklyDrawerOpen: PropTypes.func.isRequired,
  setWeekDay: PropTypes.func.isRequired,
  setStartDay: PropTypes.func.isRequired,
  setEndDay: PropTypes.func.isRequired,
};

export default React.memo(ColumnGraph);

import { Typography, Tabs } from 'antd';
import { SkillsList, SkillsSuggestions } from '~/container';

const { Title } = Typography;
const { TabPane } = Tabs;

export default function Skills() {
  return (
    <>
      <Title>Habilidades</Title>
      <div className="card-container">
        <Tabs defaultActiveKey="1" className="graph-card">
          <TabPane tab="Lista" key={1}>
            <SkillsList />
          </TabPane>
          <TabPane tab="Sugestões" key={2}>
            <SkillsSuggestions />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Spin, Typography, Row, Divider } from 'antd';
import { PropTypes } from 'prop-types';
import {
  BankOutlined,
  CalendarOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import compass from '~/assets/images/compass.png';
import SoluterGroups from './SoluterGroups';
import { PaginatedSteps, EmptyErrorWrapper } from '~/components';
import {
  getSoluterTimeline,
  getSoluterHistory,
  getSoluterJourney,
} from '~/store/fetchActions/fetchPeople';
import './styles.scss';

const { Title } = Typography;

export default function SoluterJourney({ email }) {
  const dispatch = useDispatch();
  const { isLoadingSoluterJourney } = useSelector(state => state.loading);
  const { careerPath, distance, history, timeline } = useSelector(
    state => state.people.soluter,
  );

  const toDDMMYYYY = date => {
    return date.split('-').reverse().join('-');
  };

  useEffect(() => {
    dispatch(getSoluterTimeline(email));
    dispatch(getSoluterHistory(email));
    dispatch(getSoluterJourney(email));
  }, [dispatch, email]);

  const soluterCompass = (
    <Spin spinning={isLoadingSoluterJourney} size="small">
      <>
        <p style={{ textAlign: 'center' }}>
          {careerPath ? careerPath[0]?.title : 'Não registrado.'}
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className={`soluter-profile__compass ${
              careerPath ? '' : 'soluter-profile__compass--disabled'
            }`}
          >
            <img
              style={{
                transform: `rotateZ(${careerPath ? distance : '0'}deg)`,
              }}
              src={compass}
              alt="bussola"
              data-testid="soluter-compass"
            />
          </div>
        </div>
      </>
    </Spin>
  );

  return (
    <>
      <Row>
        <Col span={20}>
          <Title level={5}>Cargos</Title>
          <EmptyErrorWrapper
            data={history || []}
            emptyCondition={history?.length > 0}
            emptyMessage="Este Soluter não possui cargos registrados."
            loaderTitle="isLoadingHistory"
          >
            <PaginatedSteps
              items={history}
              titleAttribute="title"
              descriptionAttributes={['date']}
              formatDescription={description =>
                toDDMMYYYY(description.substring(0, 10))
              }
            />
          </EmptyErrorWrapper>
        </Col>
        <Col span={4} className="soluter-profile__journey">
          <Title level={5}>Objetivo</Title>
          {soluterCompass}
        </Col>
      </Row>
      <Divider />
      <Title level={5}>Projetos</Title>
      <EmptyErrorWrapper
        data={timeline || []}
        emptyCondition={timeline?.length > 0}
        loaderTitle="isLoadingTimeline"
      >
        <PaginatedSteps
          items={timeline}
          titleAttribute="name"
          descriptionAttributes={[
            'startDate',
            'endDate',
            'mainRole',
            'technology',
          ]}
          formatDescription={(startDate, endDate, mainRole, technology) => {
            return (
              <div>
                <div className="centered-div mt-sm">
                  {startDate && <CalendarOutlined className="mr-xs" />}
                  <p className="mb-0">
                    {startDate ? `${startDate} - ${endDate}` : '-'}
                  </p>
                </div>
                <div className="centered-div mt-xs">
                  <ThunderboltOutlined className="mr-xs" />
                  <p className="mb-0">
                    {mainRole}
                    {technology &&
                      technology !== 'Não se aplica' &&
                      ` • ${technology}`}
                  </p>
                </div>
              </div>
            );
          }}
          subtitleAttribute="groupName"
          formatSubtitle={subtitle => (
            <div className="mt-xs">
              <BankOutlined /> {subtitle}
            </div>
          )}
          showSubtitle
          isStepCurrentFn={item => {
            return item.isActive;
          }}
        />
      </EmptyErrorWrapper>
      <Divider />
      <Title level={5}>Atualmente</Title>
      <SoluterGroups email={email} />
    </>
  );
}

SoluterJourney.propTypes = {
  email: PropTypes.string.isRequired,
};

/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { Form, Button, DatePicker } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { SelectTowers } from '~/components';
import { setSelectedTower } from '~/store/ducks/towers';
import '../../Towers/Scheduler/styles.scss';
import AddProjectModalProject from './AddProjectModalProject';

export default function HeaderAddProject({
  selectedTower,
  reload,
  year,
  setYear,
  soluter, // Alterado de PropTypes.func para PropTypes.object
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const currentYear = new Date().getFullYear();
  // eslint-disable-next-line

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="tower-schedule-header">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DatePicker
          onChange={value => {
            const _year = value ? moment(value).format('YYYY') : '';
            setYear(_year);
          }}
          value={moment(year)}
          allowClear={false}
          allowEmpty={false}
          data-testid="date-picker"
          placeholder="Selecionar ano"
          defaultValue={moment(currentYear.toString())}
          picker="year"
          style={{ marginLeft: 10 }}
          inputReadOnly
        />
      </div>
      <Button
        type="primary"
        onClick={showModal}
        icon={<PlusOutlined />}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
        data-testid="add-people-project-button"
      >
        Adicionar Projeto
      </Button>
      <AddProjectModalProject
        isVisible={isModalVisible}
        setVisible={setIsModalVisible}
        towerId={selectedTower}
        soluter={soluter}
        reload={reload}
      />
    </div>
  );
}

HeaderAddProject.propTypes = {
  selectedTower: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  setYear: PropTypes.func.isRequired,
  soluter: PropTypes.object.isRequired, // Alterado para PropTypes.object
};

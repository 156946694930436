import React from 'react';
import { Typography } from 'antd';
import { BusinessUnitsTable } from '~/container';

const { Title } = Typography;

export default function ManageJourney() {
  return (
    <>
      <Title data-testid="journey-page-title">Famílias e Cargos</Title>
      <BusinessUnitsTable />
    </>
  );
}

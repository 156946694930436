import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row } from 'antd';
import DualListBox from 'react-dual-listbox';
import PropTypes from 'prop-types';
import { getCostCentersNoGroup } from '~/store/fetchActions/fetchPractices';
import { addTower } from '~/store/fetchActions/fetchTowers';

export default function TowerCostCenter({ tower }) {
  const { isLoadingTower } = useSelector(state => state.loading);
  const { costCentersNoGroup } = useSelector(state => state.practices);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(getCostCentersNoGroup());
    setOptions([]);
  }, [dispatch]);

  useEffect(() => {
    setOptions([
      ...options,
      {
        options: costCentersNoGroup.map(cc => ({
          value: cc.id,
          label: `${cc.id} - ${cc.title}`,
        })),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costCentersNoGroup]);

  useEffect(() => {
    const listSelected = [];
    const costCentersSelected = [
      {
        options: tower?.costCenters?.map(cc => ({
          value: cc.id,
          label: `${cc.id} - ${cc.title}`,
        })),
      },
    ];
    setOptions([...options, { options: costCentersSelected[0]?.options }]);
    costCentersSelected[0]?.options?.map(opt => listSelected.push(opt.value));
    setSelected(listSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOptions]);

  const handleSubmit = () => {
    tower = {
      ...tower,
      costCenters: selected,
      BUs: tower.BUs.map(bu => bu._id),
    };
    dispatch(addTower(tower, false, true));
  };

  return (
    <div style={{ marginTop: 20 }}>
      <p className="no-margin soluter-name">Disponíveis</p>

      <DualListBox
        options={options}
        selected={selected}
        loading={isLoadingTower}
        onChange={value => setSelected(value)}
        iconsClass="native"
      />
      <Row style={{ marginTop: 20, justifyContent: 'right' }}>
        <Button
          type="primary"
          data-testid="tower-schedule-button"
          onClick={() => handleSubmit()}
        >
          Salvar
        </Button>
      </Row>
    </div>
  );
}

TowerCostCenter.propTypes = {
  tower: PropTypes.object.isRequired,
};

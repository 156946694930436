import locale from 'antd/lib/date-picker/locale/pt_BR';
import moment from 'moment';
import {
  Button,
  Form,
  Modal,
  Select,
  Input,
  DatePicker,
  Row,
  Col,
  message,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import { validationMessages } from '~/constants';
import { TagRender, SelectSkills } from '~/components';
import { getRole } from '~/config/environment';
import { handleCurrencyInput, formatCurrency } from '~/helpers/handleCurrency';

moment.locale('pt-BR');
const { TextArea } = Input;

export default function AddProjectForm({
  tower,
  towerId,
  project,
  button,
  year,
}) {
  const [isVisible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      startDate: project ? moment(project.startDate) : moment(),
      endDate: project ? moment(project.endDate) : moment(),
      averageCPH: project ? formatCurrency(project.averageCPH) : '0.00',
    });
  }, [form, project, isVisible]);

  const handleCostInput = e => {
    const re = /^[0-9\b]+$/;
    const key = e.key;
    let value = form.getFieldValue('averageCPH') || '';
    e.preventDefault();
    if (re.test(key) || key === 'Backspace') {
      value = handleCurrencyInput(key, value.toString());
    }

    form.setFieldsValue({
      averageCPH: value,
    });
  };

  const handleSubmit = values => {
    if (
      moment(values.startDate).startOf('day') >
      moment(values.endDate).startOf('day')
    ) {
      message.warn('A data de início não pode ser maior do que a data final');
      return;
    }
    values.startDate = values.startDate.format('DD-MM-YYYY');
    values.endDate = values.endDate.format('DD-MM-YYYY');
    let _values = { ...values, groupId: towerId };
    _values = project ? { ..._values, id: project._id } : _values;
    dispatch(fetchTowers.addProject(_values, year));
    if (project) form.setFieldsValue({ name: values.name });
    else {
      form.resetFields(['name', 'description', 'technology']);
      form.setFieldsValue({
        startDate: moment(),
        endDate: moment(),
      });
    }
    setVisible(false);
  };

  return (
    <>
      {project ? (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation();
            setVisible(true);
          }}
        >
          {button}
        </Button>
      ) : (
        <Button
          type="primary"
          shape="round"
          data-testid="add-new-project-button"
          onClick={() => setVisible(true)}
        >
          Adicionar Projeto
        </Button>
      )}
      <Modal
        title={project ? 'Editar Projeto' : 'Adicionar Projeto'}
        visible={isVisible}
        onCancel={() => setVisible(false)}
        okText={project ? 'Salvar' : 'Adicionar'}
        cancelText="Cancelar"
        okButtonProps={{
          'data-testid': 'add-project-form-submit-button',
        }}
        onOk={() => {
          form
            .validateFields()
            .then(values => handleSubmit(values))
            // eslint-disable-next-line no-console
            .catch(console.error);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark
          onFinish={handleSubmit}
          validateMessages={validationMessages.VALIDATION_MESSAGE}
        >
          <Form.Item name="group" label="Torre">
            <Select defaultValue={tower?.name} disabled />
          </Form.Item>
          <Form.Item
            name="name"
            required
            label="Nome"
            initialValue={project ? project.name : ''}
            rules={[
              {
                max: 30,
                required: true,
                message:
                  'O nome do projeto é obrigatório e deve ter menos de 30 caracteres',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="project-name-input" />
          </Form.Item>
          <Form.Item
            name="description"
            required
            label="Descrição"
            initialValue={project ? project.description : ''}
            rules={[
              {
                required: true,
                message:
                  'A descrição do projeto é obrigatória e deve ter menos de 240 caracteres',
                whitespace: true,
                max: 240,
              },
            ]}
          >
            <TextArea data-testid="project-description-input" />
          </Form.Item>
          <SelectSkills
            defaultValue={project ? project.technology : []}
            fieldName="technology"
            fieldLabel="Tecnologias"
            required={false}
            selectMode="multiple"
            tagRenderFunc={props => TagRender(props)}
          />
          <Row>
            <Col span={8}>
              <Form.Item
                initialValue={project ? project.averageCPH : '0.00'}
                name="averageCPH"
                label="Custo médio (R$/hora)"
              >
                <Input
                  style={{ width: '90%' }}
                  data-testid="project-cost-input"
                  prefix="R$"
                  onChange={e => {
                    if (e.target.value === '') {
                      form.setFieldsValue({ averageCPH: '0.00' });
                    }
                  }}
                  onKeyDown={handleCostInput}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="startDate" label="Início do Projeto">
                <DatePicker
                  data-testid="project-start-date-picker"
                  locale={locale}
                  format="DD/MM/YYYY"
                  style={{ marginRight: 10 }}
                  allowClear={false}
                  inputReadOnly
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="endDate" label="Fim do Projeto">
                <DatePicker
                  data-testid="project-end-date-picker"
                  locale={locale}
                  format="DD/MM/YYYY"
                  style={{ marginRight: 10 }}
                  allowClear={false}
                  inputReadOnly
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

AddProjectForm.propTypes = {
  tower: PropTypes.object.isRequired,
  project: PropTypes.object,
  button: PropTypes.any,
  year: PropTypes.string,
  towerId: PropTypes.string,
};

AddProjectForm.defaultProps = {
  project: null,
  button: 'Adicionar Projeto',
  year: '',
  towerId: '',
};

import { Drawer, List, Empty, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { EmptyErrorWrapper, SoluterName } from '~/components';
import {
  getSoluterJourneyList,
  getSolutersWithoutJourneyList,
} from '~/store/fetchActions/fetchIndicators';
import { journeyCategories } from '~/constants/indicators';
import compass from '~/assets/images/compass.png';

export default function SoluterJourneyDrawer({
  isDrawerVisible,
  setIsDrawerVisible,
}) {
  const { solutersList, solutersListAmount, selectedCategory } = useSelector(
    state => state.indicators.soluterJourney,
  );
  const { selectedTower } = useSelector(state => state.indicators);
  const [page, setPage] = useState(1);

  const status = journeyCategories[selectedCategory];

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDrawerVisible) setPage(1);
  }, [isDrawerVisible]);

  return (
    <Drawer
      title={selectedCategory}
      width="42vw"
      placement="right"
      closable={false}
      onClose={() => {
        setIsDrawerVisible(false);
      }}
      visible={isDrawerVisible}
      data-testid="soluter-journey-drawer"
    >
      <EmptyErrorWrapper
        data={solutersList}
        emptyCondition={solutersList}
        loaderTitle="isLoadingSoluterJourneyList"
        retryAction={
          status ? getSoluterJourneyList : getSolutersWithoutJourneyList
        }
        retryProps={status ? [selectedTower, status] : [selectedTower]}
        style={{ fontSize: 'x-small' }}
      >
        <List
          size="large"
          dataSource={solutersList || []}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Não há Soluters nesta categoria."
              />
            ),
          }}
          pagination={{
            current: page,
            pageSize: 8,
            pageSizeOptions: [],
            showSizeChanger: false,
            total: solutersListAmount,
            hideOnSinglePage: true,
            onChange: _page => {
              setPage(_page);
              dispatch(
                status
                  ? getSoluterJourneyList(selectedTower, status, _page, 8)
                  : getSolutersWithoutJourneyList(selectedTower, _page, 8),
              );
            },
          }}
          renderItem={soluter => (
            <List.Item>
              <Row
                style={{ width: '100%' }}
                justify="space-between"
                align="middle"
              >
                <Col span={11}>
                  <SoluterName name={soluter.name} email={soluter.email} />
                </Col>
                {status && (
                  <Col span={10}>
                    <p className="no-margin">Norte: {soluter.north}</p>
                  </Col>
                )}
                <Col span={2}>
                  <div
                    className={`soluter-profile__compass ${
                      status ? '' : 'soluter-profile__compass--disabled'
                    }`}
                    style={{ width: 34, height: 34 }}
                  >
                    <img
                      style={{
                        width: 30,
                        transform: `rotateZ(${soluter.distance || 0}deg)`,
                      }}
                      src={compass}
                      alt="bussola"
                    />
                  </div>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </EmptyErrorWrapper>
    </Drawer>
  );
}

SoluterJourneyDrawer.propTypes = {
  isDrawerVisible: PropTypes.bool.isRequired,
  setIsDrawerVisible: PropTypes.func.isRequired,
};
